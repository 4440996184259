import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState  } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setEserciziIndex, setEserciziShow } from './esercizi.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { Esercizi } from './esercizi.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getEserciziIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getEserciziIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Esercizi?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setEserciziIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getEserciziIndex'));
    }
}

export const getEserciziShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getEserciziShow'));

    try {
        const response = await AxiosAPI.get(`/Esercizi/${id}`)
        dispatch(setEserciziShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getEserciziShow'));
    }
}

export const postEsercizi = createAsyncThunk<
    // Return type of the payload creator
    Esercizi,
    // First argument to the payload creator
    void,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postEsercizi', async (_, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postEsercizi'));

        try {
            const response = await AxiosAPI.post(`/Esercizi`)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as Esercizi
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postEsercizi'));
        }
    })

    export const putEsercizi = (esercizi: Esercizi): AppThunk => async dispatch => {

        dispatch(setRequestLoader('putEsercizi'));
    
        try {
            const { codEsercizio } = esercizi
    
            await AxiosAPI.put(`/Esercizi/${codEsercizio}`, esercizi)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
    
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
        } finally {
            dispatch(removeRequestLoader('putEsercizi'));
        }
    }

    export const deleteEsercizi = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    Esercizi,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteEsercizi', async (esercizi: Esercizi, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteEsercizi'));

        try {
            const { codEsercizio } = esercizi

            await AxiosAPI.delete(`/Esercizi/${codEsercizio}`)

            const currentState = getState().esercizi.index;
            const data = currentState.data.filter((item) => item.codEsercizio !== codEsercizio)
            dispatch(setEserciziIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteEsercizi'));
        }
    })
