import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalCapiZona } from "@store/global/global.selector";
import { getGlobalCapiZona } from "@store/global/global.effects";

export function SelectCapiZona(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const capiZona = useAppSelector(globalCapiZona);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalCapiZona())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:areaManager`)}
            data={capiZona}
            descriptionKey={'nominativo'}
            valueKey={'codCapoZona'}
            {...props}
        />
    );
}
