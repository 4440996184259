import { AxiosAPI } from '@store/axios.config';
import { AppThunk, ResourcesIndexThunk } from '../store'
import {
    setConvenzioniDatiAggiuntiviIndex, setConvenzioniDatiAggiuntiviShow
} from './convenzioniDatiAggiuntivi.actions'
import environment from '@environment/environment'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { Filters, SortModel } from '@store/common/common.types';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { ConvenzioniDatiAggiuntiviPost } from './convenzioniDatiAggiuntivi.types';
import i18n from '@assets/i18n';




export const getConvenzioniDatiAggiuntiviIndex: ResourcesIndexThunk = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getConvenzioniDatiAggiuntiviIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/ConvenzioniDatiAggiuntivi?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setConvenzioniDatiAggiuntiviIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getConvenzioniDatiAggiuntiviIndex'));
    }
}

export const getConvenzioniDatiAggiuntiviShow = (progconvenzione: number, progressivo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getConvenzioniDatiAggiuntiviShow'));

    try {
        const response = await AxiosAPI.get(`/ConvenzioniDatiAggiuntivi/${progconvenzione}/${progressivo}`)
        dispatch(setConvenzioniDatiAggiuntiviShow(response.data.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getConvenzioniDatiAggiuntiviShow'));
    }
}
// post ancora da fare

export const postConvenzioniDatiAggiuntiviAssocia = (convenzioniDatiAggiunt: ConvenzioniDatiAggiuntiviPost): AppThunk => async dispatch => {

    dispatch(setRequestLoader('convenzioniDatiAggiunt'));

    try {
        await AxiosAPI.post(`/ConvenzioniDatiAggiuntivi/Associa/`, convenzioniDatiAggiunt)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('convenzioniDatiAggiunt'));
    }
}



