import { createReducer } from '@reduxjs/toolkit'
import {
    setScontoAddizionaleIndex,
    setScontoAddizionaleShow,
    resetScontoAddizionaleShow
} from './scontoAddizionale.actions'
import { ScontoAddizionaleIndex, ScontoAddizionaleShow } from './scontoAddizionale.type'

const initialState: {
    index: ScontoAddizionaleIndex,
    show: ScontoAddizionaleShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const scontoAddizionaleReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setScontoAddizionaleIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setScontoAddizionaleShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetScontoAddizionaleShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

