import { AxiosAPI } from '@store/axios.config';
import { AppThunk } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setIndCaricoTipoArtIndex, setIndCaricoTipoArtShow } from '@store/ind-carico-tipo-art/indCaricoTipoArt.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import i18n from '@assets/i18n';
import { getIndiciDiCaricoShow } from '@store/indici-di-carico/indiciDiCarico.effects';
import { setIndiciDiCaricoShow } from '@store/indici-di-carico/indiciDiCarico.actions';


export const getIndiciDiCaricoTipoArtIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getIndiciDiCaricoTipoArtIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/IndCaricoTipoArt?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setIndCaricoTipoArtIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getIndiciDiCaricoTipoArtIndex'));
    }
}


export const getIndiciDiCaricoTipoArtShow = (codIndiceCarico: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getIndiciDiCaricoTipoArtShow'));

    try {
        const response = await AxiosAPI.get(`/IndCaricoTipoArt/${codIndiceCarico}/${codTipoArticolo}`)
        dispatch(setIndCaricoTipoArtShow(response.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getIndiciDiCaricoTipoArtShow'));
    }
}


export const postIndiciDiCaricoTipoArt = (codIndiceCarico: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('postIndiciDiCaricoTipoArt'));

    try {

        await AxiosAPI.post(`/IndCaricoTipoArt`, {
            codIndiceCarico,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        dispatch(getIndiciDiCaricoShow(`${codIndiceCarico}`))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('postIndiciDiCaricoTipoArt'));
        return true
    }
}


export const putIndiciDiCaricoTipoArt = (codIndiceCarico: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putIndiciDiCaricoTipoArt'));

    try {
        await AxiosAPI.put(`/IndCaricoTipoArt/${codIndiceCarico}/${codTipoArticolo}`, {
            codIndiceCarico,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putIndiciDiCaricoTipoArt'));
    }
}

export const deleteIndiciDiCaricoTipoArt = (codIndiceCarico: number, codTipoArticolo: number): AppThunk => async (dispatch, getState) => {

    dispatch(setRequestLoader('deleteIndiciDiCaricoTipoArt'));

    try {

        await AxiosAPI.delete(`/IndCaricoTipoArt/${codIndiceCarico}/${codTipoArticolo}`)

        const currentState = (getState()).indiciDiCarico.show;
        if (currentState.data) {
            const data = currentState.data.indCaricoTipoArt.filter((item) => item.codTipoArticolo !== codTipoArticolo)
            dispatch(setIndiciDiCaricoShow({
                ...currentState,
                data: {
                    ...currentState.data,
                    indCaricoTipoArt: data
                }
            }))
        }

        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('deleteIndiciDiCaricoTipoArt'));
    }
}