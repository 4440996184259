import { createReducer } from '@reduxjs/toolkit'
import {
    setCausaliIvaIndex,
    setCausaliIvaShow,
    resetCausaliIvaShow,
    setTipiCausaliIvaIndex
} from './causaliIva.actions'
import { CausaliIvaIndex, CausaliIvaShow } from './causaliIva.type'

const initialState: {
    index: CausaliIvaIndex,
    show: CausaliIvaShow,
    tipiCausaleIva: string[]
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined },
    tipiCausaleIva: []
}
 
export const causaliIvaReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCausaliIvaIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setCausaliIvaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetCausaliIvaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
        .addCase(setTipiCausaliIvaIndex, (state, action) => {
            return {
                ...state,
                tipiCausaleIva: action.payload
            }
        })
})

