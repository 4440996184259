import { createReducer } from '@reduxjs/toolkit'
import {

    setConvenzioniFlotteShow,
} from './convenzioniFlotte.actions'
import { ConvenzioniFlotteeIndex, ConvenzioniFlotteeShow } from './convenzioniFlotte.types'
//import { ConvenzioniFlotteIndex, ConvenzioniFlotteShow } from './convenzioniFlotte.types';

const initialState: {
    index: ConvenzioniFlotteeIndex,
    show: ConvenzioniFlotteeShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}


export const convenzioniFlotteReducer = createReducer(initialState, (builder) => {
    builder
        //TODO add index
        .addCase(setConvenzioniFlotteShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
})
