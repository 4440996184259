import { createReducer } from '@reduxjs/toolkit'
import { Pagination } from '@store/common/common.types'
import { Allestimento } from './veicoli.types'
import { setAllestimentiIndex } from './veicoli.actions'



const allestimenti: {
    index: {
        data: Allestimento[]
        meta: {
            pagination?: Pagination
        } 
    }
} = {
    index: {
        data: [],
        meta: {}
    }
}

export const allestimentiReducer = createReducer(allestimenti, (builder) => {
    builder
        .addCase(setAllestimentiIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
})