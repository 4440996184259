import i18n from "@assets/i18n"
import { FieldFilter, FilterMethods, FilterTypes } from "@store/common/common.types"

export function getDefaultArticlesFilter(defaultCodDivisione: string = '1', fuoriProd: string = ''): { [key: string]: FieldFilter } {

    enum FILTER_ID {
        DIVISIONE = 'filter-codDivisione',
        CODICE_ARTICOLO = 'filter-codArticolo',
        CATEGORIA_MERCEOLOGICA = 'filter-codCategoriaMerceologica',
        TIPO_ARTICOLO = 'filter-codTipoArticolo',
        STAGIONALITA = 'filter-codStagionalita',
        PRODUTTORE = 'filter-codProduttore',
        DISEGNO = 'filter-codDisegno',
        LARGHEZZA_NOMINALE = 'filter-codLarghezzaNominale',
        SERIE = 'filter-codSerie',
        LARGHEZZA_CERCHIO = 'filter-codLarghezzaCerchio',
        INDICE_DI_CARICO = 'filter-codIndiceCarico1',
        CODICE_DI_VELOCITA = 'filter-codVelocita',
        RUN_ON_FLAT = 'filter-runOnFlat',
        FOUR_SEASONS = 'filter-fourSeasons',
        FLG_FUORI_PROD = 'filter-fuori-prod',
        ULTIMA_DATA_LISTINO = 'filter-ultima-data-listino',
        LIVELLO_LISTINO = 'filter-livelloListino'
    }

    return {
        divisione: new FieldFilter(FILTER_ID.DIVISIONE, i18n.t(`common:divisione`), FilterTypes.AND, 'codDivisione', FilterMethods.EQUAL, defaultCodDivisione, false, true),
        codArticolo: new FieldFilter(FILTER_ID.CODICE_ARTICOLO, i18n.t(`common:codArticolo`), FilterTypes.AND, 'codArticolo', FilterMethods.EQUAL, ''),
        categoriaMerceologica: new FieldFilter(FILTER_ID.CATEGORIA_MERCEOLOGICA, i18n.t(`common:categoriaMerceologica`), FilterTypes.AND, 'codCategoriaMerc', FilterMethods.EQUAL, ''),
        tipoArticolo: new FieldFilter(FILTER_ID.TIPO_ARTICOLO, i18n.t(`common:tipoArticolo`), FilterTypes.AND, 'codTipoArticolo', FilterMethods.EQUAL, ''),
        stagionalita: new FieldFilter(FILTER_ID.STAGIONALITA, i18n.t(`common:stagionalita`), FilterTypes.AND, 'codStagionalita', FilterMethods.EQUAL, ''),
        produttore: new FieldFilter(FILTER_ID.PRODUTTORE, i18n.t(`common:produttore`), FilterTypes.AND, 'codProduttore', FilterMethods.EQUAL, ''),
        disegno: new FieldFilter(FILTER_ID.DISEGNO, i18n.t(`common:disegno`), FilterTypes.AND, 'codDisegno', FilterMethods.EQUAL, ''),
        larghezzaNominale: new FieldFilter(FILTER_ID.LARGHEZZA_NOMINALE, i18n.t(`common:larghezzaNominale`), FilterTypes.AND, 'codLarghezzaNominale', FilterMethods.EQUAL, ''),
        serie: new FieldFilter(FILTER_ID.SERIE, i18n.t(`common:serie`), FilterTypes.AND, 'codSerie', FilterMethods.EQUAL, ''),
        larghezzaCerchio: new FieldFilter(FILTER_ID.LARGHEZZA_CERCHIO, i18n.t(`common:cerchio`), FilterTypes.AND, 'codLarghezzaCerchio', FilterMethods.EQUAL, ''),
        indiceDiCarico: new FieldFilter(FILTER_ID.INDICE_DI_CARICO, i18n.t(`common:indiceCarico`), FilterTypes.AND, 'codIndiceCarico1', FilterMethods.EQUAL, ''),
        codiceVelocita: new FieldFilter(FILTER_ID.CODICE_DI_VELOCITA, i18n.t(`common:codiceVelocita`), FilterTypes.AND, 'codVelocita', FilterMethods.EQUAL, ''),
        runOnFlat: new FieldFilter(FILTER_ID.RUN_ON_FLAT, i18n.t(`common:rof`), FilterTypes.AND, 'flgRunOnFlat', FilterMethods.EQUAL, ''),
        fourSeasons: new FieldFilter(FILTER_ID.FOUR_SEASONS, i18n.t(`common:4Seasons`), FilterTypes.AND, 'flg4Seasons', FilterMethods.EQUAL, ''),
        fuoriProd: new FieldFilter(FILTER_ID.FLG_FUORI_PROD, i18n.t(`common:flgfuoriProd`), FilterTypes.AND, 'flgInEstinzione', FilterMethods.EQUAL, fuoriProd),
        ultimaDataListino: new FieldFilter(FILTER_ID.ULTIMA_DATA_LISTINO, i18n.t(`common:ultimaDataListino`), FilterTypes.AND, 'ultimaDataListino', FilterMethods.LESSTHAN, ''),
        livelloListino: new FieldFilter(FILTER_ID.LIVELLO_LISTINO, i18n.t(`common:livelloListino`), FilterTypes.AND, 'livelloListino', FilterMethods.EQUAL, '')
    }
}