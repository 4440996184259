import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState  } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setScontoAddizionaleIndex, setScontoAddizionaleShow } from './scontoAddizionale.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { NewScontoAddizionale, ScontoAddizionale } from './scontoAddizionale.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalScontoAddizionale } from '@store/global/global.effects';


export const getScontoAddizionaleIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getScontoAddizionaleIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/ScontoAddizionale?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setScontoAddizionaleIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getScontoAddizionaleIndex'));
    }
}

export const getScontoAddizionaleShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getScontoAddizionaleShow'));

    try {
        const response = await AxiosAPI.get(`/ScontoAddizionale/${id}`)
        dispatch(setScontoAddizionaleShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getScontoAddizionaleShow'));
    }
}

export const postScontoAddizionale = createAsyncThunk<
    // Return type of the payload creator
    ScontoAddizionale,
    // First argument to the payload creator
    NewScontoAddizionale,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postScontoAddizionale', async (scontoAddizionale: NewScontoAddizionale,  { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postScontoAddizionale'));

        try {
            const response = await AxiosAPI.post(`/ScontoAddizionale`,scontoAddizionale)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalScontoAddizionale(true))
            return response.data as ScontoAddizionale
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postScontoAddizionale'));
        }
    })

    export const putScontoAddizionale = (scontoAddizionale: ScontoAddizionale): AppThunk => async dispatch => {

        dispatch(setRequestLoader('putScontoAddizionale'));
    
        try {
            const { id } = scontoAddizionale
    
            await AxiosAPI.put(`/ScontoAddizionale/${id}`, scontoAddizionale)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
    
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
        } finally {
            dispatch(removeRequestLoader('putScontoAddizionale'));
        }
    }

    export const deleteScontoAddizionale = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    ScontoAddizionale,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteScontoAddizionale', async (scontoAddizionale: ScontoAddizionale, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteScontoAddizionale'));

        try {
            const { id } = scontoAddizionale

            await AxiosAPI.delete(`/ScontoAddizionale/${id}`)

            const currentState = getState().scontoAddizionale.index;
            const data = currentState.data.filter((item) => item.id !== id)
            dispatch(setScontoAddizionaleIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteScontoAddizionale'));
        }
    })
