import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { CustomPaginatedDataGrid, FiltersAccordion, CustomHeaderPage, CommonSpeedDial } from '@components/index'
import { GridColDef } from '@material-ui/data-grid';
import { Box, TextField } from '@material-ui/core';
import { FieldFilter, FilterMethods, FilterTypes } from '@store/common/common.types';
import { useIndexPage } from '@pages/common/UseIndexPage';
import { Allestimento } from '@store/veicoli/veicoli.types';
import { getAllestimentiIndex } from '@store/veicoli/veicoli.effects';
import { allestimentiIndex } from '@store/veicoli/veicoli.selector';
import { SelectMarche, SelectModelli } from '@components/selects';
import environment from '@environment/environment';

enum FILTER_ID {
    FILTER_OR = 'filter-or-multi',
    COD_MARCA = 'filter-marca',
    COD_MODELLO = 'filter-modello',
}

export function AllestimentiIndex() {

    const { t } = useTranslation(['allestimentiIndexPage', 'common'])

    const initialFilter: { [key: string]: FieldFilter } = {
        multi: new FieldFilter(FILTER_ID.FILTER_OR, t(`allestimentiIndexPage:filter:filterOrMulti`), FilterTypes.OR, 'descrizione', '', ''),
        marca: new FieldFilter(FILTER_ID.COD_MARCA, t(`allestimentiIndexPage:filter:filterMarca`), FilterTypes.AND, 'codMarca', FilterMethods.EQUAL, ''),
        modello: new FieldFilter(FILTER_ID.COD_MODELLO, t(`allestimentiIndexPage:filter:selectMarca`), FilterTypes.AND, 'codModello', FilterMethods.EQUAL, ''),
    }

    const {data, filters, setFilters, setPage, setPageSize, sortColumns, setSortColumns, handleSelectChangeValue, handleTextFieldChangeValue} = useIndexPage<Allestimento>(initialFilter, [{ field: 'descrizione', sort: 'asc' }], getAllestimentiIndex, allestimentiIndex);

    useEffect(() => {
        let label = t(`allestimentiIndexPage:filter:selectMarca`);
        if (filters.filterFields.marca.value) {
            label = t(`allestimentiIndexPage:filter:filterModello`);
        }
        setFilters(prevState => {
            return {
                ...prevState,
                filterFields: {
                    ...prevState.filterFields,
                    modello: {
                        ...prevState.filterFields.modello,
                        label: label
                    }
                }
            }
        })
        // filters.filterFields.modello.label = label;
    }, [filters.filterFields.marca.value]) // eslint-disable-line react-hooks/exhaustive-deps

    const columns: GridColDef[] = [
        {
            field: 'descrizione',
            headerName: t('common:descrizione'),
            flex: 1,
            disableClickEventBubbling: true,
            valueGetter: (params) => params.row.descrizione
        },
        {
            field: 'marca',
            headerName: t('common:marca'),
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => params.row.marca?.descrizione
        },
        {
            field: 'modello',
            headerName: t('common:modello'),
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => params.row.modello?.descrizione
        }
    ];

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <CustomHeaderPage
                        title={t(`allestimentiIndexPage:title`)}
                        disabledGoBack
                    />
                </Grid>
                <Grid item xs={12}>
                    <FiltersAccordion
                        multiSearchFilter={
                            <TextField
                                fullWidth
                                size='small'
                                id={FILTER_ID.FILTER_OR}
                                value={filters.filterFields.multi.value}
                                onChange={(event) => handleTextFieldChangeValue(event, filters.filterFields.multi.id)}
                                label={filters.filterFields.multi.label}
                            />
                        }
                        searchFilters={
                            <Box display='flex' flexDirection='row' flexWrap='wrap' padding='1rem' >
                                <Box className='px-2 w-full 2xl:w-2/12 xl:w-5/12 md:w-6/12'>
                                    <SelectMarche
                                        id={FILTER_ID.COD_MARCA}
                                        label={filters.filterFields.marca.label}
                                        onChange={handleSelectChangeValue}
                                        value={filters.filterFields.marca.value}
                                        autocomplete
                                    />
                                </Box>
                                <Box className='px-2 w-full 2xl:w-2/12 xl:w-5/12 md:w-6/12'>
                                    <SelectModelli
                                        id={FILTER_ID.COD_MODELLO}
                                        label={filters.filterFields.modello.label}
                                        onChange={handleSelectChangeValue}
                                        value={filters.filterFields.modello.value}
                                        codMarca={filters.filterFields.marca.value}
                                        disabled={filters.filterFields.marca.value === ''}
                                    />
                                </Box>
                            </Box>
                        }
                        filters={filters}
                        setFilters={setFilters}
                        setPage={setPage}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper >
                        <CustomPaginatedDataGrid
                            data={data}
                            columns={columns}
                            getRowId={(row) => { return row.codMarca + row.codModello + row.codAllestimento }}
                            sortColumns={sortColumns}
                            onChangePageSize={setPageSize}
                            onChangeSortColumns={setSortColumns}
                            onChangePage={setPage}
                        />
                    </Paper>
                </Grid>
            </Grid >
            <CommonSpeedDial
                updateData={[
                    {
                        taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
                        entities: [
                            environment.semaphoreJobsData.anagraficheUpdates.entities.allestimenti,
                            environment.semaphoreJobsData.anagraficheUpdates.entities.marcheAuto,
                            environment.semaphoreJobsData.anagraficheUpdates.entities.modelliAuto
                        ]
                    },
                    {
                        taskName: environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
                        entities:  [
                            environment.semaphoreJobsData.centralDatabaseUpdates.entities.modelli
                        ]
                    },
                    {
                        taskName: environment.semaphoreJobsData.areaRiservataUpdates.taskName,
                        entities:  [
                            environment.semaphoreJobsData.areaRiservataUpdates.entities.allestimenti,
                            environment.semaphoreJobsData.areaRiservataUpdates.entities.allestimentiOmologazioni,
                            environment.semaphoreJobsData.areaRiservataUpdates.entities.modelliAuto,
                            environment.semaphoreJobsData.areaRiservataUpdates.entities.marcheAuto,
                            environment.semaphoreJobsData.areaRiservataUpdates.entities.gruppiMarche,
                            environment.semaphoreJobsData.areaRiservataUpdates.entities.omologazioni
                        ]
                    }
                ]}
            />
        </div >
    );
}