import { useAppDispatch, useAppSelector } from "@store/hooks";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Button, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { CodiciVelocita } from "@store/codici-velocita/codiciVelocita.types";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { codiciVelocitaShow } from "@store/codici-velocita/codiciVelocita.selector";
import { Grid } from "@material-ui/core";
import {
  deleteCodiciVelocitaTipoArt,
  postCodiciVelocitaTipoArt,
} from "@store/cod-velocita-tipo-art/codVelocitaTipoArt.effects";
import { CodVelocitaTipoArt } from "@store/cod-velocita-tipo-art/codVelocitaTipoArt.types";
import { SelectTipiArticolo } from "@components/selects";
import Auth from "@utilities/auth";

export function AssociazioneTipoArticolo() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "codiceVelocitaDetailPage"]);

  const pollingCodiciVelocita = useAppSelector(codiciVelocitaShow);
  const [codiceVelocita, setCodiceVerifica] = useState<
    CodiciVelocita | undefined
  >(undefined);
  const [tipoArticoloSearch, setTipoArticoloSearch] = useState(0);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingCodiciVelocita) {
      setCodiceVerifica(pollingCodiciVelocita);
    }
  }, [pollingCodiciVelocita]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (codiceVelocita && tipoArticoloSearch > 0) {
      dispatch(
        postCodiciVelocitaTipoArt(
          codiceVelocita.codVelocita,
          tipoArticoloSearch
        )
      );
      setTipoArticoloSearch(0);
    }
  };

  const handlerTipoArticoloSearch = (value: any, id: string) => {
    setTipoArticoloSearch(value);
  };

  const columns: GridColDef[] = [
    {
      field: "codTipoArticolo",
      headerName: `${t("common:tipiArticolo")}`,
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      valueGetter: (params) => {
        const row = params.row as typeof params.row & CodVelocitaTipoArt;
        return row?.tipiArticolo?.descrizione || "";
      },
    },
  ];

  if (Auth.hasAccess("save-codvelocita")) {
    columns.push({
      field: "remove",
      headerName: `${t("common:remove")}`,
      headerAlign: "center",
      align: "center",
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const codVelocitaTipoArt = params.row as typeof params.row &
            CodVelocitaTipoArt;
          const { codVelocita, codTipoArticolo } = codVelocitaTipoArt;

          dispatch(deleteCodiciVelocitaTipoArt(codVelocita, codTipoArticolo));
        };

        return (
          <IconButton
            onClick={onClick}
            style={{
              border: "none",
              outline: "none",
            }}
            edge="start"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    });
  }

  return (
    <Paper>
      <Box className="flex flex-col flex-grow p-4">
        <form onSubmit={handleSubmit}>
          {Auth.hasAccess("save-codvelocita") ? (
            <Box className="flex flex-wrap flex-col lg:flex-row">
              <SelectTipiArticolo
                id={"tipiArticolo"}
                onChange={handlerTipoArticoloSearch}
                value={tipoArticoloSearch}
                codDivisione="1"
                autocomplete={true}
                style={{ minWidth: "15rem" }}
              />
              <Button
                type="submit"
                className="self-center rounded-full h-10 w-10 min-w-0 mt-2 ml-8"
                style={{
                  border: "none",
                  outline: "none",
                }}
                color="primary"
              >
                <AddIcon />
              </Button>
            </Box>
          ) : null}
        </form>
        <Grid container>
          <DataGrid
            rows={codiceVelocita?.codVelocitaTipoArt || []}
            autoHeight
            columns={columns}
            getRowId={(row) => {
              return `${row.codVelocita}${row.codTipoArticolo}`;
            }}
            disableColumnFilter
            hideFooter
          />
        </Grid>
      </Box>
    </Paper>
  );
}
