import { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { logManagerIndex } from "@store/log-manager/logManager.selector";
import {
  getLogManagerIndex,
  getRelatedLogs,
} from "@store/log-manager/logManager.effects";
import routes from "@utilities/routes";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
} from "@components/index";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import moment from "moment";
import {
  LogManager,
  LogManagerTypes,
} from "@store/log-manager/logManager.type";
import {
  AspectRatio,
  Assignment,
  CalendarToday,
  Description,
  Error,
  Info,
  Message,
  Person,
  Warning,
} from "@material-ui/icons";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { CustomSelect } from "@components/selects/custom-select/CustomSelect";
import { SelectServers } from "@components/selects";
import { useAppDispatch } from "@store/hooks";
import Auth from "@utilities/auth";

export function LogManagerIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["logManagerIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    level: new FieldFilter(
      "level",
      t(`logManagerIndexPage:filter:filterlevel`),
      FilterTypes.AND,
      "level",
      FilterMethods.EQUAL,
      ""
    ),
    user: new FieldFilter(
      "user",
      t(`common:user`),
      FilterTypes.AND,
      "user",
      FilterMethods.CONTAINS,
      ""
    ),
    server: new FieldFilter(
      "server",
      t(`common:server`),
      FilterTypes.AND,
      "serverId",
      FilterMethods.EQUAL,
      ""
    ),
    source: new FieldFilter(
      "source",
      t(`common:source`),
      FilterTypes.AND,
      "source",
      FilterMethods.CONTAINS,
      ""
    ),
    message: new FieldFilter(
      "message",
      t(`common:messagemanager`),
      FilterTypes.AND,
      "message",
      FilterMethods.CONTAINS,
      ""
    ),
    event: new FieldFilter(
      "event",
      t(`common:eventmanager`),
      FilterTypes.AND,
      "event",
      FilterMethods.CONTAINS,
      ""
    ),
    timestamp: new FieldFilter(
      "timestamp",
      t(`logManagerIndexPage:filter:filtertimestamp`),
      FilterTypes.AND,
      "timestamp",
      FilterMethods.GREATERTHANOREQUALTO,
      ``
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<LogManager>(
    initialFilter,
    [{ field: "timestamp", sort: "desc" }],
    getLogManagerIndex,
    logManagerIndex
  );

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedLog, setSelectedLog] = useState<LogManager>({} as LogManager);
  const [relatedLogs, setRelatedLogs] = useState<{
    [key: string]: LogManager[];
  }>({});

  const getColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [
      {
        field: "level",
        headerName: t("common:level"),
        width: 120,
        disableClickEventBubbling: true,
        cellClassName: (params: GridCellParams) =>
          clsx({
            blue: params.value === "Info",
            red: params.value === "Error",
            yellow: params.value === "Warning",
          }),
        valueGetter: (params) => params.row.level,
      },
      {
        field: "user",
        headerName: t("common:user"),
        width: 140,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <Tooltip color="primary" title={params.row.user}>
            {(params.row.user as string).toUpperCase() === "SYSTEM" ||
            !params.row.relatedUser ? (
              <span>{params.row.user}</span>
            ) : Auth.hasAccess("view-users") ? (
              <a
                style={{ textDecoration: "underline" }}
                href={routes.users.show.replace(
                  ":id",
                  params.row.relatedUser.id
                )}
                target="_blank"
                rel="noreferrer"
              >
                {params.row.user}
              </a>
            ) : (
              <span>{params.row.user}</span>
            )}
          </Tooltip>
        ),
      },
      {
        field: "server",
        headerName: t("common:server"),
        width: 140,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <Tooltip
            color="primary"
            title={`${params.row.relatedServer?.name}${
              params.row.relatedServer?.extraData
                ? "-" + params.row.relatedServer?.extraData
                : ""
            }`}
          >
            <span>
              {params.row.relatedServer?.name}-
              {params.row.relatedServer?.extraData}
            </span>
          </Tooltip>
        ),
      },
      {
        field: "source",
        headerName: t("common:source"),
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <Tooltip color="primary" title={params.row.source}>
            <span>{params.row.source}</span>
          </Tooltip>
        ),
      },
      {
        field: "message",
        headerName: t("common:messagemanager"),
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <Tooltip color="primary" title={params.row.message}>
            <span>{params.row.message}</span>
          </Tooltip>
        ),
      },
      {
        field: "event",
        headerName: t("common:eventmanager"),
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <Tooltip color="primary" title={params.row.event}>
            <span>{params.row.event}</span>
          </Tooltip>
        ),
      },
      {
        field: "timestamp",
        headerName: t("common:dataOperazione"),
        width: 200,
        disableClickEventBubbling: true,
        valueGetter: (params) => {
          return moment(params.row.timestamp).format("DD/MM/yyyy HH:mm:ss");
        },
      },
      {
        field: "expand",
        headerName: t("common:expand"),
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton
              style={{
                border: "none",
                outline: "none",
              }}
              color="inherit"
              size="small"
              onClick={async () => {
                const log = params.row as LogManager;
                setSelectedLog(log);
                setDialogOpen(true);
                if (log.correlationId) {
                  let selectedLogRelatedLogs = relatedLogs[log.correlationId];
                  if (!(log.correlationId in relatedLogs)) {
                    const res: any = await dispatch(
                      getRelatedLogs(log.correlationId)
                    );
                    if (res && res.payload && res.payload.data) {
                      selectedLogRelatedLogs = res.payload.data;
                    } else {
                      selectedLogRelatedLogs = [];
                    }
                    setRelatedLogs((prevState) => {
                      return {
                        ...prevState,
                        [log.correlationId]: selectedLogRelatedLogs,
                      };
                    });
                  }
                  setSelectedLog((prevLog) => {
                    return {
                      ...prevLog,
                      relatedLogs: selectedLogRelatedLogs,
                    };
                  });
                }
              }}
            >
              <AspectRatio />
            </IconButton>
          );
        },
      },
    ];
    return columns;
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`logManagerIndexPage:title`)}
            disabledGoBack
          />
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            forceFirstUpdate
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <CustomSelect
                    id={filters.filterFields.level.id}
                    value={filters.filterFields.level.value}
                    onChange={handleSelectChangeValue}
                    label={filters.filterFields.level.label}
                    data={Object.values(LogManagerTypes).map((value) => {
                      return {
                        id: value,
                        description: value,
                      };
                    })}
                    valueKey="id"
                    descriptionKey="description"
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={filters.filterFields.user.id}
                    value={filters.filterFields.user.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.user.id
                      )
                    }
                    label={filters.filterFields.user.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectServers
                    id={filters.filterFields.server.id}
                    value={filters.filterFields.server.value}
                    onChange={handleSelectChangeValue}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={filters.filterFields.source.id}
                    value={filters.filterFields.source.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.source.id
                      )
                    }
                    label={filters.filterFields.source.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={filters.filterFields.event.id}
                    value={filters.filterFields.event.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.event.id
                      )
                    }
                    label={filters.filterFields.event.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    type="Date"
                    fullWidth
                    size="small"
                    id={filters.filterFields.timestamp.id}
                    InputLabelProps={{ shrink: true }}
                    value={filters.filterFields.timestamp.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.timestamp.id
                      )
                    }
                    label={filters.filterFields.timestamp.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-4/12 xl:w-6/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={filters.filterFields.message.id}
                    value={filters.filterFields.message.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.message.id
                      )
                    }
                    label={filters.filterFields.message.label}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={getColumns()}
              getRowId={(row) => {
                return row.id;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        fullScreen
        className="p-10"
      >
        <DialogTitle>{t("common:logDetail")}</DialogTitle>
        <div className="p-5">
          <List>
            <ListItem>
              <ListItemIcon>
                {selectedLog.level === "Info" ? (
                  <Info style={{ color: "#2196f3" }} />
                ) : selectedLog.level === "Warning" ? (
                  <Warning style={{ color: "#f9a825" }} />
                ) : (
                  <Error style={{ color: "red" }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={t("common:level")}
                secondary={selectedLog.level}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Person style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:user")}
                secondary={selectedLog.user}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Assignment style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:source")}
                secondary={selectedLog.source}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Message style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:messagemanager")}
                secondary={selectedLog.message}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Description style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:eventmanager")}
                secondary={selectedLog.event}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <CalendarToday style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:dataOperazione")}
                secondary={moment(selectedLog.timestamp).format(
                  "DD/MM/yyyy HH:mm:ss"
                )}
              />
            </ListItem>
          </List>
          {selectedLog.relatedLogs && selectedLog.relatedLogs.length > 1 ? (
            <div className="px-2">
              <Typography className="py-5" component="h3" variant="h6">
                {t("common:relatedLogs")}
              </Typography>
              <DataGrid
                autoHeight
                hideFooter
                rows={selectedLog.relatedLogs}
                columns={getColumns()}
                getRowClassName={(params) =>
                  params.row.id === selectedLog.id ? "highlighted-row" : ""
                }
              />
            </div>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
}
