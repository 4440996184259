import { RootState } from '../store'

export const globalCapiZona = (state: RootState) => state.global.capiZona;
export const globalCategorieMerceologice = (state: RootState) => state.global.categorieMerceologiche;
export const globalCausaliIva = (state: RootState) => state.global.causaliIva;
export const globalCodiciVelocita = (state: RootState) => state.global.codiciVelocita;
export const globalConvenzioniGeneriche = (state: RootState) => state.global.convenzioniGeneriche;
export const globalDisegni = (state: RootState) => state.global.disegni;
export const globalDistributori = (state: RootState) => state.global.distributori;
export const globalDivisioni = (state: RootState) => state.global.divisioni;
export const globalEntiConvenzionati = (state: RootState) => state.global.entiConvenzionati;
export const globalIndiciDiCarico = (state: RootState) => state.global.indiciDiCarico;
export const globalLarghezzeCerchi = (state: RootState) => state.global.larghezzeCerchi;
export const globalLarghezzeNominali = (state: RootState) => state.global.larghezzeNominali;
export const globalLivelliListino = (state: RootState) => state.global.livelliListino;
export const globalMarche = (state: RootState) => state.global.marche;
export const globalModelli = (state: RootState) => state.global.modelli;
export const globalMonete = (state: RootState) => state.global.monete;
export const globalPagamenti = (state: RootState) => state.global.pagamenti;
export const globalProduttori = (state: RootState) => state.global.produttori;
export const globalProgrammi = (state: RootState) => state.global.programmi;
export const globalProvince = (state: RootState) => state.global.province;
export const globalNazioni = (state: RootState) => state.global.nazioni;
export const globalRegioni = (state: RootState) => state.global.regioni;
export const globalReti = (state: RootState) => state.global.reti;
export const globalRuoli = (state: RootState) => state.global.role;
export const globalSemaphoresTasks = (state: RootState) => state.global.semaphoresTasks;
export const globalSerieGomme = (state: RootState) => state.global.serieGomme;
export const globalServers = (state: RootState) => state.global.servers;
export const globalStagionalita = (state: RootState) => state.global.stagionalita;
export const globalStrutture = (state: RootState) => state.global.strutture;
export const globalTasks = (state: RootState) => state.global.tasks;
export const globalTipiArticolo = (state: RootState) => state.global.tipiArticolo;
export const globalTipiCausaliIva = (state: RootState) => state.global.tipiCausaliIva;
export const globalTipiContrattoFlotte = (state: RootState) => state.global.tipiContrattoFlotte;
export const globalTipiDocumento = (state: RootState) => state.global.tipiDocumento;
export const globalUnitaDiMisura = (state: RootState) => state.global.unitaDiMisura;
export const globalScontoAddizionale = (state: RootState) => state.global.scontoAddizionale;