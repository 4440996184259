import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalTasks } from "@store/global/global.selector";
import { getGlobalTasks } from "@store/global/global.effects";

export function SelectTasks(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const tasks = useAppSelector(globalTasks);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalTasks())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:task`)}
            customDescription={(task) => `${task.name} (${task.version})`}
            data={tasks}
            descriptionKey={'name'}
            valueKey={'id'}
            {...props}
        />
    );
}
