import { createReducer } from '@reduxjs/toolkit'
import {
    setTipoContantiIndex,
    setTipoContantiShow,
    resetTipoContantiShow
} from './tipoContanti.actions'
import { TipoContantiIndex, TipoContantiShow } from './tipoContanti.type'

const initialState: {
    index: TipoContantiIndex,
    show: TipoContantiShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const tipoContantiReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setTipoContantiIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setTipoContantiShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetTipoContantiShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

