import { AxiosAPI } from '@store/axios.config';
import { AppThunk, ResourcesIndexThunk } from '../store'
import environment from '@environment/environment'

import { setError } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { Filters, SortModel } from '@store/common/common.types';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import i18n from '@assets/i18n';
import { setAllestimentiIndex } from './veicoli.actions';


export const getAllestimentiIndex: ResourcesIndexThunk = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getAllestimentiIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Allestimenti?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setAllestimentiIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getAllestimentiIndex'));
    }
}

