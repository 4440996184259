import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setLarghezzeNominaliIndex, setLarghezzeNominaliShow } from './larghezzeNominali.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { LarghezzaNominale, NewLarghezzaNominale } from './larghezzeNominali.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalLarghezzeNominali } from '@store/global/global.effects';


export const getLarghezzeNominaliIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getLarghezzeNominaliIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/LarghezzeNominali?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setLarghezzeNominaliIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLarghezzeNominaliIndex'));
    }
}


export const getLarghezzeNominaliShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getLarghezzeNominaliShow'));

    try {
        const response = await AxiosAPI.get(`/LarghezzeNominali/${id}`)
        dispatch(setLarghezzeNominaliShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLarghezzeNominaliShow'));
    }
}


export const postLarghezzeNominali = createAsyncThunk<
    // Return type of the payload creator
    LarghezzaNominale,
    // First argument to the payload creator
    NewLarghezzaNominale,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postLarghezzeNomina', async (larghezzaNominale: NewLarghezzaNominale, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postLarghezzeNominali'));

        try {
            const response = await AxiosAPI.post(`/LarghezzeNominali`, larghezzaNominale)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalLarghezzeNominali(true))
            return response.data as LarghezzaNominale
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postLarghezzeNominali'));
        }
    })


export const putLarghezzeNominali = (larghezzaNominale: LarghezzaNominale): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putLarghezzeNominali'));

    try {
        const { codLarghezzaNominale } = larghezzaNominale

        await AxiosAPI.put(`/LarghezzeNominali/${codLarghezzaNominale}`, larghezzaNominale)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalLarghezzeNominali(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putLarghezzeNominali'));
    }
}


export const deleteLarghezzeNominali = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    LarghezzaNominale,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteLarghezzeNominali', async (larghezzaNominale: LarghezzaNominale, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteLarghezzeNominali'));

        try {
            const { codLarghezzaNominale } = larghezzaNominale

            await AxiosAPI.delete(`/LarghezzeNominali/${codLarghezzaNominale}`)

            const currentState = getState().larghezzeNominali.index;
            const data = currentState.data.filter((item) => item.codLarghezzaNominale !== codLarghezzaNominale)
            dispatch(setLarghezzeNominaliIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalLarghezzeNominali(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteLarghezzeNominali'));
        }
    })
