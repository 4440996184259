import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalDivisioni } from "@store/global/global.selector";
import { getGlobalDivisioni } from "@store/global/global.effects";

export function SelectDivisioni(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const divisioni = useAppSelector(globalDivisioni);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalDivisioni())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:divisione`)}
            data={divisioni}
            descriptionKey={'descrizione'}
            valueKey={'codDivisione'}
            {...props}
        />
    );
}
