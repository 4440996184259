import { DataGrid, GridEditCellPropsParams } from "@material-ui/data-grid";
import { Listino } from "@store/listini/listini.types";
import { LivelloListino } from "@store/livello-listino/livelloListino.types";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface DataGridListiniPneumaticoProps {
    livelliListino: LivelloListino[]
    onChangeListino: (id: number, field: string, value: any) => void
    listini: Listino[] | undefined
}

export function DataGridListiniPneumatico(props: DataGridListiniPneumaticoProps) {

    const { livelliListino, onChangeListino, listini } = props;
    const { t } = useTranslation(['common'])
        
    return (
        <DataGrid
            className="mb-4 mx-4"
            rows={livelliListino}
            getRowId={(row) => { return row.codLivelloListino + 1}}
            hideFooter
            autoHeight
            onEditCellChangeCommitted={({ id, field, props }: GridEditCellPropsParams, event?: any) => {
                onChangeListino((id as number - 1), field, props.value);
            }}
            columns={[
                {
                    field: 'descrizione',
                    headerName: t('common:livelloListino'),
                    flex: 1,
                    disableColumnMenu: true,
                    disableClickEventBubbling: true,
                    sortable: false,
                    hideSortIcons: true,
                },
                {
                    field: 'dataInizioValidita',
                    headerName: t('common:dataInizioValidita'),
                    flex: 1,
                    disableColumnMenu: true,
                    disableClickEventBubbling: true,
                    sortable: false,
                    hideSortIcons: true,
                    type: 'date',
                    editable: true,
                    valueGetter: (params) => {
                        const dataInizio = listini?.find((listino: Listino) => listino.livelloListino === params.row.codLivelloListino)?.dataInizioValidita;
                        let date = new Date()
                        if (dataInizio) {
                            date = new Date(dataInizio)
                        }
                        return moment(date).format("DD/MM/YYYY");
                    }
                },
                {
                    field: 'prezzoEuro',
                    headerName: t('common:prezzoEuro'),
                    flex: 1,
                    disableColumnMenu: true,
                    disableClickEventBubbling: true,
                    sortable: false,
                    hideSortIcons: true,
                    type: 'number',
                    editable: true,
                    valueGetter: (params) => {
                        return listini?.find((listino: Listino) => listino.livelloListino === params.row.codLivelloListino)?.prezzoEuro || 0
                    }
                },
                {
                    field: 'prezzoEuroConIVA',
                    headerName: t('common:prezzoEuroConIVA'),
                    flex: 1,
                    disableColumnMenu: true,
                    disableClickEventBubbling: true,
                    sortable: false,
                    hideSortIcons: true,
                    type: 'number',
                    valueGetter: (params) => {
                        return listini?.find((listino: Listino) => listino.livelloListino === params.row.codLivelloListino)?.prezzoEuroConIVA || 0
                    }
                }
            ]}
        />
    )
}