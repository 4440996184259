import { createReducer } from '@reduxjs/toolkit'
import {
    setLarghCerchiTipoArtIndex,
    setLarghCerchiTipoArtShow,
} from './larghCerchiTipoArt.actions'
import { LarghCerchiTipoArtIndex, LarghCerchiTipoArtShow } from './larghCerchiTipoArt.types'

const initialState: {
    index: LarghCerchiTipoArtIndex
    show: LarghCerchiTipoArtShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const larghCerchiTipoArtReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLarghCerchiTipoArtIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setLarghCerchiTipoArtShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
})

