import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getDesConvenzioniGenericheShow,
  postDesConvenzioniGeneriche,
  putDesConvenzioniGeneriche,
} from "@store/des-convenzioni-generiche/desConvenzioniGeneriche.effects";
import {
  DesConvenzioniGeneriche,
  NewDesConvenzioniGeneriche,
} from "@store/des-convenzioni-generiche/desConvenzioniGeneriche.types";
import { desConvenzioniGenericheShow } from "@store/des-convenzioni-generiche/desConvenzioniGeneriche.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetDesConvenzioniGenericheShow } from "@store/des-convenzioni-generiche/desConvenzioniGeneriche.actions";
import { scrollFunction } from "@utilities/utilities";
import Auth from "@utilities/auth";

interface ConvenzioniGenericheDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function ConvenzioniGenericheDetail({
  match,
  creationMode,
}: ConvenzioniGenericheDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "convenzioniGenericheDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingDesConvenzioniGeneriche = useAppSelector(
    desConvenzioniGenericheShow
  );
  const [convenzioniGeneriche, setStruttura] = useState<
    DesConvenzioniGeneriche | NewDesConvenzioniGeneriche
  >({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getDesConvenzioniGenericheShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingDesConvenzioniGeneriche) {
      setNewElement(false);
      setStruttura(pollingDesConvenzioniGeneriche);
    }
  }, [pollingDesConvenzioniGeneriche]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetDesConvenzioniGenericheShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setStruttura((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "descrizione",
        convenzioniGeneriche?.descrizione,
        [ValidationTypes.REQUIRED]
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !convenzioniGeneriche) return;

    if (newElement && convenzioniGeneriche) {
      const newStruttura: NewDesConvenzioniGeneriche = {
        ...convenzioniGeneriche,
        codConvGenerica: 0,
        //utente: 'SYSTEM',
      };
      const resultAction = await dispatch(
        postDesConvenzioniGeneriche(newStruttura)
      );
      if (postDesConvenzioniGeneriche.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.convenzioni.generiche.descrizioni.show.replace(
              ":id",
              result.codConvGenerica.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(
        putDesConvenzioniGeneriche(
          convenzioniGeneriche as DesConvenzioniGeneriche
        )
      );
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getDesConvenzioniGenericheShow(id));
    } else {
      setStruttura({});
    }
  };

  return (
    <div>
      {convenzioniGeneriche?.codConvGenerica || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("convenzioniGenericheDetailPage:newElementTitle")}`
                : `${t("convenzioniGenericheDetailPage:title")} - ${
                    convenzioniGeneriche.descrizione
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!convenzioniGeneriche?.codConvGenerica ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codConvGenerica")}
                    onChange={(e) => handleChangeValue(e, "codConvGenerica")}
                    value={convenzioniGeneriche?.codConvGenerica || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={convenzioniGeneriche?.descrizione || ""}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-convenzioni") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
