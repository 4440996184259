import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '@assets/icons/Full/Cross.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/Full/Search.svg';
//import { closeModalArticlesSearch, setModalArticlesSearch } from '@store/common/common.actions';
import { Dialog, IconButton, makeStyles, TextField, Tooltip } from '@material-ui/core';
import { AdvancedArticleSearch } from '@components/advanced-article-search/AdvancedArticleSearch';
import { Articoli } from '@store/articoli/articoli.types';
import { useAppDispatch } from "@store/hooks";
import { getArticoliShowAsync } from '@store/articoli/articoli.effects';

const useStyles = makeStyles((theme) => ({
    headerCloseIcon: {
        position: "fixed",
        height: "48px",
        backgroundColor: "#fff",
        borderRadius: "40px",
        zIndex: 2,
        border: "1px solid #e5e7eb",
    },
    contentmodal: {
    }
}));

interface articleProps {
    onSelectedArticles: (articles: Articoli[]) => void
    error?: any
    defaultCodDivisione?: string
    defaultFuoriProd?: string
}



export function ArticlesSearch(props: articleProps) {
    let { onSelectedArticles, error, defaultCodDivisione = '1', defaultFuoriProd = '' } = props
    const [modal, setModal] = useState<boolean>(false);
    const [articleCode, setArticleCode] = useState<string>(''); // value del texfield
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const { t } = useTranslation(['common', 'tooltip'])


    const handleAdvancedSearch = (articoliSelezionati: Articoli[]) => {
        onSelectedArticles(articoliSelezionati)
        setModal(false)
    }

    const handleArticleCodeChange = (e: any) => {
        setArticleCode(e.target.value)
    }

    const handleConfermaInvio = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13) {
            // call di contorllo prima
            let res: any = await dispatch(getArticoliShowAsync(articleCode))
            if (res.error === undefined) {
                onSelectedArticles([res.payload.data.data] as Articoli[])
                setArticleCode('')
            }
        }
    }

    return (
        <div >
            <div className={'flex items-center px-2'} >
                <Tooltip title={`${t('tooltip:confermaInvio')}`}>
                    <TextField error={error} label='Cerca per codice articolo' size='small' onChange={handleArticleCodeChange} onKeyDown={handleConfermaInvio} value={articleCode} ></TextField>
                </Tooltip>
                <Tooltip title={`${t('tooltip:advancedSearch')}`}>
                    <IconButton
                        style={{
                            border: "none",
                            outline: "none"
                        }}
                        className={'mx-2'}
                        color='inherit'
                        size="medium"
                        onClick={() => { setModal(true) }}>
                        <SearchIcon width={20} height={20} />
                    </IconButton>
                </Tooltip>
            </div>
            <Dialog maxWidth={false} fullWidth open={modal} keepMounted>
                <div className={'flex justify-end p-1'}>
                    <div className={classes.headerCloseIcon}>
                        <IconButton
                            style={{
                                border: "none",
                                outline: "none"
                            }}
                            color='inherit'
                            size="medium"
                            onClick={() => { setModal(false) }}>

                            <CloseIcon width={20} height={20} />

                        </IconButton>
                    </div>
                </div>
                <div className={classes.contentmodal}>
                    <AdvancedArticleSearch selectable={true} onSelectArticles={handleAdvancedSearch} defaultCodDivisione={defaultCodDivisione} defaultFuoriProd={defaultFuoriProd} />
                </div>
            </Dialog>
        </div>
    );

}

