import { createReducer } from '@reduxjs/toolkit'
import {
    setStagionalitaIndex,
    setStagionalitaShow,
    resetStagionalitaShow
} from '@store/stagionalita/stagionalita.actions'
import { StagionalitaIndex, StagionalitaShow } from './stagionalita.types'

const initialState: {
    index: StagionalitaIndex
    show: StagionalitaShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const stagionalitaReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setStagionalitaIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setStagionalitaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetStagionalitaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

