import { AxiosAPI } from "@store/axios.config";
import { AppDispatch, AppThunk, RootState } from "../store";
import { setError } from "../common/common.effects";
import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setRequestLoader, removeRequestLoader } from "@store/common/common.actions";
import { setOperazioni } from "./operazioniHD.actions";
import { OperationResults, OperationToExecute } from "./operazioniHD.types";

export const getOperationHD = (): AppThunk => async dispatch => {

  dispatch(setRequestLoader('getOperationHD'));

  try {
      const response = await AxiosAPI.get('/HelpDesk/Operation')
      dispatch(setOperazioni(response.data.data))
  } catch (error: any) {
      if (error.response) {
          dispatch(setError({ message: error.response.data.message }));
      } else {
          console.error(error);
          dispatch(setError({ message: i18n.t('common:message:genericError') }));
      }
  } finally {
      dispatch(removeRequestLoader('getOperationHD'));
  }
}

export const putOperationResults = createAsyncThunk<
  // Return type of the payload creator
  OperationResults[],
  // First argument to the payload creator
  OperationToExecute,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putOperationResults",
  async (operationSelect: OperationToExecute, { rejectWithValue, dispatch, getState }) => {
    dispatch(setRequestLoader("putOperationResults"));

    try {
      const response = await AxiosAPI.put(
        `/HelpDesk/Operation/${operationSelect.idOp}/${operationSelect.flgSelect}`,
        operationSelect
      );
      
      // dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      
       return response.data as OperationResults[];
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("putOperationResults"));
    }
  }
);