import { createReducer } from '@reduxjs/toolkit'
import {
    setCriteriConvenzioniIndex,
    setCriteriConvenzioniShow,
    resetCriteriConvenzioniShow,
    setCriteriConvenzioniShowDettaglioSconti,
    resetCriteriConvenzioniShowDettaglioSconti
} from './criteriConvenzioni.actions'
import { CriteriConvenzioniIndex, CriteriConvenzioniShow, criteriConvenzioniShowDettaglioSconti } from './criteriConvenzioni.type'

const initialState: {
    index: CriteriConvenzioniIndex,
    show: CriteriConvenzioniShow,
    dettaglio: criteriConvenzioniShowDettaglioSconti,
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined },
    dettaglio: { data: undefined }

}

export const criteriConvenzioniReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCriteriConvenzioniIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setCriteriConvenzioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetCriteriConvenzioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
        .addCase(setCriteriConvenzioniShowDettaglioSconti, (state, action) => {
            return {
                ...state,
                dettaglio: {
                    data: action.payload
                }
            }
        })
        .addCase(resetCriteriConvenzioniShowDettaglioSconti, (state, action) => {
            return {
                ...state,
                dettaglio: {
                    data: undefined
                }
            }
        })
})

