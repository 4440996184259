import { AxiosAPI } from '@store/axios.config';
import { AppThunk } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setLarghCerchiTipoArtIndex, setLarghCerchiTipoArtShow } from './larghCerchiTipoArt.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import i18n from '@assets/i18n';
import { getLarghezzaCerchiShow } from '@store/larghezza-cerchi/larghezzaCerchi.effects';
import { setLarghezzaCerchiShow } from '@store/larghezza-cerchi/larghezzaCerchi.actions';


export const getLarghCerchiTipoArtIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getLarghCerchiTipoArtIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/LarghCerchiTipoArt?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setLarghCerchiTipoArtIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLarghCerchiTipoArtIndex'));
    }
}


export const getLarghCerchiTipoArtShow = (codLarghezzaCerchio: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getLarghCerchiTipoArtShow'));

    try {
        const response = await AxiosAPI.get(`/LarghCerchiTipoArt/${codLarghezzaCerchio}/${codTipoArticolo}`)
        dispatch(setLarghCerchiTipoArtShow(response.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLarghCerchiTipoArtShow'));
    }
}


export const postLarghCerchiTipoArt = (codLarghezzaCerchio: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('postLarghCerchiTipoArt'));

    try {

        await AxiosAPI.post(`/LarghCerchiTipoArt`, {
            codLarghezzaCerchio,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        dispatch(getLarghezzaCerchiShow(`${codLarghezzaCerchio}`))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('postLarghCerchiTipoArt'));
        return true
    }
}


export const putLarghCerchiTipoArt = (codLarghezzaCerchio: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putLarghCerchiTipoArt'));

    try {
        await AxiosAPI.put(`/LarghCerchiTipoArt/${codLarghezzaCerchio}/${codTipoArticolo}`, {
            codLarghezzaCerchio,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putLarghCerchiTipoArt'));
    }
}

export const deleteLarghCerchiTipoArt = (codLarghezzaCerchio: number, codTipoArticolo: number): AppThunk => async (dispatch, getState) => {

    dispatch(setRequestLoader('deleteLarghCerchiTipoArt'));

    try {

        await AxiosAPI.delete(`/LarghCerchiTipoArt/${codLarghezzaCerchio}/${codTipoArticolo}`)

        const currentState = (getState()).larghezzaCerchi.show;
        if (currentState.data) {
            const data = currentState.data.larghCerchiTipoArt.filter((item) => item.codTipoArticolo !== codTipoArticolo)
            dispatch(setLarghezzaCerchiShow({
                ...currentState,
                data: {
                    ...currentState.data,
                    larghCerchiTipoArt: data
                }
            }))
        }

        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('deleteLarghCerchiTipoArt'));
    }
}