import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { login } from '@store/auth/auth.effects'
import { authError } from '@store/auth/auth.selector'

import { Header, Footer } from '@components/index'

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { push } from 'connected-react-router';
import routes from '@utilities/routes'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    container: {
        padding: '0rem 4.5rem',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        textAlign: 'center',
    },
    textField: {
        marginTop: '0px',
        marginBottom: '0.813rem'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    assistance: {

    }
}));

export function LoginPage() {

    const classes = useStyles();
    const { t } = useTranslation(['loginPage', 'common'])

    const loginError = useAppSelector(authError);
    const dispatch = useAppDispatch()

    const handleSubmit = (e: React.SyntheticEvent): void => {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
        };

        const email = target.email.value;
        const password = target.password.value;

        dispatch(login(email, password))
    };

    return (
        <div className={classes.root} >
            <Header />
            <Container component="main" className={classes.container} maxWidth="xs">
                <div className="mb-9">
                    <Typography component="h1" variant="h4">
                        {t('loginPage:title')}
                    </Typography>
                </div>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        error={loginError}
                        label={t('common:emailAddress')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        error={loginError}
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        className={classes.submit}
                    >
                        {t('common:signInButton')}
                    </Button>
                    <Link onClick={() => dispatch(push(routes.auth.forgotPassword))} href="#" variant="body2" item-align='center'>
                        {t('loginPage:forgotPassword')}
                    </Link>
                </form>
            </Container>
            <Footer />
        </div >
    );
}