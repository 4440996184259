import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getProduttoriShow,
  postProduttori,
  putProduttori,
} from "@store/produttori/produttori.effects";
import { Produttori, NewProduttori } from "@store/produttori/produttori.types";
import { produttoriShow } from "@store/produttori/produttori.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetProduttoriShow } from "@store/produttori/produttori.actions";
import { scrollFunction } from "@utilities/utilities";
import Auth from "@utilities/auth";

interface ProduttoriDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function ProduttoriDetail({
  match,
  creationMode,
}: ProduttoriDetailProps) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["common", "produttoriDetailPage"]);

  const { codProduttore } = useParams<{ codProduttore: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingProduttori = useAppSelector(produttoriShow);
  const [produttore, setProduttore] = useState<Produttori | NewProduttori>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getProduttoriShow(codProduttore));
  }, [dispatch, codProduttore, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingProduttori) {
      setNewElement(false);
      setProduttore(pollingProduttori);
    }
  }, [pollingProduttori]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetProduttoriShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setProduttore((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "descrizione",
        produttore?.descrizione,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 20 }
      ),
      Validation.buildFieldConfig(
        "simbolo",
        produttore?.simbolo,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 2 }
      ),
      // Validation.buildFieldConfig('barCode', produttore?.barCode, [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH], { maxLength: 7 })
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !produttore) return;

    if (newElement && produttore) {
      const newProduttori: NewProduttori = {
        ...produttore,
        codProduttore: 0,
        flgGY: true,
      };

      const resultAction = await dispatch(postProduttori(newProduttori));
      if (postProduttori.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.produttori.show.replace(
              ":codProduttore",
              result.codProduttore.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putProduttori(produttore as Produttori));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getProduttoriShow(codProduttore));
    } else {
      setProduttore({});
    }
  };

  return (
    <div>
      {produttore?.codProduttore || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("produttoriDetailPage:newElementTitle")}`
                : `${t("produttoriDetailPage:title")} - ${
                    produttore.descrizione
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!produttore?.codProduttore ? null : (
                  <TextField
                    className="px-4 w-full xl:w-2/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codProduttore")}
                    onChange={(e) => handleChangeValue(e, "codProduttore")}
                    value={produttore?.codProduttore || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={produttore?.descrizione || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:simbolo")}*`}
                  error={errors["simbolo"] ? true : false}
                  helperText={errors["simbolo"] || ""}
                  onChange={(e) => handleChangeValue(e, "simbolo")}
                  value={produttore?.simbolo || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:barCode")}`}
                  error={errors["barCode"] ? true : false}
                  helperText={errors["barCode"] || ""}
                  onChange={(e) => handleChangeValue(e, "barCode")}
                  value={produttore?.barCode || ""}
                  style={{ minWidth: "15rem" }}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-produttori") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
