import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getIndiciDiCaricoShow,
  postIndiciDiCarico,
  putIndiciDiCarico,
} from "@store/indici-di-carico/indiciDiCarico.effects";
import {
  IndiciDiCarico,
  NewIndiciDiCarico,
} from "@store/indici-di-carico/indiciDiCarico.types";
import { indiciDiCaricoShow } from "@store/indici-di-carico/indiciDiCarico.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { AssociazioneTipoArticolo } from "../components/AssociazioneTipoArticolo";
import { resetIndiciDiCaricoShow } from "@store/indici-di-carico/indiciDiCarico.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";

interface IndiciDiCaricoDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function IndiciDiCaricoDetail({
  match,
  creationMode,
}: IndiciDiCaricoDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "indiciDiCaricoDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingIndiciDiCarico = useAppSelector(indiciDiCaricoShow);
  const [indiceDiCarico, setIndiceDiCarico] = useState<
    IndiciDiCarico | NewIndiciDiCarico
  >({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getIndiciDiCaricoShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingIndiciDiCarico) {
      setNewElement(false);
      setIndiceDiCarico(pollingIndiciDiCarico);
    }
  }, [pollingIndiciDiCarico]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetIndiciDiCaricoShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setIndiceDiCarico((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "indiceCarico",
        indiceDiCarico?.indiceCarico,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 6 }
      ),
      Validation.buildFieldConfig(
        "kg",
        indiceDiCarico?.kg,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 10 }
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !indiceDiCarico) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && indiceDiCarico) {
      const newIndiceDiCarico: NewIndiciDiCarico = {
        ...indiceDiCarico,
        codIndiceCarico: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(
        postIndiciDiCarico(newIndiceDiCarico)
      );
      if (postIndiciDiCarico.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.indiciDiCarico.show.replace(
              ":id",
              result.codIndiceCarico.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putIndiciDiCarico(indiceDiCarico as IndiciDiCarico));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getIndiciDiCaricoShow(id));
    } else {
      setIndiceDiCarico({});
    }
  };

  return (
    <div>
      {indiceDiCarico?.codIndiceCarico !== undefined || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("indiciDiCaricoDetailPage:newElementTitle")}`
                : `${t("indiciDiCaricoDetailPage:title")} - ${
                    indiceDiCarico.indiceCarico
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!indiceDiCarico?.codIndiceCarico ? null : (
                  <TextField
                    className="px-4 w-full xl:w-2/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codIndiceCarico")}
                    onChange={(e) => handleChangeValue(e, "codIndiceCarico")}
                    value={indiceDiCarico?.codIndiceCarico || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:indiceCarico")}*`}
                  error={errors["indiceCarico"] ? true : false}
                  helperText={errors["indiceCarico"] || ""}
                  onChange={(e) => handleChangeValue(e, "indiceCarico")}
                  value={indiceDiCarico?.indiceCarico || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:kgIndiceCarico")}*`}
                  error={errors["kg"] ? true : false}
                  helperText={errors["kg"] || ""}
                  onChange={(e) => handleChangeValue(e, "kg")}
                  value={indiceDiCarico?.kg || ""}
                />
              </Box>
            </Paper>
          </Box>
          {!newElement ? <AssociazioneTipoArticolo /> : null}
          {Auth.hasAccess("save-indicidicarico") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
