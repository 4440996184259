import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes from '@utilities/routes';


export function PublicRouter({ component, ...rest }: any) {
    return (
        <Route {...rest} render={props => (
            localStorage.getItem('user')
                ? <Redirect to={{ pathname: routes.dashboard, state: { from: props.location } }} />
                : React.createElement(component, props)
        )} />
    )
}