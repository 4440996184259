import { createReducer } from '@reduxjs/toolkit'
import { Auth, User } from './auth.types'
import {
    loginRequest,
    loginSuccess,
    logoutRequest,
    logoutSuccess,
    setAuthError,
    resetAuthError
} from './auth.actions'


const initialUser: User = {
    email: '',
    name: '',
    surname: '',
    username: '',
    token: '',
    roles: []
}

const initialState: Auth = {
    loggedIn: localStorage.getItem('user') ? true : false,
    user: JSON.parse(localStorage.getItem('user') || JSON.stringify(initialUser)),
    error: false
}


export const authReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loginRequest, (state, action) => {
            return {
                ...state,
                error: false
            }
        })
        .addCase(loginSuccess, (state, action) => {
            return {
                ...state,
                loggedIn: true,
                user: action.payload
            }
        })
        .addCase(logoutRequest, (state, action) => {
            return {
                ...state,
                error: false
            }
        })
        .addCase(logoutSuccess, (state, action) => {
            return {
                ...state,
                loggedIn: false,
                user: initialUser
            }
        })
        .addCase(setAuthError, (state, action) => {
            return {
                ...state,
                error: true
            }
        })
        .addCase(resetAuthError, (state, action) => {
            return {
                ...state,
                error: false
            }
        })
})