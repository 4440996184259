const translation = {
  sidebar: {
    aggiornamentiDb: "Agg. Database",
    allestimenti: "Allestimenti",
    articoliFuoriProduzione: "Articoli fuori prod.",
    dealers: "Dealers",
    admin: "Admin",
    anagrafica: "Anagrafica",
    anagraficaListini: "Anagrafica Listini",
    anagrafichegenerali: "Anagrafiche Generali",
    articoli: "Articoli",
    capiZona: "Account manager",
    causaliIva: "Causali IVA",
    clonaConvenzione: "Clona convenzione",
    codiciVelocita: "Cod. velocità",
    conventions: "Convenzioni",
    convenzioniSconti: "Conv. a sconto",
    ConvenzioniLocaliNazionali: "Conv. locali",
    convenzioniPrezziNetti: "Prezzi netti",
    convenzioniPrezziNettiFlotte: "Prezzi netti flotte",
    convenzioniFlotte: "Conv. flotte",
    convenzioniGeneriche: "Desc. conv. Gen.",
    convenzioniDescrizione: "Conv. generiche",
    convenzioniServizi: "Conv. gen. Servizi",
    disegni: "Disegni",
    enti: "Enti conv.",
    esercizi: "Esercizi",
    externalRegisters: "Registri esterni",
    faq: "F.A.Q.",
    fleets: "Flotte e conv.",
    fleetContractTypesImport: "Import contratti",
    fleetVehiclesImport: "Veicoli flotte",
    flowManager: "Flow Manager",
    importListino: "Import listino",
    indiciDiCarico: "Ind di carico",
    larghezzeCerchi: "Larg. cerchi",
    largNominali: "Larg. nominali",
    listinoClienti: "Listino Clienti",
    listini: "Listini",
    logManager: "Log Manager",
    nazioni: "Nazioni",
    news: "News",
    pneumatici: "Pneumatici",
    priceLists: "Listini",
    produttori: "Produttori",
    province: "Province",
    semaphores: "Semafori",
    serieGomme: "Serie gomme",
    servers: "Server",
    servizi: "Servizi",
    stagionalita: "Stagionalità",
    strutture: "Strutture",
    systemStatus: "System Status",
    tasks: "Job",
    tipoContanti: "Tipi Contanti",
    tipiContratto: "Tipi contratto",
    users: "Utenti",
    scontoAddizionale: "Sconto add.",
  },
  loginPage: {
    title: "Login",
    forgotPassword: "Password dimenticata?",
  },
  forgotPasswordPage: {
    title: "Reset della Password",
    resetPassword: "Resetta la Password",
  },
  allestimentiIndexPage: {
    title: "Allestimenti",
    filter: {
      filterOrMulti: "Descrizione",
      filterMarca: "Marca",
      selectMarca: "Seleziona una marca",
      filterModello: "Modello",
    },
  },
  anagraficaIndexPage: {
    title: "Anagrafica",
    newElementButton: "Nuova Anagrafica",
    filter: {
      filterOrMulti: "Partita IVA, Cod. ditta, Ragione sociale",
      filterCodRete: "Rete Commerciale",
      filterProvSedeLegale: "Provincia Sede Legale",
      filterCodPayer: "Codice Payer",
      filterDataAttivazione: "Data Attivazione Negozio dal",
    },
  },
  anagraficaDetailsPage: {
    title: "Dettaglio Anagrafica",
    newElementTitle: "Nuova Anagrafica",
    addSedeOperativa: "Aggiungi sede operativa",
    newSedeOperativaSubtitle: "Nuova Sede",
    dealerDataTitle: "Dati dealer",
    dealerDataSubtitle: "Dati relativi al dealer",
    registeredOfficeTitle: "Sede legale",
    registeredOfficeSubtitle: "Dati per la sede legale",
    operationalOfficeTitle: "Sede operativa",
    operationalOfficeSubtitle: "",
    dealerFlotteTitle: "Flotte",
    dealerFlotteSubtitle: "Dati relativi al dealer",
    filter: {
      filterOrMulti: "Sede operativa",
    },
  },
  anagraficaListiniPage: {
    title: "Anagrafica listini",
  },
  articoliIndexPage: {
    title: "Articoli",
    newElementButton: "Nuovo Articolo",
    filter: {
      filterOrMulti: "Cod. articolo, Descrizione",
      filterDataOperazione: "Data operazione",
      filtercodProduttore: "Produttore",
    },
  },
  articoliDetailPage: {
    title: "Articoli",
    newElementTitle: "Nuovo Articolo",
  },
  articoliFuoriProduzionePage: {
    title: "Articoli fuori produzione",
    rimettiInProduzione: "Rimetti in produzione",
    mettiFuoriProduzione: "Metti fuori produzione",
  },
  causaliIvaIndexPage: {
    title: "Causali IVA",
    newElementButton: "Nuova Causale IVA",
    filter: {
      filterOrMulti: "Descrizione",
      filtertipo: "Tipo",
    },
  },
  causaliIvaDetailPage: {
    title: "Causali IVA",
    newElementTitle: "Nuova Causale IVA",
  },
  capiZonaIndexPage: {
    title: "Account manager",
    newElementButton: "Nuovo Account manager",
    filter: {
      filterOrMulti: "Nominativo",
      filtercodGLS: "Cod. GLS",
    },
  },
  clonaConvenzionePage: {
    title: "Clona convenzione"
  },
  convenzioniDescrizione: {
    title: "Convenzione generiche",
  },
  capiZonaDetailPage: {
    title: "Account manager",
    newElementTitle: "Nuovo Account manager",
  },
  codiciVelocitaIndexPage: {
    title: "Codici Velocità",
    newElementButton: "Nuovo Codice Velocità",
    filter: {
      filterOrMulti: "Simbolo",
      filterkmOrari: "KM Orari",
    },
  },
  codiciVelocitaDetailPage: {
    title: "Codici Velocità",
    newElementTitle: "Nuovo Codice Velocità",
  },
  convenzioniGenericheIndexPage: {
    title: "Descrizioni convenzioni generiche",
    newElementButton: "Nuova Convenzione Generica",
    filter: {
      filterOrMulti: "Descrizione",
    },
  },
  convenzioniGenericheDetailPage: {
    title: "Convenzioni Generiche",
    newElementTitle: "Nuova Convenzione Generica",
  },
  datiAggiuntiviConvenzione: {
    filters: {
      multi: "Cod. Ditta Regione sociale",
    },
  },
  dashboardPage: {
    vediTutteLeAnagrafiche: "Vedi tutte le anagrafiche",
    vediTuttiIListini: "Vedi tutti i listini",
    vediTuttiGliPneumatici: "Vedi tutti gli pneumatici",
    vediTuttiGliUtenti: "Vedi tutti gli utenti",
    ultimeAnagraficheModificate: "Ultime anagrafiche modificate",
    ultimiListiniModificati: "Ultimi listini modificati",
  },
  disegniIndexPage: {
    title: "Disegni",
    newElementButton: "Nuovo Disegno",
    filter: {
      filterOrMulti: "Descrizione",
      filtercodProduttore: "Produttore",
    },
  },
  disegniDetailPage: {
    title: "Disegno",
    newElementTitle: "Nuovo Disegno",
  },
  entiConvenzionatiIndexPage: {
    title: "Enti Convenzionati",
    newItem: "Nuovo ente convenzionato",
    filter: {
      filterOrMulti: "Ragione sociale",
    },
  },
  entiConvenzionatiDetailPage: {
    title: "Dettaglio Ente convenzionato",
    newItemTitle: "Nuovo Ente convenzionato",
    accordions: {
      generalData: "Dati generali",
      contacts: "Contatti",
      options: "Opzioni",
    },
  },
  eserciziIndexPage: {
    title: "Esercizi",
    newElementButton: "Crea esercizio per l'anno successivo",
    filter: {
      filterOrMulti: "Cod. esercizio, Descrizione",
      filtercodMonetaRif: "Moneta",
      filterdataInizio: "Data inizio",
      filterdataFine: "Data fine",
    },
  },
  fleetContractTypesImport: {
    title: "Import contratti veicoli flotte",
    description:
      "Il file deve essere un CSV con il solo campo targa",
  },
  fleetVehiclesImport: {
    title: "Import veicoli flotte",
    description:
      "Il file deve essere un CSV (campi separati da virgola) con i seguenti campi:<br/><ul><li>Targa</li><li>Marca</li><li>Fascia (può essere lasciato vuoto)</li><li>Data fine validità (può essere lasciato vuoto)</li></ul>",
  },
  flowManagerIndexPage: {
    title: "Flow Manager",
    newElementButton: "Flow Manager",
    showAllServers: "Vedi tutti i server",
    showAllTasks: "Vedi tutti i job",
    handleSemaphores: "Gestisci semafori",
    filter: {
      filterOrMulti: "Cod. esercizio, Descrizione",
    },
  },
  eserciziDetailPage: {
    title: "Esercizi",
    newElementTitle: "Nuovo Esercizio",
  },
  importListinoPage: {
    title: "Import listino",
  },
  indiciDiCaricoIndexPage: {
    title: "Indici di Carico",
    newElementButton: "Nuovo Indice di Carico",
    filter: {
      filterOrMulti: "Indice di carico",
      filterkg: "Kg",
    },
  },
  indiciDiCaricoDetailPage: {
    title: "Indici di Carico",
    newElementTitle: "Nuovo Indice di Carico",
  },
  larghezzaCerchiIndexPage: {
    title: "Larghezza Cerchi",
    newElementButton: "Nuova Larghezza",
    filter: {
      filterOrMulti: "Larghezza cerchio",
      filtercodTipoArticolo: "Tipo articolo",
    },
  },
  larghezzaCerchiDetailPage: {
    title: "Larghezza Cerchio",
    newElementTitle: "Nuova Larghezza Cerchio",
  },
  larghezzeNominaliIndexPage: {
    title: "Larghezze Nominali",
    newElementButton: "Nuova Larghezza",
    filter: {
      filterOrMulti: "Larghezza nominale",
    },
  },
  larghezzeNominaliDetailPage: {
    title: "Larghezze Nominali",
    newElementTitle: "Nuova Larghezza",
  },
  listinoClientiDetailPage: {
    title: "Listino Clienti",
  },
  logManagerIndexPage: {
    title: "Log Manager",
    newElementButton: "Nuovo Log Manager",
    filter: {
      filterOrMulti: "Utente, Fonte",
      filterlevel: "Livello",
      filtertimestamp: "A partire dal giorno",
    },
  },
  logManagerDetailPage: {
    title: "Log Manager",
    newElementTitle: "Nuovo Log Manager",
  },
  masterDataUserIndexPage: {
    title: "Utenti",
    newElementButton: "Nuovo Utente",
    filter: {
      filterOrMulti: "Nome, Cognome",
      filteruserName: "Nome utente",
      filteremail: "Email",
      filterrole: "Ruolo",
    },
  },
  masterDataUserDetailPage: {
    title: "Utente",
    newElementTitle: "Nuovo Utente",
  },
  masterDataTata: {
    title: "Help Desk",
    openDialogOperation: "Esegui",
    databaseName: "Db",
    descriptionQuery: "Descrizione Query",
    query: "Query",
    queyDialogTitle: "",
    ipClient: "IP Cliente",
    dbClient: "DB Cliente",
    executeUpdatesQuery: "Procedi",
    executedSelects: "Query Eseguite",
    executeSelectsQuery: "Verifica",
    numPolizza:"Numero Polizza",
    annoOrdine:"Anno Ordine",
    numeroOrdine:"Numero Ordine",
    memo:"Note del Cliente",
    effectiveRows:"Righe interessate"
  },
  nazioniDetailPage: {
    title: "Nazioni",
    newElementTitle: "Nuova Nazione",
  },
  nazioniIndexPage: {
    title: "Nazioni",
    newElementButton: "Nuova Nazione",
    filter: {
      filterOrMulti: "Descrizione, Sigla",
      filtercodMoneta: "Moneta",
      filtercodISO: "Codice ISO",
    },
  },
  notFoundPage: {
    title: "Pagina non trovata",
  },
  pneumaticiIndexPage: {
    title: "Pneumatici",
    newElementButton: "Nuovo Pneumatico",
    filter: {
      filterOrMulti: "Cod. articolo, Descrizione",
      filterDataOperazione: "Data operazione",
      filtercodProduttore: "Produttore",
      filtercodTipoArticolo: "Tipo Articolo",
    },
  },
  pneumaticiDetailPage: {
    title: "Pneumatici",
    newElementTitle: "Nuovo Pneumatico",
  },
  produttoriIndexPage: {
    title: "Produttori",
    newElementButton: "Nuovo produttore",
    filter: {
      filterOrMulti: "Descrizione",
    },
  },
  produttoriDetailPage: {
    title: "Produttore",
    newElementTitle: "Nuovo produttore",
  },
  provinceIndexPage: {
    title: "Province",
    newElementButton: "Nuova provincia",
    filter: {
      filterOrMulti: "Sigla provincia, Provincia",
      filtercodRegione: "Regione",
    },
  },
  provinceDetailPage: {
    title: "Province",
    newElementTitle: "Nuova provincia",
  },
  semaphoresIndexPage: {
    title: "Semafori",
  },
  scontoAddizionaleIndexPage: {
    title: "Sconto addizionale",
    newElementButton: "Nuova sconto addizionale",
    filter: {
      filterOrMulti: "Descrizione",
      filterdataInizio: "Data inizio",
    },
  },
  scontoAddizionaleDetailPage: {
    title: "Sconto addizionale",
    newElementTitle: "Nuova sconto addizionale",
  },
  serviziIndexPage: {
    title: "Servizi",
    newElementButton: "Nuovo Servizio",
    filter: {
      filterOrMulti: "Cod. articolo, Descrizione",
      filterDataOperazione: "Data operazione",
      filtercodTipoArticolo: "Tipo articolo",
    },
  },
  serviziDetailPage: {
    title: "Servizi",
    newElementTitle: "Nuovo Servizio",
  },
  serieGommeIndexPage: {
    title: "Serie Gomme",
    newElementButton: "Nuova Serie Gomme",
    filter: {
      filterOrMulti: "Serie gomme",
    },
  },
  serieGommeDetailPage: {
    title: "Serie Gomme",
    newElementTitle: "Nuova Serie Gomme",
  },
  schedulersIndexPage: {
    title: "Server",
    filter: {
      filterOrMulti: "Nome, Indirizzo IP, Descrizione",
    },
  },
  schedulersShowPage: {
    title: "Server",
    addSchedule: "Aggiungi schedulazione",
  },
  struttureIndexPage: {
    title: "Strutture",
    newElementButton: "Nuova struttura",
    filter: {
      filterOrMulti: "Descrizione",
    },
  },
  struttureDetailPage: {
    title: "Stuttura",
    newElementTitle: "Nuova struttura",
  },
  stagionalitaIndexPage: {
    title: "Stagionalità",
    newElementButton: "Nuova stagionalità",
    filter: {
      filterOrMulti: "Stagionalità",
    },
  },
  stagionalitaDetailPage: {
    title: "Stagionalità",
    newElementTitle: "Nuova stagionalità",
  },
  convenzioniScontiDetailPage: {
    title: "Convenzioni a sconto",
    accordions: {
      generalData: "Dati generali",
    },
    allert: {
      confirm: "Conferma",
      save: "Conferma azione",
      duplicate: "Duplica su convenzione flotta",
    },
  },
  convenzioniServiziPage: {
    title: "Convenzioni generiche servizi",
    accordions: {
      generalData: "Dati generali",
    },
    allert: {
      confirm: "Conferma",
      save: "Conferma azione",
      duplicate: "Duplica su convenzione flotta",
    },
  },
  convenzioniPrezziNetti: {
    title: "Convenzioni prezzi netti",
    resetArticoli: "Reset Articoli",
    accordions: {
      generalData: "Dati generali",
    },
  },
  convenzioniPrezziNettiFlotte: {
    title: "Convenzioni prezzi netti flotte",
    resetArticoli: "Reset Articoli",
    accordions: {
      generalData: "Dati generali",
    },
  },
  convenzioniFlotte: {
    title: "Convenzioni flotte",
    resetArticoli: "Reset Articoli",
    accordions: {
      generalData: "Dati generali",
    },
  },
  ConvenzioniLocaliNazionali: {
    title: "Convenzioni Locali Nazionali",
    resetArticoli: "Reset Articoli",
    accordions: {
      generalData: "Dati generali",
    },
  },

  tasksIndexPage: {
    title: "Job",
    updateTasks: "Aggiorna i job disponibili",
  },
  tipoContantiIndexPage: {
    title: "Tipi Contanti",
    newElementButton: "Nuovo Tipo Contanti",
    filter: {
      filterOrMulti: "Descrizione",
    },
  },
  tipoContantiDetailPage: {
    title: "Tipi Contanti",
    newElementTitle: "Nuovo Tipo Contanti",
  },
  tipiContrattoIndexPage: {
    title: "Tipi Contratti",
    newElementButton: "Nuovo Tipo Contratto",
  },
  tipiContrattoDetailPage: {
    title: "Tipi Contanti",
    newElementTitle: "Nuovo Tipo Contratto"
  },
  common: {
    message: {
      columnsWithoutAssociation: "Ci sono colonne senza associazione",
      columnsWithSameAssociation:
        "Ci sono più colonne associate allo stesso campo",
      deleteSuccess: "Eliminazione completata",
      defaultConfirmationTitle: "Conferma",
      defaultConfirmationMessage: "Confermi di voler procedere?",
      emailSend: "È stata inviata un'email alla casella di posta selezionata.",
      emailNotFound: "Email inserita non è valida",
      fileIsEmptyOrMalformed: "il file è vuoto o non valido",
      genericError: "Errore generico, contattare l'amministratore",
      noFleetSelected:
      "Selezionare un ente convenzionato",
      noSemaphoreSelected: "Nessun semaforo selezionato",
      invalidDate: "Data inserita non valida",
      imposibleSplitWithNoSave:
        "Non puoi effettuare lo split in questo momento. Salva i dati e riprova",
      nothingToImport: "Non ci sono dati da importare",
      saveSuccess: "Salvataggio completato",
      selectDifferentLivelloListino:
        "Selezionare un livello listino diverso da quello della ricerca",
      showDuplicateListini: "Visualizza listini duplicati",
      showDuplicateListiniMessage: "Vuoi visualizzare i listini duplicati?",
      validationError: "I dati inseriti non sono corretti",
    },
    validatorErrors: {
      requiredField: "Campo obbligatorio",
      invalidEmail: "Email non valida",
      invalidRegex: "Inserire un valore corretto",
      invalidLength:
        "La lunghezza del campo deve essere di {{length}} caratteri",
      invalidRangeLength:
        "La lunghezza del campo deve essere tra {{minLength}} e {{maxLength}} caratteri",
      invalidMaxLength:
        "La lunghezza del campo deve essere di massimo {{maxLength}} caratteri",
      email: "Email non valida.",
      dataExists: "già associato",
      valueNotFound: "Elemento non trovato",
      fillAllFields: "Compilare tutti i campi"
    },
    accessori: "Accessori",
    accordoGY: "Accordo GY",
    actions: "Azioni",
    active: "Attivo",
    activeUsers: "Utenti attivi",
    aggiornaIVA: "Aggiorna IVA",
    aggiornaListiniFuturi: "Aggiorna listini futuri",
    aliquota: "Aliquota",
    anagraficaMancante: "Anagrafica mancante",
    anagrafiche: "Anagrafiche",
    anagraficheAttive: "Anagrafiche attive",
    annul: "Annulla",
    area: "Area",
    areaManager: "Area manager",
    articolico: "Articolo",
    aggiornamentoDb: "Aggiornamento database",
    articolicodVelocita: "Codice Velocità",
    articoliDataLabeling: "Data Labeling",
    articoliDatiGenerali: "Dati Generali",
    articoliDatiLabeling: "Dati Labeling",
    articoliDatiTecnici: "Dati Tecnici",
    articoliflgInEstinzione: "Non in Produzione",
    articoliflgRunOnFlat: "Run On Flat",
    articoliflgServVeicolo: "Intero Veicolo",
    articoliIndiceCarico1: "Indice di Carico 1",
    articoliIndiceCarico2: "Indice di Carico 2",
    articoliLarghezzaCerchio: "Diametro Cerchi",
    articolinoisePerformance: "Rumorosità",
    articoliNonTrovati: "Articoli non trovati",
    articolinumberWaves: "N. onde",
    articoliProduttore: "Produttore",
    articoliqtaDefault: "Quantità Default",
    articolirollingResistance: "Res. rotolamento",
    articoliTipoArticolo: "Tipo Articolo",
    articoliwetGrip: "Ader. sul bagnato",
    associazioneColonne: "Associazione colonne",
    assegnaConvenzioni: "Assegna convenzioni",
    back: "Indietro",
    backToLogin: "Torna alla login",
    barCode: "Barcode",
    bloccoSalvataggio: "Blocco salvataggio",
    fatturazione: "Blocco fatturazione",
    fatturazioneElettronicaNatura: "Fatturazione Elettronica Natura",
    cancel: "Annulla",
    cap: "CAP",
    causaleIva: "Causale IVA",
    categoriaMerceologica: "Cat. Merceologica",
    clienti: "Clienti",
    cliente: "Cliente",
    clone: "Clona",
    column: "Colonna",
    convenzione: "Convenzione",
    convenzioniGeneriche: "Convenzioni generiche",
    codArticolo: "Cod. articolo",
    codCapoZona: "Cod. capo zona",
    codConvGenerica: "Cod. conv. generica",
    codDescrizione: "Cod. descrizione",
    codDisegno: "Cod. disegno",
    codDitta: "Cod. Ditta",
    codDivisione: "Cod. divisione",
    codEnteConv: "Ente convenzionato",
    codEsercizio: "Cod. esercizio",
    codFiscale: "Cod. Fiscale",
    codiceGY: "Codice GY",
    codIVADetr: "Cod.IVA Detr.",
    codIVAIndetr: "Cod.IVA Indetr.",
    codNazione: "Cod. nazione",
    codIVA: "Cod. IVA",
    codGLS: "GLS",
    codiceGLS: "Codice GLS",
    codiceindexGLS: "Cod. GLS",
    codiceVelocita: "Codice velocità",
    codIndiceCarico: "Cod. indice di carico",
    codInvoice: "Codice invoice",
    codISO: "Codice ISO",
    codLarghezzaCerchio: "Cod larghezza cerchio",
    codLarghezzaNominale: "Cod. larghezza nominale",
    codPayer: "Cod. Payer",
    codProduttore: "Cod. produttore",
    codProgramma: "Cod. programma",
    codSedeOp: "Cod. sede operativa",
    codRete: "Cod. rete",
    codSerieGomme: "Cod. serie gomme",
    codShipTo: "Codice ship to",
    codAttrGYDL: "Codice GYDL",
    codSoldTo: "Cod. sold to",
    codSSXEnteConv: "Cod. banca dati",
    codStagionalita: "Cod. stagionalità",
    codStruttura: "Cod. struttura",
    codTipoArticolo: "Cod. tipo articolo",
    codTipoContanti: "Cod. tipo contanti",
    codUdm: "U.d.m.",
    codVelocita: "Cod. velocità",
    codVendor: "Cod. Venditore",
    config: "Configurazioni",
    confirmPassword: "Conferma password",
    criterioconvenzione: "Criterio convenzione",
    corrente: "Corrente",
    cerchio: "Cerchio",
    confirmAction: "conferma azione",
    dataAttivazioneNegozio: "Data attivazione negozio",
    dataInizio: "Data inizio",
    dataInizioValidita: "Data inizio validità",
    dataFine: "Data fine",
    dataFineValidita: "Data fine validità",
    dataFineValiditaCausaleIva: "Data fine validità causale IVA",
    dataInizioFattCentr: "Data inizio fatt. centralizzata",
    dataInizioValiditaCausaleIva: "Data inizio validità causale IVA",
    dataEsecuzione: "Data esecuzione",
    dateListini: "Date Listini",
    dataOperazione: "Data operazione",
    dataDb: "Dati database",
    datiListino: "Dati listino",
    delete: "Elimina",
    descrizione: "Descrizione",
    descrizioneLibroIVA: "Libro IVA",
    descrizioneSerieGomme: "Serie gomme",
    descrizioneStagionalita: "Stagionalità",
    disegno: "Disegno",
    distributore: "Distributore",
    divisione: "Divisione",
    duplicaConvenzioneFlotte: "Duplica convenzione per le flotte",
    duplicateListini: "Duplica listini",
    duplicateItems: "Elementi duplicati",
    edit: "Modifica",
    editedByMe: "Modificati da me",
    email: "Email",
    emailConfirmed: "Email confermata",
    emailNotConfirmed: "Email non confermata",
    emailAddress: "Indirizzo email",
    emailFrom: "Email mittente",
    emailPOP3: "Host POP3",
    emailSMTP: "Host SMTP",
    emailTo: "Email destinatario",
    emptyItems: "Elementi vuoti",
    enteConvenzionato: "Ente Convenzionato",
    excludeAllExtraSconti: "Escludi tutti gli extra sconti",
    excludeStagionalita: "Escludi singole stagionalità",
    itemsAdded: "Elementi aggiunti",
    itemsUpdated: "Elementi aggiornati",
    entity: "Entità",
    estensione: "Estensione",
    eventmanager: "Evento",
    entiConvenzionati: "Enti convenzionati",
    expand: "Espandi",
    fakeDotCode: "Codice DOT fittizio",
    fascia: "Fascia",
    fax1: "Fax",
    fax2: "Fax 2",
    fax3: "Fax 3",
    field: "Campo",
    filtriListini: "Filtri Listini",
    flgDaEseguireSempre: "Esegui sempre",
    flgSoloiSIGESS: "Solo iSIGeSS",
    flg4Seasons: "Flg4 seasons",
    flgAbilitatoSellout: "Abilitato sellout",
    flgAnnullamento: "Annullamento ODL",
    flgCD: "Conto deposito",
    flgCDUti: "Conto deposito utilizzatore",
    flgCertificazione: "Ditta certificata",
    flgColloquio: "Colloquio",
    flgDateObbl: "Data obbligatoria",
    flgDOT: "DOT Obbligatorio",
    flgInEstinzione: "Non in Produzione",
    flgInProd: "In Prod.",
    flgEnteConvenzionato: "Ente convenzionato",
    flgEnteConvenzionatoGY: "Ente convenzionato GY",
    flgFatturazioneDiretta: "Fatturazione diretta",
    flgFatturazioneGY: "Fatturazione a Goodyear",
    flgForFleet: "Abilitato a 4Fleet",
    flgfuoriProd: "Fuori Prod.",
    flgGoodyear: "Goodyear",
    flgGY: "Glg GY",
    flgIgnoraRagno: "Ignora ragno",
    flgNoTarga: "Acquisizione Targhe",
    flgNoTargaIns: "Registrazione targhe P.V.",
    flgOfficinaMobile: "Officina mobile",
    flgPrenotazioneOnLine: "Prenotazione online",
    flgReplicaSuListinoFornitori: "Replica su listino fornitori",
    flgRiepDest: "Riepilogo destinazioni",
    flgRiepilogoBolle: "Riepilogo bolle",
    flgSelfBillingManuale: "Self billing manuale",
    flgSmaltMass: "Smaltimento massivo",
    flgDisabilitaScontoAdd: "Disabilita applicazione sconto addizionale",
    flgSocFinanziaria: "Società finanziaria",
    flgSoggettoBolli: "Soggetto Bolli",
    flgSoloConvenzione: "Solo convenzione",
    flgTracciatoDettagliato: "Tracciato dettagliato",
    fornitori: "Fornitori",
    futuro: "Futuro",
    heartbeat: "Ultima registrazione",
    id: "Id",
    identificativo: "Identificativo",
    import: "Importa",
    importSummary: "Riepilogo import",
    indiceCarico: "Indice di carico",
    indirizzo: "Indirizzo",
    indirizzoEmail: "E-mail",
    indirizzosedelegale: "Sede legale",
    indirizzosedeoperativa: "Sede operativa",
    interfacciawebservice: "Interfaccia web service",
    inputproduttori: "Produttori *",
    invalidItems: "Elementi non validi",
    kgIndiceCarico: "Kg",
    kmOrari: "Km orari",
    larghezzaCerchio: "Larghezza cerchio",
    larghezzaNominale: "Larghezza nominale",
    lastStartDate: "Ultima data di avvio",
    lastEndDate: "Ultima data di termine",
    latitudine: "Latitudine",
    level: "Livello",
    limiteDOT: "Limite DOT",
    lingua: "Lingua",
    linkSito: "Web Site",
    listinoClienti: "Listino clienti",
    listinoFornitori: "Listino fornitori",
    livelloListino: "Livello listino",
    localita: "Località",
    logDetail: "Dettaglio log",
    longitudine: "Longitudine",
    marca: "Marca",
    messaggio: "Messaggio",
    modello: "Modello",
    nominativo: "Nominativo",
    foundArticles: "Articoli trovati",
    numCaratteriPIVA: "Num. Car. P.IVA",
    numListiniClientiImportati: "Numero listini clienti importati",
    numListiniFornitoriImportati: "Numero listini fornitori importati",
    mesiGiacenzaSmaltMass: "Mesi giac. smalt. massivo",
    messagemanager: "Messaggio",
    moneta: "Moneta",
    motivoFatturazioneDiversa: "Motivo fatt. diversa",
    next: "Successivo",
    nextStartDate: "Prossima data di avvio",
    name: "Nome",
    no: "No",
    noFileSelected: "Nessun file selezionato",
    nomeContratto: "Nome contratto",
    none: "Nessuno",
    nonExistentFascia: "Fasce inesistenti",
    nonExistentMarca: "Marche inesistenti",
    note: "Note",
    note1: "Note",
    note2: "Note 2",
    note3: "Note 3",
    numCivico: "Civico",
    numeroMaxPneu: "Numero massimo pneumatici",
    pagamento: "Pagamento",
    partitaIVA: "Part. Iva",
    PDF: "PDF",
    password: "Password",
    peso: "Peso",
    percIndetraibile: "Perc. Indetraibile",
    percentualeAumento: "Percentuale aumento",
    percentualeSconto: "Percentuale sconto",
    periodicity: "Periodicità",
    pivaExp: "Part. Iva export fatture",
    phoneNumber: "N° Telefono",
    phoneNumberConfirmed: "N° Telefono confermato",
    phoneNumberNotConfirmed: "N° Telefono non confermato",
    prezzoEuro: "Prezzo Euro",
    prezzoEuroConIVA: "Prezzo Euro + IVA",
    pneumatici: "Pneumatici",
    pneumaticiRegistrati: "Pneumatici registrati",
    priceListHistory: "Cronologia listino",
    provincia: "Provincia",
    progConvenzione: "Prog. convenzione",
    progConvenzioneFlotte: "Prog. convenzione flotte",
    produttore: "Produttore",
    propagaSuTuttleLeLine: "Propaga su tutte le linee",
    progressivo: "Progressivo",
    recordFound: "Record trovati",
    regione: "Regione",
    ragioneSociale: "Ragione sociale",
    relatedLogs: "Log correlati",
    remove: "Rimuovi",
    resetFilters: "Rimuovi Filtri",
    responsabile: "Responsabile",
    restart: "Riavvia",
    rete: "Rete",
    ricercaArticoli: "Ricerca articoli",
    rof: "Run on flat",
    role: "Ruolo",
    roles: "Ruoli",
    save: "Salva",
    saveAndDeactivate: "Salva e disattiva",
    search: "Cerca",
    seeAll: "Vedi tutte",
    seeSchedules: "Vedi schedulazioni",
    sedeOperativa: "Sede operativa",
    select: "Seleziona",
    selectAll: "Seleziona tutti",
    selectAreaManager: "Seleziona area manager",
    selectDistributore: "Seleziona distributore",
    selectField: "Seleziona un campo",
    selectJob: "Seleziona un job",
    selectProgramma: "Seleziona un programma",
    selectProvincia: "Seleziona provincia",
    semaphore: "Semaforo",
    serie: "Serie",
    server: "Server",
    extraData: "Descrizione",
    servizi: "Servizi",
    showAll: "Vedi tutti",
    simbolo: "Simbolo",
    sigla: "Sigla",
    siglaProvincia: "Sigla provincia",
    signInButton: "Entra",
    source: "Fonte",
    stagionalita: "Stagionalità",
    startJob: "Avvia procedura",
    startJobs: "Avvia procedure",
    startUpdateJob: "Avvia procedura di aggiornamento",
    stopJob: "Ferma procedura",
    stopJobs: "Ferma procedure",
    struttura: "Struttura",
    strictTime: "Esecuzione a orario prefissato",
    status: "Stato",
    stop: "Metti in pausa",
    storico: "Storico",
    summary: "Riepilogo",
    surname: "Cognome",
    support: "Assistenza",
    summer: "Estivi",
    winter: "Invernali",
    tabellariepilogativa: "Tabella riepilogativa",
    targa: "Targa",
    task: "Job",
    telefono1: "Telefono",
    telefono2: "Telefono 2",
    telefono3: "Telefono 3",
    tipo: "Tipo",
    tipoArticolo: "Tipo articolo",
    tipiArticolo: "Tipi articolo",
    tipoContratto: "Tipo Contratto",
    tipoDocFattura: "Tipo Documento",
    tipologiaCliente: "Tipologia cliente",
    tipoOperazione: "Tipo operazione",
    tireSizes: "Misure pneumatico",
    type: "Tipo",
    ultimaDataListino: "Ultima data listino",
    unitaDiMisura: "Unità di misura",
    URL: "Url",
    uploadFile: "Carica file",
    user: "Utente",
    users: "Utenti",
    userId: "User ID",
    userName: "Nome utente",
    version: "Versione",
    yes: "Si",
    pageSize: "Elementi per pagina",
    helpDesk: "Help Desk",
    userIdTrf: "Utente",
    pswTrf: "Password",
    accessoPCAny: "Pc Anywhere",
    accessoUVNC: "Ultra VNC",
    accessoTS: "Terminal server",
    fTPSrv: "Ftp server",
    eseguitoPrivacy2004: "Privacy 2004 ",
    dataServizio180: "Contratto 180",
    nomeDominio: "Dominio",
    sosServer: "S.O. Server",
    numISDN: "Numero ISDN/IP Statico"
  },
  tooltip: {
    advancedSearch: "Ricerca avanzata",
    confermaInvio: "Premere invio per confermare",
  },
  customAlert: {
    info: "Informazione",
    error: "Errore",
    warning: "Attenzione",
    success: "Completato",
  },
};

export default translation;
