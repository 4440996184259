import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 300,
        height: 300,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));



export interface TransferListProps {
    choices: any[]
    chosen?: any[]
    setChoices: any,
    setChosen: any,
    description: string
}
export default function TransferList(props: TransferListProps) {
    const classes = useStyles();
    const [checked, setChecked] = useState<any[]>([]);

    const { description, setChoices, setChosen, choices = [], chosen = [] } = props


    const leftChecked = intersection(checked, choices);
    const rightChecked = intersection(checked, chosen);

    const handleToggle = (value: any) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: any[]) => intersection(checked, items).length;

    const handleToggleAll = (items: any[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setChosen(chosen.concat(leftChecked));
        setChoices(not(choices, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setChoices(choices.concat(rightChecked));
        setChosen(not(chosen, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    function not(a: any[], b: any[]) {
        return a.filter((value: string) => b.indexOf(value) === -1);
    }

    function intersection(a: any[], b: any[]) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a: any[], b: any[]) {
        return [...a, ...not(b, a)];
    }
    const randomId = (): number => {
        return _.random(0, 99999999);
    }

    const customList = (title: string, items: any[]) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value[description]}-label`;

                    return (
                        <ListItem key={randomId()} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={randomId().toString()} primary={`${value[description]}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Grid
            spacing={2}
            container
            style={{
                justifyContent: "center",
                alignItems: "center",

            }}
            className={classes.root}

        >

            <Grid item>{customList('Choices', choices)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Chosen', chosen)}</Grid>
        </Grid>
    );
}