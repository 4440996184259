import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { AnagraficaGoodyear, DatiAggiuntiviSedeOp } from '@store/anagrafica/anagrafica.types';
import { ValidationConfig, Validation, ValidationTypes } from '@utilities/validation';
import { setError } from '@store/common/common.effects';
import { dateFormatter } from '@utilities/utilities';
import { putDatiAggiuntiviSedeOp } from '@store/anagrafica/anagrafica.effects';
import { useAppDispatch } from '@store/hooks';
import { CustomSelect } from '@components/selects/custom-select/CustomSelect';
interface TypeNameInputComponent {
    data: DatiAggiuntiviSedeOp;
    dataAnagrafica: AnagraficaGoodyear;
}

export function TataDataDealerComponent(props: TypeNameInputComponent) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['common'])


    const { data } = props;
    const [dataState, setDataState] = useState({ ...data })

    const [errors, setErrors] = useState({} as { [key: string]: string });

    useEffect(() => {
        console.log('useEffect');

        setDataState(data)
    }, [data])

    const handlerChangeAttributes = (e: React.ChangeEvent, id: string) => {


        e.preventDefault();
        const target = e.target as typeof e.target & {
            id: string;
            value: string;
        };

        setDataState((prevState) => {
            return {
                ...prevState,
                [id]: target.value,
            };
        });
    };

    const handlerChangeCheckBox = (e: React.ChangeEvent, id: string) => {
        const target = e.target as typeof e.target & {
            id: string;
            checked: boolean;
        };
        console.log(id, target.checked);

        if (dataState)
            setDataState((prevState) => {
                return {
                    ...prevState,
                    [id]: target.checked,
                };
            });

    };

    const handleChangeSelect = (value: any, id: string) => {

        setDataState((prevState) => {
            return {
                ...prevState,
                [id]: value,
            };
        });
    };

    const validate = () => {
        const fieldsConfig: ValidationConfig[] = [
                  Validation.buildFieldConfig(
                    "numISDN",
                    dataState?.numISDN,
                    [ValidationTypes.MAX_LENGTH],
                    { maxLength: 20 }
                  ),
                  Validation.buildFieldConfig(
                    "nomeDominio",
                    dataState?.nomeDominio,
                    [ValidationTypes.MAX_LENGTH],
                    { maxLength: 20 }
                  ),
                  Validation.buildFieldConfig("soServer", data.soServer, [
                    ValidationTypes.REQUIRED,ValidationTypes.FIXED_LENGTH
                  ],{length:1}),
                  Validation.buildFieldConfig(
                    "testoMemo",
                    dataState?.testoMemo,
                    [ValidationTypes.MAX_LENGTH],
                    { maxLength: 500 }
                  )
        ];

        const validationErrors = Validation.validate(fieldsConfig);
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const save = async () => {
        setErrors({});
        console.log("save", dataState)
        if (validate()) {
            dispatch(putDatiAggiuntiviSedeOp(dataState))
        }
        else {
            dispatch(setError({ message: t("common:message:validationError") }));
        }
    };

    return (
        <div>
            {data ?

                <Box className="p-2 pb-8">
                    <form>
                        <Box className='flex flex-wrap'>
                            <Box className='w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2'>
                                <TextField
                                    fullWidth
                                    label={t('common:userIdTrf')}
                                    size='small'
                                    id='UserIdTrf'
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                    // value={data?.sediOperativeGoodyear?.find(sedeOp => data.codSedeOperativa === sedeOp.codSedeOperativa)?.userIdTrf || ''}
                                    value={data?.sediOperativeGoodyear?.userIdTrf || ''}

                                />
                            </Box>
                            <Box className='w-full  2xl:w-3/12 xl:w-4/12 md:w-6/12 px-2'>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t('common:pswTrf')}
                                    size='small'
                                    id='pswTrf'
                                    // value={data?.sediOperativeGoodyear?.find(sedeOp => data.codSedeOperativa === sedeOp.codSedeOperativa)?.pswTrf || ''}
                                    value={data?.sediOperativeGoodyear?.pswTrf || ''}

                                />
                            </Box>
                            <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                                <TextField
                                    disabled
                                    fullWidth
                                    label={t('common:linkSito')}
                                    size='small'
                                    id='linkSito'
                                    value={data.anagraficaGoodyear?.linkSito || ''}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <TextField
                                    fullWidth
                                    label={t('common:dataServizio180')}
                                    size='small'
                                    type='date'
                                    id='dataServizio180'
                                    error={errors['dataServizio180'] ? true : false}
                                    value={dataState?.dataServizio180 ? dateFormatter(new Date(dataState?.dataServizio180)) : ''}
                                    onChange={(e) => {
                                        handlerChangeAttributes(e, "dataServizio180");
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <TextField
                                    fullWidth
                                    label={t('common:nomeDominio')}
                                    size='small'
                                    id='nomeDominio'
                                    type="text"
                                    error={errors['nomeDominio'] ? true : false}
                                    value={dataState?.nomeDominio || ''}
                                    onChange={(e) => {
                                        handlerChangeAttributes(e, "nomeDominio");
                                    }}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <CustomSelect
                                    label={t('common:sosServer')}
                                    data={[
                                        {
                                            value: 1,
                                            description: "Windows NT Workstation 4.0"
                                        },
                                        {
                                            value: 2,
                                            description: "Windows NT Server 4.0"
                                        },
                                        {
                                            value: 3,
                                            description: "Windows 2000 Professional"
                                        },
                                        {
                                            value: 4,
                                            description: "Windows 2000 Server"
                                        },
                                        {
                                            value: 5,
                                            description: "Windows XP Professional"
                                        },
                                        {
                                            value: 6,
                                            description: "Windows 2002 Server"
                                        }
                                    ]}
                                    descriptionKey={'description'}
                                    valueKey={'value'}
                                    id="soServer"
                                    value={dataState?.soServer}
                                    onChange={handleChangeSelect}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <TextField
                                    fullWidth
                                    label={t('common:numISDN')}
                                    size='small'
                                    id='numISDN'
                                    type="text"
                                    error={errors['numISDN'] ? true : false}
                                    value={dataState?.numISDN || ''}
                                    onChange={(e) => {
                                        handlerChangeAttributes(e, "numISDN");
                                    }}
                                />
                            </Box>

                        </Box>
                        <Box className='flex flex-wrap'>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <FormControlLabel
                                    className="w-full"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            disableRipple={true}
                                            checked={dataState.flgAccessoPcAny || false}
                                            onChange={(e) => {
                                                handlerChangeCheckBox(e, "flgAccessoPcAny");
                                            }}
                                        />
                                    }
                                    label={t('common:accessoPCAny')}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <FormControlLabel
                                    className="w-full"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={dataState?.flgAccessoUVNC || false}
                                            disableRipple={true}
                                            onChange={(e) => {
                                                handlerChangeCheckBox(e, "flgAccessoUVNC");
                                            }}
                                        />
                                    }
                                    label={t('common:accessoUVNC')}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <FormControlLabel
                                    className="w-full"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={dataState?.flgAccessoTS || false}
                                            disableRipple={true}
                                            onChange={(e) => {
                                                handlerChangeCheckBox(e, "flgAccessoTS");
                                            }}
                                        />
                                    }
                                    label={t('common:accessoTS')}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <FormControlLabel
                                    className="w-full"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={dataState?.flgFTPSrv || false}
                                            disableRipple={true}
                                            onChange={(e) => {
                                                handlerChangeCheckBox(e, "flgFTPSrv");
                                            }}
                                        />
                                    }
                                    label={t('common:fTPSrv')}
                                />
                            </Box>
                            <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                <FormControlLabel
                                    className="w-full"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={dataState?.flgEseguitoPrivacy2004 || false}
                                            disableRipple={true}
                                            onChange={(e) => {
                                                handlerChangeCheckBox(e, "flgEseguitoPrivacy2004");
                                            }}
                                        />
                                    }
                                    label={t('common:eseguitoPrivacy2004')}
                                />
                            </Box>
                            <Box className='flex w-full px-2'>
                                <TextField
                                    fullWidth
                                    label={t('masterDataTata:memo')}
                                    size='small'
                                    id='testoMemo'
                                    multiline
                                    value={dataState?.testoMemo || ''}
                                    onChange={(e) => {
                                        handlerChangeAttributes(e, "testoMemo");
                                    }}
                                />
                            </Box>
                        </Box>
                    </form>
                    <Box className="w-full flex justify-end p-2">
                        <Button
                            color="primary"
                            onClick={() => {
                                save();
                            }}
                        >
                            {t("common:save")}
                        </Button>
                    </Box>

                </Box>
                : null}
        </div >
    )
}