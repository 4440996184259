import { Dialog, DialogTitle, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { ListinoHistory } from "@store/listini/listini.types";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface CronologiaListinoDialogProps {
    open: boolean
    setOpen: (value: React.SetStateAction<boolean>) => void
    listini?: ListinoHistory[]
}

const useStyles = makeStyles((theme) => ({
    dataGrid: {
        minWidth: '600px'
    }
}));


export function CronologiaListinoDialog(props: CronologiaListinoDialogProps) {

    const classes = useStyles();
    const { open, setOpen, listini } = props;
    const { t } = useTranslation(['common'])

    return (
        <Dialog open={open} onClose={() => {
            setOpen(false);
        }}>
            <DialogTitle>{t('common:priceListHistory')}</DialogTitle>
            <DataGrid
                className={classes.dataGrid}
                rows={listini ? listini : []}
                autoHeight
                hideFooter
                getRowId={(row) => { return `${row.periodoListino}||${row.livelloListino}||${row.codProduttore}||${row.codArticolo}||${row.dataInizioValidita}` }}
                getRowClassName={(params) => {
                    return params.row.periodoListino === 'corrente' ? 'font-bold' : ''
                }}
                columns={[
                    {
                        field: 'dataInizioValidita',
                        headerName: t('common:dataInizioValidita'),
                        flex: 1,
                        disableColumnMenu: true,
                        disableClickEventBubbling: true,
                        sortable: false,
                        hideSortIcons: true,
                        valueFormatter: ({ value }) =>  {
                            return moment(new Date(value as string)).format("DD/MM/YYYY")
                        }
                    },
                    {
                        field: 'codArticolo',
                        headerName: t('common:codArticolo'),
                        flex: 1,
                        disableColumnMenu: true,
                        disableClickEventBubbling: true,
                        sortable: false,
                        hideSortIcons: true
                    },
                    {
                        field: 'prezzoEuro',
                        headerName: t('common:prezzoEuro'),
                        flex: 1,
                        disableColumnMenu: true,
                        disableClickEventBubbling: true,
                        sortable: false,
                        hideSortIcons: true,
                        valueFormatter: ({ value }) =>  {
                            return Number(value).toFixed(2)
                        }
                    },
                    {
                        field: 'prezzoEuroConIVA',
                        headerName: t('common:prezzoEuroConIVA'),
                        flex: 1,
                        disableColumnMenu: true,
                        disableClickEventBubbling: true,
                        sortable: false,
                        hideSortIcons: true,
                        valueFormatter: ({ value }) =>  {
                            return Number(value).toFixed(2)
                        }
                    }
                ]}
            />
        </Dialog>
    )
}
