import { createReducer } from '@reduxjs/toolkit'
import {
    setDesConvenzioniGenericheIndex,
    setDesConvenzioniGenericheShow,
    resetDesConvenzioniGenericheShow
} from '@store/des-convenzioni-generiche/desConvenzioniGeneriche.actions'
import { DesConvenzioniGenericheIndex, DesConvenzioniGenericheShow } from './desConvenzioniGeneriche.types'

const initialState: {
    index: DesConvenzioniGenericheIndex
    show: DesConvenzioniGenericheShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const desConvenzioniGenericheReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setDesConvenzioniGenericheIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setDesConvenzioniGenericheShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetDesConvenzioniGenericheShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

