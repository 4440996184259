import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { CriteriServizi } from './convenzionePrezziNetti.types'

export const setCriteriServiziIndex = createAction<{
    data: CriteriServizi[]
    meta: {
        pagination?: Pagination
    }
}>('criteriServizi/index')

export const setCriteriServiziShow = createAction<CriteriServizi>('criteriServizi/show')
export const resetCriteriServiziInex = createAction('criteriServizi/reset')