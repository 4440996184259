import React from "react";
import {
  AnagraficaIndex,
  AnagraficaDetails,
  CausaliIvaDetail,
  CausaliIvaIndex,
  CapiZonaDetail,
  CapiZonaIndex,
  CodiciVelocitaDetail,
  CodiciVelocitaIndex,
  ConvenzioniGenericheDetail,
  ConvenzioniGenericheIndex,
  DisegniDetail,
  DisegniIndex,
  EserciziDetail,
  EserciziIndex,
  FlowManagerIndex,
  IndiciDiCaricoDetail,
  IndiciDiCaricoIndex,
  LarghezzaCerchiDetail,
  LarghezzaCerchiIndex,
  LarghezzeNominaliDetail,
  LarghezzeNominaliIndex,
  LogManagerIndex,
  NazioniDetail,
  NazioniIndex,
  PneumaticiDetail,
  PneumaticiIndex,
  ProduttoriDetail,
  ProduttoriIndex,
  ProvinceDetail,
  ProvinceIndex,
  SerieGommeDetail,
  SerieGommeIndex,
  ServiziDetail,
  ServiziIndex,
  StagionalitaDetail,
  StagionalitaIndex,
  StruttureDetail,
  StruttureIndex,
  TipoContantiDetail,
  TipoContantiIndex,
  MasterDataUserDetail,
  MasterDataUserProfile,
  MasterDataUserIndex,
  Dashboard,
  AllestimentiIndex,
  TasksIndex,
  SchedulersIndex,
  SchedulerShow,
  ArticoliFuoriProduzione,
  AggiornamentiDb,
  AnagraficaListini,
  ImportListino,
  ConvenzioniAPrezziNetti,
  FleetVehiclesImport,
  TipiContrattoIndex,
  TipiContrattoDetail,
  EntiConvenzionatiDetail,
  EntiConvenzionatiIndex,
  SemaphoresIndex,
  ConvenzioniASconto,
  FleetContractImport,
  MasterDataTata,
  ScontoAddizionaleDetail,
  ScontoAddizionaleIndex
} from "@pages/index";
import routes from "./routes";
import i18n from "@assets/i18n";
import { ClonaConvenzione } from "@pages/convenzioni/clona-convenzione/ClonaConvenzione";
export const pathNewElement = "aggiungi";

export class Route {
  path: string;
  component: React.ComponentType<any> | undefined;
  render: any;
  permissions: string[];

  constructor(
    path: string,
    component: React.ComponentType<any> | undefined = undefined,
    render: any = null,
    permissions: string[] = []
  ) {
    this.path = path;
    this.component = component;
    this.render = render;
    this.permissions = permissions;
  }
}

interface Routing {
  [key: string]: {
    [key: string]: Route;
  };
}

export const routing: Routing = {
  dashboard: {
    dashboard: new Route(routes.dashboard, Dashboard),
  },
  auth: {
    login: new Route(routes.auth.login),
    forgotPassword: new Route(routes.auth.forgotPassword),
  },
  allestimenti: {
    index: new Route(routes.allestimenti.index, AllestimentiIndex, undefined, [
      "view-allestimenti",
    ]),
  },
  anagrafica: {
    new: new Route(
      routes.anagrafica.new,
      undefined,
      (props: any) => <AnagraficaDetails {...props} creationMode={true} />,
      ["save-anagrafiche"]
    ),
    show: new Route(routes.anagrafica.show, AnagraficaDetails, null, [
      "view-anagrafiche",
    ]),
    index: new Route(routes.anagrafica.index, AnagraficaIndex, null, [
      "view-anagrafiche",
    ]),
  },
  aggiornamentiDb: {
    index: new Route(routes.aggiornamentiDb.index, AggiornamentiDb, null, [
      "view-dbupdates",
    ]),
  },
  articoliFuoriProduzione: {
    index: new Route(
      routes.pneumatici.articoliFuoriProduzione,
      ArticoliFuoriProduzione,
      null,
      ["view-articoli"]
    ),
  },
  capiZona: {
    new: new Route(
      routes.capiZona.new,
      undefined,
      (props: any) => <CapiZonaDetail {...props} creationMode={true} />,
      ["save-capizona"]
    ),
    show: new Route(routes.capiZona.show, CapiZonaDetail, null, [
      "view-capizona",
    ]),
    index: new Route(routes.capiZona.index, CapiZonaIndex, null, [
      "view-capizona",
    ]),
  },
  causaliIva: {
    new: new Route(
      routes.causaliIva.new,
      undefined,
      (props: any) => <CausaliIvaDetail {...props} creationMode={true} />,
      ["save-causaliiva"]
    ),
    show: new Route(routes.causaliIva.show, CausaliIvaDetail, null, [
      "view-causaliiva",
    ]),
    index: new Route(routes.causaliIva.index, CausaliIvaIndex, null, [
      "view-causaliiva",
    ]),
  },
  codiciVelocita: {
    new: new Route(
      routes.codiciVelocita.new,
      undefined,
      (props: any) => <CodiciVelocitaDetail {...props} creationMode={true} />,
      ["save-codvelocita"]
    ),
    show: new Route(routes.codiciVelocita.show, CodiciVelocitaDetail, null, [
      "view-codvelocita",
    ]),
    index: new Route(routes.codiciVelocita.index, CodiciVelocitaIndex, null, [
      "view-codvelocita",
    ]),
  },
  convenzioni: {
    aSconti: new Route(
      routes.convenzioni.aSconto,
      (props: any) => (
        <ConvenzioniASconto
          {...props}
          pageTitle={i18n.t("convenzioniScontiDetailPage:title")}
          convenzioneKey="progConvenzione"
          convenzioneFlotteKey="progConvenzioneFlotte"
          duplicateConvenzione
        />
      ),
      null,
      ["view-convenzioni"]
    ),
    prezziNetti: new Route(
      routes.convenzioni.prezziNetti,
      (props: any) => (
        <ConvenzioniAPrezziNetti
          {...props}
          pageTitle={i18n.t("convenzioniPrezziNetti:title")}
          convenzioneKey="progConvenzione"
          convenzioneFlotteKey="progConvenzioneFlotte"
          duplicateConvenzione
        />
      ),
      null,
      ["view-convenzioni"]
    ),
    prezziNettiFlotte: new Route(
      routes.convenzioni.prezziNettiFlotte,
      (props: any) => (
        <ConvenzioniAPrezziNetti
          {...props}
          pageTitle={i18n.t("convenzioniPrezziNettiFlotte:title")}
          convenzioneKey="progConvenzioneFlotte"
        />
      ),
      null,
      ["view-convenzioni"]
    ),
    flotte: new Route(
      routes.convenzioni.flotte,
      (props: any) => (
        <ConvenzioniASconto
          pageTitle={i18n.t("convenzioniFlotte:title")}
          {...props}
          convenzioneKey="progConvenzioneFlotte"
        />
      ),
      null,
      ["view-convenzioni"]
    ),
    servizi: new Route(
      routes.convenzioni.servizi,
      (props: any) => (
        <ConvenzioniAPrezziNetti
          {...props}
          pageTitle={i18n.t("convenzioniServiziPage:title")}
          isConvenzioneGenerica
        />
      ),
      null,
      ["view-convenzioni"]
    ),
    generiche: new Route(
      routes.convenzioni.generiche.index,
      (props: any) => (
        <ConvenzioniASconto
          {...props}
          pageTitle={i18n.t("convenzioniDescrizione:title")}
          isConvenzioneGenerica
          convenzioneKey="progConvenzione"
        />
      ),
      null,
      ["view-convenzioni"]
    ),
    genericheDescrizioniNew: new Route(
      routes.convenzioni.generiche.descrizioni.new,
      undefined,
      (props: any) => (
        <ConvenzioniGenericheDetail {...props} creationMode={true} />
      ),
      ["view-convenzioni"]
    ),
    genericheDescrizioniShow: new Route(
      routes.convenzioni.generiche.descrizioni.show,
      ConvenzioniGenericheDetail,
      null,
      ["view-convenzioni"]
    ),
    genericheDescrizioniIndex: new Route(
      routes.convenzioni.generiche.descrizioni.index,
      ConvenzioniGenericheIndex,
      null,
      ["view-convenzioni"]
    ),
    cloneConvenzioni: new Route(
      routes.convenzioni.clone,
      ClonaConvenzione,
      null,
      ["save-convenzioni"]
    ),
  },
  disegni: {
    new: new Route(
      routes.disegni.new,
      undefined,
      (props: any) => <DisegniDetail {...props} creationMode={true} />,
      ["save-disegni"]
    ),
    show: new Route(routes.disegni.show, DisegniDetail, null, ["view-disegni"]),
    index: new Route(routes.disegni.index, DisegniIndex, null, [
      "view-disegni",
    ]),
  },
  entiConvenzionati: {
    new: new Route(
      routes.entiConvenzionati.new,
      undefined,
      (props: any) => (
        <EntiConvenzionatiDetail {...props} creationMode={true} />
      ),
      ["save-enticonv"]
    ),
    show: new Route(
      routes.entiConvenzionati.show,
      EntiConvenzionatiDetail,
      null,
      ["view-enticonv"]
    ),
    index: new Route(
      routes.entiConvenzionati.index,
      EntiConvenzionatiIndex,
      null,
      ["view-enticonv"]
    ),
  },
  esercizi: {
    show: new Route(routes.esercizi.show, EserciziDetail, null, [
      "view-esercizi",
    ]),
    index: new Route(routes.esercizi.index, EserciziIndex, null, [
      "view-esercizi",
    ]),
  },
  externalRegisters: {
    fleetVeichles: new Route(
      routes.externalRegisters.fleetVehicles,
      FleetVehiclesImport,
      null,
      ["save-enticonv"]
    ),
    fleetContractTypes: new Route(
      routes.externalRegisters.fleetContractTypes,
      FleetContractImport,
      null,
      ["save-enticonv"]
    ),
  },
  flowManager: {
    tasks: new Route(routes.flowManager.tasks, TasksIndex, null, [
      "view-tasks",
    ]),
    servers: new Route(routes.flowManager.servers, SchedulersIndex, null, [
      "view-schedulers",
    ]),
    semaphores: new Route(
      routes.flowManager.semaphores,
      SemaphoresIndex,
      null,
      ["view-semaphores"]
    ),
    server: new Route(routes.flowManager.server, SchedulerShow, null, [
      "view-schedulers",
    ]),
    index: new Route(routes.flowManager.index, FlowManagerIndex, null, [
      "view-flowmanager",
    ]),
  },
  indiciDiCarico: {
    new: new Route(
      routes.indiciDiCarico.new,
      undefined,
      (props: any) => <IndiciDiCaricoDetail {...props} creationMode={true} />,
      ["save-indicidicarico"]
    ),
    show: new Route(routes.indiciDiCarico.show, IndiciDiCaricoDetail, null, [
      "view-indicidicarico",
    ]),
    index: new Route(routes.indiciDiCarico.index, IndiciDiCaricoIndex, null, [
      "view-indicidicarico",
    ]),
  },
  larghezzaCerchi: {
    new: new Route(
      routes.larghezzaCerchi.new,
      undefined,
      (props: any) => <LarghezzaCerchiDetail {...props} creationMode={true} />,
      ["save-larghezzecerchi"]
    ),
    show: new Route(routes.larghezzaCerchi.show, LarghezzaCerchiDetail, null, [
      "view-larghezzecerchi",
    ]),
    index: new Route(routes.larghezzaCerchi.index, LarghezzaCerchiIndex, null, [
      "view-larghezzecerchi",
    ]),
  },
  larghezzeNominali: {
    new: new Route(
      routes.larghezzeNominali.new,
      undefined,
      (props: any) => (
        <LarghezzeNominaliDetail {...props} creationMode={true} />
      ),
      ["save-larghezzenominali"]
    ),
    show: new Route(
      routes.larghezzeNominali.show,
      LarghezzeNominaliDetail,
      null,
      ["view-larghezzenominali"]
    ),
    index: new Route(
      routes.larghezzeNominali.index,
      LarghezzeNominaliIndex,
      null,
      ["view-larghezzenominali"]
    ),
  },
  logManager: {
    index: new Route(routes.logManager.index, LogManagerIndex, null, [
      "view-logmanager",
    ]),
  },
  nazioni: {
    new: new Route(
      routes.nazioni.new,
      undefined,
      (props: any) => <NazioniDetail {...props} creationMode={true} />,
      ["save-nazioni"]
    ),
    show: new Route(routes.nazioni.show, NazioniDetail, null, ["view-nazioni"]),
    index: new Route(routes.nazioni.index, NazioniIndex, null, [
      "view-nazioni",
    ]),
  },
  pneumatici: {
    new: new Route(
      routes.pneumatici.new,
      undefined,
      (props: any) => <PneumaticiDetail {...props} creationMode={true} />,
      ["save-articoli"]
    ),
    show: new Route(routes.pneumatici.show, PneumaticiDetail, null, [
      "view-articoli",
    ]),
    index: new Route(routes.pneumatici.index, PneumaticiIndex, null, [
      "view-articoli",
    ]),
  },
  priceList: {
    anagraficaListini: new Route(
      routes.priceList.anagraficaListini,
      AnagraficaListini,
      null,
      ["view-listini"]
    ),
    importListino: new Route(
      routes.priceList.importListino,
      ImportListino,
      null,
      ["save-listini"]
    ),
  },
  produttori: {
    new: new Route(
      routes.produttori.new,
      undefined,
      (props: any) => <ProduttoriDetail {...props} creationMode={true} />,
      ["save-produttori"]
    ),
    show: new Route(routes.produttori.show, ProduttoriDetail, null, [
      "view-produttori",
    ]),
    index: new Route(routes.produttori.index, ProduttoriIndex, null, [
      "view-produttori",
    ]),
  },
  province: {
    new: new Route(
      routes.province.new,
      undefined,
      (props: any) => <ProvinceDetail {...props} creationMode={true} />,
      ["save-province"]
    ),
    show: new Route(routes.province.show, ProvinceDetail, null, [
      "view-province",
    ]),
    index: new Route(routes.province.index, ProvinceIndex, null, [
      "view-province",
    ]),
  },
  serieGomme: {
    new: new Route(
      routes.serieGomme.new,
      undefined,
      (props: any) => <SerieGommeDetail {...props} creationMode={true} />,
      ["save-seriegomme"]
    ),
    show: new Route(routes.serieGomme.show, SerieGommeDetail, null, [
      "view-seriegomme",
    ]),
    index: new Route(routes.serieGomme.index, SerieGommeIndex, null, [
      "view-seriegomme",
    ]),
  },
  scontoAddizionale: {
    new: new Route(
      routes.scontoAddizionale.new,
      undefined,
      (props: any) => <ScontoAddizionaleDetail {...props} creationMode={true} />,
      ["save-scontoaddizionale"]
    ),
    show: new Route(routes.scontoAddizionale.show, ScontoAddizionaleDetail, null, [
      "view-scontoaddizionale",
    ]),
    index: new Route(routes.scontoAddizionale.index, ScontoAddizionaleIndex, null, [
      "view-scontoaddizionale",
    ]),
  },
  servizi: {
    new: new Route(
      routes.servizi.new,
      undefined,
      (props: any) => <ServiziDetail {...props} creationMode={true} />,
      ["save-articoli"]
    ),
    show: new Route(routes.servizi.show, ServiziDetail, null, [
      "view-articoli",
    ]),
    index: new Route(routes.servizi.index, ServiziIndex, null, [
      "view-articoli",
    ]),
  },
  stagionalita: {
    new: new Route(
      routes.stagionalita.new,
      undefined,
      (props: any) => <StagionalitaDetail {...props} creationMode={true} />,
      ["save-stagionalita"]
    ),
    show: new Route(routes.stagionalita.show, StagionalitaDetail, null, [
      "view-stagionalita",
    ]),
    index: new Route(routes.stagionalita.index, StagionalitaIndex, null, [
      "view-stagionalita",
    ]),
  },
  strutture: {
    new: new Route(
      routes.strutture.new,
      undefined,
      (props: any) => <StruttureDetail {...props} creationMode={true} />,
      ["save-strutture"]
    ),
    show: new Route(routes.strutture.show, StruttureDetail, null, [
      "view-strutture",
    ]),
    index: new Route(routes.strutture.index, StruttureIndex, null, [
      "view-strutture",
    ]),
  },
  tipoContanti: {
    new: new Route(
      routes.tipoContanti.new,
      undefined,
      (props: any) => <TipoContantiDetail {...props} creationMode={true} />,
      ["save-tipicontanti"]
    ),
    show: new Route(routes.tipoContanti.show, TipoContantiDetail, null, [
      "view-tipicontanti",
    ]),
    index: new Route(routes.tipoContanti.index, TipoContantiIndex, null, [
      "view-tipicontanti",
    ]),
  },
  tipoContratti: {
    new: new Route(
      routes.tipoContratti.new,
      undefined,
      (props: any) => <TipiContrattoDetail {...props} creationMode={true} />,
      ["save-tipicontratto"]
    ),
    show: new Route(routes.tipoContratti.show, TipiContrattoDetail, null, [
      "view-tipicontratto",
    ]),
    index: new Route(routes.tipoContratti.index, TipiContrattoIndex, null, [
      "view-tipicontratto",
    ]),
  },
  users: {
    new: new Route(
      routes.users.new,
      undefined,
      (props: any) => <MasterDataUserDetail {...props} creationMode={true} />,
      ["save-users"]
    ),
    show: new Route(routes.users.show, MasterDataUserDetail, null, [
      "view-users",
    ]),
    index: new Route(routes.users.index, MasterDataUserIndex, null, [
      "view-users",
    ]),
  },
  profile: {
    profile: new Route(routes.profile, MasterDataUserProfile),
  },
  tata: {
    tata: new Route(routes.tata, MasterDataTata),
  },
};

export default routing;
