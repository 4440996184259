import React, { Fragment } from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import { CustomSelectProps } from "../CustomSelectProps";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";

export interface MDSelectProps extends CustomSelectProps {
  label: string;
  data?: any[];
  descriptionKey: string;
  valueKey: string;
  fullData?: boolean;
}

export function CustomSelect(props: MDSelectProps) {
  const { autocomplete, multiple } = props;

  return (
    <Fragment>
      {multiple ? (
        <CustomSelectMultipleComponent {...props} />
      ) : !autocomplete ? (
        <CustomSelectComponent {...props} />
      ) : (
        <CustomAutocompleteComponent {...props} />
      )}
    </Fragment>
  );
}

function CustomSelectMultipleComponent(props: MDSelectProps) {
  const {
    id,
    label,
    values,
    error,
    helperText,
    style,
    data,
    onChange,
    descriptionKey,
    valueKey,
    disabled,
    fullData,
    required = false,
  } = props;
  const { t } = useTranslation(["common"]);

  const handleSelectSingle = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] !== "all") {
      onChange(value, id);
    }
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      if (fullData) {
        return data;
      } else {
        onChange(
          data?.map((item) => item[valueKey]),
          id
        );
      }
    } else {
      onChange([], id);
    }
  };

  return (
    <FormControl
      className="select-multiple"
      fullWidth
      size="small"
      style={{ marginTop: 16, marginBottom: 8 }}
    >
      <InputLabel id={id}>{`${label}${required ? " *" : ""}`}</InputLabel>
      <Select
        fullWidth
        labelId={id}
        multiple
        value={values}
        onChange={handleSelectSingle}
        style={style}
        renderValue={() => (
          <div className="truncate overflow-hidden">
            {data
              ?.filter((item) => values?.includes(item[valueKey]))
              .map((item) => item[descriptionKey])
              .join(", ")}
          </div>
        )}
        input={
          <OutlinedInput id={id} label={`${label}${required ? " *" : ""}`} />
        }
        error={error}
        disabled={disabled}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              color="primary"
              checked={values && values.length > 0}
              indeterminate={
                values && values.length > 0 && values.length !== data?.length
              }
              onChange={handleSelectAll}
            />
          </ListItemIcon>
          <ListItemText primary={t("common:select")} />
        </MenuItem>
        {data?.map((option) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={values && values?.indexOf(option[valueKey]) > -1}
              />
            </ListItemIcon>
            <ListItemText primary={option[descriptionKey]} />
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}

function CustomAutocompleteComponent(props: MDSelectProps) {
  const {
    id,
    data,
    error,
    helperText,
    descriptionKey,
    value,
    valueKey,
    onChange,
    style,
    label,
    fullData,
    required = false,
  } = props;

  const handleChange = (e: React.ChangeEvent<any>, value: any) => {
    e.preventDefault();
    if (fullData) {
      onChange(value, id);
    } else {
      onChange(value ? value[valueKey] : "", id);
    }
  };

  return (
    <Autocomplete
      id={id}
      size="small"
      options={data || []}
      getOptionLabel={(option) => option[descriptionKey]?.toString()}
      value={data?.find((x) => x[valueKey] === value) || null}
      style={style}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={`${label}${required ? " *" : ""}`}
          variant="outlined"
          autoComplete="off"
        />
      )}
    />
  );
}

function CustomSelectComponent(props: MDSelectProps) {
  const {
    id,
    label,
    value,
    error,
    style,
    helperText,
    InputProps,
    data,
    onChange,
    descriptionKey,
    valueKey,
    disabled,
    fullData,
    customDescription,
    required = false,
  } = props;

  const { t } = useTranslation(["common"]);

  const handleChange = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & { value: any };
    if (fullData) {
      let fullData = data?.find((item) => item[valueKey] === target.value);
      onChange(fullData, id);
    } else {
      onChange(target.value, id);
    }
  };

  return (
    <TextField
      select
      fullWidth
      size="small"
      id={id}
      label={`${label}${required ? " *" : ""}`}
      InputProps={Object.assign({ id: id }, InputProps)}
      onChange={(event) => handleChange(event)}
      disabled={disabled || false}
      value={
        value === undefined ||
        value === null ||
        !data?.find((item) => item[valueKey] === value)
          ? ""
          : value
      }
      error={error}
      helperText={helperText}
      style={style}
    >
      <MenuItem key={`${id}-0`} value="">
        <em>{t("common:none")}</em>
      </MenuItem>
      {data?.map((value, index) => {
        return (
          <MenuItem key={`${id}-${index + 1}`} value={value[valueKey]}>
            {customDescription
              ? customDescription(value)
              : value[descriptionKey]}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
