import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { AnagraficaGoodyear, DatiAggiuntiviSedeOp } from './anagrafica.types'

export const setAnagraficaIndex = createAction<{
    data: AnagraficaGoodyear[]
    meta: {
        pagination?: Pagination
    }
}>('anagrafiche/index')
export const resetAnagraficaIndex = createAction('anagrafiche/index/reset')
export const setAnagraficaShow = createAction<AnagraficaGoodyear>('anagrafiche/show')
export const resetAnagraficaShow = createAction('anagrafiche/show/reset')
export const setAnagraficaTata = createAction<AnagraficaGoodyear>('anagrafiche/tata')
export const setDatiAggiuntiviTata = createAction<DatiAggiuntiviSedeOp>('anagrafiche/extra')
export const resetAnagraficaTata = createAction('anagrafiche/tata/reset')
