import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalMonete } from "@store/global/global.selector";
import { getGlobalMonete } from "@store/global/global.effects";

export function SelectMonete(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const monete = useAppSelector(globalMonete);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalMonete())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:moneta`)}
            data={monete}
            descriptionKey={'descrizione'}
            valueKey={'codMoneta'}
            {...props}
        />
    );
}
