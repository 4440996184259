import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import {
  CommonSpeedDial,
  CustomHeaderPage,
  CustomPaginatedDataGrid,
} from "@components/index";
import {
  getDisegniShow,
  postDisegni,
  putDisegni,
} from "@store/disegni/disegni.effects";
import { Disegni, NewDisegni } from "@store/disegni/disegni.types";
import { disegniShow } from "@store/disegni/disegni.selector";
import { Box, Paper, TextField, IconButton } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetDisegniShow } from "@store/disegni/disegni.actions";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";
import {
  Filter,
  FilterMethods,
  FilterTypes,
  SortModel,
} from "@store/common/common.types";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import {
  SelectDivisioni,
  SelectProduttori,
  SelectTipiArticolo,
} from "@components/selects";
import { getArticoliIndex } from "@store/articoli/articoli.effects";
import { articoliIndex } from "@store/articoli/articoli.selector";
import { Articoli } from "@store/articoli/articoli.types";
import { resetArticoliIndex } from "@store/articoli/articoli.actions";
import environment from "@environment/environment";
import { globalTipiArticolo } from "@store/global/global.selector";
import Auth from "@utilities/auth";

interface DisegniDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function DisegniDetail({ match, creationMode }: DisegniDetailProps) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["common", "disegniDetailPage"]);

  const { codProduttore, codDisegno } = useParams<{
    codProduttore: string;
    codDisegno: string;
  }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingDisegni = useAppSelector(disegniShow);
  const [disegno, setDisegno] = useState<Disegni | NewDisegni>({});
  const tipiArticolo = useAppSelector(globalTipiArticolo);
  const articoliAssociati = useAppSelector(articoliIndex);

  const { defaultPageSize } = environment.pagination;
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);
  const [pageIsLoad, setPageIsLoad] = useState<boolean>(false);
  const [sortColumns, setSortColumns] = useState<SortModel[]>([
    { field: "codArticolo", sort: "asc" },
  ]);

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();
    dispatch(resetArticoliIndex());

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getDisegniShow(codProduttore, codDisegno));
  }, [dispatch, codProduttore, codDisegno, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingDisegni) {
      setNewElement(false);
      setDisegno(pollingDisegni);
      handleArticoliAssociati(pollingDisegni.codDisegno);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingDisegni]);

  // componentDidUpdate() Grid
  useEffect(() => {
    if (disegno && pageIsLoad) {
      handleArticoliAssociati(disegno.codDisegno);
    } else {
      setPageIsLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, sortColumns]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetDisegniShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    const target = e.target as typeof e.target & { value: string };
    updateValue(target.value, param);
  };

  const handleChangeSelectValue = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setDisegno((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const handleArticoliAssociati = (codDisegno: number | undefined) => {
    if (codDisegno) {
      const codDisegnoFilter = new Filter(
        FilterTypes.AND,
        "codDisegno",
        FilterMethods.EQUAL,
        codDisegno.toString()
      );
      const codProduttoreFilter = new Filter(
        FilterTypes.AND,
        "codProduttore",
        FilterMethods.EQUAL,
        codProduttore.toString()
      );
      dispatch(
        getArticoliIndex(
          page,
          pageSize,
          { codProduttoreFilter, codDisegnoFilter },
          sortColumns
        )
      );
    }
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "descrizione",
        disegno?.descrizione,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 50 }
      ),
      Validation.buildFieldConfig("codProduttore", disegno?.codProduttore, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codDivisione", disegno?.codDivisione, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codTipoArticolo", disegno?.codTipoArticolo, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !disegno) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && disegno) {
      const newDisegni: NewDisegni = {
        ...disegno,
        codDisegno: 0,
        flgGY: true,
      };

      const resultAction = await dispatch(postDisegni(newDisegni));
      if (postDisegni.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.disegni.show
              .replace(":codProduttore", result.codProduttore.toString())
              .replace(":codDisegno", result.codDisegno.toString())
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putDisegni(disegno as Disegni));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getDisegniShow(codProduttore, codDisegno));
    } else {
      setDisegno({});
    }
  };

  const columns: GridColDef[] = [
    {
      field: "codArticolo",
      headerName: t("common:codArticolo"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.codArticolo,
    },
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 1,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "codTipoArticolo",
      headerName: t("common:tipoArticolo"),
      flex: 1,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;

        const codTipoArticolo = row.codTipoArticolo;
        const tipoArticolo = tipiArticolo.find((tipoArticolo) => {
          return codTipoArticolo === tipoArticolo.codTipoArticolo;
        });

        return tipoArticolo?.descrizione;
      },
    },
    {
      field: "",
      headerName: t("common:"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const { codDivisione, codArticolo } = params.row as Articoli;
          switch (codDivisione) {
            case 1:
              dispatch(
                push(
                  routes.pneumatici.show.replace(":id", codArticolo.toString())
                )
              );
              break;
            case 3:
              dispatch(
                push(routes.servizi.show.replace(":id", codArticolo.toString()))
              );
              break;
          }
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];
  return (
    <div>
      {disegno?.codDisegno || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("disegniDetailPage:newElementTitle")}`
                : `${t("disegniDetailPage:title")} - ${disegno.descrizione}`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!disegno?.codDisegno ? null : (
                  <TextField
                    className="px-4 w-full xl:w-2/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codDisegno")}
                    onChange={(e) => handleChangeValue(e, "codDisegno")}
                    value={disegno?.codDisegno || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={disegno?.descrizione || ""}
                  style={{ minWidth: "15rem" }}
                />
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectProduttori
                    id={"codProduttore"}
                    onChange={handleChangeSelectValue}
                    value={disegno?.codProduttore}
                    disabled={!creationMode}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectDivisioni
                    id="codDivisione"
                    onChange={handleChangeSelectValue}
                    value={disegno?.codDivisione}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectTipiArticolo
                    id={"codTipoArticolo"}
                    onChange={handleChangeSelectValue}
                    value={disegno?.codTipoArticolo}
                    codDivisione="1"
                  />
                </Box>

                <FormControlLabel
                  className="px-3 mr-0 text-field-l w-full xl:w-2/12 md:w-6/12"
                  control={
                    <Checkbox
                      checked={disegno?.flg4Seasons || false}
                      disableRipple={true}
                      onChange={(e) =>
                        handleChangeValue(
                          {
                            target: { value: !(disegno?.flg4Seasons || false) },
                          },
                          "flg4Seasons"
                        )
                      }
                      color="primary"
                    />
                  }
                  style={{ minWidth: "15rem" }}
                  label={t("common:flg4Seasons")}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-disegni") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
            {disegno?.codDisegno ? (
              <Paper className="mt-4">
                <Box>
                  <CustomPaginatedDataGrid
                    data={articoliAssociati}
                    columns={columns}
                    getRowId={(row) => row.codArticolo}
                    sortColumns={sortColumns}
                    onChangePageSize={setPageSize}
                    onChangeSortColumns={setSortColumns}
                    onChangePage={setPage}
                  />
                </Box>
              </Paper>
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
