import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { GridColDef, GridEditCellPropsParams } from "@material-ui/data-grid";
import {
  CustomPaginatedDataGrid,
  CustomHeaderPage,
  FiltersAccordion,
} from "@components/index";
import moment from "moment";
import {
  getSchedulersIndex,
  putScheduler,
} from "@store/flow-manager/flowManager.effects";
import { schedulersIndex } from "@store/flow-manager/flowManager.selector";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { Scheduler } from "@store/flow-manager/flowManager.type";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { Box, IconButton, TextField } from "@material-ui/core";
import { Schedule } from "@material-ui/icons";
import { FieldFilter, FilterMethods, FilterTypes } from "@store/common/common.types";
import Auth from "@utilities/auth";
import environment from "@environment/environment";
import { BooleanFilter } from "@components/boolean-filter/BooleanFilter";

export function SchedulersIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["schedulersIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      "multi",
      t(`schedulersIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "serverName,serverIp,serverDesc",
      "",
      ""
    ),
    active: new FieldFilter(
      "active",
      t(`common:active`),
      FilterTypes.AND,
      "active",
      FilterMethods.EQUAL,
      "true"
    ),
  };

  const {
    data,
    setData,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleTextFieldChangeValue,
    handleSelectChangeValue
  } = useIndexPage<Scheduler>(
    initialFilter,
    [{ field: "name", sort: "asc" }],
    getSchedulersIndex,
    schedulersIndex
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("common:name"),
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => params.row.relatedServer.name,
    },
    {
      field: "extraData",
      headerName: t("common:extraData"),
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => params.row.relatedServer.extraData,
    },
    {
      field: "ip",
      headerName: t("common:IP"),
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => params.row.relatedServer.ip,
    },
    {
      field: "heartbeat",
      headerName: t("common:heartbeat"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        let date = "...";
        if (params.row.heartbeat) {
          date = moment(params.row.heartbeat).format("DD/MM/yyyy HH:mm:ss");
        }
        return date;
      },
    },
    {
      field: "active",
      headerName: t("common:active"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      disableClickEventBubbling: true,
      type: "boolean",
      editable: Auth.hasAccess("stop-schedulers"),
      valueGetter: (params) => {
        return !params.row.disabled;
      },
    },
  ];

  if (Auth.hasAccess("view-schedules")) {
    columns.push({
      field: "seeSchedules",
      headerName: t("common:seeSchedules"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(routes.flowManager.server.replace(":id", params.row.id))
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="primary"
            size="small"
            onClick={onClick}
          >
            <Schedule />
          </IconButton>
        );
      },
    });
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`schedulersIndexPage:title`)} />
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={filters.filterFields.multi.id}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <BooleanFilter
                    label={t(`common:active`)}
                    id={filters.filterFields.active?.id}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.active?.value}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.id;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
              onEditCellChangeCommitted={(
                { id, field, props }: GridEditCellPropsParams,
                event?: React.SyntheticEvent<Element, Event> | undefined
              ) => {
                if (event && event.preventDefault) {
                  event.preventDefault();
                }
                const index = data.data.findIndex((item) => item.id === id);
                if (
                  index !== -1 &&
                  field === "active" &&
                  !props.value !== data.data[index].disabled
                ) {
                  dispatch(
                    putScheduler({
                      id: id as string,
                      disabled: !props.value,
                    })
                  )
                    .unwrap()
                    .then((res: any) => {
                      const tmpData = [...data.data];
                      tmpData[index] = res.data;
                      setData((prevState) => {
                        return {
                          ...prevState,
                          data: tmpData,
                        };
                      });
                    });
                }
              }}
              getRowClassName={(params) =>
                !params.row.heartbeat ||
                moment(params.row.heartbeat).isBefore(
                  moment().subtract(
                    environment.schedulers.minutesToCheck,
                    "minutes"
                  )
                )
                  ? "highlighted-row-text-alert"
                  : ""
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
