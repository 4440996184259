const environment = {
  urlIDP: process.env.REACT_APP_BASE_URL_IDP,
  urlAPI: process.env.REACT_APP_BASE_URL_API,
  pagination: {
    defaultPageSize: 100,
    pageSizeOptions: [10, 25, 50, 100],
  },
  anagrafica: {
    pagination: {
      defaultPageSize: 25,
    },
  },
  articleSearch: {
    pagination: {
      defaultPageSize: 100,
      pageSizeOptions: [10, 25, 50, 100],
    },
  },
  defaultCausaleIva: {
    codCausaleIva: 100,
    aliquota: 22,
  },
  defaultFiltriConvenzioni: {
    defaultTipoArticolo: [9, 11, 10, 12],
    defaultStagionalita: [1, 2],
  },
  semaphoreJobsData: {
    anagraficheUpdates: {
      taskName: "MasterData.Tasks.Updates.GenerateAnagraficheUpdates",
      entities: {
        accountManager: "CapiZona",
        allestimenti: "Allestimenti",
        articoli: "Articoli",
        articoliDatiAggiuntivi: "ArticoliDatiAggiuntivi",
        articoliLabeling: "ArticoliLabeling",
        causaliIVA: "CausaliIVA",
        codiciVelocita: "CodiciVelocita",
        codVelocitaTipoArt: "CodVelocitaTipoArt",
        convenzioniDatiAggiuntivi: "ConvenzioniDatiAggiuntivi",
        criteriConvenzioni: "CriteriConvenzioni",
        desConvenzioniGeneriche: "DesConvenzioniGeneriche",
        dettaglioScontiConvenzioni: "DettaglioScontiConvenzioni",
        disegni: "Disegni",
        disegniDatiAggiuntivi: "DisegniDatiAggiuntivi",
        entiConvenzionati: "EntiConvenzionati",
        entiConvenzionatiPerGY: "EntiConvenzionatiPerGY",
        esercizi: "Esercizi",
        indCaricoTipoArt: "IndCaricoTipoArt",
        indicidiCarico: "IndicidiCarico",
        larghCerchiTipoArt: "LarghCerchiTipoArt",
        larghezzaCerchi: "LarghezzaCerchi",
        larghezzeNominali: "LarghezzeNominali",
        largNomTipoArt: "LargNomTipoArt",
        listinoClienti: "ListinoClienti",
        listinoClientiStorico: "ListinoClientiStorico",
        listinoFornitori: "ListinoFornitori",
        listinoFornitoriStorico: "ListinoFornitoriStorico",
        livelloListino: "LivelloListino",
        marcheAuto: "MarcheAuto",
        modelliAuto: "ModelliAuto",
        nazioni: "Nazioni",
        prezziNettiConvenzioni: "PrezziNettiConvenzioni",
        produttori: "Produttori",
        serieGomme: "SerieGomme",
        serieGommeTipoArt: "SerieGommeTipoArt",
        stagionalita: "Stagionalita",
        strutture: "Strutture",
        testataConvenzioni: "TestataConvenzioni",
        tipoContanti: "TipoContanti",
        tipoContratti: "AnagraficaContrattiFlotte",
      },
    },
    areaRiservataUpdates: {
      taskName: "MasterData.Tasks.Updates.GenerateAreaRiservataUpdates",
      entities: {
        allestimenti: "Allestimenti",
        allestimentiOmologazioni: "AllestimentiOmologazioni",
        anagraficaGoodyear: "AnagraficaGoodyear",
        articoli: "Articoli",
        articoliSellout: "ArticoliSellout",
        codiciVelocita: "CodiciVelocita",
        codVelocitaTipoArt: "CodVelocitaTipoArt",
        coMrktAziendeCooperanti: "CoMrktAziendeCooperanti",
        disegni: "Disegni",
        gruppiMarche: "GruppiMarche",
        indCaricoTipoArt: "IndCaricoTipoArt",
        indiciDiCarico: "IndiciDiCarico",
        larghCerchiTipoArt: "LarghCerchiTipoArt",
        larghezzaCerchi: "LarghezzaCerchi",
        larghezzeNominali: "LarghezzeNominali",
        largNomTipoArt: "LargNomTipoArt",
        listino: "Listino",
        marcheAuto: "MarcheAuto",
        modelliAuto: "ModelliAuto",
        omologazioni: "Omologazioni",
        produttori: "Produttori",
        sediOperativeGoodyear: "SediOperativeGoodyear",
        sediOpGoodyearAbilitazioni: "SediOpGoodyearAbilitazioni",
        sediOpGoodyearReti: "SediOpGoodyearReti",
        serieGomme: "SerieGomme",
        serieGommeTipoArt: "SerieGommeTipoArt",
      },
    },
    centralDatabaseUpdates: {
      taskName: "MasterData.Tasks.Updates.CentralDatabaseUpdate",
      entities: {
        abilitazioni: "abilitazioni",
        altro: "altro",
        anagrafiche: "anagrafiche",
        articoli: "articoli",
        convenzioni: "convenzioni",
        enticonvenzionati: "enticonvenzionati",
        listini: "listini",
        modelli: "modelli",
        utenti: "utenti",
      },
    },
    databaseUpdates: {
      taskName: "MasterData.Tasks.Updates.GenerateDbUpdates",
    },
  },
  codiciTipiArticolo: {
    autovettura: 9,
    trasportoLeggero: 10,
    fuoristrada: 11,
    trasportoPesante: 12,
  },
  codiciProduttori: [1, 6, 8, 15],
  codiciProduttoriPrincipali: [1, 6, 8, 35, 5, 4, 19, 15, 39, 2, 7, 34, 3, 57, 23, 62, 24, 47, 63, 64],
  schedulers: {
    minutesToCheck: 60,
  },
};

export default environment;
