import { SelectLivelliListino } from "@components/selects";
import { Box, Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import { DuplicaListini } from "@store/listini/listini.types";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DuplicaListiniDialogProps {
    open: boolean
    setOpen: (value: React.SetStateAction<boolean>) => void
    onDuplicaListini: (duplicaListini: DuplicaListini) => void,
    errors: { [key: string]: string }
}

export function DuplicaListiniDialog(props: DuplicaListiniDialogProps) {
    const { open, setOpen, onDuplicaListini, errors } = props;
    const { t } = useTranslation(['common'])

    const [duplicaListiniData, setDuplicaListiniData] = useState<DuplicaListini>({
        livelloListino: 0,
        dataInizioValidita: moment(new Date()).format('YYYY-MM-DD'),
        percentualeSconto: 0,
        percentualeAumento: 0
    });

    const handleChangeValue = (value: any, id: string) => {
        setDuplicaListiniData(prevState => {
            return {
                ...prevState,
                [id]: value
            }
        })
    }

    const handleDuplicate = () => {
        onDuplicaListini(duplicaListiniData);
    }

    return (
        <Dialog open={open} onClose={() => {setOpen(false)}}>
            <DialogTitle>{t('common:duplicateListini')}</DialogTitle>
            <Box className="w-full" minWidth="380px">
                <Box className='px-2 w-full'>
                    <SelectLivelliListino
                        id='livelloListino'
                        onChange={handleChangeValue}
                        value={duplicaListiniData.livelloListino}
                        error={errors['livelloListino'] ? true : false}
                        helperText={errors['livelloListino']}
                    />
                </Box>
                <Box className='px-2 w-full'>
                    <TextField
                        key='dataInizioValidita'
                        label={t('common:dataInizioValidita')}
                        type='date'
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={duplicaListiniData?.dataInizioValidita}
                        onChange={(e) => {
                            const target = e.target as typeof e.target & { value: string }
                            handleChangeValue(target.value, 'dataInizioValidita');
                        }}
                    />
                </Box>
                <Box className='px-2 w-full'>
                    <TextField
                        key='percentualeAumento'
                        label={t('common:percentualeAumento')}
                        type='number'
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={duplicaListiniData?.percentualeAumento}
                        onChange={(e) => {
                            const target = e.target as typeof e.target & { value: string }
                            handleChangeValue(target.value, 'percentualeAumento');
                        }}
                    />
                </Box>
                <Box className='px-2 w-full'>
                    <TextField
                        key='percentualeSconto'
                        label={t('common:percentualeSconto')}
                        type='number'
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={duplicaListiniData?.percentualeSconto}
                        onChange={(e) => {
                            const target = e.target as typeof e.target & { value: string }
                            handleChangeValue(target.value, 'percentualeSconto');
                        }}
                    />
                </Box>
                <Box className='w-full flex justify-end px-2'>
                    <Button className='my-4 mr-0' onClick={handleDuplicate}>{t('common:duplicateListini')}</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
