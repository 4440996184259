import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";
import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getMasterDataUserShow,
  postMasterDataUser,
  putMasterDataUser,
} from "@store/master-data-user/masterDataUser.effects";
import {
  MasterDataUser,
  NewMasterDataUser,
} from "@store/master-data-user/masterDataUser.type";
import { masterDataUserShow } from "@store/master-data-user/masterDataUser.selector";
import { Box, Paper, TextField, Tooltip } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetMasterDataUserShow } from "@store/master-data-user/masterDataUser.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import { SelectRuoli } from "@components/selects/ruoli/SelectRuoli";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import Auth from "@utilities/auth";

interface MasterDataUserDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function MasterDataUserDetail({
  match,
  creationMode,
}: MasterDataUserDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "masterDataUserDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingMasterDataUser = useAppSelector(masterDataUserShow);
  const [masterDataUser, setMasterDataUser] = useState<
    MasterDataUser | NewMasterDataUser
  >({} as NewMasterDataUser);

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getMasterDataUserShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingMasterDataUser) {
      setNewElement(false);
      setMasterDataUser(pollingMasterDataUser);
    }
  }, [pollingMasterDataUser]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetMasterDataUserShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setMasterDataUser((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const handleChangeSelectValue = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setMasterDataUser((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("name", masterDataUser?.name, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("surname", masterDataUser?.surname, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("userName", masterDataUser?.userName, [
        ValidationTypes.REQUIRED,
      ]),
      // Validation.buildFieldConfig('roleId', masterDataUser?.roleId, [ValidationTypes.REQUIRED]),
      Validation.buildFieldConfig("email", masterDataUser?.email, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !masterDataUser) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && masterDataUser) {
      const newMasterDataUser: NewMasterDataUser = {
        ...masterDataUser,
      };

      const resultAction = await dispatch(
        postMasterDataUser(newMasterDataUser)
      );
      if (postMasterDataUser.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(push(routes.users.show.replace(":id", result.id.toString())));
      } else {
        //error
      }
    } else {
      dispatch(putMasterDataUser(masterDataUser as MasterDataUser));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getMasterDataUserShow(id));
    } else {
      setMasterDataUser({} as NewMasterDataUser);
    }
  };

  return (
    <div>
      {masterDataUser?.id || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("masterDataUserDetailPage:newElementTitle")}`
                : `${t("masterDataUserDetailPage:title")} - ${
                    masterDataUser.name
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:name")}*`}
                  error={errors["name"] ? true : false}
                  helperText={errors["name"] || ""}
                  onChange={(e) => handleChangeValue(e, "name")}
                  value={masterDataUser?.name || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:surname")}*`}
                  error={errors["surname"] ? true : false}
                  helperText={errors["surname"] || ""}
                  onChange={(e) => handleChangeValue(e, "surname")}
                  value={masterDataUser?.surname || ""}
                />
                <Box className="px-4  w-full xl:w-3/12 md:w-6/12">
                  <SelectRuoli
                    id="roles"
                    multiple
                    onChange={handleChangeSelectValue}
                    values={masterDataUser.roles ? masterDataUser.roles : []}
                    label={`${t("common:roles")}*`}
                    error={errors["roles"] ? true : false}
                    helperText={errors["roles"] || ""}
                  />
                </Box>
              </Box>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:userName")}*`}
                  error={errors["userName"] ? true : false}
                  helperText={errors["userName"] || ""}
                  onChange={(e) => handleChangeValue(e, "userName")}
                  value={masterDataUser?.userName || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:email")}*`}
                  error={errors["email"] ? true : false}
                  helperText={errors["email"] || ""}
                  onChange={(e) => handleChangeValue(e, "email")}
                  value={masterDataUser?.email || ""}
                  InputProps={
                    pollingMasterDataUser?.email
                      ? {
                          endAdornment: masterDataUser?.emailConfirmed ? (
                            <Tooltip title={`${t("common:emailConfirmed")}`}>
                              <CheckCircleIcon style={{ color: "green" }} />
                            </Tooltip>
                          ) : (
                            <Tooltip title={`${t("common:emailNotConfirmed")}`}>
                              <ClearIcon style={{ color: "red" }} />
                            </Tooltip>
                          ),
                        }
                      : { endAdornment: <Fragment></Fragment> }
                  }
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  type="text"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:phoneNumber")}`}
                  error={errors["phoneNumber"] ? true : false}
                  helperText={errors["phoneNumber"] || ""}
                  onChange={(e) => handleChangeValue(e, "phoneNumber")}
                  value={masterDataUser?.phoneNumber || ""}
                  InputProps={
                    pollingMasterDataUser?.phoneNumber
                      ? {
                          endAdornment: masterDataUser?.phoneNumberConfirmed ? (
                            <Tooltip
                              title={`${t("common:phoneNumberConfirmed")}`}
                            >
                              <CheckCircleIcon style={{ color: "green" }} />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={`${t("common:phoneNumberNotConfirmed")}`}
                            >
                              <ClearIcon style={{ color: "red" }} />
                            </Tooltip>
                          ),
                        }
                      : { endAdornment: <Fragment></Fragment> }
                  }
                />
              </Box>
            </Paper>
          </Box>
          {Auth.hasAccess("save-users") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
