import { CustomHeaderPage } from "@components/index";
import { SelectEntiConvenzionati } from "@components/selects";
import { CustomSelect } from "@components/selects/custom-select/CustomSelect";
import { Box, Button, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { getDettaglioScontiConvenzioniDate } from "@store/dettaglio-sconti-convenzioni/dettaglioScontiConvenzioni.effects";
import { EntiConvenzionati } from "@store/enti-convenzionati/entiConvenzionati.types";
import { useAppDispatch } from "@store/hooks";
import { dateFormatter } from "@utilities/utilities";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmAction, ConfirmActionPropsData } from '@components/alert/ConfirmAction';
import { cloneConvenzione } from "@store/criteri-convenzioni/criteriConvenzioni.effects";
import { setError } from "@store/common/common.effects";

export function ClonaConvenzione() {

  const { t } = useTranslation(["common", "clonaConvenzione"]);
  const dispatch = useAppDispatch();

  const [oldDataFineValidita, setOldDataFineValidita] = useState<string>('');
  const [dataInizioValidita, setDataInizioValidita] = useState<string>('');
  const [dataFineValidita, setDataFineValidita] = useState<string>('');
  const [excludeExtraSconti, setExcludeExtraSconti] = useState<boolean>(false);
  const [excludeCodStagionalita, setExcludeCodStagionalita] = useState<any[]>([]);

  const [selectedEnte, setSelectedEnte] = useState<number>(0);
  const [progConvenzione, setProgConvenzione] = useState<number>(0);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({ isOpen: false });

  useEffect(() => {
    if (excludeExtraSconti) {
      setExcludeCodStagionalita([]);
    }
  }, [excludeExtraSconti])

  const handleClone = () => {

    if (! progConvenzione || ! dataInizioValidita || ! dataFineValidita) {
      dispatch(setError({ message: t('common:validatorErrors:fillAllFields') }));
      return;
    }

    setConfirmAction({
      isOpen: true,
      title: t('common:message:defaultConfirmationTitle'),
      subtitle: t('common:message:defaultConfirmationMessage'),
      confirmAction: () => {
        dispatch(cloneConvenzione({
          progConvenzione,
          dataInizioValidita,
          dataFineValidita,
          excludeExtraSconti,
          excludeCodStagionalita
        }))
      }
    })
  }

  return (
    <Fragment>
      <CustomHeaderPage title={`${t("clonaConvenzionePage:title")}`} disabledGoBack />
      <Box className="flex items-center flex-wrap">
        <div className="w-full 2xl:w-3/12 xl:w-3/12 lg:w-6/12 pl-2">
          <SelectEntiConvenzionati
            id={"entiConvenzionati"}
            onChange={async (ente: EntiConvenzionati) => {
              setSelectedEnte(ente.codCliente);
              setProgConvenzione(ente.progConvenzione);
              const { payload }: any = await dispatch(
                getDettaglioScontiConvenzioniDate(ente.progConvenzione.toString())
              );
              setOldDataFineValidita(dateFormatter(new Date(payload.dataFineValidita)) || "")
            }}
            value={selectedEnte}
            fullData={true}
          />
        </div>
        {
          oldDataFineValidita ?
            <div className="w-full 2xl:w-3/12 xl:w-3/12 lg:w-6/12 pl-2">
              {t('common:dataFineValidita')}: {moment(oldDataFineValidita).format('DD/MM/YYYY')}
            </div>
            : null
        }
      </Box>
      <Box className="flex items-center flex-wrap">
        <div className="w-full lg:w-1/4 pl-2">
          <TextField
            fullWidth
            label={t("common:dataInizioValidita")}
            size="small"
            type="date"
            id="dataInizioValidita"
            value={dataInizioValidita}
            onChange={(event: any) => setDataInizioValidita(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="w-full lg:w-1/4 pl-2">
          <TextField
            fullWidth
            label={t("common:dataFineValidita")}
            size="small"
            type="date"
            id="dataFineValidita"
            value={dataFineValidita}
            onChange={(event: any) => setDataFineValidita(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </Box>
      <Box className="flex items-center flex-wrap">
        <div className="w-full lg:w-1/6 pl-2">
          <FormControlLabel
            label={t("common:excludeAllExtraSconti")}
            control={
              <Checkbox
                checked={excludeExtraSconti}
                onChange={(event: any) => {
                  setExcludeExtraSconti(event.target.checked)
                }}
              />
            }
          />
        </div>
        <div className="w-full lg:w-1/4 pl-2">
          {
            ! excludeExtraSconti ?
            <CustomSelect
              id="stagionalita"
              label={t(`common:excludeStagionalita`)}
              data={[
                {
                  id: 1,
                  description: t(`common:summer`)
                },
                {
                  id: 2,
                  description: t(`common:winter`)
                }
              ]}
              multiple
              descriptionKey={'description'}
              valueKey={'id'}
              onChange={(values: any) => {setExcludeCodStagionalita(values)}}
              values={excludeCodStagionalita}
            /> : null
          }
        </div>
      </Box>
      <Box className="py-5 px-2">
        <Button
          onClick={() => {
            handleClone();
          }}
          >
          {t("common:clone")}
        </Button>
      </Box>
      <ConfirmAction
        data={confirmAction}
        setConfirmAction={setConfirmAction}
      />
    </Fragment>
  )
}