import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { menu } from "@utilities/menu";

import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { ReactComponent as CaretRightIcon } from "@assets/icons/Full/Caret-right.svg";
import { ReactComponent as CaretLeftIcon } from "@assets/icons/Full/Caret-left.svg";

import { useAppSelector, useAppDispatch } from "@store/hooks";

import { getRouter, push } from "connected-react-router";
import { toggleSidebar, openSidebar } from "@store/common/common.actions";
import { stateSidebar } from "@store/common/common.selector";
import { Footer } from "@components/footer/Footer";
import Auth from "@utilities/auth";
import { useLocation } from "react-router-dom";

export const drawerWidthOpen = 240;
export const drawerWidthClose = 61;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    color: "#ffffff",
    paddingTop: "5.688rem",
    position: "fixed",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: drawerWidthOpen,
    background: "linear-gradient(136.93deg, #004EA8 0%, #091C2D 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaperClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerWidthClose,
  },
  appBarSpacer: theme.mixins.toolbar,
  items: {
    flexGrow: 1,
  },
  item: {
    height: "2.5rem",
    padding: " 0 1.25rem ",
    width: drawerWidthOpen,
  },
  marginTransion: {
    transition: theme.transitions.create(
      ["padding", "hidden", "width", "height"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }
    ),
  },
  collapse: {
    backdropFilter: "brightness(0.9)",
  },
  activeTab: {
    backgroundColor: "transparent !important",
    "& span, & svg": {
      fontWeight: 800,
    },
  },
}));

export function Sidebar() {
  const classes = useStyles();
  const sidebar = useAppSelector(stateSidebar);
  const path = useAppSelector(getRouter).location.pathname;
  const dispatch = useAppDispatch();
  const [activeItem, setActiveItem] = useState<string | undefined>("");
  const location = useLocation();

  const [collapse, setCollapse] = useState<{ [key: string]: boolean }>({});

  const handleCollapse = (item: string, value: boolean): void => {
    dispatch(openSidebar());
    if (!value && !sidebar.isOpen) return;
    setCollapse({
      ...collapse,
      [item]: value,
    });
  };

  const handleCollapseClose = (item: string, value: boolean): void => {
    dispatch(openSidebar());
    if (!value && !sidebar.isOpen) return;

    let init: { [key: string]: boolean } = {};
    for (let key of Object.keys(collapse)) {
      init[key] = false;
    }
    setCollapse({
      ...init,
      [item]: true,
    });
  };

  useEffect(() => {
    //Check path if path is dashboard close all tabs
    if (path === "/") {
      let collapse: { [key: string]: boolean } = {};
      for (let key of Object.keys(collapse)) {
        collapse[key] = false;
      }
      setCollapse(collapse);
      // setActiveTab('')
    } else {
      const group = menu.getGroupByPath(path);
      if (group !== undefined) {
        handleCollapseClose(group.name, !collapse[group.name]);
        const menuItem = group.getItemByPath(path);
        setActiveItem(menuItem?.path);
      }
    }
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps

  //MuiCollapse-entered

  const handleToggleMenu = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    dispatch(toggleSidebar());
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !sidebar.isOpen ? classes.drawerPaperClose : null
        ),
      }}
      open={sidebar.isOpen}
    >
      <List className={classes.items}>
        {menu.groups.map((group) => {
          if (!Auth.hasAccess(...group.permissions)) return null;
          return (
            <Fragment key={group.name}>
              <ListItem
                className={classes.item}
                button
                onClick={() => {
                  handleCollapse(group.name, !collapse[group.name]);
                }}
              >
                {group.icon}
                <ListItemText className="ml-4" primary={group.name} />
              </ListItem>
              <Collapse
                className={clsx(
                  classes.collapse,
                  classes.marginTransion,
                  sidebar.isOpen ? "pl-8" : null
                )}
                in={collapse[group.name]}
                timeout="auto"
                unmountOnExit
              >
                {group.children.map((item) => {
                  if (!Auth.hasAccess(...item.permissions)) return null;
                  return (
                    <ListItem
                      className={classes.item}
                      button
                      selected={item.path === activeItem}
                      classes={{ selected: classes.activeTab }}
                      onClick={() => {
                        if (location.pathname === item.path) {
                          window.location.reload(true);
                        } else {
                          dispatch(push(item.path));
                        }
                      }}
                      key={item.name}
                    >
                      {item.icon}
                      <ListItemText className="ml-4" primary={item.name} />
                    </ListItem>
                  );
                })}
              </Collapse>
            </Fragment>
          );
        })}
      </List>
      <Box className="mb-4 px-4 flex">
        <CaretRightIcon
          className={clsx(sidebar.isOpen ? "hidden" : null)}
          cursor="pointer"
          width="1.5rem"
          height="1.5rem"
          fill="#ffffff"
          onClick={handleToggleMenu}
        />
        <CaretLeftIcon
          className={clsx(sidebar.isOpen ? "ml-auto" : "hidden")}
          cursor="pointer"
          width="1.5rem"
          height="1.5rem"
          fill="#ffffff"
          onClick={handleToggleMenu}
        />
      </Box>
      {sidebar.isOpen ? <Footer color="initial" /> : null}
    </Drawer>
  );
}
