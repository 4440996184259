import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import { Box, Checkbox, FormControlLabel, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";
import { TipoContratto } from "@store/tipi-contratto/tipiContratto.type";
import { tipoContrattoShow } from "@store/tipi-contratto/tipiContratto.selector";
import { getTipiContrattoShow, postTipoContratto, putTipoContratto } from "@store/tipi-contratto/tipiContratto.effects";
import { resetTipoContrattoShow } from "@store/tipi-contratto/tipiContratto.actions";
import { SelectEntiConvenzionati } from "@components/selects";
import { CustomSelect } from "@components/selects/custom-select/CustomSelect";

interface TipiContrattoDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function TipiContrattoDetail({
  match,
  creationMode,
}: TipiContrattoDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "tipiContrattoDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingTipoContratto = useAppSelector(tipoContrattoShow);
  const [tipoContratto, setTipoContratto] = useState<
    TipoContratto
  >({} as TipoContratto);

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getTipiContrattoShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingTipoContratto) {
      setNewElement(false);
      setTipoContratto(pollingTipoContratto);
    }
  }, [pollingTipoContratto]);

  useEffect(() => {
    if (! tipoContratto?.numeroMaxPneu || tipoContratto?.numeroMaxPneu <= 0) {
      setTipoContratto((prevState) => {
        return {
          ...prevState,
          'bloccoSalvataggio': false
        };
      });
    }
  }, [tipoContratto?.numeroMaxPneu]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetTipoContrattoShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setTipoContratto((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("codCliente", tipoContratto?.codCliente, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("nomeContratto", tipoContratto?.nomeContratto, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("tipoContratto", tipoContratto?.tipoContratto, [
        ValidationTypes.REQUIRED,
      ])
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !tipoContratto) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && tipoContratto) {
      const newTipoContratto: TipoContratto = {
        ...tipoContratto
      };

      const resultAction = await dispatch(postTipoContratto(newTipoContratto));
      if (postTipoContratto.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        if (result && result.id) {
          dispatch(
            push(
              routes.tipoContratti.show.replace(
                ":id",
                result.id.toString()
              )
            )
          );
        }
      } else {
        //error
      }
    } else {
      dispatch(putTipoContratto(tipoContratto as TipoContratto));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getTipiContrattoShow(id));
    } else {
      setTipoContratto({} as TipoContratto);
    }
  };

  return (
    <div>
      {tipoContratto?.id || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("tipiContrattoDetailPage:newElementTitle")}`
                : `${t("tipiContrattoDetailPage:title")} - ${`${tipoContratto.nomeContratto} - ${tipoContratto.enteConvenzionato.ragioneSociale}`}`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!tipoContratto?.id ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:id")}
                    // onChange={(e) => handleChangeValue(e, "codTipoContanti")}
                    value={tipoContratto?.id || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <Box className="px-4 w-full xl:w-3/12 md:w-6/12">
                  <SelectEntiConvenzionati
                    id="codCliente"
                    required
                    value={tipoContratto.codCliente}
                    onChange={(value, id) => {
                      setTipoContratto((prevState) => {
                        return {
                          ...prevState,
                          [id]: value
                        };
                      });
                    }}
                    error={errors["codCliente"] ? true : false}
                    helperText={errors["codCliente"] || ""}
                  />
                </Box>
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:nomeContratto")}*`}
                  error={errors["nomeContratto"] ? true : false}
                  helperText={errors["nomeContratto"] || ""}
                  onChange={(e) => handleChangeValue(e, "nomeContratto")}
                  value={tipoContratto?.nomeContratto || ""}
                />
                <Box className="px-4 w-full xl:w-3/12 md:w-6/12">
                  <CustomSelect
                    id="tipoContratto"
                    error={errors["tipoContratto"] ? true : false}
                    helperText={errors["tipoContratto"] || ""}
                    onChange={(value, id) => {
                      setTipoContratto((prevState) => {
                        return {
                          ...prevState,
                          [id]: value
                        };
                      });
                    }}
                    label={`${t("common:tipoContratto")}*`}
                    data={[
                      {
                        id: 'F',
                        description: 'F'
                      },
                      {
                        id: 'NT',
                        description: 'NT'
                      },
                      {
                        id: 'R',
                        description: 'R'
                      }
                    ]}
                    descriptionKey='description'
                    valueKey='id'
                    value={tipoContratto?.tipoContratto}
                  />
                </Box>
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  type="number"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:numeroMaxPneu")}`}
                  error={errors["numeroMaxPneu"] ? true : false}
                  helperText={errors["numeroMaxPneu"] || ""}
                  onChange={(e) => handleChangeValue(e, "numeroMaxPneu")}
                  value={tipoContratto?.numeroMaxPneu || ""}
                />
                {
                  tipoContratto.numeroMaxPneu && tipoContratto.numeroMaxPneu > 0 ?
                    <Box className="px-4 w-full xl:w-3/12 md:w-6/12">
                      <FormControlLabel
                        className="w-full"
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              tipoContratto?.bloccoSalvataggio || false
                            }
                            disableRipple={true}
                            onChange={(e) => {
                              const target = e.target as typeof e.target & {
                                id: string;
                                checked: boolean;
                              };
                              setTipoContratto((prevState) => {
                                return {
                                  ...prevState,
                                  [target.id]: target.checked
                                };
                              });
                            }}
                            id="bloccoSalvataggio"
                          />
                        }
                        label={t("common:bloccoSalvataggio")}
                      />
                    </Box> : null
                }
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:messaggio")}`}
                  error={errors["messaggio"] ? true : false}
                  helperText={errors["messaggio"] || ""}
                  onChange={(e) => handleChangeValue(e, "messaggio")}
                  value={tipoContratto?.messaggio || ""}
                  multiline
                  rows={3}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-tipicontratto") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
