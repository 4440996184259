import { createReducer } from '@reduxjs/toolkit'
import {
    setMasterDataUserIndex,
    setMasterDataUserShow,
    resetMasterDataUserShow,
    resetMasterDataUserIndex
} from './masterDataUser.actions'
import { MasterDataUserIndex, MasterDataUserShow } from './masterDataUser.type'

const initialState: {
    index: MasterDataUserIndex,
    show: MasterDataUserShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const masterDataUserReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setMasterDataUserIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(resetMasterDataUserIndex, (state, action) => {
            return {
                ...state,
                index: {
                    data: [],
                    meta: {
                        pagination: undefined
                    }
                }
            }
        })
        .addCase(setMasterDataUserShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetMasterDataUserShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

