import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelectProps } from "../CustomSelectProps";
import { CustomSelect } from "../custom-select/CustomSelect";
import { globalServers } from "@store/global/global.selector";
import { getGlobalServers } from "@store/global/global.effects";

export function SelectServers(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const servers = useAppSelector(globalServers);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalServers())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:server`)}
            data={servers}
            descriptionKey={'name'}
            valueKey={'id'}
            customDescription={(row: any) => `${row.name}${row.extraData ? ' - ' + row.extraData : ''}`}
            {...props}
        />
    );
}
