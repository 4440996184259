import { createReducer } from '@reduxjs/toolkit'
import {
    setLargNomTipoArtIndex,
    setLargNomTipoArtShow,
} from './largNomTipoArt.actions'
import { LargNomTipoArtIndex, LargNomTipoArtShow } from './largNomTipoArt.types'

const initialState: {
    index: LargNomTipoArtIndex
    show: LargNomTipoArtShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const largNomTipoArtReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLargNomTipoArtIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setLargNomTipoArtShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
})

