import { createReducer } from '@reduxjs/toolkit'
import {
    setRegioniIndex,
    setRegioniShow,
    resetRegioniShow
} from './regioni.actions'
import { RegioniIndex, RegioniShow } from './regioni.type'

const initialState: {
    index: RegioniIndex,
    show: RegioniShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const regioniReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setRegioniIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setRegioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetRegioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

