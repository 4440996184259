import { AxiosAPI } from "@store/axios.config";
import { AppDispatch, RootState } from "../store";

import { setError, setSuccess } from "../common/common.effects";

import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Filters, SortModel } from "@store/common/common.types";
import { createFilters, createSortColumns } from "@utilities/apiUtilities";
import { DatiBd, DatiBdNew, DbData } from "./aggiornamentoDB.types";
import {
  removeRequestLoader,
  setRequestLoader,
} from "@store/common/common.actions";

interface DbDataIndexAsyncData {
  sizePage: number;
  filters?: Filters;
  sortModel?: SortModel[];
  page?: number;
}

export const postUpdateBd = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  DatiBdNew,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "getAggiornamentiDBShow",
  async (payload: DatiBdNew, { rejectWithValue, dispatch, getState }) => {
    try {
      let response = await AxiosAPI.post(`/AggiornamentiDB`, payload);
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      //dispatch(removeRequestLoader('getAggiornamentiDBShow'));
    }
  }
);

export const putUpdateDb = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  DatiBd,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putAggiornamentiDBShow",
  async (payload: DatiBd, { rejectWithValue, dispatch, getState }) => {
    dispatch(setRequestLoader("putAggiornamentiDBShow"));

    try {
      let response = await AxiosAPI.put(
        `/AggiornamentiDB/${payload.progressivo}`,
        payload
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("putAggiornamentiDBShow"));
    }
  }
);

export const DbDataIndexAsync = createAsyncThunk<
  // Return type of the payload creator
  DbData,
  // First argument to the payload creator
  DbDataIndexAsyncData,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "getAggiornamentiDBShow",
  async (
    payload: DbDataIndexAsyncData,
    { rejectWithValue, dispatch, getState }
  ) => {
    const filterSearch = createFilters(payload.filters);
    const sortSearch = createSortColumns(payload.sortModel);
    //dispatch(setRequestLoader('getAggiornamentiDBShow'));
    try {
      let response = await AxiosAPI.get(
        `/AggiornamentiDB/?PageIndex=${payload.page}&PageSize=${payload.sizePage}${filterSearch}${sortSearch}`
      );
      return response.data;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      //dispatch(removeRequestLoader('getAggiornamentiDBShow'));
    }
  }
);

export const deleteDbData = createAsyncThunk<
  // Return type of the payload creator
  null,
  // First argument to the payload creator
  number,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "deleteDbData",
  async (id: number, { rejectWithValue, dispatch, getState }) => {
    try {
      let response = await AxiosAPI.delete(`/AggiornamentiDB/${id}`);
      dispatch(setSuccess({ message: i18n.t("common:message:deleteSuccess") }));

      return response.data;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
    }
  }
);
