import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalProduttori } from "@store/global/global.effects";
import { globalProduttori } from "@store/global/global.selector";

export function SelectProduttori(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const produttori = useAppSelector(globalProduttori);

    // Goodyear in first position
    let sortedProduttori = [...produttori].sort((p1, p2) => { return p1.codProduttore === 1 ? -1 : p2.codProduttore === 1 ? 1 : 0; });

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalProduttori())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:produttore`)}
            data={sortedProduttori}
            descriptionKey={'descrizione'}
            valueKey={'codProduttore'}
            {...props}
        />
    );
}
