import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getArticoliShow,
  postArticoli,
  putArticoli,
} from "@store/articoli/articoli.effects";
import { Articoli, NewArticoli } from "@store/articoli/articoli.types";
import { articoliShow } from "@store/articoli/articoli.selector";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetArticoliShow } from "@store/articoli/articoli.actions";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Divider from "@material-ui/core/Divider";

import { scrollFunction } from "@utilities/utilities";
import { ArticoliDatiAggiuntivi } from "@store/articoli-dati-aggiuntivi/articoliDatiAggiuntivi.types";
import { setError } from "@store/common/common.effects";
import {
  SelectCausaliIva,
  SelectTipiArticolo,
  SelectUnitaDiMisura,
} from "@components/selects";
import Auth from "@utilities/auth";

interface ServiziDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function ServiziDetail({ match, creationMode }: ServiziDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "serviziDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingArticoli = useAppSelector(articoliShow);
  const [articolo, setArticolo] = useState<Articoli | NewArticoli>({
    codIVA: 100,
    qtaDefault: 1,
  });

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getArticoliShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingArticoli) {
      setNewElement(false);
      setArticolo(pollingArticoli);
    }
  }, [pollingArticoli]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetArticoliShow());
    };
  }, [dispatch]);

  const handleChangeValueArticolo = (e: any, param: string) => {
    const target = e.target as typeof e.target & { value: string };
    updateValue(target.value, param);
  };

  const handleChangeSelectValue = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setArticolo((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const handleChangeArticoliDatiAggiuntivi = (e: any, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setArticolo((prevState) => {
      return {
        ...prevState,
        articoliDatiAggiuntivi: {
          ...prevState.articoliDatiAggiuntivi,
          [param]: target.value,
        },
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", articolo?.descrizione, [
        ValidationTypes.REQUIRED,
      ]) /*  [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH], { maxLength: 6 }) */,
      Validation.buildFieldConfig(
        "codTipoArticolo",
        articolo?.codTipoArticolo,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig("qtaDefault", articolo?.qtaDefault, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codIVA", articolo?.codIVA, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codUdm", articolo?.codUdM, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !articolo) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement) {
      const newArticoli: NewArticoli = {
        ...articolo,
        codArticolo: "0",
        codDivisione: 3,
        utente: "SYSTEM",
        articoliDatiAggiuntivi: {
          ...articolo.articoliDatiAggiuntivi,
          codArticolo: "0",
        },
      };

      const resultAction = await dispatch(postArticoli(newArticoli));
      if (postArticoli.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.servizi.show
              .replace(":id", result.codArticolo.toString())
              .replace(":codProduttore", result.codProduttore.toString())
              .replace(":codTipoArticolo", result.codTipoArticolo.toString())
              .replace(":codCategoriaMerc", result.codCategoriaMerc.toString())
              .replace(
                ":codLarghezzaNominale",
                result.codLarghezzaNominale.toString()
              )
              .replace(":codSerie", result.codSerie.toString())
              .replace(":codStruttura", result.codStruttura.toString())
              .replace(
                ":codLarghezzaCerchio",
                result.codLarghezzaCerchio.toString()
              )
              .replace(":codIndiceCarico1", result.codIndiceCarico1.toString())
              .replace(":codIndiceCarico2", result.codIndiceCarico2.toString())
              .replace(":codVelocita", result.codVelocita.toString())
              .replace(":codStagionalita", result.codStagionalita.toString())
              .replace(":codDisegno", result.codDisegno.toString())
              .replace(":codIVA", result.codIVA.toString())
              .replace(":codUdm", result.codUdM.toString())
          )
        );
      } else {
        //error
      }
    } else {
      const putArticolo: Articoli = {
        ...(articolo as Articoli),
        articoliDatiAggiuntivi: {
          ...(articolo.articoliDatiAggiuntivi as ArticoliDatiAggiuntivi),
          codArticolo: (articolo as Articoli).codArticolo,
        },
      };

      dispatch(putArticoli(putArticolo));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getArticoliShow(id));
    } else {
      setArticolo({});
    }
  };

  return (
    <div>
      {articolo?.codArticolo || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("serviziDetailPage:newElementTitle")}`
                : `${t("serviziDetailPage:title")} - ${articolo.descrizione}`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pt-4">
                <Typography variant="h6" className="px-4">
                  {t("common:articoliDatiTecnici")}
                </Typography>
              </Box>
              <Box className="flex flex-grow flex-row flex-wrap">
                {!articolo?.codArticolo ? null : (
                  <TextField
                    className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codArticolo")}
                    onChange={(e) =>
                      handleChangeValueArticolo(e, "codArticolo")
                    }
                    value={articolo?.codArticolo || ""}
                    style={{ minWidth: "15rem" }}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full 2xl:w-4/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValueArticolo(e, "descrizione")}
                  value={articolo?.descrizione || ""}
                  style={{ minWidth: "15rem" }}
                />
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectTipiArticolo
                    id={"codTipoArticolo"}
                    onChange={handleChangeSelectValue}
                    value={articolo?.codTipoArticolo}
                    codDivisione="3"
                  />
                </Box>
              </Box>
              <Divider className="my-4 mx-4" />
              <Box className="flex flex-grow flex-row flex-wrap">
                <Typography variant="h6" className="px-4">
                  {t("common:articoliDatiGenerali")}
                </Typography>
              </Box>
              <Box className="flex flex-grow flex-row flex-wrap">
                <TextField
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:articoliqtaDefault")}
                  error={errors["qtaDefault"] ? true : false}
                  helperText={errors["qtaDefault"] || ""}
                  onChange={(e) => handleChangeValueArticolo(e, "qtaDefault")}
                  value={articolo?.qtaDefault || ""}
                  style={{ minWidth: "15rem" }}
                />
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectCausaliIva
                    id="codIVA"
                    value={(articolo as Articoli)?.codIVA}
                    onChange={handleChangeSelectValue}
                    autocomplete={true}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectUnitaDiMisura
                    id="codUdM"
                    value={(articolo as Articoli)?.codUdM}
                    onChange={handleChangeSelectValue}
                  />
                </Box>
              </Box>
              <Box className="flex flex-grow flex-row flex-wrap">
                <FormControlLabel
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  control={
                    <Checkbox
                      checked={
                        articolo?.articoliDatiAggiuntivi?.flgServVeicolo ||
                        false
                      }
                      disableRipple={true}
                      onChange={(e) =>
                        handleChangeArticoliDatiAggiuntivi(
                          {
                            target: {
                              value: !(
                                articolo?.articoliDatiAggiuntivi
                                  ?.flgServVeicolo || false
                              ),
                            },
                          },
                          "flgServVeicolo"
                        )
                      }
                      color="primary"
                    />
                  }
                  style={{ minWidth: "15rem" }}
                  label={t("common:articoliflgServVeicolo")}
                />
                <FormControlLabel
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  control={
                    <Checkbox
                      checked={
                        articolo?.articoliDatiAggiuntivi?.flgRunOnFlat || false
                      }
                      disableRipple={true}
                      onChange={(e) =>
                        handleChangeArticoliDatiAggiuntivi(
                          {
                            target: {
                              value: !(
                                articolo?.articoliDatiAggiuntivi
                                  ?.flgRunOnFlat || false
                              ),
                            },
                          },
                          "flgRunOnFlat"
                        )
                      }
                      color="primary"
                    />
                  }
                  style={{ minWidth: "15rem" }}
                  label={t("common:articoliflgRunOnFlat")}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-articoli") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
