import { createReducer } from '@reduxjs/toolkit'
import {
    setLogManagerIndex,
    setLogManagerShow,
    resetLogManagerShow
} from './logManager.actions'
import { LogManagerIndex, LogManagerShow } from './logManager.type'

const initialState: {
    index: LogManagerIndex,
    show: LogManagerShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const logManagerReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLogManagerIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setLogManagerShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetLogManagerShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

