import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { getGlobalDivisioni } from "@store/global/global.effects";
import { Box, Button, Dialog, IconButton, TextField } from "@material-ui/core";
import { convenzioniDatiAggiuntiviIndex } from "@store/convenzioni-dati-aggiuntivi/convenzioniDatiAggiuntivi.selector";
import { getConvenzioniDatiAggiuntiviIndex } from "@store/convenzioni-dati-aggiuntivi/convenzioniDatiAggiuntivi.effects";
import { anagraficaIndex } from "@store/anagrafica/anagrafica.selector";
import { AnagraficaGoodyear } from "@store/anagrafica/anagrafica.types";
import { getAnagraficaIndex } from "@store/anagrafica/anagrafica.effects";
import { FieldFilter, Filter, FilterMethods, Filters, FilterTypes } from "@store/common/common.types";
import { CustomPaginatedDataGrid, FiltersAccordion } from "@components/index";
import { useIndexPage } from '@pages/common/UseIndexPage';
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import _ from "lodash";

interface SelectDataiAggiuntivi {
    open: boolean,
    setOpen: any,
    progconvenzione: number | undefined,
    selectedDealers: any[],
    setSelectedDealers: any
    onConfirmAction: () => void
    dataProps: any
    setData: any
}
enum FILTER_ID {
    FILTER_OR = 'filter-or-multi',
    COD_RETE = 'filter-codRete',
}
export function SelectDatiAggiuntiviConvenzione(props: SelectDataiAggiuntivi) {
    const { open, setOpen, progconvenzione, onConfirmAction, selectedDealers, setSelectedDealers, dataProps, setData } = props
    const { t } = useTranslation(['common'])
    const convenzioniDatiAggIndex = useAppSelector(convenzioniDatiAggiuntiviIndex).data;

    const initialFilter: { [key: string]: FieldFilter } = {
        codReteNonAttivi: new FieldFilter('cod-rete-non-attivi', '', FilterTypes.AND, 'codRete', FilterMethods.EQUAL, '1', true),
        multi: new FieldFilter(FILTER_ID.FILTER_OR, t(`datiAggiuntiviConvenzione:filters:multi`), FilterTypes.OR, 'codDitta,ragioneSociale', '', ''),
    }

    const { data, filters, setFilters, setPage, setPageSize, sortColumns, setSortColumns, handleTextFieldChangeValue } = useIndexPage<AnagraficaGoodyear>(initialFilter, [{ field: 'codDitta', sort: 'asc' }], getAnagraficaIndex, anagraficaIndex, 10);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getGlobalDivisioni())
        dispatch(getAnagraficaIndex(1, 10))

    }, [dispatch]);

    useEffect(() => {
        if ((progconvenzione !== undefined && open)) {

            const ProgConvenzione = new Filter(FilterTypes.AND, 'ProgConvenzione', FilterMethods.EQUAL, progconvenzione.toString());
            let filter: Filters = { ProgConvenzione }
            dispatch(getConvenzioniDatiAggiuntiviIndex(undefined, undefined, filter))
        }
    }, [open, progconvenzione, dispatch]);

    useEffect(() => {
        if (convenzioniDatiAggIndex !== undefined) {
            let r: any[] = []
            convenzioniDatiAggIndex.forEach((item) => {
                r.push(item.anagraficaGoodyear)
            })
            setSelectedDealers(r)
            // set pdf an url name 
            setData({
                nomePDF: convenzioniDatiAggIndex[0]?.nomePDF,
                url: convenzioniDatiAggIndex[0]?.urlLInk
            })
        }// eslint-disable-next-line
    }, [convenzioniDatiAggIndex,]);

    const closeDialoge = () => {
        setSelectedDealers([])
        setOpen(false)
    }
    const handleChangePdfName = (e: any) => {
        setData((prevState: any) => {
            return {
                ...prevState,
                nomePDF: e.target.value
            }
        })
    }
    const handleChangeUrlName = (e: any) => {
        setData((prevState: any) => {
            return {
                ...prevState,
                url: e.target.value
            }
        })
    }


    const columns: GridColDef[] = [
        {
            field: 'codDitta',
            headerName: t('common:codDitta'),
            flex: 0.2,
        },
        {
            field: 'ragioneSociale',
            headerName: t('common:ragioneSociale'),
            flex: 0.5,
            disableClickEventBubbling: true,
        },
        {
            field: 'add',
            headerName: t('common:add'),
            flex: 0.1,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        style={{
                            border: "none",
                            outline: "none"
                        }}
                        className={'mx-2'}
                        color='inherit'
                        size="medium"
                        onClick={() => {
                            let selected = _.cloneDeep(selectedDealers)
                            let duplicate = selected.find((item: any) => {
                                return item.codDitta === params.row?.codDitta
                            })

                            if (duplicate === undefined) {
                                selected.push(params.row)
                                setSelectedDealers(selected)
                            }
                        }}>
                        <AddIcon width={20} height={20} />

                    </IconButton>
                )
            }
        }
    ]
    const columns2: GridColDef[] = [
        {
            field: 'codDitta',
            headerName: t('common:codDitta'),
            flex: 0.2,
        },
        {
            field: 'ragioneSociale',
            headerName: t('common:ragioneSociale'),
            disableClickEventBubbling: true,
            flex: 0.5,

        },
        {
            field: 'remove',
            headerName: t('common:remove'),
            flex: 0.3,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        style={{
                            border: "none",
                            outline: "none"
                        }}
                        className={'mx-2'}
                        color='inherit'
                        size="medium"
                        onClick={() => {
                            let selected: any[] = _.cloneDeep(selectedDealers)
                            let index = selected.filter((item: any) => {
                                return item.codDitta !== params.row?.codDitta
                            })
                            setSelectedDealers(index)

                        }}>
                        <RemoveIcon width={20} height={20} />

                    </IconButton>
                )
            }
        }
    ]

    return (
        <div>
            <Dialog maxWidth={false} fullWidth={true} open={open} onClose={closeDialoge}>

                <Box className='flex flex-grow flex-row flex-wrap pt-4'>
                    <Box className='w-full 2xl:w-10/12 xl:w-10/12 md:w-12/12 px-2' >
                        <TextField
                            label={t('common:PDF')}
                            size={'small'}
                            value={dataProps?.nomePDF || ''}
                            onChange={handleChangePdfName}
                        ></TextField></Box>
                </Box>
                <Box className='flex flex-grow flex-row flex-wrap pb-4'>
                    <Box className='w-full 2xl:w-10/12 xl:w-10/12 md:w-10/12 px-2' >
                        <TextField
                            label={t('common:URL')}
                            size={'small'}
                            value={dataProps?.url || ''}
                            onChange={handleChangeUrlName}

                        >
                        </TextField></Box>

                    <Box className='w-full 2xl:w-12/12 xl:w-12/12 md:w-6/12 pl-2 pt-4'>
                        <FiltersAccordion
                            multiSearchFilter={
                                <TextField
                                    fullWidth
                                    size='small'
                                    id={FILTER_ID.FILTER_OR}
                                    value={filters.filterFields.multi.value}
                                    onChange={(event) => handleTextFieldChangeValue(event, filters.filterFields.multi.id)}
                                    label={filters.filterFields.multi.label} />
                            }
                            filters={filters}
                            setFilters={setFilters}
                            setPage={setPage}
                        />
                    </Box>
                </Box>
                <Box className='flex flex-grow flex-row flex-wrap pb-4'>
                    <Box className='w-full 2xl:w-6/12 xl:w-6/12 md:w-6/12 px-2' >
                        <CustomPaginatedDataGrid
                            data={data}
                            columns={columns}
                            getRowId={(row) => { return row?.codDitta }}
                            sortColumns={sortColumns}
                            onChangePageSize={setPageSize}
                            onChangeSortColumns={setSortColumns}
                            onChangePage={setPage}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-5/12 xl:w-6/12 md:w-6/12 px-2'  >
                        <div style={{ height: 580, overflowY: 'scroll' }}>
                            <DataGrid
                                autoHeight
                                hideFooter
                                rows={selectedDealers[0] === null ? [] : _.orderBy(selectedDealers, 'codDitta')}
                                columns={columns2}
                                getRowId={(row) => { return `id/${row?.codDitta}` }}
                            />
                        </div>
                    </Box>
                </Box>
                <Box className='flex justify-end mr-6'>
                    <Button className='m-4 mr-0' onClick={onConfirmAction}>{t('common:save')}</Button>
                </Box>
            </Dialog>
        </div>

    );
}
