import { createReducer } from '@reduxjs/toolkit'
import {
    setLarghezzeNominaliIndex,
    setLarghezzeNominaliShow,
    resetLarghezzeNominaliShow
} from './larghezzeNominali.actions'
import { LarghezzaNominaleIndex, LarghezzaNominaleShow } from './larghezzeNominali.types'

const initialState: {
    index: LarghezzaNominaleIndex
    show: LarghezzaNominaleShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const larghezzeNominaliReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLarghezzeNominaliIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setLarghezzeNominaliShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetLarghezzeNominaliShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

