import { createReducer } from '@reduxjs/toolkit'
import {
    setSerieGommeIndex,
    setSerieGommeShow,
    resetSerieGommeShow
} from './serieGomme.actions'
import { SerieGommeIndex, SerieGommeShow } from './serieGomme.types'

const initialState: {
    index: SerieGommeIndex
    show: SerieGommeShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const serieGommeReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setSerieGommeIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setSerieGommeShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetSerieGommeShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

