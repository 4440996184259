import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";

import { larghezzeNominaliIndex } from "@store/larghezze-nominali/larghezzeNominali.selector";
import { getLarghezzeNominaliIndex } from "@store/larghezze-nominali/larghezzeNominali.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Button, IconButton, TextField } from "@material-ui/core";
import { FieldFilter, FilterTypes } from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { LarghezzaNominale } from "@store/larghezze-nominali/larghezzeNominali.types";
import { useIndexPage } from "@pages/common/UseIndexPage";
import environment from "@environment/environment";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
}

export function LarghezzeNominaliIndex() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["larghezzeNominaliIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`larghezzeNominaliIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "larghezzaNominale",
      "",
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleTextFieldChangeValue,
  } = useIndexPage<LarghezzaNominale>(
    initialFilter,
    [{ field: "larghezzaNominale", sort: "asc" }],
    getLarghezzeNominaliIndex,
    larghezzeNominaliIndex
  );

  const columns: GridColDef[] = [
    {
      field: "larghezzaNominale",
      headerName: t("common:larghezzaNominale"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.larghezzaNominale,
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(
              routes.larghezzeNominali.show.replace(
                ":id",
                params.row.codLarghezzaNominale
              )
            )
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`larghezzeNominaliIndexPage:title`)}
            disabledGoBack
          >
            {Auth.hasAccess("save-larghezzenominali") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.larghezzeNominali.new))}
              >
                <AddIcon color="primary" />
                {t("larghezzeNominaliIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.codLarghezzaNominale;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .larghezzeNominali,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .largNomTipoArt,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.areaRiservataUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .larghezzeNominali,
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .largNomTipoArt,
            ],
          },
        ]}
      />
    </div>
  );
}
