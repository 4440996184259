import { CommonSpeedDial } from "@components/common-speed-dial/CommonSpeedDial";
import { CustomHeaderPage } from "@components/custom-header-page/CustomHeaderPage";
import { CustomPaginatedDataGridRender } from "@components/custom-paginated-datagrid/CustomPaginatedDataGrid";
import environment from "@environment/environment";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import {
  AspectRatio,
  Assignment,
  CalendarToday,
  Description,
  Person,
  Flag,
  Sort,
} from "@material-ui/icons";
import {
  DbDataIndexAsync,
  postUpdateBd,
  putUpdateDb,
} from "@store/aggiornamento-db/aggiornamentoDB.effects";
import {
  DatiBd,
  DatiBdNew,
} from "@store/aggiornamento-db/aggiornamentoDB.types";

import { SortModel } from "@store/common/common.types";
import { useAppDispatch } from "@store/hooks";
import {
  ValidationConfig,
  Validation,
  ValidationTypes,
} from "@utilities/validation";
import moment from "moment";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Auth from "@utilities/auth";

export function AggiornamentiDb() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [dbData, setdbData] = useState<any>({});
  const [newData, setNewData] = useState<DatiBdNew>({});
  const [pageIsLoad, setPageIsLoad] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [sortColumns, setSortColumns] = useState<SortModel[]>([
    { field: "progressivo", sort: "desc" },
  ]);
  const [selectedData, setSelectedData] = useState<DatiBd>({} as DatiBd);

  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const validate = () => {
    // validate before calling api
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "progressivo",
        newData?.progressivo ? newData?.progressivo : undefined,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig(
        "stringa",
        newData.stringa ? newData.stringa : undefined,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig(
        "descrizione",
        newData.descrizione ? newData.descrizione : undefined,
        [ValidationTypes.REQUIRED]
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    dbData.data.forEach((item: any) => {
      if (item.progressivo === newData.progressivo) {
        validationErrors["progressivo"] = "progressivo non è valido";
      }
    });
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  useEffect(() => {
    if (pageIsLoad) {
      const setData = async () => {
        let { payload } = await dispatch(
          DbDataIndexAsync({
            page: page,
            sizePage: pageSize,
            sortModel: sortColumns,
          })
        );
        setdbData(payload);
      };
      setData();
    } else {
      setPageIsLoad(true);
    }
  }, [dispatch, page, pageSize, sortColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const setData = async () => {
      let { payload } = await dispatch(
        DbDataIndexAsync({
          page: page,
          sizePage: pageSize,
          sortModel: sortColumns,
        })
      );
      setdbData(payload);
    };
    setData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: GridColDef[] = [
    {
      field: "progressivo",
      headerName: t("common:progressivo"),
      flex: 0.1,
      valueGetter: (params) => params.row.progressivo,
    },
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 0.4,
      renderCell: (params) => {
        return (
          <Tooltip color="primary" title={params.row.descrizione}>
            <div style={{ overflow: "hidden" }}> {params.row.descrizione}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "tipo",
      headerName: t("common:tipo"),
      flex: 0.1,
      valueGetter: (params) => params.row.tipo,
    },
    {
      field: "stringa",
      headerName: "Query",
      flex: 0.5,

      renderCell: (params) => {
        return (
          <Tooltip color="primary" title={params.row.stringa}>
            <div style={{ overflow: "hidden" }}> {params.row.stringa}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "flgDaEseguireSempre",
      headerName: t("common:flgDaEseguireSempre"),
      flex: 0.1,
      type: "boolean",
    },
    {
      field: "dataEsecuzione",
      headerName: t("common:dataEsecuzione"),
      flex: 0.25,
      valueGetter: (params) => {
        if (!_.isEmpty(params.row.dataEsecuzione)) {
          return moment(params.row.dataEsecuzione).format(
            "DD/MM/yyyy HH:mm:ss"
          );
        } else {
          return "Nessuna data";
        }
      },
    },
    {
      field: "flgSoloiSIGeSS",
      headerName: t("common:flgSoloiSIGESS"),
      flex: 0.1,
      type: "boolean",
    },
    {
      field: "utente",
      headerName: t("common:user"),
      flex: 0.15,
      valueGetter: (params) => params.row.utente,
    },
    {
      field: "tipoOperazione",
      headerName: t("common:tipoOperazione"),
      flex: 0.1,
      valueGetter: (params) => params.row.tipoOperazione,
    },
    {
      field: "dataOperazione",
      headerName: t("common:dataOperazione"),
      flex: 0.3,
      valueGetter: (params) => {
        // return moment(params.row.dataOperazione).format('DD/MM/yyyy HH:mm:ss');
        if (!_.isEmpty(params.row.dataOperazione)) {
          return moment(params.row.dataOperazione).format(
            "DD/MM/yyyy HH:mm:ss"
          );
        } else {
          return "Nessuna data";
        }
      },
    },
    {
      field: "expand",
      headerName: t("common:expand"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={() => {
              setSelectedData(params.row as DatiBd);
              setDialogOpen(true);
            }}
          >
            <AspectRatio />
          </IconButton>
        );
      },
    },
  ];

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    e.preventDefault();

    const target = e.target as typeof e.target & { value: string };

    setNewData((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };
  const handleChangeValueCheckBox = (e: React.ChangeEvent, param: string) => {
    const target = e.target as typeof e.target & {
      id: string;
      checked: boolean;
    };
    setNewData((prevState) => {
      return {
        ...prevState,
        [param]: target.checked,
      };
    });
  };

  const updateDb = async () => {
    if (validate()) {
      await dispatch(postUpdateBd(newData));
      let { payload } = await dispatch(
        DbDataIndexAsync({ page: 1, sizePage: 10, sortModel: sortColumns })
      );
      setdbData(payload);
      setNewData({});
    }
  };

  const handleChangeQuery = (e: SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & { value: string };

    setSelectedData((prevState) => {
      return {
        ...prevState,
        stringa: target.value,
      };
    });
  };

  return (
    <Fragment>
      <CustomHeaderPage
        title={`${t("common:aggiornamentoDb")}`}
        disabledGoBack
      ></CustomHeaderPage>
      {Auth.hasAccess("save-dbupdates") ? (
        <Paper style={{ padding: 0, paddingBottom: 20 }}>
          <Box className="flex flex-wrap flex-col lg:flex-row">
            <Box className="w-full 2xl:w-2/12 xl:w-1/12 md:w-1/12 pr-4 pl-4">
              <TextField
                size="small"
                label={`${t("common:progressivo")}*`}
                value={newData.progressivo || ""}
                onChange={(e) => handleChangeValue(e, "progressivo")}
                error={errors["progressivo"] ? true : false}
                helperText={errors["progressivo"]}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-3/12 pr-4 pl-4">
              <TextField
                size="small"
                label={`${t("common:descrizione")}*`}
                value={newData.descrizione || ""}
                onChange={(e) => handleChangeValue(e, "descrizione")}
                error={errors["descrizione"] ? true : false}
                helperText={errors["descrizione"]}
              />
            </Box>
          </Box>
          <Box className="flex flex-wrap flex-col lg:flex-row">
            <Box className="w-full 2xl:w-5/12 xl:w-5/12 md:w-5/12 pr-4 pl-4">
              <TextField
                size="small"
                label={`Query*`}
                value={newData.stringa || ""}
                onChange={(e) => handleChangeValue(e, "stringa")}
                error={errors["stringa"] ? true : false}
                helperText={errors["stringa"]}
                multiline
                rows={3}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-a/12 pr-4 pl-4">
              <div className={"pt-1"}>
                <Checkbox
                  onChange={(e) => {
                    handleChangeValueCheckBox(e, "flgDaEseguireSempre");
                  }}
                />
                {t("common:flgDaEseguireSempre")}
              </div>
            </Box>
            <Box className="w-full 2xl:w-2/12 xl:w-2/12 md:w-2/12 pr-4 pl-4">
              <div className={"pt-1"}>
                <Checkbox
                  onChange={(e) => {
                    handleChangeValueCheckBox(e, "flgSoloiSIGESS");
                  }}
                />
                {t("common:flgSoloiSIGESS")}
              </div>
            </Box>
          </Box>
          <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-3/12 pr-4 pl-4">
            <Button onClick={updateDb}> {`${t("common:save")}`}</Button>
          </Box>
        </Paper>
      ) : null}
      <Paper style={{ marginTop: 10 }}>
        <Typography variant="h6" className="px-4 pt-2 pb-4">{`${t(
          "common:dataDb"
        )}`}</Typography>

        <CustomPaginatedDataGridRender
          data={dbData}
          columns={columns}
          getRowId={(row) => {
            return row.progressivo;
          }}
          sortColumns={sortColumns}
          onChangePageSize={setPageSize}
          onChangeSortColumns={setSortColumns}
          onChangePage={setPage}
        />
      </Paper>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.databaseUpdates.taskName,
            entities: null,
          },
        ]}
      />
      <Dialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        fullScreen
        className="p-10"
      >
        <DialogTitle>{t("common:Dettaglio")}</DialogTitle>
        <div className="p-5">
          <List>
            <ListItem>
              <ListItemIcon>
                <Assignment style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:progressivo")}
                secondary={selectedData.progressivo}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Person style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:utente")}
                secondary={selectedData.utente}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Description style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:descrizione")}
                secondary={selectedData.descrizione}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemIcon>
                <CalendarToday style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                style={{ maxWidth: "500px" }}
                primary={t("common:dataEsecuzione")}
                secondary={moment(selectedData.dataEsecuzione).format(
                  "DD/MM/yyyy HH:mm:ss"
                )}
              />
              <ListItemIcon>
                <CalendarToday style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:dataOperazione")}
                secondary={moment(selectedData.dataOperazione).format(
                  "DD/MM/yyyy HH:mm:ss"
                )}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Flag style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                style={{ maxWidth: "500px" }}
                primary={t("common:flgDaEseguireSempre")}
                secondary={selectedData.flgDaEseguireSempre ? "True" : "False"}
              />
              <ListItemIcon>
                <Flag style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:flgSoloiSIGESS")}
                secondary={selectedData.flgSoloiSIGeSS ? "True" : "False"}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Sort style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                style={{ maxWidth: "500px" }}
                primary={t("common:tipo")}
                secondary={selectedData.tipo}
              />
              <ListItemIcon>
                <Sort style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText
                primary={t("common:tipoOperazione")}
                secondary={selectedData.tipoOperazione}
              />
            </ListItem>
            <Divider />
          </List>
          <ListItemIcon className={"pl-4"}>
            <Assignment style={{ color: "#000" }} />{" "}
            <Typography
              variant={"body1"}
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
              className={"pl-6"}
            >
              Query
            </Typography>
          </ListItemIcon>
          <TextField
            className={"pl-16 , mt-0"}
            size="small"
            value={selectedData.stringa || ""}
            onChange={(e) => handleChangeQuery(e)}
            error={errors["stringa"] ? true : false}
            helperText={errors["stringa"]}
            multiline
            variant="outlined"
            disabled={!Auth.hasAccess("save-dbupdates")}
          />
          <Divider />
          {Auth.hasAccess("save-dbupdates") ? (
            <Button
              className={"mt-2"}
              onClick={() => {
                dispatch(putUpdateDb(selectedData))
                  .unwrap()
                  .then(() => {
                    const tmpDbData = [...dbData.data];
                    const index = tmpDbData.findIndex(
                      (x) => x.progressivo === selectedData.progressivo
                    );
                    if (index !== -1) {
                      tmpDbData[index] = selectedData;
                      setdbData((prevState: any) => {
                        return {
                          ...prevState,
                          data: tmpDbData,
                        };
                      });
                      setDialogOpen(false);
                    }
                  });
              }}
            >{`${t("common:save")}`}</Button>
          ) : null}
        </div>
      </Dialog>
    </Fragment>
  );
}
