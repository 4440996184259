import { AxiosAPI } from '@store/axios.config';
import { AppDispatch, AppThunk, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setEntiConvenzionatiSSIndex, setEntiConvenzionatiSSShow } from './entiConvenzioniSS.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filter, FilterMethods, Filters, FilterTypes, SortModel } from '@store/common/common.types';
import { EntiConvenzionatiSS } from './entiConvenzioniSS.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getEntiConvenzionatiSSIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getEntiConvenzionatiSSIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/EntiConvSS?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setEntiConvenzionatiSSIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getEntiConvenzionatiSSIndex'));
    }
}


export const getEntiConvenzionatiSSShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getEntiConvenzionatiSSShow'));

    try {
        const response = await AxiosAPI.get(`/EntiConvSS/${id}`)
        dispatch(setEntiConvenzionatiSSShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getEntiConvenzionatiSSShow'));
    }
}


export const postEntiConvenzionatiSS = (enteConvenzionato: EntiConvenzionatiSS[]): AppThunk => async dispatch => {

    dispatch(setRequestLoader('postEntiConvenzionatiSS'));

    try {
        await AxiosAPI.post(`/EntiConvSS`, enteConvenzionato)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        const entiConvenzionatiFilter = new Filter(FilterTypes.AND, 'codDitta', FilterMethods.EQUAL, enteConvenzionato[0].codDitta);
        dispatch(getEntiConvenzionatiSSIndex(1, 999, { entiConvenzionatiFilter }))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('postEntiConvenzionatiSS'));
    }
}


export const putEntiConvenzionatiSS = (enteConvenzionato: EntiConvenzionatiSS): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putEntiConvenzionatiSS'));

    try {
        await AxiosAPI.put(`/EntiConvSS`, enteConvenzionato)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        //dispatch(setEntiConvenzionatiSSShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putEntiConvenzionatiSS'));
    }
}


interface Data {
    op: string,
    path: string,
    value: boolean
}

export const updateEntiConvenzionatiSS = (enteConvenzionatoSS: EntiConvenzionatiSS, updateData: Data[]): AppThunk => async (dispatch, getState) => {

    dispatch(setRequestLoader('updateEntiConvenzionatiSS'));

    try {
        const { codEnteConv, codDitta, codSedeOp, codRete } = enteConvenzionatoSS

        const result = await AxiosAPI.put(`/EntiConvSS/PATCH/${codEnteConv}/${codDitta}/${codSedeOp}/${codRete}`, updateData)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        const currentState = getState().entiConvenzionatiSS.index;
        const updatedData = [...currentState.data];

        var index = updatedData.findIndex((ente) => {
            return ente.codEnteConv === enteConvenzionatoSS.codEnteConv
            && ente.codDitta === enteConvenzionatoSS.codDitta
            && ente.codSedeOp === enteConvenzionatoSS.codSedeOp
            && ente.codRete === enteConvenzionatoSS.codRete
        })

        if (index !== -1) {
            updatedData[index] = result.data
        }

        dispatch(setEntiConvenzionatiSSIndex({
            ...currentState,
            data: updatedData
        }))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('updateEntiConvenzionatiSS'));
    }
}

interface DeleteEntiConvSSData {
    enteConvenzionatoSS: EntiConvenzionatiSS,
    sendEmail: boolean
}

export const deleteEntiConvenzionatiSS = createAsyncThunk<
    // Return type of the payload creator
    boolean,
    // First argument to the payload creator
    DeleteEntiConvSSData,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteEntiConvenzionatiSS', async (deleteEntiConvSSData: DeleteEntiConvSSData, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteEntiConvenzionatiSS'));

        try {
            const { codEnteConv, codDitta, codSedeOp, codRete } = deleteEntiConvSSData.enteConvenzionatoSS
    
            await AxiosAPI.delete(`/EntiConvSS/${codEnteConv}/${codDitta}/${codSedeOp}/${codRete}/${deleteEntiConvSSData.sendEmail}`)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            return true;
        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            }
            return false;
        } finally {
            dispatch(removeRequestLoader('deleteEntiConvenzionatiSS'));
        }
    })