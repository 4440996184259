import { createReducer } from '@reduxjs/toolkit'
import {
    setFlowManagerIndex, setSchedulerShow, setSchedulersIndex, setSemaphoresIndex
} from './flowManager.actions'
import { FlowManagerIndex, Scheduler, SchedulerIndex, SemaphoresIndex } from './flowManager.type'

const initialState: {
    index: FlowManagerIndex,
    schedulers: SchedulerIndex,
    semaphores: SemaphoresIndex,
    scheduler: Scheduler
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    schedulers: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    semaphores: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    scheduler: {} as Scheduler
}

export const flowManagerReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setFlowManagerIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setSchedulersIndex, (state, action) => {
            return {
                ...state,
                schedulers: action.payload
            }
        })
        .addCase(setSemaphoresIndex, (state, action) => {
            return {
                ...state,
                semaphores: action.payload
            }
        })
        .addCase(setSchedulerShow, (state, action) => {
            return {
                ...state,
                scheduler: action.payload
            }
        })
})

