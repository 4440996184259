import Typography from '@material-ui/core/Typography';

export function Copyright(props: any) {
    var today = new Date();

    return (
        <Typography variant="body2" color={props.color}   >
            Goodyear Tires Italia <br />&copy; 2021{today.getFullYear() > 2021 ? "-"+today.getFullYear() : null}
        </Typography>
    );
}