import { AxiosAPI } from '@store/axios.config';
import { AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'

import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Semaphore } from '@store/flow-manager/flowManager.type';
import { removeRequestLoader, setRequestLoader } from '@store/common/common.actions';

export const semaphoreIndex = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    undefined,

    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getASemaphoreIndex', async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            let response = await AxiosAPI.get(`/FlowManager/Semaphore`)
            return response.data

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
        }
    })
export const semaphoreShow = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    any,

    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getASemaphoreIndex', async (id: string, { rejectWithValue, dispatch, getState }) => {
        try {
            let response = await AxiosAPI.get(`/FlowManager/Semaphore/${id}`)
            return response.data

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
        }
    })

interface PutSemaphore {
    taskName: string
    entities: string[] | null
    green: boolean
}

export const semaphorePut = createAsyncThunk<
    // Return type of the payload creator
    Semaphore[],
    // First argument to the payload creator
    PutSemaphore,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('semaphorePut', async (putSemaphore: PutSemaphore, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('semaphorePut'));

        try {
            let response = await AxiosAPI.put(`/FlowManager/Semaphore/${putSemaphore.taskName}`, {
                entities: putSemaphore.entities,
                green: putSemaphore.green
            })
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            return response.data as Semaphore[]

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('semaphorePut'));
        }
    })

