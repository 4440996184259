import validator from 'validator';
import i18n from '@assets/i18n';

export enum ValidationTypes {
    REQUIRED = 'required',
    EMAIL = 'email',
    REGEX = 'regex',
    FIXED_LENGTH = 'fixed_length',
    RANGE_LENGTH = 'range_length',
    MAX_LENGTH = 'max_length'
}

export interface ValidationConfigAttributes {
    /**
     * @description regexp for validation
     * @type RegExp
     */
    regex?: RegExp,
    /**
     * @description str length
     * @type number
     */
    length?: number,
    /**
     * @description str length
     * @type number
     */
    maxLength?: number,
    /**
     * @description str length
     * @description default 0
     * @type number
     */
    minLength?: number,
}

export interface ValidationConfig {
    /**
     * @description key of the field
     * @type string
     */
    key: string,
    /**
     * @description value to check
     * @type any
     */
    value: any,
    /**
     * @description validations to be applied
     * @type ValidationTypes[]
     */
    types: ValidationTypes[],
    /**
     * @description attributes used by validations
     * @type ValidationConfigAttributes
     */
    attributes?: ValidationConfigAttributes,
    /**
     * @description custom error message
     * @type string
     */
    error?: string
}

export class Validation {

    /**
     * Fields validation based on config argument
     * @param fieldsConfig: ValidationConfig[]
     * @returns {} any
     */
    public static validate(fieldsConfig: ValidationConfig[]): any {
        let errors: { [key: string]: string } = {};
        try {
            fieldsConfig.forEach(fieldConfig => {
                for (const typeIndex in fieldConfig.types) {
                    const type = fieldConfig.types[typeIndex];
                    if (type === ValidationTypes.REQUIRED && validator.isEmpty(fieldConfig?.value?.toString() || '')) {
                        errors[fieldConfig.key] = fieldConfig.error || i18n.t('common:validatorErrors:requiredField');
                        break;
                    }
                    if (type === ValidationTypes.EMAIL && !validator.isEmpty(fieldConfig?.value?.toString() || '') && !validator.isEmail(fieldConfig.value)) {
                        errors[fieldConfig.key] = fieldConfig.error || i18n.t('common:validatorErrors:invalidEmail');
                        break;
                    }
                    if (type === ValidationTypes.REGEX && !validator.isEmpty(fieldConfig?.value?.toString() || '')) {

                        if (!fieldConfig.attributes || !fieldConfig.attributes.regex) {
                            throw Error('Missing regex attribute');
                        }
                        if (!fieldConfig.attributes.regex.test(fieldConfig.value+'')) {
                            errors[fieldConfig.key] = fieldConfig.error || i18n.t('common:validatorErrors:invalidRegex');
                            break;
                        }
                    }
                    if (type === ValidationTypes.FIXED_LENGTH && !validator.isEmpty(fieldConfig?.value?.toString() || '')) {
                        if (!fieldConfig.attributes || !fieldConfig.attributes.length) {
                            throw Error('Missing length attribute');
                        }

                        const { length } = fieldConfig.attributes
                        if (!validator.isLength(fieldConfig.value+'', {
                            min: length,
                            max: length
                        })) {
                            errors[fieldConfig.key] = fieldConfig.error || i18n.t('common:validatorErrors:invalidLength', { length });
                            break;
                        }
                    }
                    if (type === ValidationTypes.RANGE_LENGTH) {
                        if (!fieldConfig.attributes || !fieldConfig.attributes.maxLength) {
                            throw Error('Missing length attribute');
                        }
                        const { minLength = 0, maxLength } = fieldConfig.attributes
                        if (!validator.isLength(fieldConfig.value+'', {
                            min: minLength,
                            max: maxLength
                        })) {
                            errors[fieldConfig.key] = fieldConfig.error || i18n.t('common:validatorErrors:invalidRangeLength', { minLength, maxLength });
                            break;
                        }
                    }
                    if (type === ValidationTypes.MAX_LENGTH && !validator.isEmpty(fieldConfig?.value?.toString() || '')) {
                        if (!fieldConfig.attributes || !fieldConfig.attributes.maxLength) {
                            throw Error('Missing max length attribute');
                        }
                        const { maxLength } = fieldConfig.attributes
                        if (!validator.isLength(fieldConfig.value+'', {
                            min: 0,
                            max: maxLength
                        })) {
                            errors[fieldConfig.key] = fieldConfig.error || i18n.t('common:validatorErrors:invalidMaxLength', { maxLength });
                            break;
                        }
                    }
                }
            });
        } catch (e) {
            console.error(e);
        }

        return errors;
    }

    /**
     * Fields validation based on config argument
     * @param key: string
     * @param value: any
     * @param types: ValidationTypes[]
     * @param attributes: ValidationConfigAttributes
     * @param error: string
     * @returns ValidationConfig
     */
    public static buildFieldConfig(key: string, value: any, types: ValidationTypes[], attributes: ValidationConfigAttributes = {}, error?: string): ValidationConfig {
        return {
            key,
            value,
            types,
            attributes,
            error
        };
    }
}



// ^ (?=.* [a - z])(?=.* [A - Z])(?=.* d)(?=.* [@$!%*?&])[A - Za - zd@$!%*?&]{ 8,} $
// ^ (?=.* [a - z])(?=.* [A - Z])(?=.*\d)(?=.* [@$!%*?&])[A - Za - z\d@$!%*?&]{ 8,} $


