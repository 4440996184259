import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getNazioniShow,
  postNazioni,
  putNazioni,
} from "@store/nazioni/nazioni.effects";
import { Nazioni, NewNazioni } from "@store/nazioni/nazioni.type";
import { nazioniShow } from "@store/nazioni/nazioni.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetNazioniShow } from "@store/nazioni/nazioni.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import { SelectMonete } from "@components/selects";
import Auth from "@utilities/auth";

interface NazioniDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function NazioniDetail({ match, creationMode }: NazioniDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "nazioniDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingNazioni = useAppSelector(nazioniShow);
  const [nazione, setNazione] = useState<Nazioni | NewNazioni>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getNazioniShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingNazioni) {
      setNewElement(false);
      setNazione(pollingNazioni);
    }
  }, [pollingNazioni]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetNazioniShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    const target = e.target as typeof e.target & { value: string };
    updateValue(target.value, param);
  };

  const handleChangeSelectValue = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setNazione((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", nazione?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("siglaNazione", nazione?.siglaNazione, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codMoneta", nazione?.codMoneta, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codMoneta", nazione?.codISO, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !nazione) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && nazione) {
      const newNazioni: NewNazioni = {
        ...nazione,
        codNazione: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(postNazioni(newNazioni));
      if (postNazioni.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(routes.nazioni.show.replace(":id", result.codNazione.toString()))
        );
      } else {
        //error
      }
    } else {
      dispatch(putNazioni(nazione as Nazioni));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getNazioniShow(id));
    } else {
      setNazione({});
    }
  };

  return (
    <div>
      {nazione?.codNazione || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("nazioniDetailPage:newElementTitle")}`
                : `${t("nazioniDetailPage:title")} - ${nazione.descrizione}`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!nazione?.codNazione ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codNazione")}
                    onChange={(e) => handleChangeValue(e, "codNazione")}
                    value={nazione?.codNazione || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={nazione?.descrizione || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:sigla")}*`}
                  error={errors["siglaNazione"] ? true : false}
                  helperText={errors["siglaNazione"] || ""}
                  onChange={(e) => handleChangeValue(e, "siglaNazione")}
                  value={nazione?.siglaNazione || ""}
                />
                <Box
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectMonete
                    id="codMoneta"
                    onChange={handleChangeSelectValue}
                    value={nazione?.codMoneta}
                  />
                </Box>
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:lingua")}
                  error={errors["lingua"] ? true : false}
                  helperText={errors["lingua"] || ""}
                  onChange={(e) => handleChangeValue(e, "lingua")}
                  value={nazione?.lingua || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:area")}
                  error={errors["area"] ? true : false}
                  helperText={errors["area"] || ""}
                  onChange={(e) => handleChangeValue(e, "area")}
                  value={nazione?.area || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:codISO")}
                  error={errors["codISO"] ? true : false}
                  helperText={errors["codISO"] || ""}
                  onChange={(e) => handleChangeValue(e, "codISO")}
                  value={nazione?.codISO || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:numCaratteriPIVA")}
                  error={errors["numCaratteriPIVA"] ? true : false}
                  helperText={errors["numCaratteriPIVA"] || ""}
                  onChange={(e) => handleChangeValue(e, "numCaratteriPIVA")}
                  value={nazione?.numCaratteriPIVA?.toString() || ""}
                  type="numer"
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-nazioni") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
