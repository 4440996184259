import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';

import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { AnagraficaGoodyear, AnagraficaGoodyearDatiAggiuntivi } from '@store/anagrafica/anagrafica.types';
import { dateFormatter } from '@utilities/utilities';
import { SelectProvince } from '@components/selects/province/SelectProvince';
import { SelectCausaliIva } from '@components/selects';


interface TypeSedeLegaleComponent {
    data: AnagraficaGoodyear,
    additionalData: AnagraficaGoodyearDatiAggiuntivi,
    errors: { [key: string]: string }
    handlerChangeData: (e: ChangeEvent) => void,
    handleChangeCheckBoxStatus: (e: ChangeEvent) => void,
    handlerChangeSelect: (e: ChangeEvent<any>, id: string) => void,
    handlerChangeCheckBoxDati: (e: ChangeEvent) => void,
}
export function SedeLegaleComponent(props: TypeSedeLegaleComponent) {

    const { t } = useTranslation(['common'])

    const { data, additionalData, errors, handlerChangeData, handleChangeCheckBoxStatus, handlerChangeSelect, handlerChangeCheckBoxDati } = props

    return (
        <div>
            <Box className='p-2 pb-8'>
                <Box className='flex flex-wrap'>
                    <Box className='w-full 2xl:w-3/12 xl:w-4/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:indirizzo')}
                            size='small'
                            error={errors['indSedeLegale'] ? true : false}
                            helperText={errors['indSedeLegale']}
                            id='indSedeLegale'
                            type="text"
                            value={data?.indSedeLegale || ''}
                            onChange={handlerChangeData}
                            required={true}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:cap')}
                            type="number"
                            size='small'
                            error={errors['capSedeLegale'] ? true : false}
                            helperText={errors['capSedeLegale']}
                            id='capSedeLegale'
                            value={data?.capSedeLegale || ''}
                            onChange={handlerChangeData}
                            required={true}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:localita')}
                            size='small'
                            error={errors['locSedeLegale'] ? true : false}
                            helperText={errors['locSedeLegale']}
                            id='locSedeLegale'
                            value={data?.locSedeLegale || ''}
                            onChange={handlerChangeData}
                            required={true}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <SelectProvince
                            id={'provSedeLegale'}
                            onChange={handlerChangeSelect}
                            value={data?.provSedeLegale || ''}
                            error={errors['provSedeLegale'] ? true : false}
                            helperText={errors['provSedeLegale']}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:telefono1')}
                            size='small'
                            id='telefono1'
                            value={data?.telefono1 || ''}
                            onChange={handlerChangeData}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:telefono2')}
                            size='small'
                            id='telefono2'
                            value={data?.telefono2 || ''}
                            onChange={handlerChangeData}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:fax1')}
                            size='small'
                            id='fax1'
                            value={data?.fax1 || ''}
                            onChange={handlerChangeData}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:codPayer')}
                            size='small'
                            id='codPayer'
                            value={data?.codPayer || ''}
                            onChange={handlerChangeData}

                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:codVendor')}
                            size='small'
                            id='codVendor'
                            value={data?.codVendor || ''}
                            onChange={handlerChangeData}

                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:codSoldTo')}
                            size='small'
                            id='codSoldTo'
                            value={data?.codSoldTo || ''}
                            onChange={handlerChangeData}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:linkSito')}
                            size='small'
                            id='linkSito'
                            value={data?.linkSito || ''}
                            onChange={handlerChangeData}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <SelectCausaliIva
                            id="causaleIva"
                            value={data?.causaleIva}
                            onChange={handlerChangeSelect}
                            autocomplete={true}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:dataInizioValiditaCausaleIva')}
                            size='small'
                            type="date"
                            id='dataInizioValiditaCausaleIva'
                            value={data?.dataInizioValiditaCausaleIva ? dateFormatter(new Date(data?.dataInizioValiditaCausaleIva)) : ''}
                            onChange={handlerChangeData}
                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:dataFineValiditaCausaleIva')}
                            size='small'
                            type='date'
                            id='dataFineValiditaCausaleIva'
                            value={data?.dataFineValiditaCausaleIva ? dateFormatter(new Date(data?.dataFineValiditaCausaleIva)) : ''}
                            onChange={handlerChangeData}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>
                <Box className='flex flex-wrap'>
                    <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>
                        <FormControlLabel
                            className='w-full'
                            control={<Checkbox checked={data?.flgCertificazione || false}
                                color='primary'
                                disableRipple={true}
                                onChange={handleChangeCheckBoxStatus}
                                id='flgCertificazione' />}
                            label={t('common:flgCertificazione')}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>
                        <FormControlLabel
                            className='w-full'
                            control={<Checkbox checked={data?.flgSelfBillingManuale || false}
                                color='primary'
                                disableRipple={true}
                                onChange={handleChangeCheckBoxStatus}
                                id='flgSelfBillingManuale' />}
                            label={t('common:flgSelfBillingManuale')}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>
                        <FormControlLabel
                            className='w-full'
                            control={<Checkbox checked={data?.flgGoodyear || false}
                                color='primary'
                                disableRipple={true}
                                onChange={handleChangeCheckBoxStatus}
                                id='flgGoodyear' />}
                            label={t('common:flgGoodyear')}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>
                        <FormControlLabel
                            className='w-full'
                            control={<Checkbox checked={additionalData?.flgAbilitatoSellout || false}
                                color='primary'
                                disableRipple={true}
                                onChange={handlerChangeCheckBoxDati}
                                id='flgAbilitatoSellout' />}
                            label={t('common:flgAbilitatoSellout')}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>
                        <FormControlLabel
                            className='w-full'
                            control={<Checkbox checked={data?.flgDisabilitaScontoAdd || false}
                                color='primary'
                                disableRipple={true}
                                onChange={handleChangeCheckBoxStatus}
                                id='flgDisabilitaScontoAdd' />}
                            label={t('common:flgDisabilitaScontoAdd')}
                        />
                    </Box>                
                </Box>
            </Box >
        </div>
    )
}

