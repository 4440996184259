import { createReducer } from '@reduxjs/toolkit'
import { AnagraficaGoodyear, DatiAggiuntiviSedeOp } from './anagrafica.types'
import { Pagination } from '@store/common/common.types'
import {
    setAnagraficaIndex,
    setAnagraficaShow,
    resetAnagraficaShow,
    resetAnagraficaIndex,
    resetAnagraficaTata,
    setAnagraficaTata,
    setDatiAggiuntiviTata
} from './anagrafica.actions'

const anagraficaGoodyear: {
    index: {
        data: AnagraficaGoodyear[]
        meta: {
            pagination?: Pagination
        } 
    },
    show: {
        data: AnagraficaGoodyear | undefined
    },
    tata:{
        data: AnagraficaGoodyear | undefined
    },
    extra:{
        data: DatiAggiuntiviSedeOp | undefined
    }
} = {
    index: {
        data: [],
        meta: {}
    },
    show: { data: undefined },
    tata: { data: undefined },
    extra: { data: undefined }

}

export const anagraficaReducer = createReducer(anagraficaGoodyear, (builder) => {
    builder
        .addCase(setAnagraficaIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(resetAnagraficaIndex, (state, action) => {
            return {
                ...state,
                index: {
                    data: [],
                    meta: {}
                }
            }
        })
        .addCase(setAnagraficaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetAnagraficaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
        .addCase(setAnagraficaTata, (state, action) => {
            return {
                ...state,
                tata: {
                    data: action.payload
                }
            }
        })
        .addCase(setDatiAggiuntiviTata, (state, action) => {
            return {
                ...state,
                extra: {
                    data: action.payload
                }
            }
        })
        .addCase(resetAnagraficaTata, (state, action) => {
            return {
                ...state,
                tata: {
                    data: undefined
                }
            }
        })
})