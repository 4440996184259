import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface CustomRecordFoundProps {
  recordFound: number;
}

export function CustomRecordFound(props: CustomRecordFoundProps) {
  const { recordFound } = props;
  const { t } = useTranslation(["common"]);

  return (
    <Box className="flex p-4">
      {t("common:recordFound")}:&nbsp;
      <strong>{recordFound.toLocaleString("it")}</strong>
    </Box>
  );
}
