export const permissions: { [key: string]: string[] } = {
  "send-updates": ["admin"],
  "view-anagrafiche": ["admin", "user", "reporter"],
  "save-anagrafiche": ["admin", "user"],
  "view-capizona": ["admin", "user", "reporter"],
  "save-capizona": ["admin", "user"],
  "view-enticonv": ["admin", "user", "reporter"],
  "save-enticonv": ["admin", "user"],
  "view-tipicontratto": ["admin", "user", "reporter"],
  "save-tipicontratto": ["admin", "user"],
  "view-convenzioni": ["admin", "user", "reporter"],
  "save-convenzioni": ["admin", "user"],
  "view-codvelocita": ["admin", "user", "reporter"],
  "save-codvelocita": ["admin", "user"],
  "view-disegni": ["admin", "user", "reporter"],
  "save-disegni": ["admin", "user"],
  "view-indicidicarico": ["admin", "user", "reporter"],
  "save-indicidicarico": ["admin", "user"],
  "view-larghezzecerchi": ["admin", "user", "reporter"],
  "save-larghezzecerchi": ["admin", "user"],
  "view-larghezzenominali": ["admin", "user", "reporter"],
  "save-larghezzenominali": ["admin", "user"],
  "view-produttori": ["admin", "user", "reporter"],
  "save-produttori": ["admin", "user"],
  "view-seriegomme": ["admin", "user", "reporter"],
  "save-seriegomme": ["admin", "user"],
  "view-articoli": ["admin", "user", "reporter"],
  "save-articoli": ["admin", "user"],
  "view-stagionalita": ["admin", "user", "reporter"],
  "save-stagionalita": ["admin", "user"],
  "view-strutture": ["admin", "user", "reporter"],
  "save-strutture": ["admin", "user"],
  "view-listini": ["admin", "user", "reporter"],
  "save-listini": ["admin", "user"],
  "view-allestimenti": ["admin", "user", "reporter"],
  "view-causaliiva": ["admin", "user", "reporter"],
  "save-causaliiva": ["admin", "user"],
  "view-esercizi": ["admin", "user", "reporter"],
  "save-esercizi": ["admin", "user"],
  "view-nazioni": ["admin", "user", "reporter"],
  "save-nazioni": ["admin", "user"],
  "view-province": ["admin", "user", "reporter"],
  "save-province": ["admin", "user"],
  "view-tipicontanti": ["admin", "user", "reporter"],
  "save-tipicontanti": ["admin", "user"],
  "view-flowmanager": ["admin", "user", "reporter"],
  "view-tasks": ["admin", "user", "reporter"],
  "save-tasks": ["admin"],
  "view-schedulers": ["admin", "user", "reporter"],
  "stop-schedulers": ["admin"],
  "stopstart-flowmanager": ["admin"],
  "view-schedules": ["admin", "user", "reporter"],
  "save-schedules": ["admin"],
  "view-semaphores": ["admin", "user", "reporter"],
  "save-semaphores": ["admin"],
  "view-logmanager": ["admin", "user", "reporter"],
  "view-users": ["admin"],
  "save-users": ["admin"],
  "view-dbupdates": ["admin"],
  "save-dbupdates": ["admin"],
  "view-helpdesk": ["admin"],
  "view-scontoaddizionale": ["admin", "user", "reporter"],
  "save-scontoaddizionale": ["admin", "user"],
};
