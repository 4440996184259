import { AxiosAPI } from "@store/axios.config";
import {
  AppDispatch,
  AppThunk,
  ResourcesIndexThunk,
  RootState,
} from "../store";

import { setError, setSuccess } from "../common/common.effects";
import {
  setRequestLoader,
  removeRequestLoader,
} from "@store/common/common.actions";
import {
  setEntiConvenzionatIndex,
  setEntiConvenzionatiShow,
} from "./entiConvenzionati.actions";
import environment from "@environment/environment";
import { createFilters, createSortColumns } from "@utilities/apiUtilities";
import { Filters, SortModel } from "@store/common/common.types";
import { EntiConvenzionati } from "./entiConvenzionati.types";
import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGlobalEntiConvenzionati } from "@store/global/global.effects";

export const getEntiConvenzionatiIndex: ResourcesIndexThunk =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRequestLoader("getEntiConvenzionatiIndex"));

      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/EntiConvenzionati?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setEntiConvenzionatIndex(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getEntiConvenzionatiIndex"));
    }
  };

export const getEntiConvenzionatiShow =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("getEntiConvenzionatiShow"));

    try {
      const response = await AxiosAPI.get(`/EntiConvenzionati/${id}`);
      dispatch(setEntiConvenzionatiShow(response.data.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getEntiConvenzionatiShow"));
    }
  };

export const putEnteConvenzionato =
  (enteConvenzionato: EntiConvenzionati): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("putEnteConvenzionato"));

    try {
      const response = await AxiosAPI.put(
        `/EntiConvenzionati/${enteConvenzionato.codCliente}`,
        enteConvenzionato
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      dispatch(setEntiConvenzionatiShow(response.data));
      dispatch(getGlobalEntiConvenzionati(true));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("putEnteConvenzionato"));
    }
  };

export const postEnteConvenzionato = createAsyncThunk<
  // Return type of the payload creator
  EntiConvenzionati,
  // First argument to the payload creator
  EntiConvenzionati,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "postEnteConvenzionato",
  async (
    enteConvenzionato: EntiConvenzionati,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("postEnteConvenzionato"));

    try {
      const response = await AxiosAPI.post(
        `/EntiConvenzionati`,
        enteConvenzionato
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      dispatch(getGlobalEntiConvenzionati(true));
      return response.data as EntiConvenzionati;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("postEnteConvenzionato"));
    }
  }
);

export interface ImportFleetVehicles {
  targa: string;
  marca: string;
  fascia: string;
  dataFineValidita: string;
}

interface ImportFleetVehiclesRequest {
  codEnteConv: number;
  veicoliFlotta: ImportFleetVehicles[];
}

export interface ImportFleetVehiclesResponse {
  duplicateItems: string[];
  invalidItems: string[];
  emptyItems: number;
  nonExistentFascia: ImportFleetVehicles[];
  nonExistentMarca: ImportFleetVehicles[];
  itemsAdded : number;
  itemsUpdated : number;
}

export const importVehicles = createAsyncThunk<
  // Return type of the payload creator
  ImportFleetVehiclesResponse,
  // First argument to the payload creator
  ImportFleetVehiclesRequest,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "importVehicles",
  async (
    vehicles: ImportFleetVehiclesRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("importVehicles"));

    try {
      const response = await AxiosAPI.post(
        `/EntiConvenzionati/Veicoli`,
        vehicles
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      return response.data as ImportFleetVehiclesResponse;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("importVehicles"));
    }
  }
);
