import { Filters, SortModel } from "@store/common/common.types"


function createFilters(filters?: Filters): string {

    let filterSearch = ''
    if (filters) {
        Object.entries(filters).forEach(([key, fields]) => {
            if (fields.value !== '') {
                filterSearch += `&${fields.type}Filters[${fields.param}]=${fields.method}${fields.value}`
            }
        })
    }

    return filterSearch
}

function createSortColumns(sortColomns?: SortModel[]): string {

    let sortColumnsSearch = ''
    if (sortColomns) {
        sortColomns.forEach(column => {
            sortColumnsSearch += `${column.sort === 'asc' ? '' : '-'}${column.field},`
        })

        if (sortColumnsSearch !== '') sortColumnsSearch = `&sort=${sortColumnsSearch.slice(0, -1)}`
    }

    return sortColumnsSearch
}



export { createFilters, createSortColumns }