import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { Pagamenti } from './pagamenti.types';

export const setPagamentiIndex = createAction<{
    data: Pagamenti[]
    meta: {
        pagination: Pagination
    }
}>('pagamenti/index')
