import { AxiosAPI } from "@store/axios.config";
import { AppDispatch, AppThunk, RootState } from "../store";

import { setError, setSuccess } from "../common/common.effects";
import { push } from "connected-react-router";
import routes from "@utilities/routes";
import {
  setRequestLoader,
  removeRequestLoader,
} from "@store/common/common.actions";
import {
  setSediOperativeIndex,
  setSedeOperativaShow,
} from "./sedeOperativa.actions";
import environment from "@environment/environment";
import { createFilters, createSortColumns } from "@utilities/apiUtilities";
import {
  Filter,
  FilterMethods,
  Filters,
  FilterTypes,
  SortModel,
} from "@store/common/common.types";
import { SedeOperativa } from "./sedeOperativa.types";
import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSedeOperativaIndex =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[]
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("getSedeOperativaIndex"));

    try {
      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/SediOperativeGoodyear?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setSediOperativeIndex(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getSedeOperativaIndex"));
    }
  };

export const getSedeOperativaShow =
  (id: string, numeroSede: number): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("getSedeOperativaShow"));

    try {
      const response = await AxiosAPI.get(
        `/SediOperativeGoodyear/${id}/${numeroSede}`
      );
      dispatch(setSedeOperativaShow(response.data.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      dispatch(push(routes.anagrafica.index));
    } finally {
      dispatch(removeRequestLoader("getSedeOperativaShow"));
    }
  };

export const putSedeOperativa = createAsyncThunk<
  // Return type of the payload creator
  SedeOperativa,
  // First argument to the payload creator
  SedeOperativa,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putSedeOperativa",
  async (
    sedeOperativa: SedeOperativa,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("putSedeOperativa"));

    try {
      const { codDitta, codSedeOperativa } = sedeOperativa;

      const response = await AxiosAPI.put(
        `/SediOperativeGoodyear/${codDitta}/${codSedeOperativa}`,
        sedeOperativa
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      return response.data;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("putSedeOperativa"));
    }
    return sedeOperativa;
  }
);

export const postSedeOperativa = createAsyncThunk<
  // Return type of the payload creator
  SedeOperativa,
  // First argument to the payload creator
  SedeOperativa,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "postSedeOperativa",
  async (
    sedeOperativa: SedeOperativa,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("postSedeOperativa"));

    try {
      const response = await AxiosAPI.post(
        `/SediOperativeGoodyear`,
        sedeOperativa
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response.data as SedeOperativa;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("postSedeOperativa"));
    }
    return sedeOperativa;
  }
);

interface Data {
  codSedeOp: number;
  codDitta: string;
}
export const deleteSedeOperativa =
  (sedeOperativa: Data): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("deleteSedeOperativa"));

    try {
      const { codDitta, codSedeOp } = sedeOperativa;

      await AxiosAPI.delete(`/SediOperativeGoodyear/${codDitta}/${codSedeOp}`);
      dispatch(setSuccess({ message: i18n.t("common:message:deleteSuccess") }));

      const sedeOperativaFilter = new Filter(
        FilterTypes.AND,
        "codDitta",
        FilterMethods.EQUAL,
        codDitta
      );
      dispatch(getSedeOperativaIndex(1, 999, { sedeOperativaFilter }));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("deleteSedeOperativa"));
    }
  };
