import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";

import { tipoContrattoIndex } from "@store/tipi-contratto/tipiContratto.selector";
import { getTipiContrattoIndex } from "@store/tipi-contratto/tipiContratto.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  CustomHeaderPage,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Button, IconButton } from "@material-ui/core";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { TipoContratto } from "@store/tipi-contratto/tipiContratto.type";
import { useIndexPage } from "@pages/common/UseIndexPage";
import environment from "@environment/environment";
import Auth from "@utilities/auth";

export function TipiContrattoIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["tipiContrattoIndexPage", "common"]);

  const {
    data,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
  } = useIndexPage<TipoContratto>(
    {},
    [
      { field: "enteConvenzionato", sort: "asc" },
      { field: "nomeContratto", sort: "asc" }
    ],
    getTipiContrattoIndex,
    tipoContrattoIndex,
    environment.pagination.defaultPageSize,
    true
  );

  const columns: GridColDef[] = [
    {
      field: "enteConvenzionato",
      headerName: t("common:enteConvenzionato"),
      flex: 1,
      disableClickEventBubbling: true,
      sortable: false,
      valueGetter: (params) => params.row.enteConvenzionato.ragioneSociale,
    },
    {
      field: "nomeContratto",
      headerName: t("common:nomeContratto"),
      flex: 1,
      disableClickEventBubbling: true,
      sortable: false,
      valueGetter: (params) => params.row.nomeContratto,
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(
              routes.tipoContratti.show.replace(
                ":id",
                params.row.id
              )
            )
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`tipiContrattoIndexPage:title`)}
            disabledGoBack
          >
            {Auth.hasAccess("save-tipicontratto") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.tipoContratti.new))}
              >
                <AddIcon color="primary" />
                {t("tipiContrattoIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.id;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities.tipoContratti
            ]
          },{
            taskName: environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.centralDatabaseUpdates.entities
                .enticonvenzionati
            ],
          },
        ]}
      />
    </div>
  );
}
