import { SelectCausaliIva } from "@components/selects";
import { Box, Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import { globalCausaliIva } from "@store/global/global.selector";
import { useAppSelector } from "@store/hooks";
import { Validation, ValidationConfig, ValidationTypes } from "@utilities/validation";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface AggiornaIvaDialogProps {
    open: boolean
    setOpen: (value: React.SetStateAction<boolean>) => void
    onAggiornaIva: (aliquota: number, dataInizioValidita: string) => void,
}

export function AggiornaIvaDialog(props: AggiornaIvaDialogProps) {
    const { open, setOpen, onAggiornaIva } = props;
    const { t } = useTranslation(['common'])

    const causaliIva = useAppSelector(globalCausaliIva)

    const [codIva, setCodIva] = useState<number>(0);
    const [dataInizioValidita, setDataInizioValidita] = useState<string>(moment(new Date()).format('YYYY-MM-DD'))
    const [errors, setErrors] = useState({} as { [key: string]: string });

    const handleChangeCausaleIva = (value: any, id: string) => {
        setCodIva(value);
    }

    const validate = () => {
        setErrors({})

        const fieldsConfig: ValidationConfig[] = [
            Validation.buildFieldConfig('codIva', codIva, [ValidationTypes.REQUIRED]),
            Validation.buildFieldConfig('dataInizioValidita', codIva, [ValidationTypes.REQUIRED])
        ];

        const validationErrors = Validation.validate(fieldsConfig);
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    }

    const handleAggiornaIva = () => {
        if (validate()) {
            onAggiornaIva(causaliIva.find(causale => causale.codIVA === codIva)?.aliquota || 0, dataInizioValidita);
        }
    }

    return (
        <Dialog open={open} onClose={() => {setOpen(false)}}>
            <DialogTitle>{t('common:aggiornaIVA')}</DialogTitle>
            <Box className="w-full" minWidth="380px">
                <Box className='px-2 w-full'>
                    <SelectCausaliIva
                        id='causaleIva'
                        onChange={handleChangeCausaleIva}
                        value={codIva}
                        autocomplete
                        error={errors['codIva'] ? true : false}
                        helperText={errors['codIva']}
                    />
                </Box>
                <Box className='px-2 w-full'>
                    <TextField
                        key='dataInizioValidita'
                        label={t('common:dataInizioValidita')}
                        type='date'
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={dataInizioValidita}
                        error={errors['dataInizioValidita'] ? true : false}
                        helperText={errors['dataInizioValidita']}
                        onChange={(e) => {
                            const target = e.target as typeof e.target & { value: string }
                            setDataInizioValidita(target.value)
                        }}
                    />
                </Box>
                <Box className='w-full flex justify-end px-2'>
                    <Button className='my-4 mr-0' onClick={handleAggiornaIva}>{t('common:aggiornaIVA')}</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
