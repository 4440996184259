import { AxiosAPI } from '@store/axios.config';
import { AppDispatch, AppThunk  } from '../store/store';

import { setError, setSuccess } from '../store/common/common.effects';
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import i18n from '@assets/i18n';
import { ActionCreatorWithPayload, AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createFilters, createSortColumns } from './apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';

interface GetRequest {
  path: string,
  loaderId: string
}

export const handleError = (error: any): AppThunk => async dispatch => {
  if (error.response && error.response.data && error.response.data.message) {
    dispatch(setError({ message: error.response.data.message }));
  } else {
    console.error(error);
    dispatch(setError({ message: i18n.t('common:message:genericError') }));
  }
}

export const get = createAsyncThunk <any, GetRequest, { dispatch: AppDispatch }> ('getRequest', async (params: GetRequest, {dispatch}) => {

  dispatch(setRequestLoader(params.loaderId));

  try {
    const path = params.path.startsWith('/') ? params.path : `/${params.path}`;
    const response = await AxiosAPI.get(path)
    return response.data;

  } catch (error: any) {
    dispatch(handleError(error));
    throw new Error(error);
  } finally {
    dispatch(removeRequestLoader(params.loaderId));
  }
});

interface PostRequest {
  path: string,
  loaderId: string,
  entity: any
}

export const post = createAsyncThunk<any, PostRequest, { dispatch: AppDispatch }>('postRequest', async (params: PostRequest, {dispatch}) => {

  dispatch(setRequestLoader(params.loaderId));

  try {
    const path = params.path.startsWith('/') ? params.path : `/${params.path}`;
    const response = await AxiosAPI.post(path, params.entity)
    return response.data;

  } catch (error: any) {
    dispatch(handleError(error));
    throw new Error(error);
  } finally {
    dispatch(removeRequestLoader(params.loaderId));
  }
});

interface PutRequest {
  path: string,
  loaderId: string,
  entity: any
}

export const put = createAsyncThunk<any, PutRequest, { dispatch: AppDispatch }>('putRequest', async (params: PutRequest, {dispatch}) => {

  dispatch(setRequestLoader(params.loaderId));

  try {
    const path = params.path.startsWith('/') ? params.path : `/${params.path}`;
    const response = await AxiosAPI.put(path, params.entity)
    return response.data;

  } catch (error: any) {
    dispatch(handleError(error));
    throw new Error(error);
  } finally {
    dispatch(removeRequestLoader(params.loaderId));
  }
});

export const getEntityIndex = <Type>(
  basePath: string,
  loaderId: string,
  page: number,
  pageSize: number,
  indexAction: ActionCreatorWithPayload<Type, string>,
  filters?: Filters,
  sortModel?: SortModel[]): AppThunk => async dispatch => {

  const filterSearch = createFilters(filters)
  const sortSearch = createSortColumns(sortModel)

  const response: AnyAction = await dispatch(get({
    path: `/${basePath}?PageIndex=${page}&PageSize=${pageSize}${filterSearch}${sortSearch}`,
    loaderId: loaderId
  }));

  dispatch(indexAction(response.payload))
};

export const getEntityShow = <Type>(
  path: string,
  loaderId: string,
  showAction: ActionCreatorWithPayload<Type, string>,): AppThunk => async dispatch => {

  const response: AnyAction = await dispatch(get({
    path: `/${path}`,
    loaderId: loaderId
  }));

  dispatch(showAction(response.payload.data));
};

export const postEntity = createAsyncThunk<any,PostRequest,{dispatch: AppDispatch}>('postEntity', async (params: PostRequest, {dispatch}) => {

  const response: AnyAction = await dispatch(post(params));
  if (! response.error) {
    dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
  } else {
    throw new Error(response.error);
  }

  return response.payload && response.payload.data ? response.payload.data : response.payload;
});


export const putEntity = createAsyncThunk<any,PostRequest,{dispatch: AppDispatch}>('putEntity', async (params: PutRequest, {dispatch}) => {

  const response: AnyAction = await dispatch(put(params))
  if (! response.error) {
    dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
  } else {
    throw new Error(response.error);
  }

  return response.payload && response.payload.data ? response.payload.data : response.payload;
});