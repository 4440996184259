import { createReducer } from '@reduxjs/toolkit'
import {
    setStruttureIndex,
    setStruttureShow,
    resetStruttureShow
} from '@store/strutture/strutture.actions'
import { StruttureIndex, StruttureShow } from './strutture.types'

const initialState: {
    index: StruttureIndex
    show: StruttureShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const struttureReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setStruttureIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setStruttureShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetStruttureShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

