import { CustomHeaderPage } from '@components/custom-header-page/CustomHeaderPage';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { Box, Button, Card, CardContent, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, Step, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';
import { CustomSelect } from '@components/selects/custom-select/CustomSelect';
import { SelectLivelliListino, SelectProduttori } from '@components/selects';
import moment from 'moment';
import { Validation, ValidationConfig, ValidationTypes } from '@utilities/validation';
import { setError } from '@store/common/common.effects';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { scrollFunction } from '@utilities/utilities';
import { postListini } from '@store/listini/listini.effects';
import { ImportListiniSummary, NewListino } from '@store/listini/listini.types';
import { ImportSummaryDialog } from '../components/ImportSummaryDialog';
import { globalLivelliListino, globalProduttori } from '@store/global/global.selector';
import { ConfirmAction, ConfirmActionPropsData } from '@components/alert/ConfirmAction';
import { ReactComponent as TireIcon } from '@assets/icons/Full/Tire.svg';
import { ReactComponent as ClipboardIcon } from '@assets/icons/Full/Clipboard-alt.svg';
import { ReactComponent as CalendarIcon } from '@assets/icons/Full/Calendar.svg';
import { ReactComponent as CopyIcon } from '@assets/icons/Full/Copy.svg';
import { ReactComponent as DocumentIcon } from '@assets/icons/Full/Document.svg';
import i18n from '@assets/i18n';

export function ImportListino() {

    const dispatch = useAppDispatch();
    const { t } = useTranslation(['anagraficaListiniPage', 'common'])

    const produttori = useAppSelector(globalProduttori);
    const livelliListini = useAppSelector(globalLivelliListino);

    const steps: string[] = [
        t('common:datiListino'),
        t('common:associazioneColonne'),
        t('common:summary')
    ];

    const [activeStep, setActiveStep] = useState(0);

    const [fileName, setFileName] = useState<string>(t('common:noFileSelected'));
    const [data, setData] = useState<any[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [columnsAssociation, setColumnsAssociation] = useState<{
        index: string,
        generatedDescription: string
        field: string,
        description: string
    }[]>([]);

    const [filters, setFilters] = useState<{
        flgReplicaSuListinoFornitori: boolean,
        livelloListino: string
        codProduttore: string
        dataInizioValidita: string
    }>({
        livelloListino: '',
        codProduttore: '',
        dataInizioValidita: moment(new Date()).format('YYYY-MM-DD'),
        flgReplicaSuListinoFornitori: true
    });
    const [errors, setErrors] = useState({} as { [key: string]: string });
    const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({ isOpen: false });

    const [importListiniSummary, setImportListiniSummary] = useState<ImportListiniSummary>({} as ImportListiniSummary);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleFileChange = (e: any) => {
        setFileName(e.target.files[0].name);
        // Parso il CSV e lo setto il contenuto stato
        Papa.parse(e.target.files[0], {
            header: false,
            delimiter: ";",
            complete: results => {
                setData(results.data)
            },
            encoding: 'utf-8',
            transform: (value, field) => {
                return value.replace('�', '').trim(); //TODO Capire come gestire i caratteri speciali
            },
            skipEmptyLines: true
        });
    };

    useEffect(() => {
        const colAssociation: any[] = [];
        if (data && data.length > 0) {
            Object.keys(data[0]).forEach((key) => {
                colAssociation.push({
                    index: key,
                    generatedDescription: `Col${key}`,
                    field: '',
                    description: `Col${key}`
                });
            })
        }
        setColumnsAssociation(colAssociation);
    }, [data])

    useEffect(() => {
        if (filters.livelloListino !== '' && Number(filters.livelloListino) === 0) {
            handleFiltersChangeValue(true, 'flgReplicaSuListinoFornitori');
        } else {
            handleFiltersChangeValue(false, 'flgReplicaSuListinoFornitori');
        }
    }, [filters.livelloListino])

    // Ricreo le colonne della tabella quando cambia l'associazione tra colonna e campo
    useEffect(() => {
        const cols: GridColDef[] = [];
        if (columnsAssociation && columnsAssociation.length > 0) {
            columnsAssociation.forEach((columnAssociation) => {
                cols.push({
                    field: columnAssociation.index,
                    headerName: columnAssociation.description,
                    disableColumnMenu: true,
                    disableClickEventBubbling: true,
                    sortable: false,
                    hideSortIcons: true,
                    flex: 1
                });
            })
        }
        setColumns(cols);
    }, [columnsAssociation])

    const handleFiltersChangeValue = (value: any, id: string) => {
        setFilters(prevState => {
            return {
                ...prevState,
                [id]: value
            }
        })
    }

    const handleSelectChangeValue = (value: any, id: string) => {
        let colIndex = columnsAssociation.findIndex((item) => {
            return item.index === id;
        })
        if (colIndex !== -1) {

            let tempColumns = [...columnsAssociation]
            const col = {...tempColumns[colIndex]}
            col.description = columnsConfig.find((colConfig) => colConfig.id === value)?.description || '';
            col.field = value;
            tempColumns[colIndex] = col
            setColumnsAssociation(tempColumns);
        }
    }

    const validate = () => {
        setErrors({})

        if (data.length === 0) {
            dispatch(setError({ message: t('common:message:nothingToImport') }))
            return false
        }

        if (columnsAssociation.findIndex(x => x.field === '') !== -1) {
            dispatch(setError({ message: t('common:message:columnsWithoutAssociation') }))
            return false;
        }

        if (Array.from(new Set(columnsAssociation.map(item => item.field))).length !== columnsAssociation.length) {
            dispatch(setError({ message: t('common:message:columnsWithSameAssociation') }))
            return false;
        }

        const fieldsConfig: ValidationConfig[] = [
            Validation.buildFieldConfig('livelloListino', filters.livelloListino, [ValidationTypes.REQUIRED]),
            Validation.buildFieldConfig('codProduttore', filters.codProduttore, [ValidationTypes.REQUIRED]),
            Validation.buildFieldConfig('dataInizioValidita', filters.dataInizioValidita, [ValidationTypes.REQUIRED])
        ];

        const validationErrors = Validation.validate(fieldsConfig);
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    }

    const handleImport = () => {
        if (validate()) {
            const produttore = produttori.find(prod => prod.codProduttore === Number(filters.codProduttore));
            setConfirmAction({
                isOpen: true,
                title: t('common:message:defaultConfirmationTitle'),
                subtitle: t('common:message:defaultConfirmationMessage'),
                confirmAction: () => {
                    // Mappo l'array da inviare al server
                    const listini = data.map(item => {
                        let newItem: { [key: string]: any } = {};
                        columnsAssociation.forEach(col => {
                            if (col.field === 'codArticolo') {
                                newItem[col.field] = produttore?.simbolo + item[col.index];
                            } else {
                                newItem[col.field] = item[col.index];
                            }
                        });
                        return newItem;
                    });

                    dispatch(postListini({
                        livelloListino: Number(filters.livelloListino),
                        codProduttore: Number(filters.codProduttore),
                        dataInizioValidita: filters.dataInizioValidita,
                        listini: listini as NewListino[],
                        flgReplicaSuListinoFornitori: filters.flgReplicaSuListinoFornitori
                    }))
                    .unwrap()
                    .then((res: any) => {
                        // Visualizzo il riepilogo
                        setImportListiniSummary(res.data as ImportListiniSummary);
                        setDialogOpen(true);
                    })
                }
            })
        } else {
            scrollFunction();
        }
    }

    const nextIsActive = (): boolean => {
        let isActive = true;
        if (activeStep === 0) {
            isActive = (data?.length > 0 && columns?.length > 0 && filters.livelloListino !== '' && filters.codProduttore !== '' && filters.dataInizioValidita !== '');
        }
        return isActive;
    }
    
    return (
        <Fragment>
            <CustomHeaderPage title={`${t('importListinoPage:title')}`} />
            <Paper className="my-2">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Paper>
            <Box className='flex justify-end'>
                <Button disabled={activeStep === 0} variant="outlined" className='m-4' type='button' onClick={() => {setActiveStep((prevState) => prevState - 1)}}>{t('common:back')}</Button>
                {
                    (activeStep === steps.length -1) ?
                        <Button className='m-4 mr-0' onClick={handleImport}>{t('common:import')}</Button>
                        :
                        <Button disabled={! nextIsActive()} className='m-4 mr-0' onClick={() => {setActiveStep((prevState) => prevState + 1)}}>{t('common:next')}</Button>
                }
            </Box>
            {
                activeStep === 0 ?
                    <Paper className="p-4 my-4">
                        <Box display='flex' flexDirection='row' flexWrap='wrap'>
                            <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                                <SelectLivelliListino
                                    id='livelloListino'
                                    onChange={handleFiltersChangeValue}
                                    value={filters?.livelloListino}
                                    error={errors['livelloListino'] ? true : false}
                                    helperText={errors['livelloListino']}
                                />
                            </Box>
                            <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                                <SelectProduttori
                                    id='codProduttore'
                                    onChange={handleFiltersChangeValue}
                                    value={filters?.codProduttore}
                                    error={errors['codProduttore'] ? true : false}
                                    helperText={errors['codProduttore']}
                                />
                            </Box>
                            <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                                <TextField
                                    key='dataInizioValidita'
                                    label={t('common:dataInizioValidita')}
                                    type='date'
                                    fullWidth
                                    size='small'
                                    variant="outlined"
                                    value={filters?.dataInizioValidita}
                                    onChange={(e) => {
                                        const target = e.target as typeof e.target & { value: string }
                                        handleFiltersChangeValue(target.value, 'dataInizioValidita');
                                    }}
                                    error={errors['dataInizioValidita'] ? true : false}
                                    helperText={errors['dataInizioValidita']}
                                />
                            </Box>
                            {
                                /*
                                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 flex items-center'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color='primary'
                                                checked={filters?.flgReplicaSuListinoFornitori}
                                                onChange={(event, checked) => {
                                                    handleFiltersChangeValue(checked, 'flgReplicaSuListinoFornitori');
                                                }}
                                            />
                                        }
                                        label={t(`common:flgReplicaSuListinoFornitori`)}
                                    />
                                </Box>
                                */
                            }
                            <Box className='px-2 w-full mt-4'>
                                <Button
                                    variant="contained"
                                    component="label"
                                    className="mr-4"
                                >
                                    {t('common:uploadFile')}
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </Button>
                                {fileName}
                            </Box>
                        </Box>
                    </Paper>
                : null
            }
            
            {
                columns?.length > 0 && activeStep === 1 ?
                <Box display='flex' flexDirection='row' flexWrap='wrap' className="my-4 p-2">
                    {
                        columnsAssociation.map((column) => {
                            return (
                                <Box className='my-2 px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                                    <Card>
                                        <CardContent>
                                            <Typography>{column.generatedDescription}</Typography>
                                            <CustomSelect
                                                id={column.index}
                                                value={column.field}
                                                label={t('common:selectField')}
                                                data={columnsConfig}
                                                descriptionKey='description'
                                                valueKey='id'
                                                onChange={handleSelectChangeValue}
                                            />
                                        </CardContent>
                                    </Card>
                                </Box>
                            )
                        })
                    }
                </Box>
                : null
            }
            {
                activeStep === steps.length -1 ?
                <Paper className="p-4 my-4">
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <ClipboardIcon width='1.5rem' height='1.5rem'/>
                            </ListItemIcon>
                            <ListItemText primary={t('common:livelloListino')} secondary={livelliListini.find(livListino => livListino.codLivelloListino === Number(filters.livelloListino))?.descrizione}/>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <TireIcon width='1.5rem' height='1.5rem'/>
                            </ListItemIcon>
                            <ListItemText primary={t('common:produttore')} secondary={produttori.find(prod => prod.codProduttore === Number(filters.codProduttore))?.descrizione}/>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <CalendarIcon width='1.5rem' height='1.5rem' />
                            </ListItemIcon>
                            <ListItemText primary={t('common:dataInizioValidita')} secondary={moment(new Date(filters.dataInizioValidita)).format('DD/MM/YYYY')}/>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <CopyIcon width='1.5rem' height='1.5rem' />
                            </ListItemIcon>
                            <ListItemText primary={t('common:flgReplicaSuListinoFornitori')} secondary={filters.flgReplicaSuListinoFornitori ? 'Si' : 'No'}/>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <DocumentIcon width='1.5rem' height='1.5rem' />
                            </ListItemIcon>
                            <ListItemText primary="File" secondary={fileName}/>
                        </ListItem>
                    </List>
                </Paper>
                : null
            }
            {
                data?.length > 0 && columns?.length > 0 ?
                <Paper className="p-4">
                    <DataGrid
                        autoHeight
                        rows={data || []}
                        columns={columns}
                        getRowId={(row) => {return row[columns[0].field]}}
                        disableColumnFilter
                        hideFooter
                    />
                </Paper>
                : null
            }
            <ConfirmAction
                data={confirmAction}
                setConfirmAction={setConfirmAction}
            />
            <ImportSummaryDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                importListiniSummary={importListiniSummary}
            />
        </Fragment>
    );
}

const columnsConfig = [
    {
        id: 'codArticolo',
        description: i18n.t('common:codArticolo')
    },
    {
        id: 'barCode',
        description: i18n.t('common:barCode')
    },
    {
        id: 'flgRunOnFlat',
        description: i18n.t('common:articoliflgRunOnFlat')
    },
    {
        id: 'peso',
        description: i18n.t('common:peso')
    },
    {
        id: 'rollingResistance',
        description: i18n.t('common:articolirollingResistance')
    },
    {
        id: 'wetGrip',
        description: i18n.t('common:articoliwetGrip')
    },
    {
        id: 'noisePerformance',
        description: i18n.t('common:articolinoisePerformance')
    },
    {
        id: 'numberWaves',
        description: i18n.t('common:articolinumberWaves')
    },
    {
        id: 'prezzoEuro',
        description: i18n.t('common:prezzoEuro')
    },
    {
        id: 'prezzoEuroConIva',
        description: i18n.t('common:prezzoEuroConIVA')
    },
    {
        id: 'note',
        description: i18n.t('common:note')
    }
]