import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  DataGrid,
  GridColDef,
  GridEditCellPropsParams,
} from "@material-ui/data-grid";
import { Button, TextField } from "@material-ui/core";
import { CustomHeaderPage, FiltersAccordion } from "@components/index";
import { globalTasks } from "@store/global/global.selector";
import {
  getGlobalTasks,
  updateGlobalTasks,
} from "@store/global/global.effects";
import { Refresh } from "@material-ui/icons";
import { Task } from "@store/flow-manager/flowManager.type";
import { FieldFilter, FilterTypes } from "@store/common/common.types";
import Auth from "@utilities/auth";
import _ from "lodash";
import { putTask } from "@store/flow-manager/flowManager.effects";

export function TasksIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["tasksIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    name: new FieldFilter(
      "name",
      t(`common:name`),
      FilterTypes.AND,
      "",
      "",
      ""
    ),
  };

  const [filters, setFilters] = useState({
    filterFields: initialFilter,
    activeFilters: {} as typeof initialFilter,
  });

  // update filters on local state
  const updateFilters = (value: any, id: string) => {
    let currentFilterFields = filters.filterFields;
    Object.entries(currentFilterFields).forEach(([key, val]) => {
      if (val.id === id) currentFilterFields[key].value = value;
    });

    setFilters((prevState) => {
      return {
        ...prevState,
        filterFields: currentFilterFields,
      };
    });
  };

  const globalJobs = useAppSelector(globalTasks);
  const [tasks, setTasks] = useState<Task[]>([]);

  useEffect(() => {
    dispatch(
      getGlobalTasks(false, [
        { field: "name", sort: "asc" },
        { field: "version", sort: "asc" },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(filters.activeFilters)) {
      let filteredTasks = globalJobs;
      if (filters.activeFilters.name) {
        filteredTasks = globalJobs.filter((job) =>
          job.name
            .toLocaleLowerCase()
            .includes(filters.activeFilters.name.value.toLocaleLowerCase())
        );
      }
      setTasks(filteredTasks);
    } else {
      setTasks(globalJobs);
    }
  }, [filters.activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTasks(globalJobs);
  }, [globalJobs]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("common:name"),
      flex: 1,
    },
    {
      field: "version",
      headerName: t("common:version"),
      flex: 1,
      disableClickEventBubbling: true,
    },
    {
      field: "strictTime",
      headerName: t("common:strictTime"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      disableClickEventBubbling: true,
      type: "boolean",
      editable: Auth.hasAccess("save-tasks"),
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`tasksIndexPage:title`)}>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(
                  updateGlobalTasks([
                    { field: "name", sort: "asc" },
                    { field: "version", sort: "asc" },
                  ])
                );
              }}
            >
              <Refresh color="primary" />
              {t("tasksIndexPage:updateTasks")}
            </Button>
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={filters.filterFields.name.id}
                value={filters.filterFields.name.value}
                onChange={(e) => {
                  const target = e.target as typeof e.target & {
                    value: string;
                  };
                  updateFilters(target.value, filters.filterFields.name.id);
                }}
                label={filters.filterFields.name.label}
              />
            }
            filters={filters}
            setFilters={setFilters}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              autoHeight
              hideFooter
              rows={tasks}
              columns={columns}
              onEditCellChangeCommitted={(
                { id, field, props }: GridEditCellPropsParams,
                event?: React.SyntheticEvent<Element, Event> | undefined
              ) => {
                if (event && event.preventDefault) {
                  event.preventDefault();
                }
                const index = tasks.findIndex((task) => task.id === id);
                if (index !== -1 && field === "strictTime") {
                  dispatch(
                    putTask({
                      id: id as string,
                      strictTime: props.value as boolean,
                    })
                  )
                    .unwrap()
                    .then((res: any) => {
                      const tmpData = [...tasks];
                      tmpData[index] = res.data;
                      setTasks(tmpData);
                    });
                }
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
