import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState  } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setNazioniIndex, setNazioniShow } from './nazioni.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { Nazioni, NewNazioni  } from './nazioni.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalNazioni } from '@store/global/global.effects';


export const getNazioniIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getNazioniIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Nazioni?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setNazioniIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getNazioniIndex'));
    }
}

export const getNazioniShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getNazioniShow'));

    try {
        const response = await AxiosAPI.get(`/Nazioni/${id}`)
        dispatch(setNazioniShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getNazioniShow'));
    }
}

export const postNazioni = createAsyncThunk<
    // Return type of the payload creator
    Nazioni,
    // First argument to the payload creator
    NewNazioni,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postNazioni', async (nazioni: NewNazioni, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postNazioni'));

        try {
            const response = await AxiosAPI.post(`/Nazioni`, nazioni)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalNazioni(true))
            return response.data as Nazioni
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postNazioni'));
        }
    })

    export const putNazioni = (nazioni: Nazioni): AppThunk => async dispatch => {

        dispatch(setRequestLoader('putNazioni'));
    
        try {
            const { codNazione } = nazioni
    
            await AxiosAPI.put(`/Nazioni/${codNazione}`, nazioni)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalNazioni(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
        } finally {
            dispatch(removeRequestLoader('putNazioni'));
        }
    }

    export const deleteNazioni = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    Nazioni,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteNazioni', async (nazioni: Nazioni, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteNazioni'));

        try {
            const { codNazione } = nazioni

            await AxiosAPI.delete(`/Nazioni/${codNazione}`)

            const currentState = getState().nazioni.index;
            const data = currentState.data.filter((item) => item.codNazione !== codNazione)
            dispatch(setNazioniIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalNazioni(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteNazioni'));
        }
    })
