import { AxiosAPI } from '@store/axios.config';
import { AppDispatch, AppThunk, ResourcesIndexThunk, RootState } from '../store'
import {
    setCriteriServiziIndex,
    setCriteriServiziShow
} from './convenzionePrezziNetti.actions'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { Filters, SortModel } from '@store/common/common.types';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { CriteriServizi } from './convenzionePrezziNetti.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface newCriteriServizi {
    progConvenzine: number,
    data: CriteriServizi[],
    duplicate: boolean,
    progFlotte: number
}
export const getCriteriServiziIndex: ResourcesIndexThunk = (page: number = 1, sizePage = 999, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('setCriteriServiziIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/PrezziNettiConvenzioni?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setCriteriServiziIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('setCriteriServiziIndex'));
    }
}

export const getCriteriServiziShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getAnagraficaShow'));

    try {
        const response = await AxiosAPI.get(`/AnagraficaGoodyear/${id}`)
        dispatch(setCriteriServiziShow(response.data.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getAnagraficaShow'));
    }
}

export const prorogaPrezziNetti = (id: string, date: string, filters?: Filters): AppThunk => async dispatch => {

    dispatch(setRequestLoader('prorogaPrezziNetti'));
    try {
        const filterSearch = createFilters(filters)
        await AxiosAPI.put(`/PrezziNettiConvenzioni/${id}/Proroga?${filterSearch}`, {
            date: date
        })
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
        throw error;
    } finally {
        dispatch(removeRequestLoader('prorogaPrezziNetti'));
    }
}

export const putCriteriServizi = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    newCriteriServizi,

    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postCriteriConvenzioni', async (payload: newCriteriServizi, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postPrezziNettiConvenzioni'));

        try {
            // await AxiosAPI.put(`/PrezziNettiConvenzioni/${payload.progConvenzine}`, payload.data)
            await AxiosAPI.put(`/PrezziNettiConvenzioni/${payload.progConvenzine}/${payload.duplicate}/${payload.progFlotte}`, payload.data)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postPrezziNettiConvenzioni'));
        }
    })



