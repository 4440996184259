import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setStagionalitaIndex, setStagionalitaShow } from './stagionalita.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { Stagionalita, NewStagionalita } from './stagionalita.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalStagionalita } from '@store/global/global.effects';


export const getStagionalitaIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getStagionalitaIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Stagionalita?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setStagionalitaIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getStagionalitaIndex'));
    }
}


export const getStagionalitaShow = (codStruttura: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getStagionalitaShow'));

    try {
        const response = await AxiosAPI.get(`/Stagionalita/${codStruttura}`)
        dispatch(setStagionalitaShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getStagionalitaShow'));
    }
}


export const postStagionalita = createAsyncThunk<
    // Return type of the payload creator
    Stagionalita,
    // First argument to the payload creator
    NewStagionalita,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postStagionalita', async (struttura: NewStagionalita, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postStagionalita'));

        try {
            const response = await AxiosAPI.post(`/Stagionalita`, struttura)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalStagionalita(true))
            return response.data as Stagionalita
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postStagionalita'));
        }
    })


export const putStagionalita = (struttura: Stagionalita): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putStagionalita'));

    try {
        const { codStagionalita } = struttura

        await AxiosAPI.put(`/Stagionalita/${codStagionalita}`, struttura)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalStagionalita(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putStagionalita'));
    }
}


export const deleteStagionalita = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    Stagionalita,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteStagionalita', async (struttura: Stagionalita, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteStagionalita'));

        try {
            const { codStagionalita } = struttura

            await AxiosAPI.delete(`/Stagionalita/${codStagionalita}`)

            const currentState = getState().stagionalita.index;
            const data = currentState.data.filter((item) => item.codStagionalita !== codStagionalita)
            dispatch(setStagionalitaIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalStagionalita(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteStagionalita'));
        }
    })
