import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setCriteriConvenzioniIndex, setCriteriConvenzioniShow, setCriteriConvenzioniShowDettaglioSconti } from './criteriConvenzioni.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { CriteriConvenzioni, NewCriteriConvenzioni } from './criteriConvenzioni.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface newScontiConvenzione {
    progConvenzine: number,
    data: CriteriConvenzioni[]
    duplicate: boolean,
    progFlotte: number
}
export interface data {
    progConvenzine: number,
    data: CriteriConvenzioni[]
}
export const getCriteriConvenzioniIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getCriteriConvenzioniIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/CriteriConvenzioni?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setCriteriConvenzioniIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCriteriConvenzioniIndex'));
    }
}

export const getCriteriConvenzioniShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getCriteriConvenzioniShow'));

    try {
        const response = await AxiosAPI.get(`/CriteriConvenzioni/${id}`)
        dispatch(setCriteriConvenzioniShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCriteriConvenzioniShow'));
    }
}

export const getCriteriConvenzioniDettagliSconti = (id: string, page: number = 1, sizePage = 9999, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getCriteriConvenzioniShow'));
    try {
        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/CriteriConvenzioni/${id}/?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setCriteriConvenzioniShowDettaglioSconti(response.data.data))
    } catch (error: any) {
        dispatch(setError({ message: error.message }))
    } finally {
        dispatch(removeRequestLoader('getCriteriConvenzioniShow'));
    }
}

export const prorogaCriteriConvenzioniDettagliSconti = (id: string, date: string, filters?: Filters): AppThunk => async dispatch => {

    dispatch(setRequestLoader('prorogaCriteriConvenzioniDettagliSconti'));
    try {
        const filterSearch = createFilters(filters)
        await AxiosAPI.put(`/CriteriConvenzioni/${id}/Proroga?${filterSearch}`, {
            date: date
        })
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
        throw error;
    } finally {
        dispatch(removeRequestLoader('prorogaCriteriConvenzioniDettagliSconti'));
    }
}

export const decurtaCriteriConvenzioniDettagliSconti = (id: string, value: number, filters?: Filters): AppThunk => async dispatch => {

    dispatch(setRequestLoader('decurtaCriteriConvenzioniDettagliSconti'));
    try {
        const filterSearch = createFilters(filters)
        await AxiosAPI.put(`/CriteriConvenzioni/${id}/Decurta?${filterSearch}`, {
            value: value
        })
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
        throw error;
    } finally {
        dispatch(removeRequestLoader('decurtaCriteriConvenzioniDettagliSconti'));
    }
}

export const getCriteriConvenzioniDettagliScontiTest = createAsyncThunk<
    // Return type of the payload creator
    CriteriConvenzioni[],
    // First argument to the payload creator
    data,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getCriteriConvenzioniDettagliSconti', async (payload: any, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('getCriteriConvenzioniDettagliSconti'));

        try {
            const filterSearch = createFilters(payload.filters)
            const sortSearch = createSortColumns(payload.sortModel)
            const response = await AxiosAPI.get(`/CriteriConvenzioni/${payload.id}/?PageIndex=${1}&PageSize=${environment.pagination.defaultPageSize}${filterSearch}${sortSearch}`)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            return response.data.data

        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('getCriteriConvenzioniDettagliSconti'));
        }
    })

export const putCriteriConvenzioniDettagliSconti = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    newScontiConvenzione,

    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postCriteriConvenzioni', async (payload: newScontiConvenzione, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postCriteriConvenzioni'));

        try {
            await AxiosAPI.put(`/CriteriConvenzioni/${payload.progConvenzine}/${payload.duplicate}/${payload.progFlotte}`, payload.data)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postCriteriConvenzioni'));
        }
    })

export const postCriteriConvenzioni = createAsyncThunk<
    // Return type of the payload creator
    CriteriConvenzioni,
    // First argument to the payload creator
    NewCriteriConvenzioni,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postCriteriConvenzioni', async (criteriConvenzioni: NewCriteriConvenzioni, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postCriteriConvenzioni'));

        try {
            const response = await AxiosAPI.post(`/CriteriConvenzioni`, criteriConvenzioni)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as CriteriConvenzioni
        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postCriteriConvenzioni'));
        }
    })

export const putCriteriConvenzioni = (criteriConvenzioni: CriteriConvenzioni): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putCriteriConvenzioni'));

    try {
        const { progConvenzione } = criteriConvenzioni

        await AxiosAPI.put(`/CriteriConvenzioni/${progConvenzione}`, criteriConvenzioni)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error: any) {
        dispatch(setError({ message: error.message }))
    } finally {
        dispatch(removeRequestLoader('putCriteriConvenzioni'));
    }
}

export const deleteCriteriConvenzioni = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    CriteriConvenzioni,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteCriteriConvenzioni', async (criteriConvenzioni: CriteriConvenzioni, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteCriteriConvenzioni'));

        try {
            const { progConvenzione } = criteriConvenzioni

            await AxiosAPI.delete(`/CriteriConvenzioni/${progConvenzione}`)

            const currentState = getState().criteriConvenzioni.index;
            const data = currentState.data.filter((item) => item.progConvenzione !== progConvenzione)
            dispatch(setCriteriConvenzioniIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteCriteriConvenzioni'));
        }
    })

interface CloneConvenzione {
    progConvenzione: number
    dataInizioValidita: string
    dataFineValidita: string
    excludeExtraSconti: boolean
    excludeCodStagionalita: number[]
}

export const cloneConvenzione = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    CloneConvenzione,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('cloneConvenzione', async (cloneConvenzione: CloneConvenzione, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('cloneConvenzione'));

        try {

            await AxiosAPI.post(`/CriteriConvenzioni/Clone`, cloneConvenzione);
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('cloneConvenzione'));
        }
    })
