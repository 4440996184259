import { AxiosAPI } from '@store/axios.config';
import {  AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setProvinceIndex, setProvinceShow } from './province.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { Province, NewProvince  } from './province.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalProvince } from '@store/global/global.effects';
//import { push } from 'connected-react-router';
//import route from '@utilities/routes';


export const getProvinceIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getProvinceIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Province?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setProvinceIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getProvinceIndex'));
    }
}

export const getProvinceShow = (siglaProvincia: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getProvinceShow'));

    try {
        const response = await AxiosAPI.get(`/Province/1/${siglaProvincia}`)
        dispatch(setProvinceShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }

        //dispatch(push(route.anagrafica.index))
    } finally {
        dispatch(removeRequestLoader('getProvinceShow'));
    }
}

export const postProvince = createAsyncThunk<
    // Return type of the payload creator
    Province,
    // First argument to the payload creator
    NewProvince,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postProvince', async (province: NewProvince, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postProvince'));

        try {
            const response = await AxiosAPI.post(`/Province`, province)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalProvince(true))
            return response.data as Province
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postProvince'));
        }
    })

    export const putProvince = (province: Province): AppThunk => async dispatch => {

        dispatch(setRequestLoader('putProvince'));
    
        try {
            const { codNazione, siglaProvincia } = province
    
            await AxiosAPI.put(`/Province/${codNazione}/${siglaProvincia}`, province)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalProvince(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
        } finally {
            dispatch(removeRequestLoader('putProvince'));
        }
    }

    export const deleteProvince = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    Province,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteProvince', async (province: Province, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteProvince'));

        try {
            const { codNazione, siglaProvincia } = province

            await AxiosAPI.delete(`/Province/${codNazione}/${siglaProvincia}`)

            const currentState = getState().province.index;
            const data = currentState.data.filter((item) => item.siglaProvincia !== siglaProvincia)
            dispatch(setProvinceIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalProvince(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteProvince'));
        }
    })
