import { createReducer } from '@reduxjs/toolkit'
import {
    setCategorieMerceologicheIndex,
    setCategorieMerceologicheShow,
    resetCategorieMerceologicheShow
} from '@store/categorie-merceologiche/categorieMerceologiche.actions'
import { CategorieMerceologicheIndex, CategorieMerceologicheShow } from './categorieMerceologiche.types'

const initialState: {
    index: CategorieMerceologicheIndex
    show: CategorieMerceologicheShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const categorieMerceologicheReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCategorieMerceologicheIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setCategorieMerceologicheShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetCategorieMerceologicheShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

