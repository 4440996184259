import { AxiosAPI } from '@store/axios.config';
import { AppThunk } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setLargNomTipoArtIndex, setLargNomTipoArtShow } from './largNomTipoArt.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import i18n from '@assets/i18n';
import { getLarghezzeNominaliShow } from '@store/larghezze-nominali/larghezzeNominali.effects';
import { setLarghezzeNominaliShow } from '@store/larghezze-nominali/larghezzeNominali.actions';


export const getLargNomTipoArtIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getLargNomTipoArtIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/LargNomTipoArt?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setLargNomTipoArtIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLargNomTipoArtIndex'));
    }
}


export const getLargNomTipoArtShow = (codLarghezzaNominale: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getLargNomTipoArtShow'));

    try {
        const response = await AxiosAPI.get(`/LargNomTipoArt/${codLarghezzaNominale}/${codTipoArticolo}`)
        dispatch(setLargNomTipoArtShow(response.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLargNomTipoArtShow'));
    }
}


export const postLargNomTipoArt = (codLarghezzaNominale: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('postLargNomTipoArt'));

    try {

        await AxiosAPI.post(`/LargNomTipoArt`, {
            codLarghezzaNominale,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        dispatch(getLarghezzeNominaliShow(`${codLarghezzaNominale}`))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('postLargNomTipoArt'));
        return true
    }
}


export const putLargNomTipoArt = (codLarghezzaNominale: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putLargNomTipoArt'));

    try {
        await AxiosAPI.put(`/LargNomTipoArt/${codLarghezzaNominale}/${codTipoArticolo}`, {
            codLarghezzaNominale,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putLargNomTipoArt'));
    }
}

export const deleteLargNomTipoArt = (codLarghezzaNominale: number, codTipoArticolo: number): AppThunk => async (dispatch, getState) => {

    dispatch(setRequestLoader('deleteLargNomTipoArt'));

    try {

        await AxiosAPI.delete(`/LargNomTipoArt/${codLarghezzaNominale}/${codTipoArticolo}`)

        const currentState = (getState()).larghezzeNominali.show;
        if (currentState.data) {
            const data = currentState.data.largNomTipoArt.filter((item) => item.codTipoArticolo !== codTipoArticolo)
            dispatch(setLarghezzeNominaliShow({
                ...currentState,
                data: {
                    ...currentState.data,
                    largNomTipoArt: data
                }
            }))
        }

        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('deleteLargNomTipoArt'));
    }
}