import { createReducer } from '@reduxjs/toolkit'
import {
    setCapiZonaIndex,
    setCapiZonaShow,
    resetCapiZonaShow,
    //resetCapiZonaIndex
} from './capiZona.actions'
import { CapiZonaIndex, CapiZonaShow } from './capiZona.types';


const initialState: {
    index: CapiZonaIndex,
    show: CapiZonaShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const capiZonaReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCapiZonaIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setCapiZonaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetCapiZonaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
/*         .addCase(resetCapiZonaIndex, (state, action) => {
            return {
                ...state,
                index: {
                    data: [],
                    meta: {
                        pagination: undefined
                    }
                }
            }
        }) */
})

