import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { EntiConvenzionati } from './entiConvenzionati.types';

export const setEntiConvenzionatIndex = createAction<{
    data: EntiConvenzionati[]
    meta: {
        pagination: Pagination
    }
}>('entiConvenzionati/index')

export const setEntiConvenzionatiShow = createAction<EntiConvenzionati>('entiConvenzionati/show')