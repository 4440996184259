import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import { getEserciziShow, putEsercizi } from "@store/esercizi/esercizi.effects";
import { Esercizi, NewEsercizi } from "@store/esercizi/esercizi.type";
import { eserciziShow } from "@store/esercizi/esercizi.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { resetEserciziShow } from "@store/esercizi/esercizi.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import moment from "moment";
import Auth from "@utilities/auth";

export function EserciziDetail() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "eserciziDetailPage"]);

  const { id } = useParams<{ id: string }>();

  const pollingEsercizi = useAppSelector(eserciziShow);
  const [esercizi, setEsercizi] = useState<Esercizi | NewEsercizi>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();
    dispatch(getEserciziShow(id));
  }, [dispatch, id]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingEsercizi) {
      setEsercizi(pollingEsercizi);
    }
  }, [pollingEsercizi]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetEserciziShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setEsercizi((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", esercizi?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !esercizi) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    dispatch(putEsercizi(esercizi as Esercizi));
  };

  const handleCancel = () => {
    setErrors({});
    dispatch(getEserciziShow(id));
  };

  return (
    <div>
      {esercizi?.codEsercizio ? (
        <Fragment>
          <CustomHeaderPage
            title={`${t("eserciziDetailPage:title")} - ${esercizi.descrizione}`}
          />
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!esercizi?.codEsercizio ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codEsercizio")}
                    onChange={(e) => handleChangeValue(e, "codEsercizio")}
                    value={esercizi?.codEsercizio || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={esercizi?.descrizione || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  type="date"
                  InputLabelProps={{ className: "pl-4, mx-4", shrink: true }}
                  label={t("common:dataInizio")}
                  error={errors["dataInizio"] ? true : false}
                  helperText={errors["dataInizio"] || ""}
                  onChange={(e) => handleChangeValue(e, "dataInizio")}
                  value={
                    esercizi?.dataInizio
                      ? moment(esercizi?.dataInizio).format("YYYY-MM-DD")
                      : ""
                  }
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  type="date"
                  InputLabelProps={{ className: "pl-4, mx-4", shrink: true }}
                  label={t("common:dataFine")}
                  error={errors["dataFine"] ? true : false}
                  helperText={errors["dataFine"] || ""}
                  onChange={(e) => handleChangeValue(e, "dataFine")}
                  value={
                    esercizi?.dataFine
                      ? moment(esercizi?.dataFine).format("YYYY-MM-DD")
                      : ""
                  }
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-esercizi") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
