import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalTipiDocumento } from "@store/global/global.effects";
import { globalTipiDocumento } from "@store/global/global.selector";

export function SelectTipiDocumento(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const tipiDocumento = useAppSelector(globalTipiDocumento);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalTipiDocumento())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:tipoDocumento`)}
            data={tipiDocumento}
            descriptionKey={'descrizione'}
            valueKey={'codTipoDoc'}
            {...props}
        />
    );
}
