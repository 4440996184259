import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { CategorieMerceologiche } from "@store/categorie-merceologiche/categorieMerceologiche.types";
import { globalCategorieMerceologice } from "@store/global/global.selector";
import { getGlobalCategorieMerceologiche } from "@store/global/global.effects";

export interface CustomSelectCategorieMerceologicheProps extends CustomSelectProps{
    codDivisione: string
}

export function SelectCategorieMerceologiche(props: CustomSelectCategorieMerceologicheProps) {
    
    const { codDivisione } = props;

    const { t } = useTranslation(['common'])
    const dispatch = useAppDispatch();

    const allCategorieMerceologiche = useAppSelector(globalCategorieMerceologice);
    const [categorieMerceologiche, setCategorieMerceologiche] = useState<CategorieMerceologiche[]>([])

    useEffect(() => {
        let filteredCategorieMerceologiche = allCategorieMerceologiche;
        if (codDivisione) {
            filteredCategorieMerceologiche = filteredCategorieMerceologiche.filter(c => c.codDivisione === Number(codDivisione));
        }
        setCategorieMerceologiche(filteredCategorieMerceologiche);

    }, [allCategorieMerceologiche, codDivisione]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getGlobalCategorieMerceologiche())
    }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CustomSelect
            label={t(`common:categoriaMerceologica`)}
            data={categorieMerceologiche}
            descriptionKey={'descrizione'}
            valueKey={'codCategoriaMerc'}
            {...props}
        />
    );
}
