import { Fragment, FunctionComponent, useEffect } from "react";
import environment from "@environment/environment";
import { Pagination, SortModel } from "@store/common/common.types";
import {
  DataGrid,
  GridColDef,
  GridEditCellPropsParams,
  GridFeatureMode,
  GridRowIdGetter,
  GridRowParams,
  GridSelectionModelChangeParams,
  GridSortModelParams,
} from "@material-ui/data-grid";
import { CustomDataGridPagination } from "@components/custom-datagrid-pagination/CustomDataGridPagination";
import { scrollFunction } from "@utilities/utilities";
import React from "react";
import _ from "lodash";
import { CustomRecordFound } from "@components/custom-record-found/CustomRecordFound";

interface CustomPaginatedDataGridProps {
  data?: {
    data: any[];
    meta?: {
      pagination?: Pagination;
    };
  };
  columns: GridColDef[];
  getRowId: GridRowIdGetter;
  sortColumns?: SortModel[];
  onChangePageSize: (pageSize: number) => void;
  onChangeSortColumns?: (sortModel: SortModel[]) => void;
  onChangePage: (page: number) => void;
  checkboxSelection?: boolean;
  onSelectionModelChange?: (model: GridSelectionModelChangeParams) => void;
  selectionModel?: any[];
  sortingMode?: GridFeatureMode;
  onEditCellChangeCommitted?:
    | ((
        params: GridEditCellPropsParams,
        event?: React.SyntheticEvent<Element, Event> | undefined
      ) => void)
    | undefined;
  getRowClassName?: ((params: GridRowParams) => string) | undefined;
}

export const CustomPaginatedDataGrid: FunctionComponent<
  CustomPaginatedDataGridProps
> = (props) => {
  const {
    data,
    columns,
    getRowId,
    onChangeSortColumns,
    sortColumns,
    onChangePage,
    onChangePageSize,
    checkboxSelection,
    onSelectionModelChange,
    selectionModel,
    sortingMode = "server",
    onEditCellChangeCommitted,
    getRowClassName,
  } = props;
  const currentPage = data?.meta?.pagination?.currentPage || 1;

  const pagination = data?.meta?.pagination;
  const pageSize =
    pagination?.pageSize || environment.pagination.defaultPageSize;

  useEffect(() => {
    scrollFunction();
  }, [currentPage, pageSize]);

  const handleSortModelChange = (params: GridSortModelParams) => {
    const sortModel = params.sortModel as SortModel[];
    if (onChangeSortColumns) {
      onChangeSortColumns(sortModel);
    }

    handleOnChangePage(1);
  };

  const handleOnChangePage = (page: number): void => {
    if (onChangePage) {
      onChangePage(page);
    }
  };

  const handleOnChangePageSize = (newPageSize: number): void => {
    if (onChangePageSize) {
      onChangePageSize(newPageSize);
    }

    const page = Math.trunc(
      (currentPage * pageSize - pageSize) / newPageSize + 1
    );
    handleOnChangePage(page);
  };

  return (
    <Fragment>
      <CustomRecordFound recordFound={pagination?.totalCount || 0} />
      <DataGrid
        autoHeight
        rows={data?.data || []}
        columns={columns}
        getRowId={getRowId}
        disableColumnFilter
        sortingMode={sortingMode}
        sortModel={sortColumns || []}
        onSortModelChange={handleSortModelChange}
        hideFooter
        checkboxSelection={checkboxSelection || false}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        onEditCellChangeCommitted={onEditCellChangeCommitted}
        getRowClassName={getRowClassName}
      />
      {data?.meta ? (
        <CustomDataGridPagination
          pagination={pagination}
          onChangePage={handleOnChangePage}
          onChangePageSize={handleOnChangePageSize}
        />
      ) : null}
    </Fragment>
  );
};
// function getObjectDiff(obj1: any, obj2: any) {
//     const diff = Object.keys(obj1).reduce((result, key) => {
//         if (!obj2.hasOwnProperty(key)) {
//             result.push(key);
//         } else if (_.isEqual(obj1[key], obj2[key])) {
//             const resultKeyIndex = result.indexOf(key);
//             result.splice(resultKeyIndex, 1);
//         }
//         return result;
//     }, Object.keys(obj2));

//     return diff;
// }

const dataEqual = (prevProps: any, nextProps: any) => {
  if (_.isEqual(prevProps.data, nextProps.data)) {
    console.log("gid Data is equal so block Rerender");
    return true; // dose not render grid
  } else {
    console.log("render grid");
    return false; // dose RENDER grid
  }
};

export const CustomPaginatedDataGridRender = React.memo(
  CustomPaginatedDataGrid,
  dataEqual
);
