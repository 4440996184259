import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { Disegni } from "@store/disegni/disegni.types";
import { globalDisegni } from "@store/global/global.selector";
import { getGlobalDisegni } from "@store/global/global.effects";

export interface CustomSelectDisegniProps extends CustomSelectProps {
    codDivisione?: string
    codProduttore?: string
    codTipoArticolo?: string
    resetSelectedDisegno?: () => void
}

export function SelectDisegni(props: CustomSelectDisegniProps) {

    const { codDivisione, codProduttore, codTipoArticolo, value, resetSelectedDisegno } = props;

    const { t } = useTranslation(['common'])
    const dispatch = useAppDispatch();

    const allDisegni = useAppSelector(globalDisegni);
    const [disegni, setDisegni] = useState<Disegni[]>([])

    // reload disegni on change produttore or tipo articolo
    useEffect(() => {
        let filteredDisegni: Disegni[] = allDisegni;
        if ((codProduttore || codTipoArticolo) && codDivisione && codDivisione === '1') {
            if (codProduttore) {
                filteredDisegni = filteredDisegni.filter(d => d.codProduttore === Number(codProduttore));
            }
            if (codTipoArticolo) {
                filteredDisegni = filteredDisegni.filter(d => d.codTipoArticolo === Number(codTipoArticolo));
            }
        } else {
            filteredDisegni = [];
        }
        setDisegni(filteredDisegni);
        if (resetSelectedDisegno && value && ! filteredDisegni.find(disegno => disegno.codDisegno === Number(value))) {
            resetSelectedDisegno();
        }
    }, [allDisegni, codDivisione, codProduttore, codTipoArticolo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getGlobalDisegni())
    }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CustomSelect
            label={t(`common:disegno`)}
            data={disegni}
            descriptionKey={'descrizione'}
            valueKey={'codDisegno'}
            {...props}
        />
    );
}