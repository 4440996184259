import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setMasterDataUserIndex, setMasterDataUserShow } from './masterDataUser.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { MasterDataUser, NewMasterDataUser } from './masterDataUser.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface userData {
    name: string,
    surname: string,
    phoneNumber: string,
    oldPassword?: string,
    password?: string,
    confirmPassword?: string
}
export const getMasterDataUserIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getMasterDataUserIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/MasterDataUser?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setMasterDataUserIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getMasterDataUserIndex'));
    }
}

export const getMasterDataUserShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getMasterDataUserShow'));

    try {
        const response = await AxiosAPI.get(`/MasterDataUser/${id}`)
        dispatch(setMasterDataUserShow(response.data.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getMasterDataUserShow'));
    }
}

export const postMasterDataUser = createAsyncThunk<
    // Return type of the payload creator
    MasterDataUser,
    // First argument to the payload creator
    NewMasterDataUser,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postMasterDataUser', async (masterDataUser: NewMasterDataUser, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postMasterDataUser'));

        try {
            const response = await AxiosAPI.post(`/MasterDataUser`, masterDataUser)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as MasterDataUser
        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postMasterDataUser'));
        }
    })

export const putMasterDataUser = (masterDataUser: MasterDataUser): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putMasterDataUser'));

    try {
        const { id } = masterDataUser

        await AxiosAPI.put(`/MasterDataUser/${id}`, masterDataUser)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putMasterDataUser'));
    }
}

export const deleteMasterDataUser = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    MasterDataUser,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteMasterDataUser', async (masterDataUser: MasterDataUser, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteMasterDataUser'));

        try {
            const { id } = masterDataUser

            await AxiosAPI.delete(`/MasterDataUser/${id}`)

            const currentState = getState().masterDataUser.index;
            const data = currentState.data.filter((item) => item.id !== id)
            dispatch(setMasterDataUserIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteMasterDataUser'));
        }
    })
export const MasterDataUserME = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    undefined,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getMasterDataUserMe', async (_, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('getMasterDataUserMe'));

        try {
            const response = await AxiosAPI.get('MasterDataUser/me')
            return response.data as any // set the correct type
        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('getMasterDataUserMe'));
        }
    })
export const putMasterDataUserME = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    userData,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('putMasterDataUserMe', async (userData: userData, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('putMasterDataUserMe'));

        try {
            const response = await AxiosAPI.put('MasterDataUser/me', userData)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as any // set the correct type

        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('putMasterDataUserMe'));
        }
    })