import { Chip, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, SyntheticEvent } from "react";

interface TypeChipFilter {
    label?: string
    id?: string
    onDelete?: (event: SyntheticEvent) => void
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '0.5rem',
    },
}));

export const ChipFilter: FunctionComponent<TypeChipFilter> = (props) => {

    const classes = useStyles();

    const { onDelete, label, id } = props

    return (
        <div>
            {
                onDelete ?
                    <Chip
                        key={id}
                        className={classes.container}
                        label={label}
                        onDelete={(event) => onDelete(event)}
                    />
                : 
                    <Chip
                        key={id}
                        className={classes.container}
                        label={label}
                    />
            }
        </div>
    );
}