import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import logoFull from "@assets/svg/logo_full.svg";
import logoIcon from "@assets/svg/logo_icon.svg";
import Grow from "@material-ui/core/Grow";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { drawerWidthOpen, drawerWidthClose } from "@components/index";
import { stateSidebar } from "../../store/common/common.selector";
import { ProfileHeader } from "@components/header/profile-header/ProfileHeader";
import { push } from "connected-react-router";
import routes from "@utilities/routes";

const version = "1.0.28";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolBar: {
    minHeight: "3.688rem",
    padding: 0,
    justifyContent: "space-between",
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  header: {
    display: "flex",
    padding: "0",
    backgroundColor: "white",
    flexDirection: "row",
  },
  logoContainer: {
    height: "3.75rem",
    display: "flex",
    backgroundColor: "#002D68",
    cursor: "pointer",
  },
  logoFull: {
    padding: "0.5rem 1.563rem",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
    width: drawerWidthOpen,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoIcon: {
    padding: "0.5rem 0.438rem",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
    width: drawerWidthClose,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoHidden: {
    display: "none",
  },
  title: {
    alignSelf: "center",
    flexGrow: 1,
    marginLeft: "1.563rem",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
}));

export function Header() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const sidebar = useAppSelector(stateSidebar);

  const goToDashbord = () => {
    dispatch(push(routes.dashboard));
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      className={clsx(
        classes.appBar,
        sidebar.isOpen ? classes.appBarShift : null
      )}
    >
      <Toolbar className={classes.toolBar}>
        <Box component="header" className={classes.header}>
          <Box
            onClick={goToDashbord}
            className={clsx(
              classes.logoContainer,
              sidebar.isOpen ? classes.logoFull : classes.logoIcon
            )}
          >
            {sidebar.isOpen ? (
              <Grow in={sidebar.isOpen}>
                <img src={logoFull} alt="" />
              </Grow>
            ) : (
              <Grow in={!sidebar.isOpen}>
                <img src={logoIcon} alt="" />
              </Grow>
            )}
          </Box>
          <Box className={classes.title}>
            <Typography display="inline" variant="h6">
              {"Master Data "}
            </Typography>
            <Typography display="inline" variant="subtitle1">
              by Softec
            </Typography>
            <Typography display="inline" variant="caption">
              &nbsp;{version}
            </Typography>
          </Box>
        </Box>
        <ProfileHeader />
      </Toolbar>
    </AppBar>
  );
}
