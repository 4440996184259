import { createReducer } from '@reduxjs/toolkit'
import {
    setDisegniIndex,
    setDisegniShow,
    resetDisegniShow,
    resetDisegniIndex
} from '@store/disegni/disegni.actions'
import { DisegniIndex, DisegniShow } from './disegni.types'

const initialState: {
    index: DisegniIndex
    show: DisegniShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const disegniReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setDisegniIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setDisegniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetDisegniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
        .addCase(resetDisegniIndex, (state, action) => {
            return {
                ...state,
                index: {
                    data: [],
                    meta: {
                        pagination: undefined
                    }
                }
            }
        })
})

