import React, { useEffect, useState } from "react";
import environment from "@environment/environment";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@store/hooks";

import { capiZonaIndex } from "@store/capi-zona/capiZona.selector";
import { getCapiZonaIndex } from "@store/capi-zona/capiZona.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Button, IconButton, TextField } from "@material-ui/core";
import {
  FieldFilter,
  FilterTypes,
  SortModel,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
}

export function CapiZonaIndex() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["capiZonaIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`capiZonaIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "nominativo,",
      "",
      ""
    ),
  };

  const capiZona = useAppSelector(capiZonaIndex);

  const { defaultPageSize } = environment.pagination;
  const [sortColumns, setSortColumns] = useState<SortModel[]>([
    { field: "nominativo", sort: "asc" },
  ]);

  const [filters, setFilters] = useState({
    filterFields: initialFilter,
    activeFilters: {} as typeof initialFilter,
  });

  const [pageIsLoad, setPageIsLoad] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (pageIsLoad) {
      dispatch(
        getCapiZonaIndex(page, pageSize, filters.activeFilters, sortColumns)
      );
    } else {
      setPageIsLoad(true);
    }
  }, [dispatch, page, pageSize, filters.activeFilters, sortColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeValue = (e: React.SyntheticEvent, id: string) => {
    const target = e.target as typeof e.target & { value: string };

    let currentFilterFields = filters.filterFields;
    Object.entries(currentFilterFields).forEach(([key, value]) => {
      if (value.id === id) currentFilterFields[key].value = target.value;
    });

    setFilters((prevState) => {
      return {
        ...prevState,
        filterFields: currentFilterFields,
      };
    });
  };

  const columns: GridColDef[] = [
    {
      field: "nominativo",
      headerName: t("common:nominativo"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.nominativo,
    },
    {
      field: "indirizzoEmail",
      headerName: t("common:indirizzoEmail"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.indirizzoEmail,
    },
    {
      field: "codGLS",
      headerName: t("common:codiceindexGLS"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.codGLS,
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(routes.capiZona.show.replace(":id", params.row.codCapoZona))
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`capiZonaIndexPage:title`)} disabledGoBack>
            {Auth.hasAccess("save-capizona") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.capiZona.new))}
              >
                <AddIcon color="primary" />
                {t("capiZonaIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleChangeValue(event, filters.filterFields.multi.id)
                }
                label={filters.filterFields.multi.label}
              />
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={capiZona}
              columns={columns}
              getRowId={(row) => {
                return row.codCapoZona;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .accountManager,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.centralDatabaseUpdates.entities
                .altro,
            ],
          },
        ]}
      />
    </div>
  );
}
