import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";

import { scontoAddizionaleIndex } from "@store/sconto-addizionale/scontoAddizionale.selector";
import { getScontoAddizionaleIndex } from "@store/sconto-addizionale/scontoAddizionale.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Box, Button, IconButton, TextField } from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
//import { SelectTipiCausaliIva } from "@components/selects";
import { ScontoAddizionale } from "@store/sconto-addizionale/scontoAddizionale.type";
import { useIndexPage } from "@pages/common/UseIndexPage";
import moment from "moment";
import Auth from "@utilities/auth";
import _ from "lodash";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  DATA_INIZIO = "filter-dataInizio",
}

export function ScontoAddizionaleIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["scontoAddizionaleIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`scontoAddizionaleIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "descrizione",
      "",
      ""
    ),
    dataInizioValidita: new FieldFilter(
      FILTER_ID.DATA_INIZIO,
      t(`scontoAddizionaleIndexPage:filter:filterdataInizio`),
      FilterTypes.AND,
      "dataInizioValidita",
      FilterMethods.GREATERTHANOREQUALTO,
      ``
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleTextFieldChangeValue,
  } = useIndexPage<ScontoAddizionale>(
    initialFilter,
    [{ field: "id", sort: "asc" }],
    getScontoAddizionaleIndex,
    scontoAddizionaleIndex
  );

  const columns: GridColDef[] = [
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "sconto",
      headerName: t("common:sconto"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.sconto,
    },
    {
      field: "dataInizioValidita",
      headerName: t("common:dataInizioValidita"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        if (!_.isEmpty(params.row.dataInizioValidita)) {
          return moment(params.row.dataInizioValidita).format(
            "DD/MM/yyyy"
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "dataFineValidita",
      headerName: t("common:dataFineValidita"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        if (!_.isEmpty(params.row.dataFineValidita)) {
          return moment(params.row.dataFineValidita).format(
            "DD/MM/yyyy"
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(routes.scontoAddizionale.show.replace(":id", params.row.id))
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`scontoAddizionaleIndexPage:title`)}
            disabledGoBack
          >
            {Auth.hasAccess("save-scontoaddizionale") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.scontoAddizionale.new))}
              >
                <AddIcon color="primary" />
                {t("scontoAddizionaleIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    type="Date"
                    fullWidth
                    size="small"
                    id={FILTER_ID.DATA_INIZIO}
                    InputLabelProps={{ shrink: true }}
                    value={filters.filterFields.dataInizioValidita.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.dataInizioValidita.id
                      )
                    }
                    label={filters.filterFields.dataInizioValidita.label}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.id;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
