import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalProgrammi } from "@store/global/global.selector";
import { getGlobalProgrammi } from "@store/global/global.effects";

export function SelectProgrammi(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const programmi = useAppSelector(globalProgrammi);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalProgrammi())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:selectProgramma`)}
            data={programmi}
            descriptionKey={'sigla'}
            valueKey={'codProgramma'}
            {...props}
        />
    );
}
