import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { Distributori } from './distributori.types';

export const setDistributoriIndex = createAction<{
    data: Distributori[]
    meta: {
        pagination: Pagination
    }
}>('distributore/index')

export const setDistributoreShow = createAction<Distributori>('distributore/show')