import { AxiosAPI } from "@store/axios.config";
import { AppThunk, AppDispatch, RootState } from "../store";

import { setError, setSuccess } from "../common/common.effects";
import {
  setRequestLoader,
  removeRequestLoader,
} from "@store/common/common.actions";
import { setProduttoriIndex, setProduttoriShow } from "./produttori.actions";
import environment from "@environment/environment";
import { createFilters, createSortColumns } from "@utilities/apiUtilities";
import { Filters, SortModel } from "@store/common/common.types";
import { Produttori, NewProduttori } from "./produttori.types";
import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGlobalProduttori } from "@store/global/global.effects";

export const getProduttoriIndex =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRequestLoader("getProduttoriIndex"));

      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/Produttori?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setProduttoriIndex(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getProduttoriIndex"));
    }
  };

export const getProduttoriShow =
  (codProduttore: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("getProduttoriShow"));

    try {
      const response = await AxiosAPI.get(`/Produttori/${codProduttore}`);
      dispatch(setProduttoriShow(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getProduttoriShow"));
    }
  };

export const postProduttori = createAsyncThunk<
  // Return type of the payload creator
  Produttori,
  // First argument to the payload creator
  NewProduttori,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "postProduttori",
  async (
    produttore: NewProduttori,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("postProduttori"));

    try {
      const response = await AxiosAPI.post(`/Produttori`, produttore);
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      dispatch(getGlobalProduttori(true));
      return response.data as Produttori;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("postProduttori"));
    }
  }
);

export const putProduttori =
  (produttore: Produttori): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("putProduttori"));

    try {
      const { codProduttore } = produttore;

      await AxiosAPI.put(`/Produttori/${codProduttore}`, produttore);
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      dispatch(getGlobalProduttori(true));
      dispatch(getProduttoriShow(codProduttore.toString()));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("putProduttori"));
    }
  };

export const deleteProduttori = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Produttori,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "deleteProduttori",
  async (produttore: Produttori, { rejectWithValue, dispatch, getState }) => {
    dispatch(setRequestLoader("deleteProduttori"));

    try {
      const { codProduttore } = produttore;

      await AxiosAPI.delete(`/Produttori/${codProduttore}`);

      const currentState = getState().produttori.index;
      const data = currentState.data.filter(
        (item) => item.codProduttore !== codProduttore
      );
      dispatch(
        setProduttoriIndex({
          ...currentState,
          data: data,
        })
      );

      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      dispatch(getGlobalProduttori(true));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("deleteProduttori"));
    }
  }
);
