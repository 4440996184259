import { AxiosAPI } from "@store/axios.config";
import { AppDispatch, AppThunk, RootState } from "../store";

import { setError, setSuccess } from "../common/common.effects";
import {
  setRequestLoader,
  removeRequestLoader,
} from "@store/common/common.actions";
import {
  setFlowManagerIndex,
  setSchedulerShow,
  setSchedulersIndex,
  setSemaphoresIndex,
} from "./flowManager.actions";
import environment from "@environment/environment";
import { createFilters, createSortColumns } from "@utilities/apiUtilities";
import { Filters, SortModel } from "@store/common/common.types";
import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FlowManager,
  Scheduler,
  SchedulerLiveData,
  Semaphore,
  Task,
} from "./flowManager.type";

export const getFlowManagerIndex =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[],
    avoidLoader: boolean = false
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (!avoidLoader) {
        dispatch(setRequestLoader("getFlowManagerIndex"));
      }

      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/FlowManager/ScheduleHistory/GetAll?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setFlowManagerIndex(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      if (!avoidLoader) {
        dispatch(removeRequestLoader("getFlowManagerIndex"));
      }
    }
  };

export const getSchedulersIndex =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRequestLoader("getSchedulersIndex"));

      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/FlowManager/Scheduler?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setSchedulersIndex(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getSchedulersIndex"));
    }
  };

export const getSchedulerShow =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRequestLoader("getSchedulerShow"));

      const response = await AxiosAPI.get(`/FlowManager/Scheduler/${id}`);
      dispatch(setSchedulerShow(response.data.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getSchedulerShow"));
    }
  };

interface PutLiveData {
  schedulerId: string;
  liveData: SchedulerLiveData[];
}

export const putLiveData = createAsyncThunk<
  // Return type of the payload creator
  SchedulerLiveData[],
  // First argument to the payload creator
  PutLiveData,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putLiveData",
  async (putLiveData: PutLiveData, { rejectWithValue, dispatch, getState }) => {
    dispatch(setRequestLoader("putLiveData"));

    try {
      const response = await AxiosAPI.put(
        `/FlowManager/Scheduler/${putLiveData.schedulerId}/SchedulerLiveData`,
        putLiveData.liveData
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response.data as SchedulerLiveData[];
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("putLiveData"));
    }
  }
);

interface PutTaskHistoryData {
  status: string;
  id: string;
}

export const putTaskHistory = createAsyncThunk<
  // Return type of the payload creator
  FlowManager,
  // First argument to the payload creator
  PutTaskHistoryData,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putLiveData",
  async (
    putTaskHistoryData: PutTaskHistoryData,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("putLiveData"));

    try {
      const response = await AxiosAPI.put(
        `/FlowManager/ScheduleHistory/${putTaskHistoryData.id}/Status`,
        {
          status: putTaskHistoryData.status,
        }
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response.data as FlowManager;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("putLiveData"));
    }
  }
);

interface PutSchedulerData {
  disabled: boolean;
  id: string;
}

export const putScheduler = createAsyncThunk<
  // Return type of the payload creator
  Scheduler,
  // First argument to the payload creator
  PutSchedulerData,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putScheduler",
  async (
    putSchedulerData: PutSchedulerData,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("putScheduler"));

    try {
      const response = await AxiosAPI.put(
        `/FlowManager/Scheduler/${putSchedulerData.id}/Disabled`,
        {
          disabled: putSchedulerData.disabled,
        }
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response.data as Scheduler;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("putScheduler"));
    }
  }
);

interface PutTaskData {
  id: string;
  strictTime: boolean;
}

export const putTask = createAsyncThunk<
  // Return type of the payload creator
  Task,
  // First argument to the payload creator
  PutTaskData,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putTask",
  async (putTaskData: PutTaskData, { rejectWithValue, dispatch, getState }) => {
    dispatch(setRequestLoader("putTask"));

    try {
      const response = await AxiosAPI.put(
        `/FlowManager/Task/${putTaskData.id}`,
        {
          strictTime: putTaskData.strictTime,
        }
      );
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response.data as Task;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("putTask"));
    }
  }
);

export const getSemaphoresIndex =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRequestLoader("getSemaphoresIndex"));

      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/FlowManager/Semaphore?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setSemaphoresIndex(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getSemaphoresIndex"));
    }
  };

interface PutSemaphoreData {
  ids: string[];
  green: boolean;
}

export const putSemaphore = createAsyncThunk<
  // Return type of the payload creator
  Semaphore,
  // First argument to the payload creator
  PutSemaphoreData,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "putSemaphore",
  async (
    putSemaphoreData: PutSemaphoreData,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setRequestLoader("putSemaphore"));

    try {
      const response = await AxiosAPI.put(`/FlowManager/Semaphore`, {
        ids: putSemaphoreData.ids,
        green: putSemaphoreData.green,
      });
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));

      return response.data as Semaphore;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("putSemaphore"));
    }
  }
);
