import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@store/hooks";

import { nazioniIndex } from "@store/nazioni/nazioni.selector";
import {
  /* deleteNazioni, */ getNazioniIndex,
} from "@store/nazioni/nazioni.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import {
  Box,
  Button,
  IconButton,
  TextField /* , Typography */,
} from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { SelectMonete } from "@components/selects";
import { globalMonete } from "@store/global/global.selector";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { Nazioni } from "@store/nazioni/nazioni.type";
import environment from "@environment/environment";
/* import DeleteIcon from '@material-ui/icons/Delete';
import { Nazioni } from '@store/nazioni/nazioni.type'; */
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_MONETA = "filter-codMoneta",
  COD_ISO = "filter-codISO",
}

export function NazioniIndex() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["nazioniIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`nazioniIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "descrizione,siglaNazione",
      "",
      ""
    ),
    codMoneta: new FieldFilter(
      FILTER_ID.COD_MONETA,
      t(`nazioniIndexPage:filter:filtercodMoneta`),
      FilterTypes.AND,
      "codMoneta",
      FilterMethods.EQUAL,
      ""
    ),
    codISO: new FieldFilter(
      FILTER_ID.COD_ISO,
      t(`nazioniIndexPage:filter:filtercodISO`),
      FilterTypes.AND,
      "codISO",
      FilterMethods.EQUAL,
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<Nazioni>(
    initialFilter,
    [{ field: "descrizione", sort: "asc" }],
    getNazioniIndex,
    nazioniIndex
  );

  const monete = useAppSelector(globalMonete);

  const columns: GridColDef[] = [
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "siglaNazione",
      headerName: t("common:sigla"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.siglaNazione,
    },
    {
      field: "codMoneta",
      headerName: t("common:moneta"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;

        const codMoneta = row.codMoneta;
        const moneta = monete?.find((moneta) => {
          return codMoneta === moneta.codMoneta;
        });

        return moneta?.descrizione;
      },
    },
    {
      field: "lingua",
      headerName: t("common:lingua"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.lingua,
    },
    {
      field: "codISO",
      headerName: t("common:codISO"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.codISO,
    },
    {
      field: "numCaratteriPIVA",
      headerName: t("common:numCaratteriPIVA"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.numCaratteriPIVA,
    },
    {
      field: "area",
      headerName: t("common:area"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.area,
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(routes.nazioni.show.replace(":id", params.row.codNazione))
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`nazioniIndexPage:title`)} disabledGoBack>
            {Auth.hasAccess("save-nazioni") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.nazioni.new))}
              >
                <AddIcon color="primary" />
                {t("nazioniIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectMonete
                    id={FILTER_ID.COD_MONETA}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.codMoneta.value}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={FILTER_ID.COD_ISO}
                    value={filters.filterFields.codISO.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.codISO.id
                      )
                    }
                    label={filters.filterFields.codISO.label}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.codNazione;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities.nazioni,
            ],
          },
        ]}
      />
    </div>
  );
}
