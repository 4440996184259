import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getProvinceShow,
  postProvince,
  putProvince,
} from "@store/province/province.effects";
import { Province, NewProvince } from "@store/province/province.types";
import { provinceShow } from "@store/province/province.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetProvinceShow } from "@store/province/province.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import { SelectRegioni } from "@components/selects/regioni/SelectRegioni";
import Auth from "@utilities/auth";

interface ProvinceDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function ProvinceDetail({ match, creationMode }: ProvinceDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "provinceDetailPage"]);

  const { siglaProvincia } = useParams<{ siglaProvincia: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingProvince = useAppSelector(provinceShow);
  const [provincia, setProvincia] = useState<Province | NewProvince>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getProvinceShow(siglaProvincia));
  }, [dispatch, siglaProvincia, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingProvince) {
      setNewElement(false);
      setProvincia(pollingProvince);
    }
  }, [pollingProvince]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetProvinceShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: { target: { value: any } }, param: string) => {
    const target = e.target;
    updateValue(target.value, param);
  };

  const handleChangeSelectValue = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setProvincia((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "siglaProvincia",
        provincia?.siglaProvincia,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 2 }
      ),
      Validation.buildFieldConfig("descrizione", provincia?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codRegione", provincia?.codRegione, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !provincia) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && provincia) {
      const newProvince: NewProvince = {
        ...provincia,
        codNazione: 1,
      };

      const resultAction = await dispatch(postProvince(newProvince));
      if (postProvince.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.province.show.replace(
              ":siglaProvincia",
              result.siglaProvincia
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putProvince(provincia as Province));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getProvinceShow(siglaProvincia));
    } else {
      setProvincia({});
    }
  };

  return (
    <div>
      {provincia?.codNazione || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("provinceDetailPage:newElementTitle")}`
                : `${t("provinceDetailPage:title")} - ${provincia.descrizione}`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:siglaProvincia")}*`}
                  error={errors["siglaProvincia"] ? true : false}
                  helperText={errors["siglaProvincia"] || ""}
                  onChange={(e) => handleChangeValue(e, "siglaProvincia")}
                  value={provincia?.siglaProvincia || ""}
                  InputProps={{ readOnly: !creationMode }}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:provincia")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={provincia?.descrizione || ""}
                />
                <Box
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectRegioni
                    id="codRegione"
                    onChange={handleChangeSelectValue}
                    value={provincia?.codRegione}
                  />
                </Box>
              </Box>
            </Paper>
            {Auth.hasAccess("save-province") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
