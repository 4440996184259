import { createAction } from '@reduxjs/toolkit';
//import { Pagination } from '@store/common/common.types';
import { Province, ProvinceIndex } from './province.types';

/* export const setProvinceIndex = createAction<{
    data: Province[]
    meta: {
        pagination: Pagination
    }
}>('province/index') */


export const setProvinceIndex = createAction<ProvinceIndex>('province/index')
export const setProvinceShow = createAction<Province>('province/show')
export const resetProvinceShow = createAction('province/rest')