import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalTipiCausaliIva } from "@store/global/global.effects";
import { globalTipiCausaliIva } from "@store/global/global.selector";

export function SelectTipiCausaliIva(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const tipiCausaliIva = useAppSelector(globalTipiCausaliIva);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalTipiCausaliIva())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:tipo`)}
            data={tipiCausaliIva}
            descriptionKey={'id'}
            valueKey={'description'}
            {...props}
        />
    );
}
