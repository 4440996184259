import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setLarghezzaCerchiIndex, setLarghezzaCerchiShow } from './larghezzaCerchi.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { LarghezzaCerchi, NewLarghezzaCerchi } from './larghezzaCerchi.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalLarghezzeCerchi } from '@store/global/global.effects';


export const getLarghezzaCerchiIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getLarghezzaCerchiIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/LarghezzaCerchi?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setLarghezzaCerchiIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLarghezzaCerchiIndex'));
    }
}


export const getLarghezzaCerchiShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getLarghezzaCerchiShow'));

    try {
        const response = await AxiosAPI.get(`/LarghezzaCerchi/${id}`)
        dispatch(setLarghezzaCerchiShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getLarghezzaCerchiShow'));
    }
}


export const postLarghezzaCerchi = createAsyncThunk<
    // Return type of the payload creator
    LarghezzaCerchi,
    // First argument to the payload creator
    NewLarghezzaCerchi,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postLarghezzeCerchi', async (larghezzaCerchi: NewLarghezzaCerchi, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postLarghezzaCerchi'));

        try {
            const response = await AxiosAPI.post(`/LarghezzaCerchi`, larghezzaCerchi)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalLarghezzeCerchi(true))
            return response.data as LarghezzaCerchi
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postLarghezzaCerchi'));
        }
    })


export const putLarghezzaCerchi = (larghezzaCerchi: LarghezzaCerchi): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putLarghezzaCerchi'));

    try {
        const { codLarghezzaCerchio } = larghezzaCerchi

        await AxiosAPI.put(`/LarghezzaCerchi/${codLarghezzaCerchio}`, larghezzaCerchi)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalLarghezzeCerchi(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putLarghezzaCerchi'));
    }
}


export const deleteLarghezzaCerchi = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    LarghezzaCerchi,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteLarghezzaCerchi', async (larghezzaCerchi: LarghezzaCerchi, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteLarghezzaCerchi'));

        try {
            const { codLarghezzaCerchio } = larghezzaCerchi

            await AxiosAPI.delete(`/LarghezzaCerchi/${codLarghezzaCerchio}`)

            const currentState = getState().larghezzaCerchi.index;
            const data = currentState.data.filter((item) => item.codLarghezzaCerchio !== codLarghezzaCerchio)
            dispatch(setLarghezzaCerchiIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalLarghezzeCerchi(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteLarghezzaCerchi'));
        }
    })
