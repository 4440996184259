import { createReducer } from '@reduxjs/toolkit'
import {
    setDettaglioScontiConvenzioniIndex,
    setDettaglioScontiConvenzioniShow,
    resetDettaglioScontiConvenzioniShow
} from './dettaglioScontiConvenzioni.actions'
import { DettaglioScontiConvenzioniIndex, DettaglioScontiConvenzioniShow } from './dettaglioScontiConvenzioni.type'

const initialState: {
    index: DettaglioScontiConvenzioniIndex,
    show: DettaglioScontiConvenzioniShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const dettaglioScontiConvenzioniReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setDettaglioScontiConvenzioniIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setDettaglioScontiConvenzioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetDettaglioScontiConvenzioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

