import React, { Fragment, useEffect } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useState } from "react";
import { EntiConvenzionati } from "@store/enti-convenzionati/entiConvenzionati.types";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  getEntiConvenzionatiShow,
  postEnteConvenzionato,
  putEnteConvenzionato,
} from "@store/enti-convenzionati/entiConvenzionati.effects";
import {
  Accordion,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import { useTranslation } from "react-i18next";
import { entiConvenzionatiShow } from "@store/enti-convenzionati/entiConvenzionati.selector";
import { dateFormatter, scrollFunction } from "@utilities/utilities";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { setError } from "@store/common/common.effects";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { SelectProvince } from "@components/selects/province/SelectProvince";
import {
  SelectLivelliListino,
  SelectMonete,
  SelectPagamenti,
  SelectTipiDocumento,
} from "@components/selects";
import { SelectNazioni } from "@components/selects/nazioni/SelectNazioni";
import Auth from "@utilities/auth";

interface EntiConvenzionatiDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function EntiConvenzionatiDetail({
  match,
  creationMode,
}: EntiConvenzionatiDetailProps) {
  const dispatch = useAppDispatch();

  const [enteConvenzionato, setEnteConvenzionato] = useState(
    {} as EntiConvenzionati
  );
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [newElement, setNewElement] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation(["common", "entiConvenzionatiDetailPage"]);

  const loadedEnteConvenzionato = useAppSelector(entiConvenzionatiShow);

  useEffect(() => {
    scrollFunction();

    if (creationMode || !loadedEnteConvenzionato) return setNewElement(true);

    setNewElement(false);
    setEnteConvenzionato(loadedEnteConvenzionato);
  }, [loadedEnteConvenzionato, creationMode]);

  useEffect(() => {
    if (!creationMode) {
      dispatch(getEntiConvenzionatiShow(id));
    }
  }, [dispatch, creationMode, id]);

  const handleChangeAttribute = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      id: string;
      value: string;
    };
    setEnteConvenzionato((prevState) => {
      return {
        ...prevState,
        [target.id]: target.value,
      };
    });
  };

  const handleChangeEntiConvenzionatiPerGYAttribute = (
    e: React.SyntheticEvent
  ) => {
    const target = e.target as typeof e.target & {
      id: string;
      value: string;
    };
    setEnteConvenzionato((prevState) => {
      return {
        ...prevState,
        entiConvenzionatiPerGY: {
          ...prevState.entiConvenzionatiPerGY,
          [target.id]: target.value,
        },
      };
    });
  };

  const handleChangeEntiConvenzionatiPerGYCheckbox = (e: React.ChangeEvent) => {
    const target = e.target as typeof e.target & {
      id: string;
      checked: boolean;
    };

    setEnteConvenzionato((prevState) => {
      return {
        ...prevState,
        entiConvenzionatiPerGY: {
          ...prevState.entiConvenzionatiPerGY,
          [target.id]: target.checked,
        },
      };
    });
  };

  const handleChangeCheckBox = (e: React.ChangeEvent) => {
    const target = e.target as typeof e.target & {
      id: string;
      checked: boolean;
    };
    updateValue(target.checked, target.id);
  };

  const handleChangeSelect = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setEnteConvenzionato((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "ragioneSociale",
        enteConvenzionato?.ragioneSociale,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 50 }
      ),
      Validation.buildFieldConfig(
        "estensione",
        enteConvenzionato?.estensione,
        [ValidationTypes.MAX_LENGTH],
        { maxLength: 50 }
      ),
      Validation.buildFieldConfig(
        "indirizzoEmail",
        enteConvenzionato?.indirizzoEmail,
        [
          ValidationTypes.REQUIRED,
          ValidationTypes.EMAIL,
          ValidationTypes.MAX_LENGTH,
        ],
        { maxLength: 40 }
      ),
      Validation.buildFieldConfig(
        "indirizzo",
        enteConvenzionato?.indirizzo,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 50 }
      ),
      Validation.buildFieldConfig("localita", enteConvenzionato?.localita, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("provincia", enteConvenzionato?.provincia, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("codNazione", enteConvenzionato?.codNazione, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "cap",
        enteConvenzionato?.cap,
        [ValidationTypes.REQUIRED, ValidationTypes.FIXED_LENGTH],
        { length: 5 }
      ),
      Validation.buildFieldConfig("codISO", enteConvenzionato?.codISO, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "note1",
        enteConvenzionato?.note1,
        [ValidationTypes.MAX_LENGTH],
        { maxLength: 20 }
      ),
      Validation.buildFieldConfig(
        "note2",
        enteConvenzionato?.note1,
        [ValidationTypes.MAX_LENGTH],
        { maxLength: 20 }
      ),
      Validation.buildFieldConfig(
        "note3",
        enteConvenzionato?.note1,
        [ValidationTypes.MAX_LENGTH],
        { maxLength: 20 }
      ),
      Validation.buildFieldConfig(
        "identificativo",
        enteConvenzionato?.entiConvenzionatiPerGY?.identificativo,
        [ValidationTypes.REQUIRED]
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    setErrors({});
    if (validate()) {
      if (creationMode) {
        const resultAction = await dispatch(
          postEnteConvenzionato(enteConvenzionato)
        );
        if (postEnteConvenzionato.fulfilled.match(resultAction)) {
          const result = resultAction.payload;
          dispatch(
            push(
              routes.entiConvenzionati.show.replace(
                ":id",
                result.codCliente.toString()
              )
            )
          );
        }
      } else {
        dispatch(putEnteConvenzionato(enteConvenzionato));
      }
    } else {
      dispatch(setError({ message: t("common:message:validationError") }));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getEntiConvenzionatiShow(id));
    } else {
      setEnteConvenzionato({} as EntiConvenzionati);
    }
  };

  return (
    <Fragment>
      <CustomHeaderPage
        title={
          newElement
            ? `${t("entiConvenzionatiDetailPage:newItemTitle")}`
            : `${t("entiConvenzionatiDetailPage:title")} - ${
                enteConvenzionato.ragioneSociale || ""
              }`
        }
      ></CustomHeaderPage>
      <Grid container>
        <Grid item xs={12}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <Typography>
                {t("entiConvenzionatiDetailPage:accordions:generalData")}
              </Typography>
            </AccordionSummary>
            <Box className="p-2 pb-8">
              <Box className="flex flex-wrap">
                <Box className="w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:ragioneSociale")}
                    size="small"
                    required={true}
                    error={errors["ragioneSociale"] ? true : false}
                    helperText={errors["ragioneSociale"]}
                    id="ragioneSociale"
                    value={enteConvenzionato?.ragioneSociale || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-6/12 xl:w-5/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:estensione")}
                    size="small"
                    error={errors["estensione"] ? true : false}
                    helperText={errors["estensione"]}
                    id="estensione"
                    value={enteConvenzionato?.estensione || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:lingua")}
                    size="small"
                    error={errors["lingua"] ? true : false}
                    helperText={errors["lingua"]}
                    id="lingua"
                    value={enteConvenzionato?.lingua || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2">
                  <SelectLivelliListino
                    id="livelloListino"
                    onChange={handleChangeSelect}
                    value={enteConvenzionato?.livelloListino}
                    error={errors["livelloListino"] ? true : false}
                    helperText={errors["livelloListino"]}
                  />
                </Box>
                <Box className="w-full 2xl:w-6/12 xl:w-4/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:indirizzo")}
                    size="small"
                    required={true}
                    error={errors["indirizzo"] ? true : false}
                    helperText={errors["indirizzo"]}
                    id="indirizzo"
                    value={enteConvenzionato?.indirizzo || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2">
                  <SelectNazioni
                    id={"codNazione"}
                    onChange={handleChangeSelect}
                    value={enteConvenzionato?.codNazione || ""}
                    error={errors["codNazione"] ? true : false}
                    helperText={errors["codNazione"]}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:localita")}
                    size="small"
                    required={true}
                    error={errors["localita"] ? true : false}
                    helperText={errors["localita"]}
                    id="localita"
                    value={enteConvenzionato?.localita || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2">
                  <SelectProvince
                    id={"provincia"}
                    onChange={handleChangeSelect}
                    value={enteConvenzionato?.provincia || ""}
                    error={errors["provincia"] ? true : false}
                    helperText={errors["provincia"]}
                  />
                </Box>

                <Box className="w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:cap")}
                    type="number"
                    size="small"
                    required={true}
                    error={errors["cap"] ? true : false}
                    helperText={errors["cap"]}
                    id="cap"
                    value={enteConvenzionato?.cap || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:codISO")}
                    size="small"
                    error={errors["codISO"] ? true : false}
                    helperText={errors["codISO"]}
                    id="codISO"
                    value={enteConvenzionato?.codISO || ""}
                    onChange={handleChangeAttribute}
                    required={true}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:partitaIVA")}
                    size="small"
                    error={errors["partitaIVA"] ? true : false}
                    helperText={errors["partitaIVA"]}
                    id="partitaIVA"
                    value={enteConvenzionato?.partitaIVA || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:codFiscale")}
                    size="small"
                    error={errors["codFiscale"] ? true : false}
                    helperText={errors["codFiscale"]}
                    id="codFiscale"
                    value={enteConvenzionato?.codFiscale || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-6/12 xl:w-6/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:accordoGY")}
                    size="small"
                    error={errors["accordoGY"] ? true : false}
                    helperText={errors["accordoGY"]}
                    id="accordoGY"
                    value={enteConvenzionato?.accordoGY || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:dataInizioFattCentr")}
                    size="small"
                    type="date"
                    id="dataInizioFattCentr"
                    value={dateFormatter(
                      new Date(enteConvenzionato?.dataInizioFattCentr)
                    )}
                    onChange={handleChangeAttribute}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:dataFineValidita")}
                    size="small"
                    type="date"
                    id="dataFineValidita"
                    value={dateFormatter(
                      new Date(enteConvenzionato?.dataFineValidita ?? "")
                    )}
                    onChange={handleChangeAttribute}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:fakeDotCode")}
                    size="small"
                    error={errors["fakeDotCode"] ? true : false}
                    helperText={errors["fakeDotCode"]}
                    id="fakeDotCode"
                    value={enteConvenzionato?.fakeDotCode || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <SelectMonete
                    id="codMoneta"
                    onChange={handleChangeSelect}
                    value={enteConvenzionato?.codMoneta}
                    error={errors["codMoneta"] ? true : false}
                    helperText={errors["codMoneta"]}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <SelectPagamenti
                    id="codPagamento"
                    onChange={handleChangeSelect}
                    value={enteConvenzionato?.codPagamento}
                    error={errors["codPagamento"] ? true : false}
                    helperText={errors["codPagamento"]}
                    autocomplete={true}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <SelectTipiDocumento
                    id="tipoDocFattura"
                    label={t("common:tipoDocFattura")}
                    onChange={handleChangeSelect}
                    value={enteConvenzionato?.tipoDocFattura}
                    error={errors["tipoDocFattura"] ? true : false}
                    helperText={errors["tipoDocFattura"]}
                  />
                </Box>

                <Box className="w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:identificativo")}
                    size="small"
                    error={errors["identificativo"] ? true : false}
                    helperText={errors["identificativo"]}
                    id="identificativo"
                    required={true}
                    value={
                      enteConvenzionato?.entiConvenzionatiPerGY
                        ?.identificativo || ""
                    }
                    onChange={handleChangeEntiConvenzionatiPerGYAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:codiceGY")}
                    size="small"
                    error={errors["codiceGY"] ? true : false}
                    helperText={errors["codiceGY"]}
                    id="codiceGY"
                    value={
                      enteConvenzionato?.entiConvenzionatiPerGY?.codiceGY || ""
                    }
                    onChange={handleChangeEntiConvenzionatiPerGYAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-4/12 xl:w-4/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:motivoFatturazioneDiversa")}
                    size="small"
                    error={errors["motivoFatturazioneDiversa"] ? true : false}
                    helperText={errors["motivoFatturazioneDiversa"]}
                    id="motivoFatturazioneDiversa"
                    value={
                      enteConvenzionato?.entiConvenzionatiPerGY
                        ?.motivoFatturazioneDiversa || ""
                    }
                    onChange={handleChangeEntiConvenzionatiPerGYAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-4/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:mesiGiacenzaSmaltMass")}
                    size="small"
                    error={errors["mesiGiacenzaSmaltMass"] ? true : false}
                    helperText={errors["mesiGiacenzaSmaltMass"]}
                    id="mesiGiacenzaSmaltMass"
                    value={
                      enteConvenzionato?.entiConvenzionatiPerGY
                        ?.mesiGiacenzaSmaltMass || ""
                    }
                    onChange={handleChangeEntiConvenzionatiPerGYAttribute}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:limiteDOT")}
                    size="small"
                    type="date"
                    id="limiteDOT"
                    value={dateFormatter(
                      new Date(
                        enteConvenzionato?.entiConvenzionatiPerGY?.limiteDOT
                      )
                    )}
                    onChange={handleChangeEntiConvenzionatiPerGYAttribute}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box className="w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:pivaExp")}
                    size="small"
                    error={errors["pivaExp"] ? true : false}
                    helperText={errors["pivaExp"]}
                    id="pivaExp"
                    value={
                      enteConvenzionato?.entiConvenzionatiPerGY?.pivaExp || ""
                    }
                    onChange={handleChangeEntiConvenzionatiPerGYAttribute}
                  />
                </Box>
              </Box>
            </Box>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <Typography>
                {t("entiConvenzionatiDetailPage:accordions:contacts")}
              </Typography>
            </AccordionSummary>
            <Box className="p-2 pb-8">
              <Box className="flex flex-wrap">
                <Box className="w-full flex flex-wrap">
                  <Box className="w-full lg:w-3/12 px-2">
                    <TextField
                      fullWidth
                      label={t("common:responsabile")}
                      size="small"
                      error={errors["responsabile"] ? true : false}
                      helperText={errors["responsabile"]}
                      id="responsabile"
                      value={enteConvenzionato?.responsabile || ""}
                      onChange={handleChangeAttribute}
                    />
                  </Box>
                  <Box className="w-full lg:w-3/12 px-2">
                    <TextField
                      type="email"
                      fullWidth
                      label={t("common:email")}
                      size="small"
                      required={true}
                      error={errors["indirizzoEmail"] ? true : false}
                      helperText={errors["indirizzoEmail"]}
                      id="indirizzoEmail"
                      value={enteConvenzionato?.indirizzoEmail || ""}
                      onChange={handleChangeAttribute}
                    />
                  </Box>
                </Box>
                <Box className="w-full lg:w-3/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:telefono1")}
                    size="small"
                    error={errors["telefono1"] ? true : false}
                    helperText={errors["telefono1"]}
                    id="telefono1"
                    value={enteConvenzionato?.telefono1 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-3/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:fax1")}
                    size="small"
                    error={errors["fax1"] ? true : false}
                    helperText={errors["fax1"]}
                    id="fax1"
                    value={enteConvenzionato?.fax1 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:note1")}
                    size="small"
                    error={errors["note1"] ? true : false}
                    helperText={errors["note1"]}
                    id="note1"
                    value={enteConvenzionato?.note1 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-3/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:telefono2")}
                    size="small"
                    error={errors["telefono2"] ? true : false}
                    helperText={errors["telefono2"]}
                    id="telefono2"
                    value={enteConvenzionato?.telefono2 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-3/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:fax2")}
                    size="small"
                    error={errors["fax2"] ? true : false}
                    helperText={errors["fax2"]}
                    id="fax2"
                    value={enteConvenzionato?.fax2 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:note2")}
                    size="small"
                    error={errors["note2"] ? true : false}
                    helperText={errors["note2"]}
                    id="note2"
                    value={enteConvenzionato?.note2 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-3/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:telefono3")}
                    size="small"
                    error={errors["telefono3"] ? true : false}
                    helperText={errors["telefono3"]}
                    id="telefono3"
                    value={enteConvenzionato?.telefono3 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-3/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:fax3")}
                    size="small"
                    error={errors["fax3"] ? true : false}
                    helperText={errors["fax3"]}
                    id="fax3"
                    value={enteConvenzionato?.fax3 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
                <Box className="w-full lg:w-6/12 px-2">
                  <TextField
                    fullWidth
                    label={t("common:note3")}
                    size="small"
                    error={errors["note3"] ? true : false}
                    helperText={errors["note3"]}
                    id="note3"
                    value={enteConvenzionato?.note3 || ""}
                    onChange={handleChangeAttribute}
                  />
                </Box>
              </Box>
            </Box>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <Typography>
                {t("entiConvenzionatiDetailPage:accordions:options")}
              </Typography>
            </AccordionSummary>
            <Box className="p-2 pb-8">
              <Box className="flex flex-wrap">
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.flgEnteConvenzionato || false
                        }
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgEnteConvenzionato"
                      />
                    }
                    label={t("common:flgEnteConvenzionato")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.flgEnteConvenzionatoGY || false
                        }
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgEnteConvenzionatoGY"
                      />
                    }
                    label={t("common:flgEnteConvenzionatoGY")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgFatturazioneGY || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgFatturazioneGY"
                      />
                    }
                    label={t("common:flgFatturazioneGY")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgColloquio || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgColloquio"
                      />
                    }
                    label={t("common:flgColloquio")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgSocFinanziaria || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgSocFinanziaria"
                      />
                    }
                    label={t("common:flgSocFinanziaria")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgRiepilogoBolle || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgRiepilogoBolle"
                      />
                    }
                    label={t("common:flgRiepilogoBolle")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgRiepDest || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgRiepDest"
                      />
                    }
                    label={t("common:flgRiepDest")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgDOT || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgDOT"
                      />
                    }
                    label={t("common:flgDOT")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgSoloConvenzione || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgSoloConvenzione"
                      />
                    }
                    label={t("common:flgSoloConvenzione")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgCD || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgCD"
                      />
                    }
                    label={t("common:flgCD")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgCDUti || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgCDUti"
                      />
                    }
                    label={t("common:flgCDUti")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={enteConvenzionato?.flgGoodyear || false}
                        disableRipple={true}
                        onChange={handleChangeCheckBox}
                        id="flgGoodyear"
                      />
                    }
                    label={t("common:flgGoodyear")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.entiConvenzionatiPerGY
                            ?.flgTracciatoDettagliato || false
                        }
                        disableRipple={true}
                        onChange={handleChangeEntiConvenzionatiPerGYCheckbox}
                        id="flgTracciatoDettagliato"
                      />
                    }
                    label={t("common:flgTracciatoDettagliato")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.entiConvenzionatiPerGY
                            ?.flgNoTarga || false
                        }
                        disableRipple={true}
                        onChange={handleChangeEntiConvenzionatiPerGYCheckbox}
                        id="flgNoTarga"
                      />
                    }
                    label={t("common:flgNoTarga")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.entiConvenzionatiPerGY
                            ?.flgDateObbl || false
                        }
                        disableRipple={true}
                        onChange={handleChangeEntiConvenzionatiPerGYCheckbox}
                        id="flgDateObbl"
                      />
                    }
                    label={t("common:flgDateObbl")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.entiConvenzionatiPerGY
                            ?.flgNoTargaIns || false
                        }
                        disableRipple={true}
                        onChange={handleChangeEntiConvenzionatiPerGYCheckbox}
                        id="flgNoTargaIns"
                      />
                    }
                    label={t("common:flgNoTargaIns")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.entiConvenzionatiPerGY
                            ?.flgSmaltMass || false
                        }
                        disableRipple={true}
                        onChange={handleChangeEntiConvenzionatiPerGYCheckbox}
                        id="flgSmaltMass"
                      />
                    }
                    label={t("common:flgSmaltMass")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.entiConvenzionatiPerGY
                            ?.flgAnnullamento || false
                        }
                        disableRipple={true}
                        onChange={handleChangeEntiConvenzionatiPerGYCheckbox}
                        id="flgAnnullamento"
                      />
                    }
                    label={t("common:flgAnnullamento")}
                  />
                </Box>
                <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                  <FormControlLabel
                    className="w-full"
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          enteConvenzionato?.entiConvenzionatiPerGY
                            ?.flgDisabilitaScontoAdd || false
                        }
                        disableRipple={true}
                        onChange={handleChangeEntiConvenzionatiPerGYCheckbox}
                        id="flgDisabilitaScontoAdd"
                      />
                    }
                    label={t("common:flgDisabilitaScontoAdd")}
                  />
                </Box>              
              </Box>
            </Box>
          </Accordion>
          {Auth.hasAccess("save-enticonv") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </Grid>
      </Grid>
    </Fragment>
  );
}
