import React from "react";
//import React, { useEffect, useState } from 'react';
//import environment from '@environment/environment'
import { useTranslation } from "react-i18next";
import {
  /* useAppSelector, */ useAppDispatch,
  useAppSelector,
} from "@store/hooks";
import { masterDataUserIndex } from "@store/master-data-user/masterDataUser.selector";
import {
  /* deleteMasterDataUser, */ getMasterDataUserIndex,
} from "@store/master-data-user/masterDataUser.effects";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import {
  Box,
  Button,
  IconButton,
  TextField /* , Typography */,
} from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes /* SortModel */,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
//import DeleteIcon from '@material-ui/icons/Delete';
import { MasterDataUser } from "@store/master-data-user/masterDataUser.type";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { SelectRuoli } from "@components/selects/ruoli/SelectRuoli";
import { globalRuoli } from "@store/global/global.selector";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_USERNAME = "filter-userName",
  COD_EMAIL = "filter-email",
  COD_ROLE = "filter-role",
}

export function MasterDataUserIndex() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["masterDataUserIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`masterDataUserIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "name,surname",
      "",
      ""
    ),
    userName: new FieldFilter(
      FILTER_ID.COD_USERNAME,
      t(`masterDataUserIndexPage:filter:filteruserName`),
      FilterTypes.AND,
      "userName",
      FilterMethods.EQUAL,
      ""
    ),
    email: new FieldFilter(
      FILTER_ID.COD_EMAIL,
      t(`masterDataUserIndexPage:filter:filteremail`),
      FilterTypes.AND,
      "email",
      FilterMethods.EQUAL,
      ""
    ),
    role: new FieldFilter(
      FILTER_ID.COD_ROLE,
      t(`masterDataUserIndexPage:filter:filterrole`),
      FilterTypes.AND,
      "roleid",
      FilterMethods.IN,
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<MasterDataUser>(
    initialFilter,
    [{ field: "name", sort: "asc" }],
    getMasterDataUserIndex,
    masterDataUserIndex
  );

  const roles = useAppSelector(globalRuoli);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("common:name"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.name,
    },
    {
      field: "surname",
      headerName: t("common:surname"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.surname,
    },
    {
      field: "userName",
      headerName: t("common:userName"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.userName,
    },
    {
      field: "email",
      headerName: t("common:email"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.email,
    },
    {
      field: "roles",
      headerName: t("common:roles"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) =>
        roles
          .filter((role) => params.row.roles.includes(role.id))
          .map((x) => x.name)
          .join(", "),
    },
    {
      field: "emailConfirmed",
      headerName: t("common:emailConfirmed"),
      flex: 1,
      disableClickEventBubbling: true,
      type: "boolean",
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(routes.users.show.replace(":id", params.row.id))
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`masterDataUserIndexPage:title`)}
            disabledGoBack
          >
            {Auth.hasAccess("save-users") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.users.new))}
              >
                <AddIcon color="primary" />
                {t("masterDataUserIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={FILTER_ID.COD_USERNAME}
                    value={filters.filterFields.userName.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.userName.id
                      )
                    }
                    label={filters.filterFields.userName.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={FILTER_ID.COD_EMAIL}
                    value={filters.filterFields.email.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.email.id
                      )
                    }
                    label={filters.filterFields.email.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectRuoli
                    id={FILTER_ID.COD_ROLE}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.role.value}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.id;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
