import { AxiosAPI } from "@store/axios.config";
import { AppThunk, AppDispatch, RootState } from "../store";

import { setError } from "../common/common.effects";
import {
  setRequestLoader,
  removeRequestLoader,
} from "@store/common/common.actions";
import { setLogManagerIndex } from "./logManager.actions";
import environment from "@environment/environment";
import { createFilters, createSortColumns } from "@utilities/apiUtilities";
import { Filters, SortModel } from "@store/common/common.types";
import { LogManager } from "./logManager.type";
import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLogManagerIndex =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRequestLoader("getLogManagerIndex"));

      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/LogManager?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setLogManagerIndex(response.data));
    } catch (error) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getLogManagerIndex"));
    }
  };

export const getRelatedLogs = createAsyncThunk<
  // Return type of the payload creator
  LogManager[],
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "getRelatedLogs",
  async (correlationId: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await AxiosAPI.get(
        `/LogManager/${correlationId}/RelatedLogs`
      );

      return response.data as LogManager[];
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    }
  }
);
