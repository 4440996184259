import { CustomHeaderPage } from "@components/custom-header-page/CustomHeaderPage";
import { SelectEntiConvenzionati } from "@components/selects";
import { Box, Button, Paper } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { setWarning } from "@store/common/common.effects";
import { useAppDispatch } from "@store/hooks";
import { importContratti } from "@store/tipi-contratto/tipiContratto.effects";
import { SelectTipiContrattoFlotte } from "@components/selects/tipi-contratto-flotte/SelectTipiContrattoFlotte";
import { ConfirmAction, ConfirmActionPropsData } from "@components/index";

export function FleetContractImport() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["fleetContractTypesImport", "common"]);

  const [data, setData] = useState<{
    targa: string
  }[]>([]);
  const [codEnteConv, setCodEnteConv] = useState(0);
  const [idContrattoFlotta, setIdContrattoFlotta] = useState(0);
  const [fileName, setFileName] = useState<string>(t("common:noFileSelected"));
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  useEffect(() => {
    setIdContrattoFlotta(0);
  }, [codEnteConv])

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      // Parso il CSV e lo setto il contenuto stato
      Papa.parse(e.target.files[0], {
        header: false,
        complete: (results) => {
          if (
            results.data &&
            results.data.length > 0 &&
            Array.isArray(results.data[0])
          ) {
            setData(
              results.data.map((row: any) => {
                return {
                  targa: row[0]
                };
              })
            );
          } else {
            setData([]);
            dispatch(
              setWarning({
                message: t("common:message:fileIsEmptyOrMalformed"),
              })
            );
          }
        },
        encoding: "utf-8",
        transform: (value, field) => {
          return value.replace("�", "").trim();
        },
        skipEmptyLines: true,
      });
    } else {
      setData([]);
      setFileName(t("common:noFileSelected"));
    }
  };

  const handleImport = () => {
    setConfirmAction({
      isOpen: true,
      title: t("common:message:defaultConfirmationTitle"),
      subtitle: t("common:message:defaultConfirmationMessage"),
      confirmAction: () => {
        dispatch(
          importContratti({
            targhe: data.map(x => x.targa),
            idFlotta: codEnteConv,
            idAnagraficaContrattoFlotte: idContrattoFlotta
          })
        )
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "targa",
      headerName: t("common:targa"),
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      flex: 1,
    }
  ];

  return (
    <Fragment>
      <CustomHeaderPage
        title={`${t("fleetContractTypesImport:title")}`}
        disabledGoBack
      />
      <div
        dangerouslySetInnerHTML={{
          __html: `${t("fleetContractTypesImport:description")}`,
        }}
      ></div>
      <Paper className="p-4 my-4">
        <Box className="flex items-center flex-row flex-wrap justify-between">
          <Box className="flex items-center flex-row flex-wrap w-full md:w-10/12">
            <Box className="px-2 w-full md:w-4/12">
              <SelectEntiConvenzionati
                id="enteConv"
                value={codEnteConv}
                onChange={(value, id) => {
                  setCodEnteConv(value);
                }}
              />
            </Box>
            <Box className="px-2 w-full md:w-4/12">
              <SelectTipiContrattoFlotte
                id="contrattoFlotta"
                value={idContrattoFlotta}
                onChange={(value, id) => {
                  setIdContrattoFlotta(value);
                }}
                disabled={! codEnteConv}
                codEnteConv={codEnteConv.toString()}
              />
            </Box>
            <Box className="px-2 w-full md:w-8/12 flex items-center mt-2">
              <Button variant="contained" component="label" className="mr-4">
                {t("common:uploadFile")}
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
              {fileName}
            </Box>
          </Box>
          <Box className="w-full md:w-2/12 flex justify-end">
            <Button
              className="m-4 mr-0"
              onClick={handleImport}
              disabled={!codEnteConv || data.length === 0 || !idContrattoFlotta}
            >
              {t("common:import")}
            </Button>
          </Box>
        </Box>
      </Paper>
      {data?.length > 0 ? (
        <Paper className="p-4">
          <DataGrid
            autoHeight
            rows={data.slice(0, 99) || []}
            columns={columns}
            getRowId={(row) => {
              return (
                row.targa + Math.floor(Math.random() * 10000000).toString()
              );
            }}
            disableColumnFilter
            hideFooter
          />
        </Paper>
      ) : null}
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
    </Fragment>
  );
}
