import { useAppDispatch } from '@store/hooks';
import { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonSpeedDial, CustomHeaderPage } from '@components/index'
import { MasterDataUserME, putMasterDataUserME, userData } from '@store/master-data-user/masterDataUser.effects';
import { Box, Paper, TextField } from '@material-ui/core';
import { Validation, ValidationConfig, ValidationTypes } from '@utilities/validation';
import { scrollFunction } from '@utilities/utilities';
import { setError } from '@store/common/common.effects';




export function MasterDataUserProfile() {

    //const classes = useStyles();
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['common', 'masterDataUserDetailPage'])
    const [newElement, setNewElement] = useState<boolean>(false)
    const [masterDataUser, setMasterDataUser] = useState<any>({})
    const [originalUserDAta, setOriginalUserDAta] = useState<any>({})

    const [errors, setErrors] = useState({} as { [key: string]: string });
    // componentDidMount()
    useEffect(() => {
        scrollFunction()
        async function fetchMyData() {
            let { payload } = await dispatch(MasterDataUserME())
            if (payload && payload.data) {
                setMasterDataUser(payload.data)
                setOriginalUserDAta(payload.data)
            }
            setNewElement(false)
        }
        fetchMyData()
    }, [dispatch]);

    const handleChangeValue = (e: SyntheticEvent, param: string) => {
        setErrors({})

        const target = e.target as typeof e.target & { value: string }

        setMasterDataUser((prevState: any) => {
            return {
                ...prevState,
                [param]: target.value
            }
        })
    }

    const validate = () => {
        const fieldsConfig: ValidationConfig[] = [
            Validation.buildFieldConfig('name', masterDataUser?.name, [ValidationTypes.REQUIRED]),
            Validation.buildFieldConfig('surname', masterDataUser?.surname, [ValidationTypes.REQUIRED]),
            Validation.buildFieldConfig('email', masterDataUser?.email, [ValidationTypes.REQUIRED]),
        ];

        const validationErrors = Validation.validate(fieldsConfig);
        if (masterDataUser?.password || masterDataUser?.confirmPassword) {
            // password coincidono
            if (masterDataUser?.password === masterDataUser?.confirmPassword) {
                if (!/[^\w\s]/.test(masterDataUser?.password)) {
                    validationErrors['password'] = 'Almeno 1 carattere speciale'
                }
                if (!/[A-Z]/.test(masterDataUser?.password)) {
                    validationErrors['password'] = 'Deve contenere al meno 1 lettera Maiuscola'
                }
                if (!/[0-9]/.test(masterDataUser?.password)) {
                    validationErrors['password'] = 'Deve contenere al meno 1 numero'
                }
                if (masterDataUser?.password.length < 8) {
                    validationErrors['password'] = 'Minimo 8 caratteri'
                }



            } else {
                validationErrors['password'] = 'Password non coincidono'
                validationErrors['confirmPassword'] = 'Password non coincidono'
            }

        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    }

    const handleSave = async () => {

        if (!validate() || !masterDataUser) {
            dispatch(setError({ message: t('common:message:validationError') }))
            return
        } else {
            let dataUser: userData = {
                name: masterDataUser.name,
                surname: masterDataUser.surname,
                phoneNumber: masterDataUser.phoneNumber,
                password: masterDataUser.password,
                confirmPassword: masterDataUser.confirmPassword
            }
            dispatch(putMasterDataUserME(dataUser))
        }
    }

    const handleCancel = () => {
        setErrors({});
        setMasterDataUser(originalUserDAta)

    }

    return (
        <div>
            <Fragment>
                <CustomHeaderPage
                    title={newElement ? `${t('masterDataUserDetailPage:newElementTitle')}` : `${t('masterDataUserDetailPage:title')} - ${masterDataUser.name}`}
                >
                </CustomHeaderPage>
                <Box className='flex flex-grow flex-col mb-4'>
                    <Paper>
                        <Box className='flex flex-grow flex-row flex-wrap pb-2'>

                            <TextField
                                className='px-4 w-full xl:w-3/12 md:w-6/12'
                                size='small'
                                InputLabelProps={{ className: 'pl-4, mx-4' }}
                                label={`${t('common:name')}*`}
                                error={errors['name'] ? true : false}
                                helperText={errors['name'] || ''}
                                onChange={(e) => handleChangeValue(e, 'name')}
                                value={masterDataUser?.name || ''}
                            />
                            <TextField
                                className='px-4 w-full xl:w-3/12 md:w-6/12'
                                size='small'
                                InputLabelProps={{ className: 'pl-4, mx-4' }}
                                label={`${t('common:surname')}*`}
                                error={errors['surname'] ? true : false}
                                helperText={errors['surname'] || ''}
                                onChange={(e) => handleChangeValue(e, 'surname')}
                                value={masterDataUser?.surname || ''}
                            />
                        </Box>
                        <Box className='flex flex-grow flex-row flex-wrap pb-2'>
                            <TextField
                                className='px-4 w-full xl:w-3/12 md:w-6/12'
                                size='small'
                                InputLabelProps={{ className: 'pl-4, mx-4' }}
                                label={`${t('common:phoneNumber')}*`}
                                error={errors['phoneNumber'] ? true : false}
                                helperText={errors['phoneNumber'] || ''}
                                onChange={(e) => handleChangeValue(e, 'phoneNumber')}
                                value={masterDataUser?.phoneNumber || ''}
                            />
                            <TextField
                                className='px-4 w-full xl:w-3/12 md:w-6/12'
                                size='small'
                                disabled
                                InputLabelProps={{ className: 'pl-4, mx-4' }}
                                label={`${t('common:email')}*`}
                                error={errors['email'] ? true : false}
                                helperText={errors['email'] || ''}
                                onChange={(e) => handleChangeValue(e, 'email')}
                                value={masterDataUser?.email || ''}
                            />
                        </Box>
                        <Box className='flex flex-grow flex-col mb-4 pl-5'>
                            <div style={{ fontWeight: 'bold' }}> Modifica password </div>
                        </Box>
                        <Box className='flex flex-grow flex-row flex-wrap pb-2'>
                            <Fragment>
                                <TextField
                                    className='px-4 w-full xl:w-3/12 md:w-6/12'
                                    size='small'
                                    type='password'
                                    InputLabelProps={{ className: 'pl-4, mx-4' }}
                                    label={`${t('common:NewPassword')}`}
                                    error={errors['password'] ? true : false}
                                    helperText={errors['password'] || ''}
                                    onChange={(e) => handleChangeValue(e, 'password')}
                                    value={masterDataUser?.password || ''}
                                />
                                <TextField
                                    className='px-4 w-full xl:w-3/12 md:w-6/12'
                                    size='small'
                                    type='password'
                                    InputLabelProps={{ className: 'pl-4, mx-4' }}
                                    label={`${t('common:confirmPassword')}`}
                                    error={errors['confirmPassword'] ? true : false}
                                    helperText={errors['confirmPassword'] || ''}
                                    onChange={(e) => handleChangeValue(e, 'confirmPassword')}
                                    value={masterDataUser?.confirmPassword || ''}
                                />
                            </Fragment>
                        </Box>
                    </Paper>
                </Box>
                <CommonSpeedDial
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            </Fragment>

        </div >);
}