import environment from '@environment/environment';
import { FieldFilter, Pagination, SortModel } from '@store/common/common.types';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ResourcesIndexThunk, RootState } from '@store/store';
import { scrollFunction } from '@utilities/utilities';
import { useEffect, useState } from 'react';


// Common functions and states of index pages
export function useIndexPage<Entity>(
    initialFilter: { [key: string]: FieldFilter }, // initial filters config
    defaultSortModel: SortModel[], // default sorts config
    resourcesIndexThunk: ResourcesIndexThunk, // thunk for data loading
    resourcesIndexSelector: (state: RootState) => {  // redux selector to get the data
        data: Entity[];
        meta: {
            pagination?: Pagination | undefined;
        };
    },
    defaultPageSize: number = environment.pagination.defaultPageSize, // default pageSize
    execRequest: boolean = false
) {
    const dispatch = useAppDispatch();

    const index = useAppSelector(resourcesIndexSelector);
    const [data, setData] = useState<{
        data: Entity[];
        meta: {
            pagination?: Pagination | undefined;
        };
    }>({
        data: [],
        meta: {
            pagination: undefined
        }
    })
    const [sortColumns, setSortColumns] = useState<SortModel[]>(defaultSortModel)

    const [filters, setFilters] = useState({
        filterFields: initialFilter,
        activeFilters: {} as typeof initialFilter
    })

    const [pageIsLoad, setPageIsLoad] = useState<boolean>(execRequest)
    const [pageSize, setPageSize] = useState<number>(defaultPageSize)
    const [page, setPage] = useState<number>(1)

    useEffect(() => {
        setData(index)
    }, [index]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (pageIsLoad) {
            dispatch(resourcesIndexThunk(page, pageSize, filters.activeFilters, sortColumns))
        } else {
            setPageIsLoad(true);
        }
    }, [page, pageSize, filters.activeFilters, sortColumns]) // eslint-disable-line react-hooks/exhaustive-deps

    // scroll to top
    useEffect(() => {
        scrollFunction()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // handle textfield change value
    const handleTextFieldChangeValue = (e: React.SyntheticEvent, id: string) => {
        const target = e.target as typeof e.target & { value: string }
        updateFilters(target.value, id)
    };

    // handle custom selects change value
    const handleSelectChangeValue = (value: any, id: string) => {
        updateFilters(value, id)
    }

    // handle checkbox change value
    const handleCheckBoxChangeValue = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, id: string) => {
        updateFilters(checked ? '1' : '', id);
    };

    // update filters on local state
    const updateFilters = (value: any, id: string) => {
        let currentFilterFields = filters.filterFields
        Object.entries(currentFilterFields).forEach(([key, val]) => {
            if (val.id === id) currentFilterFields[key].value = value
        })

        setFilters(prevState => {
            return {
                ...prevState,
                filterFields: currentFilterFields
            }
        })
    }

    return { data, setData, filters, setFilters, page, setPage, pageSize, setPageSize, sortColumns, setSortColumns, handleSelectChangeValue, handleTextFieldChangeValue, handleCheckBoxChangeValue, updateFilters }
}