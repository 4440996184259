import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalModelli } from "@store/global/global.selector";
import { getGlobalModelli } from "@store/global/global.effects";
import { Modello } from "@store/veicoli/veicoli.types";

export interface CustomSelectModelliProps extends CustomSelectProps{
    codMarca?: string
}

export function SelectModelli(props: CustomSelectModelliProps) {
    
    const { codMarca } = props;

    const { t } = useTranslation(['common'])
    const dispatch = useAppDispatch();

    const allModelli = useAppSelector(globalModelli);
    const [modelli, setModelli] = useState<Modello[]>([])

    // reload disegni on change produttore or tipo articolo
    useEffect(() => {
        let filteredModelli: Modello[] = allModelli;
        if (codMarca) {
            filteredModelli = filteredModelli.filter(modello => modello.codMarca === Number(codMarca))
        } else {
            filteredModelli = [];
        }
        setModelli(filteredModelli);
    }, [allModelli, codMarca]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getGlobalModelli())
    }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <CustomSelect
            label={t(`common:modello`)}
            data={modelli}
            descriptionKey={'descrizione'}
            valueKey={'codModello'}
            {...props}
        />
    );
}