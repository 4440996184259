import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import { articoliIndex } from "@store/articoli/articoli.selector";
import { getArticoliIndex } from "@store/articoli/articoli.effects";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Box, Button, IconButton, TextField } from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { globalTipiArticolo } from "@store/global/global.selector";
import { SelectTipiArticolo } from "@components/selects";
import { Articoli } from "@store/articoli/articoli.types";
import { useIndexPage } from "@pages/common/UseIndexPage";
import environment from "@environment/environment";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_DIVISIONE = "filter-codDivisione",
  COD_TIPOARTICOLO = "filter-codTipoArticolo",
  DATA_OPERAZIONE = "filter-dataOperazione",
}
export function ServiziIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["serviziIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`serviziIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "descrizione,codArticolo",
      "",
      ""
    ),
    codTipoArticolo: new FieldFilter(
      FILTER_ID.COD_TIPOARTICOLO,
      t(`serviziIndexPage:filter:filtercodTipoArticolo`),
      FilterTypes.AND,
      "codTipoArticolo",
      FilterMethods.EQUAL,
      ""
    ),
    dataOperazione: new FieldFilter(
      FILTER_ID.DATA_OPERAZIONE,
      t(`serviziIndexPage:filter:filterDataOperazione`),
      FilterTypes.AND,
      "dataOperazione",
      FilterMethods.GREATERTHANOREQUALTO,
      ""
    ),
    codDivisione: new FieldFilter(
      FILTER_ID.COD_DIVISIONE,
      "",
      FilterTypes.AND,
      "codDivisione",
      FilterMethods.EQUAL,
      "3",
      true
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<Articoli>(
    initialFilter,
    [{ field: "codArticolo", sort: "asc" }],
    getArticoliIndex,
    articoliIndex
  );

  const tipoArticoli = useAppSelector(globalTipiArticolo);

  const columns: GridColDef[] = [
    {
      field: "codArticolo",
      headerName: t("common:codArticolo"),
      //flex: 1,
      width: 205,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.codArticolo,
    },
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "codTipoArticolo",
      headerName: t("common:tipoArticolo"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;

        const codTipoArticolo = row.codTipoArticolo;
        const tipoArticolo = tipoArticoli.find((tipoArticolo) => {
          return codTipoArticolo === tipoArticolo.codTipoArticolo;
        });

        return tipoArticolo?.descrizione;
      },
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(routes.servizi.show.replace(":id", params.row.codArticolo))
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`serviziIndexPage:title`)} disabledGoBack>
            {Auth.hasAccess("save-articoli") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.servizi.new))}
              >
                <AddIcon color="primary" />
                {t("serviziIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            forceFirstUpdate
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-3/12 xl:w-5/12 md:w-6/12">
                  <SelectTipiArticolo
                    id={FILTER_ID.COD_TIPOARTICOLO}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.codTipoArticolo.value}
                    codDivisione="3"
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    type="Date"
                    fullWidth
                    size="small"
                    id={FILTER_ID.DATA_OPERAZIONE}
                    InputLabelProps={{ shrink: true }}
                    value={filters.filterFields.dataOperazione.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.dataOperazione.id
                      )
                    }
                    label={filters.filterFields.dataOperazione.label}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.codArticolo;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .articoli,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .articoliLabeling,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .articoliDatiAggiuntivi,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.centralDatabaseUpdates.entities
                .articoli,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.areaRiservataUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .articoli,
            ],
          },
        ]}
      />
    </div>
  );
}
