import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { entiConvenzionatiSSIndex } from "@store/enti-convenzionati-SS/entiConvenzioniSS.selector";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { EntiConvenzionatiSS } from "@store/enti-convenzionati-SS/entiConvenzioniSS.types";
import { entiConvenzionatiIndex } from "@store/enti-convenzionati/entiConvenzionati.selector";
import { useEffect, useState } from "react";
import { sediOperativeIndex } from "@store/sedi-operative/sedeOperativa.selector";
import {
  deleteEntiConvenzionatiSS,
  updateEntiConvenzionatiSS,
  postEntiConvenzionatiSS,
  getEntiConvenzionatiSSIndex,
} from "@store/enti-convenzionati-SS/entiConvenzioniSS.effects";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { setError } from "@store/common/common.effects";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { ConfirmAction, ConfirmActionPropsData } from "@components/index";
import { FiltersAccordion } from "@components/index";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import environment from "@environment/environment";
import Divider from "@material-ui/core/Divider";
import { SelectEntiConvenzionati } from "@components/selects";
import { CustomSelect } from "@components/selects/custom-select/CustomSelect";
import { SedeOperativa } from "@store/sedi-operative/sedeOperativa.types";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_DITTA = "cod-ditta",
}

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: "solid 1px #97979752",
    height: "6.25rem",
    display: "flex",
    alignItems: "center",
    padding: "0 1.531rem",
    flexWrap: "wrap",
  },
  copyright: {
    flexGrow: 1,
  },
  assistance: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

interface TypeEntiConvenzionatiSelect {
  codDitta: string;
}

export function EntiConvSSComponent(props: TypeEntiConvenzionatiSelect) {
  const classes = useStyles();
  const { t } = useTranslation(["common", "tooltip"]);
  const dispatch = useAppDispatch();

  const { codDitta } = props;

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`anagraficaDetailsPage:filter:filterOrMulti`),
      FilterTypes.AND,
      "codSedeOp",
      FilterMethods.EQUAL,
      ""
    ),
    codDitta: new FieldFilter(
      FILTER_ID.COD_DITTA,
      "",
      FilterTypes.AND,
      "codDitta",
      FilterMethods.EQUAL,
      codDitta,
      true
    ),
  };

  const [filters, setFilters] = useState({
    filterFields: initialFilter,
    activeFilters: {} as typeof initialFilter,
  });
  const { defaultPageSize } = environment.pagination;

  const entiConvenzionatiSS = useAppSelector(entiConvenzionatiSSIndex);
  const entiConvenzionati = useAppSelector(entiConvenzionatiIndex);

  const sediOperative = useAppSelector(sediOperativeIndex);

  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [entiConvSS, setEntiConvSS] = useState<EntiConvenzionatiSS[]>([]);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  const [pageIsLoad, setPageIsLoad] = useState<boolean>(false);

  const [entiConv, setEntiConv] = useState<number[]>([]);
  const [sedeOp, setSedeOp] = useState<SedeOperativa>({
    codSedeOperativa: 0,
  } as SedeOperativa);

  useEffect(() => {
    if (pageIsLoad) {
      dispatch(
        getEntiConvenzionatiSSIndex(1, defaultPageSize, filters.activeFilters)
      );
    } else {
      setPageIsLoad(true);
    }
  }, [dispatch, filters.activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEntiConvSS(entiConvenzionatiSS.data || []);
  }, [entiConvenzionatiSS]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeValue = (e: { target: { value: any } }, id: string) => {
    const target = e.target as typeof e.target & { value: string };

    let currentFilterFields = filters.filterFields;
    Object.entries(currentFilterFields).forEach(([key, value]) => {
      if (value.id === id) currentFilterFields[key].value = target.value;
    });

    setFilters((prevState) => {
      return {
        ...prevState,
        filterFields: currentFilterFields,
      };
    });
  };

  const validateNewEnteSS = (newEnteConvSSS: EntiConvenzionatiSS) => {
    let newKey =
      newEnteConvSSS.codEnteConv +
      newEnteConvSSS.codDitta +
      newEnteConvSSS.codSedeOp +
      newEnteConvSSS.codRete;

    return entiConvSS?.some((enteConvSS) => {
      let chiave =
        enteConvSS?.codEnteConv +
        enteConvSS?.codDitta +
        enteConvSS?.codSedeOp +
        enteConvSS?.codRete;
      return chiave === newKey;
    });
  };

  const validate = (newEnteConvSS: EntiConvenzionatiSS) => {
    const { codSedeOp } = newEnteConvSS;

    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "indirizzo",
        codSedeOp > 0 ? codSedeOp.toString() : undefined,
        [ValidationTypes.REQUIRED]
      )
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    console.log(validationErrors);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmitEnteConvSS = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    let listEntiConvenzionatiSS: EntiConvenzionatiSS[] = [];

    if (entiConv.length > 0) {
      entiConv.forEach((codCliente) => {
        const enteConvenzionato = entiConvenzionati.data.find(
          (ente) => ente.codCliente === codCliente
        ); 
        let newEnteConvSS: EntiConvenzionatiSS = {
          codSSXEnteConv: 0,
          utente: "TestUt",
          tipoOperazione: "C",
          dataOperazione: "",
          dataAttivazione: "",
          dataDisattivazione: "",
    
          codDitta: sedeOp.codDitta,
          codRete: sedeOp?.sediOpGoodyearReti?.codRete || 0,
          codEnteConv: enteConvenzionato?.codCliente || 0,
          codSedeOp: sedeOp?.codSedeOperativa || 0,
          flgIgnoraRagno: false,
          flgColloquio: false,
          flgFatturazioneDiretta: false,
        };    

        if (!validate(newEnteConvSS)) {
          return dispatch(setError({ message: t("common:message:validationError") }));
        }
        
        if (validateNewEnteSS(newEnteConvSS)) {
          const nomeFlotte = " " + entiConvenzionati.data.find(ente => ente.codCliente === newEnteConvSS.codEnteConv)?.ragioneSociale + " ";
          return dispatch(setError({ message: t("common:codEnteConv") + nomeFlotte + t("common:validatorErrors:dataExists")}));
        }

        listEntiConvenzionatiSS.push(newEnteConvSS);
      });
    }
    else {
      return dispatch(setError({ message: t("common:message:noFleetSelected") })
      );     
    }

    console.log(listEntiConvenzionatiSS); 

    if(listEntiConvenzionatiSS.length > 0){
      dispatch(postEntiConvenzionatiSS(listEntiConvenzionatiSS));
      setEntiConv([]);
      setSedeOp({codSedeOperativa:0} as SedeOperativa);
    }
  };
  const handlerResetError = () => setErrors({});

  const columns: GridColDef[] = [
    {
      field: "codSedeOp",
      headerName: `${t("common:sedeOperativa")}`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;
        const sede = sediOperative.data.find(
          (x) => x.codSedeOperativa === row.codSedeOp
        );
        return (
          sede?.codSedeOperativa +
          " - " +
          sede?.indirizzo +
          " - " +
          sede?.provincia
        );
      },
    },
    {
      field: "codEnteConv",
      headerName: `${t("common:codEnteConv")}`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;

        const codEnteConv = row?.codEnteConv;
        const enteConvenzionato = entiConvenzionati?.data?.find(
          (enteConvenzionato) => {
            return codEnteConv === enteConvenzionato?.codCliente;
          }
        );
        return enteConvenzionato?.ragioneSociale;
      },
    },
    {
      field: "Numero da aggiungere",
      headerName: `${t("common:codSSXEnteConv")}`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;
        return row.codSSXEnteConv;
      },
      renderCell: (params) => {
        const enteConvenzionatoSS = params.row as typeof params.row &
          EntiConvenzionatiSS;

        const updateEnte = (e: React.KeyboardEvent<HTMLDivElement>) => {
          const target = e.target as typeof e.target & { value: boolean };

          if (e.keyCode === 13) {
            let data = [
              {
                op: "replace",
                path: "/codSSXEnteConv",
                value: target.value,
              },
            ];
            dispatch(updateEntiConvenzionatiSS(enteConvenzionatoSS, data));
          }
        };

        return (
          <Tooltip title={`${t("tooltip:confermaInvio")}`} aria-label="add">
            <span>
              <TextField
                type="number"
                onKeyDown={updateEnte}
                defaultValue={enteConvenzionatoSS.codSSXEnteConv}
                disabled={!Auth.hasAccess("save-anagrafiche")}
              />
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "flgIgnoraRagno",
      headerName: `${t("common:flgIgnoraRagno")}`,
      headerAlign: "center",
      width: 150,
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const enteConvenzionatoSS = params.row as typeof params.row &
          EntiConvenzionatiSS;
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let data = [
            {
              op: "replace",
              path: "/flgIgnoraRagno",
              value: e.target.checked,
            },
          ];
          dispatch(updateEntiConvenzionatiSS(enteConvenzionatoSS, data));
        };

        return (
          <Checkbox
            color="primary"
            onChange={onChange}
            checked={enteConvenzionatoSS.flgIgnoraRagno}
            disabled={!Auth.hasAccess("save-anagrafiche")}
          />
        );
      },
    },
    {
      field: "flgColloquio",
      headerName: `${t("common:flgColloquio")}`,
      headerAlign: "center",
      width: 150,
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;
        return row.flgColloquio;
      },
      renderCell: (params) => {
        const enteConvenzionatoSS = params.row as typeof params.row &
          EntiConvenzionatiSS;
        const onChange = (
          e: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          let data = [
            {
              op: "replace",
              path: "/flgColloquio",
              value: e.target.checked,
            },
          ];
          dispatch(updateEntiConvenzionatiSS(enteConvenzionatoSS, data));
        };

        return (
          <Checkbox
            color="primary"
            onChange={onChange}
            checked={enteConvenzionatoSS.flgColloquio}
            disabled={!Auth.hasAccess("save-anagrafiche")}
          />
        );
      },
    },
    {
      field: "flgFatturazioneDiretta",
      headerName: `${t("common:flgFatturazioneDiretta")}`,
      headerAlign: "center",
      align: "center",
      width: 200,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const enteConvenzionatoSS = params.row as typeof params.row &
          EntiConvenzionatiSS;
        const onChange = (
          e: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          let data = [
            {
              op: "replace",
              path: "/flgFatturazioneDiretta",
              value: e.target.checked,
            },
          ];
          dispatch(updateEntiConvenzionatiSS(enteConvenzionatoSS, data));
        };

        return (
          <Checkbox
            color="primary"
            onChange={onChange}
            checked={enteConvenzionatoSS?.flgFatturazioneDiretta}
            disabled={!Auth.hasAccess("save-anagrafiche")}
          />
        );
      },
    },
  ];

  if (Auth.hasAccess("save-anagrafiche")) {
    columns.push({
      field: "cancel",
      headerName: `${t("common:cancel")}`,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          let sendEmail = false;
          const emailSend = (e: React.ChangeEvent<HTMLInputElement>) => {
            sendEmail = e.target.checked;
          };
          let checkboxEmail: JSX.Element = (
            <FormControlLabel
              control={<Checkbox color="primary" onChange={emailSend} />}
              label="Inviare email "
            />
          );
          const enteConvenzionatoSS = params.row as typeof params.row &
            EntiConvenzionatiSS;
          setConfirmAction({
            isOpen: true,
            title: "Delete",
            subtitle: "Confermare l'eliminazione ?",
            confirmAction: async () => {
              var res = await dispatch(
                deleteEntiConvenzionatiSS({ enteConvenzionatoSS, sendEmail })
              );
              if (res && res.payload) {
                var tmpEntiConvSS = [...entiConvSS];
                var index = tmpEntiConvSS.findIndex((ente) => {
                  return (
                    ente.codEnteConv === enteConvenzionatoSS.codEnteConv &&
                    ente.codDitta === enteConvenzionatoSS.codDitta &&
                    ente.codSedeOp === enteConvenzionatoSS.codSedeOp &&
                    ente.codRete === enteConvenzionatoSS.codRete
                  );
                });
                if (index !== -1) {
                  tmpEntiConvSS.splice(index, 1);
                  setEntiConvSS(tmpEntiConvSS);
                }
              }
            },
            customContent: checkboxEmail,
          });
        };

        return (
          <IconButton
            onClick={onClick}
            style={{
              border: "none",
            }}
            edge="start"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    });
  }

  return (
    <div>
      <Paper
        style={{ padding: 0, paddingBottom: 20 }}
        className={`{ ${classes.paper} p-4 }`}
      >
        {Auth.hasAccess("save-anagrafiche") ? (
          <>
            <Box className="flex flex-wrap flex-col lg:flex-row">
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-12/12">
                <SelectEntiConvenzionati
                  id="enteConv"
                  multiple
                  values={entiConv}
                  onChange={(enti: number[]) => {
                    handlerResetError();
                    setEntiConv(enti);
                  }}
                  error={errors["ragioneSociale"] ? true : false}
                  helperText={errors["ragioneSociale"]}
                />
              </Box>
              <Box className="xl:mx-3 md:mx-0 w-full 2xl:w-3/12 xl:w-3/12 md:w-12/12">
                <CustomSelect
                  id="sedeOp"
                  label={t("common:sedeOperativa")}
                  onChange={(sedeOp: any, id: string) => {
                    handlerResetError();
                    setSedeOp(
                      sedeOp
                        ? sedeOp
                        : ({ codSedeOperativa: 0 } as SedeOperativa)
                    );
                  }}
                  style={{ textAlign: "left" }}
                  value={sedeOp.codSedeOperativa}
                  error={errors["indirizzo"] ? true : false}
                  helperText={errors["indirizzo"]}
                  customDescription={(row: any) =>
                    row.codSedeOperativa +
                    " - " +
                    row.indirizzo +
                    " - " +
                    row.provincia
                  }
                  descriptionKey="indirizzo"
                  valueKey="codSedeOperativa"
                  data={sediOperative.data || []}
                  fullData
                />
              </Box>
                <Button
                  type="submit"
                  className="self-center rounded-full h-10 w-10 min-w-0"
                  onClick={handleSubmitEnteConvSS}
                >
                  <AddIcon />
                </Button>
            </Box>
            <Divider className="my-4 mx-2" />
          </>
        ) : null}
        <FiltersAccordion
          multiSearchFilter={
            <TextField
              select
              fullWidth
              size="small"
              className="text-left w-full 2xl:w-3/12 xl:w-3/12 md:w-12/12"
              id={FILTER_ID.FILTER_OR}
              label={filters.filterFields.multi.label}
              InputProps={{ id: initialFilter.multi.id }}
              onChange={(event) =>
                handleChangeValue(event, filters.filterFields.multi.id)
              }
              value={filters.filterFields.multi.value}
            >
              <MenuItem key={"filter-codSedeOperativa-0"} value="">
                <em>None</em>
              </MenuItem>
              {sediOperative.data.map((value, index) => {
                return (
                  <MenuItem
                    key={`filter-codSedeOperativa-${index + 1}`}
                    value={value.codSedeOperativa}
                  >
                    {value.codSedeOperativa +
                      " - " +
                      value.indirizzo +
                      " - " +
                      value.provincia}
                  </MenuItem>
                );
              })}
            </TextField>
          }
          filters={filters}
          setFilters={setFilters}
          setPage={() => {}}
        />
        <Box flexGrow="1" height="20rem">
          <DataGrid
            rows={entiConvSS || []}
            columns={columns}
            getRowId={(row) => {
              return `${row.codEnteConv}/${row.codDitta}/${row.codSedeOp}/${row.codRete}`;
            }}
            sortModel={[
              {
                field: "codSedeOp",
                sort: "asc",
              },
              {
                field: "codEnteConv",
                sort: "asc",
              },
            ]}
            disableColumnFilter
            hideFooter
          />
        </Box>
        <ConfirmAction
          data={confirmAction}
          setConfirmAction={setConfirmAction}
        />
      </Paper>
    </div>
  );
}
