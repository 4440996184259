import { Box, Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DecurtaConvenzioniDialogProps {
    open: boolean
    setOpen: (value: React.SetStateAction<boolean>) => void,
    handleDecurtaConvenzioni: (value: number) => void
}

export function DecurtaConvenzioniDialog(props: DecurtaConvenzioniDialogProps) {
    const { open, setOpen, handleDecurtaConvenzioni } = props;
    const { t } = useTranslation(['common'])


    const [value, setValue] = useState<number | string>('')

    return (
        <Dialog open={open} onClose={() => {setOpen(false)}}>
            <DialogTitle>{t('Decurta convenzioni')}</DialogTitle>
            <Box className="w-full" minWidth="380px">
                <Box className='px-2 w-full'>
                    <TextField
                        key='value'
                        label={t('Importo')}
                        type='number'
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={value}
                        onChange={(e) => {
                            if (e.target.value) {
                              setValue(Number(e.target.value))
                            } else {
                              setValue(Number(''))
                            }
                        }}
                    />
                </Box>
                <Box className='w-full flex justify-end px-2'>
                    <Button className='my-4 mr-0' onClick={() => {
                      handleDecurtaConvenzioni(typeof value === 'number' ? Number(value) : 0);
                    }}>{t('Decurta convenzioni')}</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
