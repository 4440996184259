import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getTipoContantiShow,
  postTipoContanti,
  putTipoContanti,
} from "@store/tipo-contanti/tipoContanti.effects";
import {
  TipoContanti,
  NewTipoContanti,
} from "@store/tipo-contanti/tipoContanti.type";
import { tipoContantiShow } from "@store/tipo-contanti/tipoContanti.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetTipoContantiShow } from "@store/tipo-contanti/tipoContanti.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";

interface TipoContantiDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function TipoContantiDetail({
  match,
  creationMode,
}: TipoContantiDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "tipoContantiDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingTipoContanti = useAppSelector(tipoContantiShow);
  const [tipoContanti, setTipoContanti] = useState<
    TipoContanti | NewTipoContanti
  >({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getTipoContantiShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingTipoContanti) {
      setNewElement(false);
      setTipoContanti(pollingTipoContanti);
    }
  }, [pollingTipoContanti]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetTipoContantiShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setTipoContanti((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", tipoContanti?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !tipoContanti) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && tipoContanti) {
      const newTipoContanti: NewTipoContanti = {
        ...tipoContanti,
        codTipoContanti: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(postTipoContanti(newTipoContanti));
      if (postTipoContanti.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.tipoContanti.show.replace(
              ":id",
              result.codTipoContanti.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putTipoContanti(tipoContanti as TipoContanti));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getTipoContantiShow(id));
    } else {
      setTipoContanti({});
    }
  };

  return (
    <div>
      {tipoContanti?.codTipoContanti || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("tipoContantiDetailPage:newElementTitle")}`
                : `${t("tipoContantiDetailPage:title")} - ${
                    tipoContanti.descrizione
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!tipoContanti?.codTipoContanti ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codTipoContanti")}
                    onChange={(e) => handleChangeValue(e, "codTipoContanti")}
                    value={tipoContanti?.codTipoContanti || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={tipoContanti?.descrizione || ""}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-tipicontanti") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
