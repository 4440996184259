import React from 'react';
import { useTranslation } from 'react-i18next';
import { Copyright } from '@components/index'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme) => ({

    footer: {
        borderTop: "solid 1px #97979752",
        height: '6.25rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0 1.531rem',
        flexWrap: 'wrap'
    },
    copyright: {
        flexGrow: 1,
    },
    assistance: {
    }
}));
export function Footer(props: any) {

    const classes = useStyles();
    const { t } = useTranslation(['common'])

    return (
        <Box component="footer" className={classes.footer}>
            <Box className={classes.copyright}>
                <Copyright color={props.color} />
            </Box>
            <Box flexWrap="wrap" className={classes.assistance}>
                <Typography variant="body2" color='textPrimary' align="center">
                    <Link href="mailto:goodyear.hd@softecspa.it">
                        {t('common:support')}
                    </Link>
                </Typography>
            </Box>
        </Box>
    )
}
