import { createReducer } from '@reduxjs/toolkit'
import {
    setNazioniIndex,
    setNazioniShow,
    resetNazioniShow
} from './nazioni.actions'
import { NazioniIndex, NazioniShow } from './nazioni.type'

const initialState: {
    index: NazioniIndex,
    show: NazioniShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const nazioniReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setNazioniIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setNazioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetNazioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

