import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '200px',
        marginTop: '100px'
    }
}));

export function NotFound() {
    
    const classes = useStyles();
    const { t } = useTranslation(['notFoundPage'])

    return (
        <div className="text-center">
            <Typography variant="h1">404 - {t('notFoundPage:title')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );
}

