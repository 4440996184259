import { AppThunk } from '../store'
import i18n from "i18next";

import { setCustomAlert } from '@store/common/common.actions'

export const setError = ({ title = 'error', message = '' }): AppThunk => async dispatch => {

    title = i18n.t(`customAlert:${title}`)

    dispatch(setCustomAlert({
        severity: 'error',
        title,
        message
    }))
}

export const setWarning = ({ title = 'warning', message = '' }): AppThunk => async dispatch => {

    title = i18n.t(`customAlert:${title}`)

    dispatch(setCustomAlert({
        severity: 'warning',
        title,
        message
    }))
}

export const setInfo = ({ title = 'info', message = '' }): AppThunk => async dispatch => {

    title = i18n.t(`customAlert:${title}`)

    dispatch(setCustomAlert({
        severity: 'info',
        title,
        message
    }))
}

export const setSuccess = ({ title = 'success', message = '' }): AppThunk => async dispatch => {

    title = i18n.t(`customAlert:${title}`)

    dispatch(setCustomAlert({
        severity: 'success',
        title,
        message
    }))
}

