import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@store/hooks";

import { provinceIndex } from "@store/province/province.selector";
import { getProvinceIndex } from "@store/province/province.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Box, Button, IconButton, TextField } from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { globalRegioni } from "@store/global/global.selector";
import { SelectRegioni } from "@components/selects/regioni/SelectRegioni";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { Province } from "@store/province/province.types";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_REGIONE = "filter-codRegione",
}

export function ProvinceIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["provinceIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`provinceIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "codNazione,descrizione",
      "",
      ""
    ),
    codRegione: new FieldFilter(
      FILTER_ID.COD_REGIONE,
      t(`provinceIndexPage:filter:filtercodRegione`),
      FilterTypes.AND,
      "codRegione",
      FilterMethods.EQUAL,
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<Province>(
    initialFilter,
    [{ field: "siglaProvincia", sort: "asc" }],
    getProvinceIndex,
    provinceIndex
  );

  const regioni = useAppSelector(globalRegioni);

  const columns: GridColDef[] = [
    {
      field: "siglaProvincia",
      headerName: t("common:siglaProvincia"),
      // sortable: false,
      flex: 1,
      /* width: 135, */
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.siglaProvincia,
    },
    {
      field: "descrizione",
      headerName: t("common:provincia"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "codRegione",
      headerName: t("common:regione"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;

        const codRegione = row.codRegione;
        const regione = regioni?.find((regione) => {
          return codRegione === regione.codRegione;
        });

        return regione?.descrizione;
      },
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(
              routes.province.show.replace(
                ":siglaProvincia",
                params.row.siglaProvincia
              )
            )
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`provinceIndexPage:title`)} disabledGoBack>
            {Auth.hasAccess("save-province") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.province.new))}
              >
                <AddIcon color="primary" />
                {t("provinceIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectRegioni
                    id={FILTER_ID.COD_REGIONE}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.codRegione.value}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return `${row.codNazione}${row.siglaProvincia}`;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
