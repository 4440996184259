import React, { useEffect } from "react";
import environment from "@environment/environment";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@store/hooks";
//import { makeStyles } from '@material-ui/core/styles';
import { push } from "connected-react-router";

import { getAnagraficaIndex } from "@store/anagrafica/anagrafica.effects";
import { anagraficaIndex } from "@store/anagrafica/anagrafica.selector";

import {
  CommonSpeedDial,
  CustomPaginatedDataGridRender,
} from "@components/index";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";
import { GridColDef } from "@material-ui/data-grid";
import {
  Box,
  Button,
  IconButton,
  TextField /* , Typography */,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { FiltersAccordion, CustomHeaderPage } from "@components/index";
import { SelectProvince } from "@components/selects/province/SelectProvince";
import { globalReti } from "@store/global/global.selector";
import { SelectReti } from "@components/selects";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { AnagraficaGoodyear } from "@store/anagrafica/anagrafica.types";
import { resetAnagraficaIndex } from "@store/anagrafica/anagrafica.actions";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_RETE = "filter-codRete",
  PROV_LEGALE = "filter-provSedeLegale",
  COD_PAYER = "filter-codPayer",
  DATA_ATTIVAZIONE = "filter-dataAttivazione",
}

export function AnagraficaIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["anagraficaIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`anagraficaIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "partitaIVA,codDitta,ragioneSociale",
      "",
      ""
    ),
    codRete: new FieldFilter(
      FILTER_ID.COD_RETE,
      t(`anagraficaIndexPage:filter:filterCodRete`),
      FilterTypes.AND,
      "codRete",
      FilterMethods.EQUAL,
      ""
    ),
    codReteNonAttivi: new FieldFilter(
      "cod-rete-non-attivi",
      "",
      FilterTypes.AND,
      "codRete",
      FilterMethods.NOTEQUAL,
      "99",
      true
    ),
    provLegale: new FieldFilter(
      FILTER_ID.PROV_LEGALE,
      t(`anagraficaIndexPage:filter:filterProvSedeLegale`),
      FilterTypes.AND,
      "provSedeLegale",
      FilterMethods.CONTAINS,
      ""
    ),
    codPayer: new FieldFilter(
      FILTER_ID.COD_PAYER,
      t(`anagraficaIndexPage:filter:filterCodPayer`),
      FilterTypes.AND,
      "codPayer",
      FilterMethods.EQUAL,
      ""
    ),
    dataAttivazione: new FieldFilter(
      FILTER_ID.DATA_ATTIVAZIONE,
      t(`anagraficaIndexPage:filter:filterDataAttivazione`),
      FilterTypes.AND,
      "dataAttivazioneNegozio",
      FilterMethods.GREATERTHANOREQUALTO,
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<AnagraficaGoodyear>(
    initialFilter,
    [{ field: "codDitta", sort: "asc" }],
    getAnagraficaIndex,
    anagraficaIndex,
    environment.anagrafica.pagination.defaultPageSize
  );
  const reti = useAppSelector(globalReti);

  useEffect(() => {
    return () => {
      dispatch(resetAnagraficaIndex());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: GridColDef[] = [
    {
      field: "codDitta",
      headerName: t("common:codDitta"),
      flex: 1,
      valueGetter: (params) => params.row.codDitta,
    },
    {
      field: "ragioneSociale",
      headerName: t("common:ragioneSociale"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.ragioneSociale,
    },
    {
      field: "codRete",
      headerName: t("common:rete"),
      sortable: false,
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;

        const codRete = row.codRete;
        const rete = reti?.find((rete) => {
          return codRete === rete.codRete;
        });

        return rete?.descrizione;
      },
    },
    {
      field: "indSedeLegale",
      headerName: t("common:indirizzo"),
      sortable: false,
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.indSedeLegale,
    },
    {
      field: "locSedeLegale",
      headerName: t("common:localita"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.locSedeLegale,
    },
    {
      field: "provSedeLegale",
      headerName: t("common:provincia"),
      width: 150,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.provSedeLegale,
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(push(`/anagrafica/${params.row.codDitta}`));
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`anagraficaIndexPage:title`)}
            disabledGoBack
          >
            {Auth.hasAccess("save-anagrafiche") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(`${routes.anagrafica.new}`))}
              >
                <AddIcon color="primary" />
                {t("anagraficaIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectReti
                    id={FILTER_ID.COD_RETE}
                    label={filters.filterFields.codRete.label}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.codRete.value}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectProvince
                    id={FILTER_ID.PROV_LEGALE}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.provLegale.value}
                    label={filters.filterFields.provLegale.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={FILTER_ID.COD_PAYER}
                    value={filters.filterFields.codPayer.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.codPayer.id
                      )
                    }
                    label={filters.filterFields.codPayer.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    type="Date"
                    fullWidth
                    size="small"
                    id={FILTER_ID.DATA_ATTIVAZIONE}
                    InputLabelProps={{ shrink: true }}
                    value={filters.filterFields.dataAttivazione.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.dataAttivazione.id
                      )
                    }
                    label={filters.filterFields.dataAttivazione.label}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGridRender
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.codDitta;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName:
              environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.centralDatabaseUpdates.entities
                .anagrafiche,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.areaRiservataUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .anagraficaGoodyear,
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .sediOperativeGoodyear,
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .sediOpGoodyearReti,
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .sediOpGoodyearAbilitazioni,
            ],
          },
        ]}
      />
    </div>
  );
}
