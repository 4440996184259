import { AxiosAPI } from "@store/axios.config";
import { AppThunk, AppDispatch, RootState } from "../store";

import { setError, setSuccess } from "../common/common.effects";
import {
  setRequestLoader,
  removeRequestLoader,
} from "@store/common/common.actions";
import { setDisegniIndex, setDisegniShow } from "./disegni.actions";
import environment from "@environment/environment";
import { createFilters, createSortColumns } from "@utilities/apiUtilities";
import { Filters, SortModel } from "@store/common/common.types";
import { Disegni, NewDisegni } from "./disegni.types";
import i18n from "@assets/i18n";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGlobalDisegni } from "@store/global/global.effects";

export const getDisegniIndex =
  (
    page: number = 1,
    sizePage = environment.pagination.defaultPageSize,
    filters?: Filters,
    sortModel?: SortModel[],
    avoidLoader: boolean = false
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (!avoidLoader) {
        dispatch(setRequestLoader("getDisegniIndex"));
      }

      const filterSearch = createFilters(filters);
      const sortSearch = createSortColumns(sortModel);

      const response = await AxiosAPI.get(
        `/Disegni?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`
      );
      dispatch(setDisegniIndex(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      if (!avoidLoader) {
        dispatch(removeRequestLoader("getDisegniIndex"));
      }
    }
  };

export const getDisegniShow =
  (codProduttore: string, codDisegno: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("getDisegniShow"));

    try {
      const response = await AxiosAPI.get(
        `/Disegni/${codProduttore}/${codDisegno}`
      );
      dispatch(setDisegniShow(response.data));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("getDisegniShow"));
    }
  };

export const postDisegni = createAsyncThunk<
  // Return type of the payload creator
  Disegni,
  // First argument to the payload creator
  NewDisegni,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "postDisegni",
  async (disegno: NewDisegni, { rejectWithValue, dispatch, getState }) => {
    dispatch(setRequestLoader("postDisegni"));

    try {
      const response = await AxiosAPI.post(`/Disegni`, disegno);
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      dispatch(getGlobalDisegni(true));
      return response.data as Disegni;
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("postDisegni"));
    }
  }
);

export const putDisegni =
  (disegno: Disegni): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("putDisegni"));

    try {
      const { codProduttore, codDisegno } = disegno;

      await AxiosAPI.put(`/Disegni/${codProduttore}/${codDisegno}`, disegno);
      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      dispatch(getGlobalDisegni(true));
      dispatch(getDisegniShow(codProduttore.toString(), codDisegno.toString()));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } finally {
      dispatch(removeRequestLoader("putDisegni"));
    }
  };

export const deleteDisegni = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Disegni,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "deleteDisegni",
  async (disegno: Disegni, { rejectWithValue, dispatch, getState }) => {
    dispatch(setRequestLoader("deleteDisegni"));

    try {
      const { codProduttore, codDisegno } = disegno;

      await AxiosAPI.delete(`/Disegni/${codProduttore}/${codDisegno}`);

      const currentState = getState().disegni.index;
      const data = currentState.data.filter(
        (item) => item.codProduttore !== codProduttore
      );
      dispatch(
        setDisegniIndex({
          ...currentState,
          data: data,
        })
      );

      dispatch(setSuccess({ message: i18n.t("common:message:saveSuccess") }));
      dispatch(getGlobalDisegni(true));
    } catch (error: any) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
      throw new Error(error);
    } finally {
      dispatch(removeRequestLoader("deleteDisegni"));
    }
  }
);
