import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalReti } from "@store/global/global.selector";
import { getGlobalReti } from "@store/global/global.effects";

export function SelectReti(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const reti = useAppSelector(globalReti);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalReti())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:rete`)}
            data={reti}
            descriptionKey={'descrizione'}
            valueKey={'codRete'}
            {...props}
        />
    );
}
