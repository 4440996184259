import { RootState } from '../store'

export const stateSidebar = (state: RootState) => state.common.sidebar;

export const customAlert = (state: RootState) => state.common.customAlert;

export const loader = (state: RootState) => state.common.loader;

export const router = (state: RootState) => state.router;

