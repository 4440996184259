import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalDistributori } from "@store/global/global.selector";
import { getGlobalDistributori } from "@store/global/global.effects";

export function SelectDistributori(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const distributori = useAppSelector(globalDistributori);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalDistributori())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:distributore`)}
            data={distributori}
            descriptionKey={'ragioneSociale'}
            valueKey={'codClienteGY'}
            {...props}
        />
    );
}
