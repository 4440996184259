import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setStruttureIndex, setStruttureShow } from './strutture.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { Strutture, NewStrutture } from './strutture.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalStrutture } from '@store/global/global.effects';


export const getStruttureIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getStruttureIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Strutture?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setStruttureIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getStruttureIndex'));
    }
}


export const getStruttureShow = (codStruttura: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getStruttureShow'));

    try {
        const response = await AxiosAPI.get(`/Strutture/${codStruttura}`)
        dispatch(setStruttureShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getStruttureShow'));
    }
}


export const postStrutture = createAsyncThunk<
    // Return type of the payload creator
    Strutture,
    // First argument to the payload creator
    NewStrutture,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postStrutture', async (struttura: NewStrutture, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postStrutture'));

        try {
            const response = await AxiosAPI.post(`/Strutture`, struttura)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalStrutture(true))
            return response.data as Strutture
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postStrutture'));
        }
    })


export const putStrutture = (struttura: Strutture): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putStrutture'));

    try {
        const { codStruttura } = struttura

        await AxiosAPI.put(`/Strutture/${codStruttura}`, struttura)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalStrutture(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putStrutture'));
    }
}


export const deleteStrutture = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    Strutture,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteStrutture', async (struttura: Strutture, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteStrutture'));

        try {
            const { codStruttura } = struttura

            await AxiosAPI.delete(`/Strutture/${codStruttura}`)

            const currentState = getState().strutture.index;
            const data = currentState.data.filter((item) => item.codStruttura !== codStruttura)
            dispatch(setStruttureIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalStrutture(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteStrutture'));
        }
    })
