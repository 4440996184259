import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import { AnagraficaGoodyear } from '@store/anagrafica/anagrafica.types';


interface TypeNameInputComponent {
    data: AnagraficaGoodyear,
    handlerChangeData: (e: React.SyntheticEvent) => void,
    errors: { [key: string]: string },
    helpDesk: boolean
}
export function DataDealerComponent(props: TypeNameInputComponent) {
    const { t } = useTranslation(['common'])

    const { data, errors, handlerChangeData,helpDesk } = props
    return (
        <div>
            <Box className='p-2 pb-8'>
                <Box className='flex flex-wrap'>
                    <Box className='w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:codDitta')}
                            size='small'
                            id='codDitta'
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled={true}
                            value={data?.codDitta || ''}
                        />
                    </Box>
                    <Box className='w-full  2xl:w-3/12 xl:w-4/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:ragioneSociale')}
                            size='small'
                            required={true}
                            error={errors['ragioneSociale'] ? true : false}
                            helperText={errors['ragioneSociale']}
                            id='ragioneSociale'
                            value={data?.ragioneSociale || ''}
                            onChange={handlerChangeData}
                            disabled={helpDesk}
                        />
                    </Box>
                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:responsabile')}
                            size='small'
                            id='responsabile'
                            value={data?.responsabile || ''}
                            onChange={handlerChangeData}
                            disabled={helpDesk}

                        />
                    </Box>
                    <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:codFiscale')}
                            size='small'
                            id='codFiscale'
                            value={data?.codFiscale || ''}
                            onChange={handlerChangeData}
                            disabled={helpDesk}

                        />
                    </Box>
                    <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                        <TextField
                            fullWidth
                            label={t('common:partitaIVA')}
                            size='small'
                            id='partitaIVA'
                            value={data?.partitaIVA || ''}
                            onChange={handlerChangeData}
                            disabled={helpDesk}

                        />
                    </Box>
                </Box>
            </Box>
        </div >
    )
}