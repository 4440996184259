import { createReducer } from '@reduxjs/toolkit'
import { resetListiniIndex, setDateListini, setListiniIndex } from './listini.actions'
import { ListiniIndex } from './listini.types'

const initialState: {
    index: ListiniIndex,
    dateListini: string[]
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    dateListini: []
}

export const listiniReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setListiniIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(resetListiniIndex, (state, action) => {
            return {
                ...state,
                index: {
                    data: [],
                    meta: {
                        pagination: undefined
                    }
                }
            }
        })
        .addCase(setDateListini, (state, action) => {
            return {
                ...state,
                dateListini: action.payload
            }
        })
})

