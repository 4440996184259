import { createReducer } from '@reduxjs/toolkit'
import {
    setCodiciVelocitaIndex,
    setCodiciVelocitaShow,
    resetCodiciVelocitaShow
} from '@store/codici-velocita/codiciVelocita.actions'
import { CodiciVelocitaIndex, CodiciVelocitaShow } from './codiciVelocita.types'

const initialState: {
    index: CodiciVelocitaIndex
    show: CodiciVelocitaShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const codiciVelocitaReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCodiciVelocitaIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setCodiciVelocitaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetCodiciVelocitaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

