import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getSerieGommeShow,
  postSerieGomme,
  putSerieGomme,
} from "@store/serie-gomme/serieGomme.effects";
import { NewSerieGomme, SerieGomme } from "@store/serie-gomme/serieGomme.types";
import { serieGommeShow } from "@store/serie-gomme/serieGomme.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { AssociazioneTipoArticolo } from "../components/AssociazioneTipoArticolo";
import { resetSerieGommeShow } from "@store/serie-gomme/serieGomme.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";

interface SerieGommeDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function SerieGommeDetail({
  match,
  creationMode,
}: SerieGommeDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "serieGommeDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingSerieGomme = useAppSelector(serieGommeShow);
  const [serieGomma, setSerieGomma] = useState<SerieGomme | NewSerieGomme>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getSerieGommeShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingSerieGomme) {
      setNewElement(false);
      setSerieGomma(pollingSerieGomme);
    }
  }, [pollingSerieGomme]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetSerieGommeShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setSerieGomma((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", serieGomma?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !serieGomma) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement) {
      const newSerieGomme: NewSerieGomme = {
        ...serieGomma,
        codSerie: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(postSerieGomme(newSerieGomme));
      if (postSerieGomme.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.serieGomme.show.replace(":id", result.codSerie.toString())
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putSerieGomme(serieGomma as SerieGomme));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getSerieGommeShow(id));
    } else {
      setSerieGomma({});
    }
  };

  return (
    <div>
      {serieGomma?.codSerie || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("serieGommeDetailPage:newElementTitle")}`
                : `${t("serieGommeDetailPage:title")} - ${
                    serieGomma.descrizione
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!serieGomma?.codSerie ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codSerieGomme")}
                    onChange={(e) => handleChangeValue(e, "codSerie")}
                    value={serieGomma?.codSerie || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizioneSerieGomme")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  type="number"
                  value={serieGomma?.descrizione || ""}
                />
              </Box>
            </Paper>
          </Box>
          {!newElement ? <AssociazioneTipoArticolo /> : null}
          {Auth.hasAccess("save-seriegomme") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
