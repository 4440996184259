import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalNazioni } from "@store/global/global.effects";
import { globalNazioni } from "@store/global/global.selector";

export function SelectNazioni(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const nazioni = useAppSelector(globalNazioni);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalNazioni())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:Nazione`)}
            data={nazioni}
            descriptionKey={'descrizione'}
            valueKey={'codNazione'}
            {...props}
        />
    );
}
