import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalSemaphoresTasks } from "@store/global/global.effects";
import { globalSemaphoresTasks } from "@store/global/global.selector";

export function SelectSemaphoresTasks(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const semaphoresTasks = useAppSelector(globalSemaphoresTasks);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalSemaphoresTasks())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:task`)}
            data={semaphoresTasks}
            descriptionKey={'id'}
            valueKey={'description'}
            {...props}
        />
    );
}
