
import { FiltersAccordion } from "@components/filters-accordion/FiltersAccordion";
import { Box, Button, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from '@store/hooks';
import environment from "@environment/environment";
import { CustomPaginatedDataGrid } from "@components/custom-paginated-datagrid/CustomPaginatedDataGrid";
import { GridColDef, GridSelectionModelChangeParams } from "@material-ui/data-grid";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { Articoli } from "@store/articoli/articoli.types";
import { ArticlesFilters } from "@components/articles-filters/ArticlesFilters";
import { globalLarghezzeCerchi, globalLarghezzeNominali, globalSerieGomme, globalTipiArticolo } from "@store/global/global.selector";
import { getDefaultArticlesFilter } from "@components/articles-filters/defaultArticleFilters";
import { ricercaArticoli } from "@store/articoli/articoli.selector";
import { getRicercaArticoli } from "@store/articoli/articoli.effects";
import { FieldFilter } from "@store/common/common.types";
import { Listino } from "@store/listini/listini.types";
import moment from "moment";

export interface AdvancedArticleSearchProps {
    selectable?: boolean // selectable rows
    onSelectArticles?: (articles: Articoli[]) => void // selected articles,
    selectButtonLabel?: string
    defaultCodDivisione?: string
    defaultFuoriProd?: string
    onActiveFiltersChange?: (activeFilters: { [key: string]: FieldFilter }) => void
    showDataUltimoListino?: boolean
}

export function AdvancedArticleSearch(props: AdvancedArticleSearchProps) {

    let { selectable, onSelectArticles, selectButtonLabel, defaultCodDivisione = '1', defaultFuoriProd = '', onActiveFiltersChange, showDataUltimoListino = false } = props;

    const initialFilter = getDefaultArticlesFilter(defaultCodDivisione, defaultFuoriProd);

    const { t } = useTranslation(['common'])

    const { data, filters, setFilters, setPage, setPageSize, handleSelectChangeValue, handleTextFieldChangeValue, handleCheckBoxChangeValue } = useIndexPage<Articoli>(initialFilter, [{ field: 'CodArticolo', sort: 'asc' }], getRicercaArticoli, ricercaArticoli, environment.articleSearch.pagination.defaultPageSize);
    const tipiArticolo = useAppSelector(globalTipiArticolo);
    const larghezzeNominali = useAppSelector(globalLarghezzeNominali);
    const larghezzeCerchi = useAppSelector(globalLarghezzeCerchi);
    const serieGomme = useAppSelector(globalSerieGomme);
    const [selectedArticles, setSelectedArticles] = useState<string[]>([])
    useEffect(() => {
        setSelectedArticles([]);
    }, [data]);

    useEffect(() => {
        if (onActiveFiltersChange) {
            onActiveFiltersChange(filters.activeFilters);
        }
    }, [filters.activeFilters]);  // eslint-disable-line react-hooks/exhaustive-deps

    // handle row selection
    const handleRowSelection = (model: GridSelectionModelChangeParams) => {
        setSelectedArticles((prevState) => { return model.selectionModel as string[] })
    };

    // on select rows click
    const handleSelect = () => {
        if (onSelectArticles) {
            onSelectArticles(data?.data?.filter((articolo) => selectedArticles.includes(articolo.codArticolo)));
            setSelectedArticles([]);
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'codArticolo',
            headerName: t('common:codArticolo'),
            //flex: 1,
            width: 130,
            disableClickEventBubbling: true,
            valueGetter: (params) => params.row.codArticolo,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'descrizione',
            headerName: t('common:descrizione'),
            flex: 1,
            disableClickEventBubbling: true,
            valueGetter: (params) => params.row.descrizione,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'tipoArticolo',
            headerName: t('common:tipoArticolo'),
            flex: 1,
            disableClickEventBubbling: true,
            valueGetter: (params) => tipiArticolo.find(tipoArticolo => params.row.codTipoArticolo === tipoArticolo.codTipoArticolo)?.descrizione,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'tire_sizes',
            headerName: t('common:tireSizes'),
            flex: 1,
            disableClickEventBubbling: true,
            valueGetter: (params) => {
                const descLarghezza = larghezzeNominali.find(larghezzaNominale => params.row.codLarghezzaNominale === larghezzaNominale.codLarghezzaNominale)?.larghezzaNominale
                const descSerie = serieGomme.find(serieGomma => params.row.codSerie === serieGomma.codSerie)?.descrizione
                const descCerchio = larghezzeCerchi.find(larghezzaCerchio => params.row.codLarghezzaCerchio === larghezzaCerchio.codLarghezzaCerchio)?.larghezza
                return (descLarghezza && descSerie && descCerchio) ? `${descLarghezza}/${descSerie}R${descCerchio}` : 'N/D';
            },
            sortable: false,
            disableColumnMenu: true,
            hide: filters.activeFilters?.divisione?.value !== '1'
        },
        {
            field: 'dataListini',
            headerName: t('common:ultimaDataListino'),
            flex: 1,
            disableClickEventBubbling: true,
            valueGetter: (params) => {
                let livelloListino = filters.activeFilters?.livelloListino?.value ? Number(filters.activeFilters?.livelloListino.value) : 0
                let dateListino  = params.row.listiniClienti?.find((listino : Listino) => listino.livelloListino === livelloListino)?.dataInizioValidita
                return dateListino ? moment(dateListino).format('DD/MM/yyyy') : 'N/D'
            },
            sortable: false,
            disableColumnMenu: true,
            hide: !showDataUltimoListino
        },
    ];

    return (
        <div className="p-4">
            <Grid item xs={12}>
                <FiltersAccordion
                    searchFilters={
                        <ArticlesFilters
                            filters={filters}
                            onSelectChangeValue={handleSelectChangeValue}
                            onTextFieldChangeValue={handleTextFieldChangeValue}
                            onCheckBoxChangeValue={handleCheckBoxChangeValue}
                            showDataUltimoListino={showDataUltimoListino}
                        />
                    }
                    forceFirstUpdate={false}
                    filters={filters}
                    setFilters={setFilters}
                    setPage={setPage}
                />
                <Paper className="p-4">
                    <CustomPaginatedDataGrid
                        data={data}
                        columns={columns}
                        getRowId={(row) => { return row.codArticolo }}
                        onChangePageSize={setPageSize}
                        onChangePage={setPage}
                        checkboxSelection={selectable || false}
                        onSelectionModelChange={handleRowSelection}
                        selectionModel={selectedArticles}

                    />
                </Paper>
                {selectable ?
                    <Box className='flex justify-end m-4'>
                        <Button onClick={handleSelect}>{selectButtonLabel ? selectButtonLabel : t('common:select')}</Button>
                    </Box>
                    :
                    null
                }
            </Grid>
        </div>
    );
}
