import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setCapiZonaIndex, setCapiZonaShow } from './capiZona.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { CapiZona, NewCapiZona } from './capiZona.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalCapiZona } from '@store/global/global.effects';

export const getCapiZonaIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getCapiZonaIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/CapiZona?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setCapiZonaIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCapiZonaIndex'));
    }
}

export const getCapiZonaShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getCapiZonaShow'));

    try {
        const response = await AxiosAPI.get(`/CapiZona/${id}`)
        dispatch(setCapiZonaShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCapiZonaShow'));
    }
}

export const postCapiZona = createAsyncThunk<
    // Return type of the payload creator
    CapiZona,
    // First argument to the payload creator
    NewCapiZona,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postCapiZona', async (capiZona: NewCapiZona, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postCapiZona'));

        try {
            const response = await AxiosAPI.post(`/CapiZona`, capiZona)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalCapiZona(true))
            return response.data as CapiZona
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postCapiZona'));
        }
    })

export const putCapiZona = (capiZona: CapiZona): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putCapiZona'));

    try {
        const { codCapoZona } = capiZona

        await AxiosAPI.put(`/CapiZona/${codCapoZona}`, capiZona)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalCapiZona(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putCapiZona'));
    }
}

export const deleteCapiZona = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    CapiZona,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteCapiZona', async (capiZona: CapiZona, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteCapiZona'));

        try {
            const { codCapoZona } = capiZona

            await AxiosAPI.delete(`/CapiZona/${codCapoZona}`)

            const currentState = getState().capiZona.index;
            const data = currentState.data.filter((item) => item.codCapoZona !== codCapoZona)
            dispatch(setCapiZonaIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalCapiZona(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteCapiZona'));
        }
    })
