import { FunctionComponent } from "react";
import { Box, MenuItem, TextField } from "@material-ui/core";
import environment from '@environment/environment'
import { Pagination as PaginationComponent } from "@material-ui/lab";
import { Pagination } from "@store/common/common.types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    button: {
        '& ul.MuiPagination-ul button': {
            outline: 'none',
        },
        '& ul.MuiPagination-ul button:hover': {
            color: '#fff',
            background: '#1D7EB5'
        },
    },
  }),
); 

interface CustomDataGridPaginationProps {
    pagination?: Pagination,
    onChangePage: (page: number) => void
    onChangePageSize: (newPageSize: number) => void
}

export const CustomDataGridPagination: FunctionComponent<CustomDataGridPaginationProps> = (props) => {

    const classes = useStyles();

    const { pagination, onChangePage, onChangePageSize } = props

    const { t } = useTranslation(['common'])

    const { pageSizeOptions, defaultPageSize } = environment.pagination

    const handleOnChangeSizePage = (event: React.SyntheticEvent): void => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            value: number
        };

        onChangePageSize(target.value);
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, page: number): void => {
        event.preventDefault();
        onChangePage(page)
    }

    return (
        <Box display='flex' flexDirection='row' height="64px" >
            <Box display='flex' position="absolute" alignSelf='center' minWidth='10rem' marginLeft="15px">
                <TextField
                    select
                    fullWidth
                    size='small'
                    label={t('common:pageSize')}
                    InputProps={{ id: 'pagination-pageSize' }}
                    onChange={handleOnChangeSizePage}
                    // undefined is not allowed
                    value={pagination?.pageSize || defaultPageSize}
                >
                    {
                        pageSizeOptions.map((value, index) => {
                            return <MenuItem key={`option-pageSize-${index + 1}`} value={value}>{value}</MenuItem>
                        })
                    }
                </TextField>
            </Box>
            <Box display='flex' alignSelf='center' flexGrow='1' justifyContent='center'>
                <PaginationComponent
                    className={classes.button}
                    count={pagination?.totalPages}
                    page={pagination?.currentPage || 1}
                    onChange={handleOnChangePage}
                    color='primary' />
            </Box>
        </Box>
    );
}