import { createReducer } from '@reduxjs/toolkit'
import {
    setTipoContrattoIndex,
    setTipoContrattoShow,
    resetTipoContrattoShow
} from './tipiContratto.actions'
import { TipoContrattoIndex, TipoContrattoShow } from './tipiContratto.type'

const initialState: {
    index: TipoContrattoIndex,
    show: TipoContrattoShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const tipoContrattoReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setTipoContrattoIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setTipoContrattoShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetTipoContrattoShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

