import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState  } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setTipoContantiIndex, setTipoContantiShow } from './tipoContanti.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { TipoContanti, NewTipoContanti  } from './tipoContanti.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getTipoContantiIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getTipoContantiIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/TipoContanti?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setTipoContantiIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getTipoContantiIndex'));
    }
}

export const getTipoContantiShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getTipoContantiShow'));

    try {
        const response = await AxiosAPI.get(`/TipoContanti/${id}`)
        dispatch(setTipoContantiShow(response.data.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getTipoContantiShow'));
    }
}

export const postTipoContanti = createAsyncThunk<
    // Return type of the payload creator
    TipoContanti,
    // First argument to the payload creator
    NewTipoContanti,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postTipoContanti', async (tipoContanti: NewTipoContanti, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postTipoContanti'));

        try {
            const response = await AxiosAPI.post(`/TipoContanti`, tipoContanti)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as TipoContanti
        } catch (error) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postTipoContanti'));
        }
    })

    export const putTipoContanti = (tipoContanti: TipoContanti): AppThunk => async dispatch => {

        dispatch(setRequestLoader('putTipoContanti'));
    
        try {
            const { codTipoContanti } = tipoContanti
    
            await AxiosAPI.put(`/TipoContanti/${codTipoContanti}`, tipoContanti)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
    
        } catch (error) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
        } finally {
            dispatch(removeRequestLoader('putTipoContanti'));
        }
    }

    export const deleteTipoContanti = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    TipoContanti,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteTipoContanti', async (tipoContanti: TipoContanti, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteTipoContanti'));

        try {
            const { codTipoContanti } = tipoContanti

            await AxiosAPI.delete(`/TipoContanti/${codTipoContanti}`)

            const currentState = getState().tipoContanti.index;
            const data = currentState.data.filter((item) => item.codTipoContanti !== codTipoContanti)
            dispatch(setTipoContantiIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteTipoContanti'));
        }
    })
