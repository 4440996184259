import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";

import { disegniIndex } from "@store/disegni/disegni.selector";
import { getDisegniIndex } from "@store/disegni/disegni.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Box, Button, IconButton, TextField } from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { SelectProduttori } from "@components/selects";
import { Disegni } from "@store/disegni/disegni.types";
import { useIndexPage } from "@pages/common/UseIndexPage";
import environment from "@environment/environment";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_PRODUTTORE = "filter-codProduttore",
}

export function DisegniIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["disegniIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`disegniIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "descrizione",
      "",
      ""
    ),
    codProduttore: new FieldFilter(
      FILTER_ID.COD_PRODUTTORE,
      t(`disegniIndexPage:filter:filtercodProduttore`),
      FilterTypes.AND,
      "codProduttore",
      FilterMethods.EQUAL,
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<Disegni>(
    initialFilter,
    [{ field: "descrizione", sort: "asc" }],
    getDisegniIndex,
    disegniIndex
  );

  const columns: GridColDef[] = [
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 1,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "produttore",
      headerName: t("common:produttore"),
      flex: 1,
      valueGetter: (params) => params.row.produttori.descrizione,
      // TODO mancano API di ordinamento per produttore
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(
              routes.disegni.show
                .replace(":codProduttore", params.row.codProduttore)
                .replace(":codDisegno", params.row.codDisegno)
            )
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`disegniIndexPage:title`)} disabledGoBack>
            {Auth.hasAccess("save-disegni") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.disegni.new))}
              >
                <AddIcon color="primary" />
                {t("disegniIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-4/12">
                  <SelectProduttori
                    id={FILTER_ID.COD_PRODUTTORE}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.codProduttore.value}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return `${row.codProduttore}${row.codDisegno}`;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities.disegni,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .disegniDatiAggiuntivi,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.centralDatabaseUpdates.entities
                .articoli,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.areaRiservataUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .disegni,
            ],
          },
        ]}
      />
    </div>
  );
}
