import { AxiosAPI } from '@store/axios.config';
import { AppThunk } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setCodVelocitaTipoArtIndex, setCodVelocitaTipoArtShow } from '@store/cod-velocita-tipo-art/codVelocitaTipoArt.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import i18n from '@assets/i18n';
import { getCodiciVelocitaShow } from '@store/codici-velocita/codiciVelocita.effects';
import { setCodiciVelocitaShow } from '@store/codici-velocita/codiciVelocita.actions';


export const getCodiciVelocitaTipoArtIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getCodiciVelocitaTipoArtIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/CodVelocitaTipoArt?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setCodVelocitaTipoArtIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCodiciVelocitaTipoArtIndex'));
    }
}


export const getCodiciVelocitaTipoArtShow = (codVelocita: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getCodiciVelocitaTipoArtShow'));

    try {
        const response = await AxiosAPI.get(`/CodVelocitaTipoArt/${codVelocita}/${codTipoArticolo}`)
        dispatch(setCodVelocitaTipoArtShow(response.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCodiciVelocitaTipoArtShow'));
    }
}


export const postCodiciVelocitaTipoArt = (codVelocita: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('postCodiciVelocitaTipoArt'));

    try {

        await AxiosAPI.post(`/CodVelocitaTipoArt`, {
            codVelocita,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        dispatch(getCodiciVelocitaShow(`${codVelocita}`))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('postCodiciVelocitaTipoArt'));
        return true
    }
}


export const putCodiciVelocitaTipoArt = (codVelocita: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putCodiciVelocitaTipoArt'));

    try {
        await AxiosAPI.put(`/CodVelocitaTipoArt/${codVelocita}/${codTipoArticolo}`, {
            codVelocita,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putCodiciVelocitaTipoArt'));
    }
}

export const deleteCodiciVelocitaTipoArt = (codVelocita: number, codTipoArticolo: number): AppThunk => async (dispatch, getState) => {

    dispatch(setRequestLoader('deleteCodiciVelocitaTipoArt'));

    try {

        await AxiosAPI.delete(`/CodVelocitaTipoArt/${codVelocita}/${codTipoArticolo}`)

        const currentState = (getState()).codiciVeloci.show;
        if (currentState.data) {
            const data = currentState.data.codVelocitaTipoArt.filter((item) => item.codTipoArticolo !== codTipoArticolo)
            dispatch(setCodiciVelocitaShow({
                ...currentState,
                data: {
                    ...currentState.data,
                    codVelocitaTipoArt: data
                }
            }))
        }

        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('deleteCodiciVelocitaTipoArt'));
    }
}