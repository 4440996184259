import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getCausaliIvaShow,
  postCausaliIva,
  putCausaliIva,
} from "@store/causali-iva/causaliIva.effects";
import { CausaliIva, NewCausaliIva } from "@store/causali-iva/causaliIva.type";
import { causaliIvaShow } from "@store/causali-iva/causaliIva.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetCausaliIvaShow } from "@store/causali-iva/causaliIva.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import { SelectTipiCausaliIva } from "@components/selects";
import Auth from "@utilities/auth";

interface CausaliIvaDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function CausaliIvaDetail({
  match,
  creationMode,
}: CausaliIvaDetailProps) {
  //const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "causaliIvaDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingCausaliIva = useAppSelector(causaliIvaShow);
  const [causaliIva, setCausaliIva] = useState<CausaliIva | NewCausaliIva>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getCausaliIvaShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingCausaliIva) {
      setNewElement(false);
      setCausaliIva(pollingCausaliIva);
    }
  }, [pollingCausaliIva]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetCausaliIvaShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    const target = e.target as typeof e.target & { value: string };
    updateValue(target.value, param);
  };

  const handleChangeSelectValue = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setCausaliIva((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", causaliIva?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("tipo", causaliIva?.tipo, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "natura",
        causaliIva?.fatturazioneElettronicaNatura,
        [ValidationTypes.REQUIRED]
      ),

      // Validation.buildFieldConfig('aliquota', causaliIva?.aliquota, [ValidationTypes.REQUIRED]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !causaliIva) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && causaliIva) {
      const newCausaliIva: NewCausaliIva = {
        ...causaliIva,
        codIVA: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(postCausaliIva(newCausaliIva));
      if (postCausaliIva.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(routes.causaliIva.show.replace(":id", result.codIVA.toString()))
        );
      } else {
        //error
      }
    } else {
      dispatch(putCausaliIva(causaliIva as CausaliIva));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getCausaliIvaShow(id));
    } else {
      setCausaliIva({});
    }
  };

  return (
    <div>
      {causaliIva?.codIVA || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("causaliIvaDetailPage:newElementTitle")}`
                : `${t("causaliIvaDetailPage:title")} - ${
                    causaliIva.descrizione
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!causaliIva?.codIVA ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codIVA")}
                    onChange={(e) => handleChangeValue(e, "codIVA")}
                    value={causaliIva?.codIVA || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={causaliIva?.descrizione || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:aliquota")}*`}
                  error={errors["aliquota"] ? true : false}
                  helperText={errors["aliquota"] || ""}
                  onChange={(e) => handleChangeValue(e, "aliquota")}
                  value={causaliIva?.aliquota?.toString() || ""}
                  type="number"
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:percIndetraibile")}
                  error={errors["percIndetraibile"] ? true : false}
                  helperText={errors["percIndetraibile"] || ""}
                  onChange={(e) => handleChangeValue(e, "percIndetraibile")}
                  value={causaliIva?.percIndetraibile?.toString() || ""}
                  type="number"
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:descrizioneLibroIVA")}
                  error={errors["descrizioneLibroIVA"] ? true : false}
                  helperText={errors["descrizioneLibroIVA"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizioneLibroIVA")}
                  value={causaliIva?.descrizioneLibroIVA || ""}
                />
                <Box
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectTipiCausaliIva
                    id="tipo"
                    onChange={handleChangeSelectValue}
                    value={causaliIva?.tipo}
                  />
                </Box>
                {/*
                                        <TextField
                                            className='px-4 w-full xl:w-3/12 md:w-6/12'
                                            size='small'
                                            InputLabelProps={{ className: 'pl-4, mx-4' }}
                                            label={t('common:codIVADetr')}
                                            error={errors['codIVADetr'] ? true : false}
                                            helperText={errors['codIVADetr'] || ''}
                                            onChange={(e) => handleChangeValue(e, 'codIVADetr')}
                                            value={causaliIva?.codIVADetr?.toString() || ''}
                                        />
                                        <TextField
                                            className='px-4 w-full xl:w-3/12 md:w-6/12'
                                            size='small'
                                            InputLabelProps={{ className: 'pl-4, mx-4' }}
                                            label={t('common:codIVAIndetr')}
                                            error={errors['codIVAIndetr'] ? true : false}
                                            helperText={errors['codIVAIndetr'] || ''}
                                            onChange={(e) => handleChangeValue(e, 'codIVAIndetr')}
                                            value={causaliIva?.codIVAIndetr?.toString() || ''}
                                        />
                                        */}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:fatturazioneElettronicaNatura")}
                  error={errors["fatturazioneElettronicaNatura"] ? true : false}
                  helperText={errors["fatturazioneElettronicaNatura"] || ""}
                  onChange={(e) =>
                    handleChangeValue(e, "fatturazioneElettronicaNatura")
                  }
                  value={causaliIva?.fatturazioneElettronicaNatura || ""}
                />
                <FormControlLabel
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  control={
                    <Checkbox
                      checked={causaliIva?.flgSoggettoBolli || false}
                      disableRipple={true}
                      onChange={(e) =>
                        handleChangeValue(
                          {
                            target: {
                              value: !(causaliIva?.flgSoggettoBolli || false),
                            },
                          },
                          "flgSoggettoBolli"
                        )
                      }
                      color="primary"
                    />
                  }
                  style={{ minWidth: "15rem" }}
                  label={t("common:flgSoggettoBolli")}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-causaliiva") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
