import { createReducer } from '@reduxjs/toolkit'
import {
    setUnitaDiMisuraIndex,
    setUnitaDiMisuraShow,
    resetUnitaDiMisuraShow
} from '@store/unita-di-misura/unitaDiMisura.actions'
import { UnitaDiMisuraIndex, UnitaDiMisuraShow } from './unitaDiMisura.types'

const initialState: {
    index: UnitaDiMisuraIndex
    show: UnitaDiMisuraShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const unitaDiMisuraReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setUnitaDiMisuraIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setUnitaDiMisuraShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetUnitaDiMisuraShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

