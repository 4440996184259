import axios from "axios";
import environment from "@environment/environment";
import { store } from "@store/store";
import { logout } from "./auth/auth.effects";

const { dispatch } = store;

const AxiosIDP = axios.create({
  baseURL: `${environment.urlIDP}/idp`,
  timeout: 20000,
});

const AxiosAPI = axios.create({
  baseURL: `${environment.urlAPI}/api`,
  timeout: 240000,
});

AxiosAPI.interceptors.request.use(
  function (config) {
    const authToken = localStorage.getItem("authToken");
    config.headers.Authorization = `Bearer ${authToken}`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

AxiosIDP.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

AxiosAPI.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch(logout());
    } else {
      return Promise.reject(error);
    }
  }
);

export { AxiosIDP, AxiosAPI };
