import { AxiosAPI } from '@store/axios.config';
import { AppThunk } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setSerieGommeTipoArtIndex, setSerieGommeTipoArtShow } from './serieGommeTipoArt.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import i18n from '@assets/i18n';
import { getSerieGommeShow } from '@store/serie-gomme/serieGomme.effects';
import { setSerieGommeShow } from '@store/serie-gomme/serieGomme.actions';


export const getSerieGommeTipoArtIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getSerieGommeTipoArtIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/SerieGommeTipoArt?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setSerieGommeTipoArtIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getSerieGommeTipoArtIndex'));
    }
}


export const getSerieGommeTipoArtShow = (codSerie: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getSerieGommeTipoArtShow'));

    try {
        const response = await AxiosAPI.get(`/SerieGommeTipoArt/${codSerie}/${codTipoArticolo}`)
        dispatch(setSerieGommeTipoArtShow(response.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getSerieGommeTipoArtShow'));
    }
}


export const postSerieGommeTipoArt = (codSerie: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('postSerieGommeTipoArt'));

    try {

        await AxiosAPI.post(`/SerieGommeTipoArt`, {
            codSerie,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        dispatch(getSerieGommeShow(`${codSerie}`))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('postSerieGommeTipoArt'));
        return true
    }
}


export const putSerieGommeTipoArt = (codSerie: number, codTipoArticolo: number): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putSerieGommeTipoArt'));

    try {
        await AxiosAPI.put(`/SerieGommeTipoArt/${codSerie}/${codTipoArticolo}`, {
            codSerie,
            codTipoArticolo
        })
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putSerieGommeTipoArt'));
    }
}

export const deleteSerieGommeTipoArt = (codSerie: number, codTipoArticolo: number): AppThunk => async (dispatch, getState) => {

    dispatch(setRequestLoader('deleteSerieGommeTipoArt'));

    try {

        await AxiosAPI.delete(`/SerieGommeTipoArt/${codSerie}/${codTipoArticolo}`)

        const currentState = (getState()).serieGomme.show;
        if (currentState.data) {
            const data = currentState.data.serieGommeTipoArt.filter((item) => item.codTipoArticolo !== codTipoArticolo)
            dispatch(setSerieGommeShow({
                ...currentState,
                data: {
                    ...currentState.data,
                    serieGommeTipoArt: data
                }
            }))
        }

        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('deleteSerieGommeTipoArt'));
    }
}