import {
  CommonSpeedDial,
  ConfirmAction,
  ConfirmActionPropsData,
  CustomHeaderPage,
} from "@components/index";
import { SelectTasks } from "@components/selects";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { DataGrid, GridEditCellPropsParams } from "@material-ui/data-grid";
import { Delete } from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import {
  getSchedulerShow,
  putLiveData,
} from "@store/flow-manager/flowManager.effects";
import { schedulerShow } from "@store/flow-manager/flowManager.selector";
import { SchedulerLiveData } from "@store/flow-manager/flowManager.type";
import { globalTasks } from "@store/global/global.selector";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { v4 as uuid } from "uuid";
import Auth from "@utilities/auth";
import cronstrue from 'cronstrue';
import 'cronstrue/locales/it';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "600px",
  },
}));

export function SchedulerShow() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["schedulersShowPage", "common"]);
  const { id } = useParams<{ id: string }>();

  const scheduler = useAppSelector(schedulerShow);
  const tasks = useAppSelector(globalTasks);

  const [newTaskId, setNewTaskId] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [liveData, setLiveData] = useState<SchedulerLiveData[]>([]);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  useEffect(() => {
    setLiveData(scheduler.schedulerLiveData);
  }, [scheduler]);

  useEffect(() => {
    dispatch(getSchedulerShow(id));
  }, [dispatch, id]);

  const handleChangeLiveData = (id: string, value: any, field: string) => {
    const index = liveData.findIndex((data) => data.id === id);
    if (index === -1) return;

    const tmpLiveData = [...liveData];
    const row = { ...tmpLiveData[index] };
    row[field] = value;

    tmpLiveData[index] = row;
    setLiveData(tmpLiveData);
  };

  const handleDeleteLiveData = (id: string) => {
    setConfirmAction({
      isOpen: true,
      title: t("common:message:defaultConfirmationTitle"),
      subtitle: t("common:message:defaultConfirmationMessage"),
      confirmAction: () => {
        const index = liveData.findIndex((data) => data.id === id);
        if (index === -1) return;

        const tmpLiveData = [...liveData];
        tmpLiveData.splice(index, 1);
        setLiveData(tmpLiveData);
      },
    });
  };

  const handleAddLiveData = () => {
    const tmpLiveData = [...liveData];
    tmpLiveData.push({
      id: uuid(),
      type: "daily",
      config: "",
      schedulerId: id,
      taskId: newTaskId,
    });
    setLiveData(tmpLiveData);
  };

  const handleCancel = () => {
    setLiveData([]);
    dispatch(getSchedulerShow(id));
  };

  const handleSave = () => {
    dispatch(
      putLiveData({
        schedulerId: id,
        liveData: liveData,
      })
    )
      .unwrap()
      .then((res: any) => {
        setLiveData(res.data);
      });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={`${t(`schedulersShowPage:title`)} - ${
              scheduler?.relatedServer?.name
            }`}
          >
            {Auth.hasAccess("save-schedules") ? (
              <Button
                variant="outlined"
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                <Add color="primary" />
                {t("schedulersShowPage:addSchedule")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              hideFooter
              autoHeight
              rows={liveData || []}
              onEditCellChangeCommitted={(
                { id, field, props }: GridEditCellPropsParams,
                event?: React.SyntheticEvent<Element, Event> | undefined
              ) => {
                if (event && event.preventDefault) {
                  event.preventDefault();
                }
                handleChangeLiveData(id as string, props.value, field);
              }}
              columns={[
                {
                  field: "task",
                  headerName: t("common:task"),
                  flex: 1,
                  disableClickEventBubbling: true,
                  disableColumnMenu: true,
                  sortable: false,
                  valueGetter: (params) => {
                    if (params.row.relatedTask) {
                      return `${params.row.relatedTask.name} (${params.row.relatedTask.version})`;
                    } else {
                      const task = tasks.find(
                        (task) => task.id === params.row.taskId
                      );
                      return task ? `${task.name} (${task.version})` : "";
                    }
                  },
                },
                {
                  field: "type",
                  headerName: t("common:periodicity"),
                  flex: 1,
                  disableClickEventBubbling: true,
                  disableColumnMenu: true,
                  sortable: false,
                  editable: Auth.hasAccess("save-schedules"),
                  renderCell: (params) => {

                    let readableCron = '';
                    try {
                      readableCron = cronstrue.toString(
                        params.row.type,
                        { verbose: true, use24HourTimeFormat: true, locale: 'it' }
                      );
                    } catch(e) {}

                    return (
                      <Tooltip
                        title={<Fragment>
                          <div className="text-center">{params.row.type}</div>
                          {readableCron}
                        </Fragment>}
                      >
                        <div>{params.row.type}</div>
                      </Tooltip>
                    );
                  },
                },
                {
                  field: "config",
                  headerName: t("common:config"),
                  flex: 1,
                  disableClickEventBubbling: true,
                  disableColumnMenu: true,
                  sortable: false,
                  editable: Auth.hasAccess("save-schedules"),
                },
                {
                  field: "delete",
                  headerName: t("common:delete"),
                  headerAlign: "center",
                  align: "center",
                  disableColumnMenu: true,
                  disableClickEventBubbling: true,
                  sortable: false,
                  hide: !Auth.hasAccess("save-schedules"),
                  renderCell: (params) => {
                    return (
                      <IconButton
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleDeleteLiveData(params.row.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Paper>
          {Auth.hasAccess("save-schedules") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
          <ConfirmAction
            data={confirmAction}
            setConfirmAction={setConfirmAction}
          />
        </Grid>
      </Grid>
      <Dialog onClose={handleDialogClose} open={dialogOpen}>
        <DialogTitle>{t("common:selectJob")}</DialogTitle>
        <DialogContent className={classes.dialog}>
          <SelectTasks
            id="newTask"
            value={newTaskId}
            onChange={(value: any, id: string) => {
              setHasError(false);
              setNewTaskId(value);
            }}
            error={hasError}
            helperText={
              hasError ? t("common:validatorErrors:requiredField") : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="m-4 mr-0"
            type="button"
            onClick={() => {
              if (newTaskId === "") {
                setHasError(true);
              } else {
                setDialogOpen(false);
                handleAddLiveData();
              }
            }}
          >
            {t("common:save")}
          </Button>
        </DialogActions>
        <Box className="flex justify-end"></Box>
      </Dialog>
    </Fragment>
  );
}
