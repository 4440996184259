import { createAction } from '@reduxjs/toolkit';
import { Distributori } from '@store/anagrafica-distributori/distributori.types';
import { CapiZona } from '@store/capi-zona/capiZona.types';
import { CategorieMerceologiche } from '@store/categorie-merceologiche/categorieMerceologiche.types';
import { CausaliIva } from '@store/causali-iva/causaliIva.type';
import { CodiciVelocita } from '@store/codici-velocita/codiciVelocita.types';
import { Disegni } from '@store/disegni/disegni.types';
import { Divisioni } from '@store/divisioni/divisioni.types';
import { IndiciDiCarico } from '@store/indici-di-carico/indiciDiCarico.types';
import { LarghezzaCerchi } from '@store/larghezza-cerchi/larghezzaCerchi.types';
import { LarghezzaNominale } from '@store/larghezze-nominali/larghezzeNominali.types';
import { LivelloListino } from '@store/livello-listino/livelloListino.types';
import { Monete } from '@store/monete/monete.types';
import { Nazioni } from '@store/nazioni/nazioni.type';
import { Pagamenti } from '@store/pagamenti/pagamenti.types';
import { Produttori } from '@store/produttori/produttori.types';
import { Province } from '../province/province.types';
import { Programmi } from '@store/programma/programma.types';
import { Regioni } from '@store/regioni/regioni.type';
import { Reti } from '@store/rete-commerciale/reti.types';
import { MasterDataUserRole } from '@store/master-data-user/masterDataUser.type';
import { SerieGomme } from '@store/serie-gomme/serieGomme.types';
import { Stagionalita } from '@store/stagionalita/stagionalita.types';
import { Strutture } from '@store/strutture/strutture.types';
import { TipiArticolo } from '@store/tipi-articolo/tipiArticolo.types';
import { TipoDocumenti } from '@store/tipo-documenti/tipoDocumenti.types';
import { UnitaDiMisura } from '@store/unita-di-misura/unitaDiMisura.types';
import { Marca, Modello } from '@store/veicoli/veicoli.types';
import { Server, Task } from '@store/flow-manager/flowManager.type';
import { EntiConvenzionati } from '@store/enti-convenzionati/entiConvenzionati.types';
import { DesConvenzioniGeneriche } from '@store/des-convenzioni-generiche/desConvenzioniGeneriche.types';
import { TipoContratto } from '@store/tipi-contratto/tipiContratto.type';
import { ScontoAddizionale } from '@store/sconto-addizionale/scontoAddizionale.type';

export const setGlobalCapiZona = createAction<CapiZona[]>('global/capiZona')
export const setGlobalCategorieMerceologice = createAction<CategorieMerceologiche[]>('global/categorieMerceologiche')
export const setGlobalCausaliIva = createAction<CausaliIva[]>('global/causaliIva')
export const setGlobalCodiciVelocita = createAction<CodiciVelocita[]>('global/codiciVelocita')
export const setGlobalConvenzioniGeneriche = createAction<DesConvenzioniGeneriche[]>('global/convenzioniGeneriche')
export const setGlobalDisegni = createAction<Disegni[]>('global/disegni')
export const setGlobalDistributori = createAction<Distributori[]>('global/distributori')
export const setGlobalDivisioni = createAction<Divisioni[]>('global/divisioni')
export const setGlobalEntiConvenzionati = createAction<EntiConvenzionati[]>('global/entiConvenzionati')
export const setGlobalIndiciDiCarico = createAction<IndiciDiCarico[]>('global/indiciDiCarico')
export const setGlobalLarghezzeCerchi = createAction<LarghezzaCerchi[]>('global/larghezzeCerchi')
export const setGlobalLarghezzeNominali = createAction<LarghezzaNominale[]>('global/larghezzeNominali')
export const setGlobalLivelliListino = createAction<LivelloListino[]>('global/livelliListino')
export const setGlobalMarche = createAction<Marca[]>('global/marche')
export const setGlobalModelli = createAction<Modello[]>('global/modelli')
export const setGlobalMonete = createAction<Monete[]>('global/monete')
export const setGlobalPagamenti = createAction<Pagamenti[]>('global/pagamenti')
export const setGlobalProduttori = createAction<Produttori[]>('global/produttori')
export const setGlobalProgrammi = createAction<Programmi[]>('global/programmi')
export const setGlobalProvince = createAction<Province[]>('global/province')
export const setGlobalNazioni = createAction<Nazioni[]>('global/nazioni')
export const setGlobalRegioni = createAction<Regioni[]>('global/regioni')
export const setGlobalReti = createAction<Reti[]>('global/reti')
export const setGlobalRuoli = createAction<MasterDataUserRole[]>('global/role')
export const setGlobalSemaphoresTasks = createAction<{
    id: string,
    description: string,
}[]>('global/semaphoresTasks')
export const setGlobalSerieGomme = createAction<SerieGomme[]>('global/serieGomme')
export const setGlobalServers = createAction<Server[]>('global/servers')
export const setGlobalStagionalita = createAction<Stagionalita[]>('global/stagionalita')
export const setGlobalStrutture = createAction<Strutture[]>('global/strutture')
export const setGlobalTasks = createAction<Task[]>('global/tasks')
export const setGlobalTipiArticolo = createAction<TipiArticolo[]>('global/tipiArticolo')
export const setGlobalTipiContrattoFlotte = createAction<TipoContratto[]>('global/tipiContrattoFlotte')
export const setGlobalTipiDocumento = createAction<TipoDocumenti[]>('global/tipiDocumento')
export const setGlobalTipiCausaliIva = createAction<{
    id: string,
    description: string,
}[]>('global/tipiCausaliIva')
export const setGlobalUnitaDiMisura = createAction<UnitaDiMisura[]>('global/unitaDiMisura')
export const setGlobalScontoAddizionale= createAction<ScontoAddizionale[]>('global/scontoAddizionale')