import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { Reti } from '@store/rete-commerciale/reti.types';

export const setRetiIndex = createAction<{
    data: Reti[]
    meta: {
        pagination: Pagination
    }
}>('reti/index')

export const setRetiShow = createAction<Reti>('reti/show')