import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalPagamenti } from "@store/global/global.selector";
import { getGlobalPagamenti } from "@store/global/global.effects";

export function SelectPagamenti(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const pagamenti = useAppSelector(globalPagamenti);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalPagamenti())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:pagamento`)}
            data={pagamenti}
            descriptionKey={'descrizione'}
            valueKey={'codPagamento'}
            {...props}
        />
    );
}
