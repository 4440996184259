import { createReducer } from '@reduxjs/toolkit'
import { Pagination } from '@store/common/common.types'
import { Reti } from '@store/rete-commerciale/reti.types'
import {
    setRetiIndex,
    setRetiShow
} from './reti.actions'

const initialState: {
    index: {
        data: Reti[]
        meta: {
            pagination: Pagination | undefined
        }
    },
    show: {
        data: Reti | undefined
    }
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const retiReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setRetiIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setRetiShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
})

