import { Box, Chip, Dialog, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ImportListiniSummary } from "@store/listini/listini.types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as TireIcon } from '@assets/icons/Full/Tire.svg';
import { ReactComponent as ClipboardIcon } from '@assets/icons/Full/Clipboard-alt.svg';

interface ImportSummaryDialogProps {
    open: boolean
    setOpen: (value: React.SetStateAction<boolean>) => void
    importListiniSummary?: ImportListiniSummary
}

export function ImportSummaryDialog(props: ImportSummaryDialogProps) {

    const { open, setOpen, importListiniSummary } = props;
    const { t } = useTranslation(['common'])

    return (
        <Dialog open={open} onClose={() => {
            setOpen(false);
        }}>
            <DialogTitle>{t('common:importSummary')}</DialogTitle>
            <Box>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <ClipboardIcon width='1.5rem' height='1.5rem'/>
                        </ListItemIcon>
                        <ListItemText primary={t('common:numListiniClientiImportati')} secondary={importListiniSummary?.importedListiniClienti}/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <ClipboardIcon width='1.5rem' height='1.5rem'/>
                        </ListItemIcon>
                        <ListItemText primary={t('common:numListiniFornitoriImportati')} secondary={importListiniSummary?.importedListiniFornitori}/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <TireIcon width='1.5rem' height='1.5rem'/>
                        </ListItemIcon>
                        <ListItemText primary={t('common:foundArticles')} secondary={importListiniSummary?.foundArticles}/>
                    </ListItem>
                {
                    importListiniSummary && importListiniSummary?.notFoundArticles && importListiniSummary?.notFoundArticles.length > 0 ?
                    <Fragment>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                            <TireIcon width='1.5rem' height='1.5rem'/>
                            </ListItemIcon>
                            <ListItemText primary={t('common:articoliNonTrovati')} secondary={importListiniSummary?.notFoundArticles.length}/>
                        </ListItem>
                        <Box className="p-2">
                            {
                                importListiniSummary?.notFoundArticles.map(codArticolo => 
                                    <Chip label={codArticolo} className="m-2"/>
                                ) 
                            }
                        </Box>
                    </Fragment>
                    : null
                }
                </List>
            </Box>
        </Dialog>
    )
}
