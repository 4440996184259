import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalCausaliIva } from "@store/global/global.effects";
import { globalCausaliIva } from "@store/global/global.selector";

export function SelectCausaliIva(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const causaliIva = useAppSelector(globalCausaliIva);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalCausaliIva())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:causaleIva`)}
            data={causaliIva}
            descriptionKey={'descrizione'}
            valueKey={'codIVA'}
            {...props}
        />
    );
}
