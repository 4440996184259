import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getCodiciVelocitaShow,
  postCodiciVelocita,
  putCodiciVelocita,
} from "@store/codici-velocita/codiciVelocita.effects";
import {
  CodiciVelocita,
  NewCodiciVelocita,
} from "@store/codici-velocita/codiciVelocita.types";
import { codiciVelocitaShow } from "@store/codici-velocita/codiciVelocita.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { AssociazioneTipoArticolo } from "../components/AssociazioneTipoArticolo";
import { resetCodiciVelocitaShow } from "@store/codici-velocita/codiciVelocita.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";

interface CodiciVelocitaDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function CodiciVelocitaDetail({
  match,
  creationMode,
}: CodiciVelocitaDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "codiciVelocitaDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingCodiciVelocita = useAppSelector(codiciVelocitaShow);
  const [codiceVelocita, setCodiciVelocita] = useState<
    CodiciVelocita | NewCodiciVelocita
  >({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getCodiciVelocitaShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingCodiciVelocita) {
      setNewElement(false);
      setCodiciVelocita(pollingCodiciVelocita);
    }
  }, [pollingCodiciVelocita]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetCodiciVelocitaShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setCodiciVelocita((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "simbolo",
        codiceVelocita?.simbolo,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 6 }
      ),
      Validation.buildFieldConfig(
        "kmOrari",
        codiceVelocita?.kmOrari,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 10 }
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !codiceVelocita) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && codiceVelocita) {
      const newCodiciVelocita: NewCodiciVelocita = {
        ...codiceVelocita,
        codVelocita: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(
        postCodiciVelocita(newCodiciVelocita)
      );
      if (postCodiciVelocita.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.codiciVelocita.show.replace(
              ":id",
              result.codVelocita.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putCodiciVelocita(codiceVelocita as CodiciVelocita));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getCodiciVelocitaShow(id));
    } else {
      setCodiciVelocita({});
    }
  };

  return (
    <div>
      {codiceVelocita?.codVelocita || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("codiciVelocitaDetailPage:newElementTitle")}`
                : `${t("codiciVelocitaDetailPage:title")} - ${
                    codiceVelocita.simbolo
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!codiceVelocita?.codVelocita ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codVelocita")}
                    onChange={(e) => handleChangeValue(e, "codVelocita")}
                    value={codiceVelocita?.codVelocita || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:Simbolo")}*`}
                  error={errors["simbolo"] ? true : false}
                  helperText={errors["simbolo"] || ""}
                  onChange={(e) => handleChangeValue(e, "simbolo")}
                  value={codiceVelocita?.simbolo || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:kmOrari")}*`}
                  error={errors["kmOrari"] ? true : false}
                  helperText={errors["kmOrari"] || ""}
                  onChange={(e) => handleChangeValue(e, "kmOrari")}
                  value={codiceVelocita?.kmOrari || ""}
                />
              </Box>
            </Paper>
          </Box>
          {!newElement ? <AssociazioneTipoArticolo /> : null}
          {Auth.hasAccess("save-codvelocita") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
