import { Box, Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ProrogaConvenzioniDialogProps {
    defaultDate: string,
    open: boolean,
    setOpen: (value: React.SetStateAction<boolean>) => void,
    handleProrogaConvenzioni: (date: string) => void
}

export function ProrogaConvenzioniDialog(props: ProrogaConvenzioniDialogProps) {
    const { defaultDate, open, setOpen, handleProrogaConvenzioni } = props;
    const { t } = useTranslation(['common'])

    const [dataFineValidita, setDataFineValidita] = useState<string>('')

    useEffect(() => {
        if (defaultDate) {
            setDataFineValidita(defaultDate);
        } else {
            setDataFineValidita(moment(new Date()).format('YYYY-MM-DD'));
        }
    }, [defaultDate])

    return (
        <Dialog open={open} onClose={() => {setOpen(false)}}>
            <DialogTitle>{t('Proroga convenzioni')}</DialogTitle>
            <Box className="w-full" minWidth="380px">
                <Box className='px-2 w-full'>
                    <TextField
                        key='dataFineValidita'
                        label={t('common:dataFineValidita')}
                        type='date'
                        fullWidth
                        size='small'
                        variant="outlined"
                        value={dataFineValidita}
                        onChange={(e) => {
                            const target = e.target as typeof e.target & { value: string }
                            setDataFineValidita(target.value)
                        }}
                    />
                </Box>
                <Box className='w-full flex justify-end px-2'>
                    <Button className='my-4 mr-0' onClick={() => {
                      handleProrogaConvenzioni(dataFineValidita);
                    }}>{t('Proroga convenzioni')}</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
