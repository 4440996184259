import i18n from "@assets/i18n";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Store.svg";
import { ReactComponent as DealersRegistryIcon } from "@assets/icons/Full/View-list.svg";
import { ReactComponent as DealersAccountManagerIcon } from "@assets/icons/Full/Briefcase-account.svg";
import { ReactComponent as ConventionsIcon } from "@assets/icons/Full/Text-box-multiple.svg";
import { ReactComponent as FleetsIcon } from "@assets/icons/Full/Car-multiple.svg";
import { ReactComponent as ConventionsMinusIcon } from "@assets/icons/Full/Text-box-minus.svg";
import { ReactComponent as EntityGenericIcon } from "@assets/icons/Full/Text-box.svg";
import { ReactComponent as ConventionsNetPriceIcon } from "@assets/icons/Full/Text-box-check.svg";
import { ReactComponent as TireIcon } from "@assets/icons/Full/Tire.svg";
import { ReactComponent as GeneralRegistryIcon } from "@assets/icons/Full/Table-multiple.svg";
import { ReactComponent as GeneralRegistryGenericIcon } from "@assets/icons/Full/Table-large.svg";
import { ReactComponent as Allestimenti } from "@assets/icons/Full/Car-sports.svg";
import { ReactComponent as UsersIcon } from "@assets/icons/Full/Account-multiple.svg";
import { ReactComponent as DatabaseIcon } from "@assets/icons/Full/Database.svg";
import { ReactComponent as LogManagerIcon } from "@assets/icons/Full/Chart-box.svg";
import { ReactComponent as ClipboardIcon } from "@assets/icons/Full/Clipboard-alt.svg";
import { ReactComponent as SettingsIcon } from "@assets/icons/Full/Settings-alt.svg";
import { ReactComponent as ShuffleIcon } from "@assets/icons/Full/Shuffle.svg";
import { ReactComponent as FileImportIcon } from "@assets/icons/Full/File-import.svg";
import { ReactComponent as ClipboardListIcon } from "@assets/icons/Full/Clipboard-list.svg";
import { ReactComponent as CarMultiple } from "@assets/icons/Full/Car-multiple.svg";
import { ReactComponent as ExternalRegisters } from "@assets/icons/Full/Edit.svg";
import { ReactComponent as ContractTypes } from "@assets/icons/Full/Text-box-multiple.svg";
import routes from "./routes";

class MenuBase {
  name: string;
  icon: JSX.Element | null;
  permissions: string[];

  constructor(
    name: string,
    icon: JSX.Element | null,
    permissions: string[] = []
  ) {
    this.name = name;
    this.icon = icon;
    this.permissions = permissions;
  }
}

class MenuItem extends MenuBase {
  path: string;

  constructor(
    name: string,
    icon: JSX.Element | null,
    path: string,
    permissions: string[] = []
  ) {
    super(name, icon, permissions);
    this.path = path;
  }
}

class MenuGroup extends MenuBase {
  children: MenuItem[];

  constructor(
    name: string,
    icon: JSX.Element | null,
    children: MenuItem[],
    permissions: string[] = []
  ) {
    super(name, icon, permissions);
    this.children = children;
  }

  // Ottimizzabile
  public getItemByPath = (path: string): MenuItem | undefined => {
    const splittedPath = path.split("/");
    let basePath = "";
    if (splittedPath.length > 3) {
      splittedPath.pop();
      splittedPath.shift();
      basePath = splittedPath.join("/");
    } else {
      basePath = splittedPath[1];
    }
    let item = this.children.find((item) => item.path === path);
    if (item === undefined) {
      item = this.children.find((item) => item.path.includes(basePath));
    }
    if (item === undefined) {
      item = this.children.find((item) =>
        item.path.includes(path.split("/")[1])
      );
    }
    return item;
  };
}

class Menu {
  groups: MenuGroup[];

  constructor(groups: MenuGroup[]) {
    this.groups = groups;
  }

  // Ottimizzabile
  public getGroupByPath = (path: string): MenuGroup | undefined => {
    const basePath = path.split("/")[1];
    let group = this.groups.find((group) => {
      const item =
        group.children.find((item) => {
          return item.path.split("/")[1] === basePath;
        }) !== undefined;
      return item;
    });
    if (group === undefined) {
      group = this.groups.find((group) => {
        const item =
          group.children.find((item) => item.path.includes(basePath)) !==
          undefined;
        return item;
      });
    }
    return group;
  };
}

export const menu: Menu = new Menu([
  new MenuGroup(
    i18n.t("sidebar:dealers"),
    <DealersIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
    [
      new MenuItem(
        i18n.t("sidebar:anagrafica"),
        <DealersRegistryIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.anagrafica.index,
        ["view-anagrafiche"]
      ),
      new MenuItem(
        i18n.t("sidebar:capiZona"),
        (
          <DealersAccountManagerIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.capiZona.index,
        ["view-capizona"]
      ),
    ],
    ["view-anagrafiche", "view-capizona"]
  ),
  new MenuGroup(
    i18n.t("sidebar:fleets"),
    <ConventionsIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
    [
      new MenuItem(
        i18n.t("sidebar:enti"),
        <FleetsIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.entiConvenzionati.index,
        ["view-enticonv"]
      ),
      new MenuItem(
        i18n.t("sidebar:convenzioniSconti"),
        <ConventionsMinusIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.convenzioni.aSconto,
        ["view-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:convenzioniGeneriche"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.convenzioni.generiche.descrizioni.index,
        ["view-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:convenzioniPrezziNetti"),
        (
          <ConventionsNetPriceIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.convenzioni.prezziNetti,
        ["view-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:convenzioniPrezziNettiFlotte"),
        (
          <ConventionsNetPriceIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.convenzioni.prezziNettiFlotte,
        ["view-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:convenzioniFlotte"),
        (
          <ConventionsNetPriceIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.convenzioni.flotte,
        ["view-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:convenzioniDescrizione"),
        (
          <ConventionsNetPriceIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.convenzioni.generiche.index,
        ["view-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:convenzioniServizi"),
        (
          <ConventionsNetPriceIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.convenzioni.servizi,
        ["view-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:clonaConvenzione"),
        (
          <ConventionsIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.convenzioni.clone,
        ["save-convenzioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:tipiContratto"),
        (
          <ContractTypes
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.tipoContratti.index,
        ["view-tipicontratto"]
      ),
    ],
    ["view-enticonv", "view-convenzioni", "view-tipicontratto"]
  ),
  new MenuGroup(
    i18n.t("sidebar:articoli"),
    <TireIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
    [
      new MenuItem(
        i18n.t("sidebar:codiciVelocita"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.codiciVelocita.index,
        ["view-codvelocita"]
      ),
      new MenuItem(
        i18n.t("sidebar:disegni"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.disegni.index,
        ["view-disegni"]
      ),
      new MenuItem(
        i18n.t("sidebar:indiciDiCarico"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.indiciDiCarico.index,
        ["view-indicidicarico"]
      ),
      new MenuItem(
        i18n.t("sidebar:larghezzeCerchi"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.larghezzaCerchi.index,
        ["view-larghezzecerchi"]
      ),
      new MenuItem(
        i18n.t("sidebar:largNominali"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.larghezzeNominali.index,
        ["view-larghezzenominali"]
      ),
      new MenuItem(
        i18n.t("sidebar:pneumatici"),
        <TireIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.pneumatici.index,
        ["view-articoli"]
      ),
      new MenuItem(
        i18n.t("sidebar:produttori"),
        <TireIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.produttori.index,
        ["view-produttori"]
      ),
      new MenuItem(
        i18n.t("sidebar:serieGomme"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.serieGomme.index,
        ["view-seriegomme"]
      ),
      new MenuItem(
        i18n.t("sidebar:servizi"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.servizi.index,
        ["view-articoli"]
      ),
      new MenuItem(
        i18n.t("sidebar:stagionalita"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.stagionalita.index,
        ["view-stagionalita"]
      ),
      new MenuItem(
        i18n.t("sidebar:strutture"),
        <EntityGenericIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.strutture.index,
        ["view-strutture"]
      ),
      new MenuItem(
        i18n.t("sidebar:articoliFuoriProduzione"),
        <TireIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.pneumatici.articoliFuoriProduzione,
        ["view-articoli"]
      ),
    ],
    [
      "view-codvelocita",
      "view-disegni",
      "view-indicidicarico",
      "view-larghezzecerchi",
      "view-larghezzenominali",
      "view-produttori",
      "view-seriegomme",
      "view-articoli",
      "view-stagionalita",
      "view-strutture",
    ]
  ),
  new MenuGroup(
    i18n.t("sidebar:listini"),
    <ClipboardIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
    [
      new MenuItem(
        i18n.t("sidebar:anagraficaListini"),
        <ClipboardListIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.priceList.anagraficaListini,
        ["view-listini"]
      ),
      new MenuItem(
        i18n.t("sidebar:importListino"),
        <FileImportIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.priceList.importListino,
        ["save-listini"]
      ),
    ],
    ["view-listini"]
  ),
  new MenuGroup(
    i18n.t("sidebar:anagrafichegenerali"),
    <GeneralRegistryIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
    [
      new MenuItem(
        i18n.t("sidebar:allestimenti"),
        <Allestimenti width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.allestimenti.index,
        ["view-allestimenti"]
      ),
      new MenuItem(
        i18n.t("sidebar:causaliIva"),
        (
          <GeneralRegistryGenericIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.causaliIva.index,
        ["view-causaliiva"]
      ),
      new MenuItem(
        i18n.t("sidebar:esercizi"),
        (
          <GeneralRegistryGenericIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.esercizi.index,
        ["view-esercizi"]
      ),
      new MenuItem(
        i18n.t("sidebar:nazioni"),
        (
          <GeneralRegistryGenericIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.nazioni.index,
        ["view-nazioni"]
      ),
      new MenuItem(
        i18n.t("sidebar:province"),
        (
          <GeneralRegistryGenericIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.province.index,
        ["view-province"]
      ),
      new MenuItem(
        i18n.t("sidebar:tipoContanti"),
        (
          <GeneralRegistryGenericIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.tipoContanti.index,
        ["view-tipicontanti"]
      ),      
      new MenuItem(
        i18n.t("sidebar:scontoAddizionale"),
        (
          <GeneralRegistryGenericIcon
            width="1.5rem"
            height="1.5rem"
            fill="#ffffff"
          />
        ),
        routes.scontoAddizionale.index,
        ["view-scontoaddizionale"]
      ),
    ],
    [
      "view-allestimenti",
      "view-causaliiva",
      "view-esercizi",
      "view-nazioni",
      "view-province",
      "view-tipicontanti",
      "view-scontoaddizionale",
    ]
  ),
  new MenuGroup(
    i18n.t("sidebar:externalRegisters"),
    <ExternalRegisters width="1.5rem" height="1.5rem" fill="#ffffff" />,
    [
      new MenuItem(
        i18n.t("sidebar:fleetVehiclesImport"),
        <CarMultiple width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.externalRegisters.fleetVehicles
      ),
      new MenuItem(
        i18n.t("sidebar:fleetContractTypesImport"),
        <ContractTypes width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.externalRegisters.fleetContractTypes
      ),
    ],
    ["save-enticonv"]
  ),
  new MenuGroup(
    i18n.t("sidebar:admin"),
    <SettingsIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
    [
      new MenuItem(
        i18n.t("sidebar:flowManager"),
        <ShuffleIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.flowManager.index,
        ["view-flowmanager"]
      ),
      new MenuItem(
        i18n.t("sidebar:logManager"),
        <LogManagerIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.logManager.index,
        ["view-logmanager"]
      ),
      new MenuItem(
        i18n.t("sidebar:users"),
        <UsersIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.users.index,
        ["view-users"]
      ),
      new MenuItem(
        i18n.t("sidebar:aggiornamentiDb"),
        <DatabaseIcon width="1.5rem" height="1.5rem" fill="#ffffff" />,
        routes.aggiornamentiDb.index,
        ["view-dbupdates"]
      ),
    ],
    ["view-flowmanager", "view-logmanager", "view-users", "view-dbupdates"]
  ),
]);
