import { createReducer } from '@reduxjs/toolkit'
import { Pagination } from '@store/common/common.types'
import { setPagamentiIndex } from './pagamenti.actions'
import { Pagamenti } from './pagamenti.types'

const initialState: {
    index: {
        data: Pagamenti[]
        meta: {
            pagination: Pagination | undefined
        }
    }
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    }
}

export const pagamentiReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setPagamentiIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
})

