import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getStruttureShow,
  postStrutture,
  putStrutture,
} from "@store/strutture/strutture.effects";
import { Strutture, NewStrutture } from "@store/strutture/strutture.types";
import { struttureShow } from "@store/strutture/strutture.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetStruttureShow } from "@store/strutture/strutture.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";

interface StruttureDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function StruttureDetail({ match, creationMode }: StruttureDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "struttureDetailPage"]);

  const { codStruttura } = useParams<{ codStruttura: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingStrutture = useAppSelector(struttureShow);
  const [struttura, setStruttura] = useState<Strutture | NewStrutture>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getStruttureShow(codStruttura));
  }, [dispatch, codStruttura, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingStrutture) {
      setNewElement(false);
      setStruttura(pollingStrutture);
    }
  }, [pollingStrutture]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetStruttureShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setStruttura((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", struttura?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !struttura) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && struttura) {
      const newStruttura: NewStrutture = {
        ...struttura,
        codStruttura: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(postStrutture(newStruttura));
      if (postStrutture.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.strutture.show.replace(
              ":codStruttura",
              result.codStruttura.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putStrutture(struttura as Strutture));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getStruttureShow(codStruttura));
    } else {
      setStruttura({});
    }
  };

  return (
    <div>
      {struttura?.codStruttura || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("struttureDetailPage:newElementTitle")}`
                : `${t("struttureDetailPage:title")} - ${struttura.descrizione}`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!struttura?.codStruttura ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codStruttura")}
                    onChange={(e) => handleChangeValue(e, "codStruttura")}
                    value={struttura?.codStruttura || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={struttura?.descrizione || ""}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-strutture") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
