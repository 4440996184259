
import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogActions, makeStyles, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    dialog: {
        minWidth: '400px',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0px 21px 0px;'
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
}))

export interface ConfirmActionProps {
    data: ConfirmActionPropsData,
    setConfirmAction: any,
}
export interface ConfirmActionPropsData {
    isOpen: boolean
    title?: string
    subtitle?: string
    confirmAction?: () => void,
    cancelAction?: () => void,
    customContent?: JSX.Element
}


export function ConfirmAction(props: ConfirmActionProps) {
    const classes = useStyles()

    const { t } = useTranslation(['common'])

    const { data, setConfirmAction } = props
    const { title = '', subtitle = '', confirmAction = () => { }, cancelAction = () => { } } = data

    const confirm = () => {
        if (confirmAction) {
            confirmAction()
        }
        setConfirmAction({ ...data, isOpen: false })
    }

    const cancel = () => {
        if (cancelAction) {
            cancelAction()
        }
        setConfirmAction({ ...data, isOpen: false })
    }

    return (
        <Dialog open={data.isOpen}>
            <div className={classes.dialog}>
                <DialogTitle className={classes.dialogTitle}>
                    {title}
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Typography variant='body1'>
                        {subtitle}
                    </Typography>
                    {data.customContent ? data.customContent : null}
                </DialogContent>

                <DialogActions className={classes.dialogAction}>
                    <Button className="mx-3" variant="outlined" onClick={() => { cancel() }}>{t('common:no')}</Button>
                    <Button className="mx-3" onClick={() => confirm()}>{t('common:yes')}</Button>
                </DialogActions>
            </div>
        </ Dialog >
    );
}