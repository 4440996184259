import { createReducer } from '@reduxjs/toolkit'
import {
    resetOperationResults,
    setOpertionResultsShow,
    setOperazioni,
    setOperazioniQuery,
} from './operazioniHD.actions'
import { OperationResults, Operazioni, OperazioniQuery } from './operazioniHD.types'


const operationsHD: {
    operations: {
        data: Operazioni[] | undefined
    },
    operationsResults: {
        data: OperationResults[] | undefined
    },
    queries: {
        data: OperazioniQuery[] | undefined
    },
} = {
    operations: { data: [] },
    operationsResults: { data: [] },
    queries: { data: [] },
}

export const operationHdReducer = createReducer(operationsHD, (builder) => {
    builder
        .addCase(setOperazioni, (state, action) => {
            return {
                ...state,
                operations: {
                    data: action.payload
                }
            }
        })
        .addCase(setOperazioniQuery, (state, action) => {
            return {
                ...state,
                queries: {
                    data: action.payload
                }
            }
        })
        .addCase(setOpertionResultsShow, (state, action) => {
            return {
                ...state,
                operationsResults: {
                    data: action.payload
                }
            }
        })
    .addCase(resetOperationResults, (state, action) => {
        return {
            ...state,
            operationsResults: {
                data: undefined
            }
        }
    })
})