import React, { Fragment, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";

import {
  getAnagraficaShow,
  postAnagrafica,
  putAnagrafica,
} from "@store/anagrafica/anagrafica.effects";
import { anagraficaShow } from "@store/anagrafica/anagrafica.selector";
import { resetAnagraficaShow } from "@store/anagrafica/anagrafica.actions";
import { EntiConvSSComponent } from "../components/EntiConvSS";
import { DataDealerComponent } from "../components/DataDealer";
import { SedeOperativaComponent } from "../components/SedeOperativa";
import { SedeLegaleComponent } from "../components/SedeLegale";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import {
  AnagraficaGoodyear,
  AnagraficaGoodyearDatiAggiuntivi,
} from "@store/anagrafica/anagrafica.types";
import {
  getSedeOperativaIndex,
  deleteSedeOperativa,
} from "@store/sedi-operative/sedeOperativa.effects";
import { sediOperativeIndex } from "@store/sedi-operative/sedeOperativa.selector";
import {
  SedeOperativa,
  SedeOperativaIndex,
} from "@store/sedi-operative/sedeOperativa.types";
import { getEntiConvenzionatiSSIndex } from "@store/enti-convenzionati-SS/entiConvenzioniSS.effects";
import { getEntiConvenzionatiIndex } from "@store/enti-convenzionati/entiConvenzionati.effects";
import { Filter, FilterTypes, FilterMethods } from "@store/common/common.types";
import { setError } from "@store/common/common.effects";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { scrollFunction } from "@utilities/utilities";
import Auth from "@utilities/auth";

const useStyles = makeStyles((theme) => ({
  main: {},
  box: {
    padding: "0rem 4.5rem",
    display: "flex",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

interface TypeAnagraficaDetails extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function AnagraficaDetails({
  match,
  creationMode,
}: TypeAnagraficaDetails) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "anagraficaDetailsPage"]);

  const { id } = useParams<{ id: string }>();

  const payload = useAppSelector(anagraficaShow);
  const sediOperative = useAppSelector(sediOperativeIndex);

  const [anagrafica, setAnagrafica] = useState({} as AnagraficaGoodyear);
  const [disabledAdd, setDisabledAdd] = useState(false);

  const [stateSediOperative, setStateSediOperative] = useState({
    data: [],
    meta: {
      pagination: undefined,
    },
  } as SedeOperativaIndex);

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    dispatch(
      getEntiConvenzionatiIndex(1, 999, undefined, [
        { field: "ragioneSociale", sort: "asc" },
      ])
    );

    const codDittaFilter = new Filter(
      FilterTypes.AND,
      "codDitta",
      FilterMethods.EQUAL,
      id
    );
    dispatch(getSedeOperativaIndex(1, 999, { codDittaFilter }));
    dispatch(getEntiConvenzionatiSSIndex(1, 999, { codDittaFilter }));

    if (creationMode) return;

    dispatch(getAnagraficaShow(id));
  }, [creationMode, dispatch, id]);

  const updateEntiSS = () => {
    dispatch(
      getEntiConvenzionatiIndex(1, 999, undefined, [
        { field: "ragioneSociale", sort: "asc" },
      ])
    );
    const codDittaFilter = new Filter(
      FilterTypes.AND,
      "codDitta",
      FilterMethods.EQUAL,
      id
    );
    dispatch(
      getEntiConvenzionatiSSIndex(1, 999, { codDittaFilter }, [
        { field: "codSedeOp", sort: "asc" },
        { field: "codEnteConv", sort: "asc" },
      ])
    );
  };
  // componentDidUpdate()
  useEffect(() => {
    if (payload) {
      setAnagrafica(() => {
        return payload;
      });
    }
  }, [payload]);

  useEffect(() => {
    setStateSediOperative(() => {
      return sediOperative;
    });
  }, [sediOperative]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetAnagraficaShow());
    };
  }, [dispatch]);

  const handleChangeAttributes = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      id: string;
      value: string;
    };
    setAnagrafica((prevState) => {
      return {
        ...prevState,
        [target.id]: target.value,
      };
    });
  };

  const handleChangeCheckBox = (e: React.ChangeEvent) => {
    const target = e.target as typeof e.target & {
      id: string;
      checked: boolean;
    };
    setAnagrafica((prevState) => {
      return {
        ...prevState,
        [target.id]: target.checked,
      };
    });
  };

  const handleChangeSelectSelector = (value: any, id: string) => {
    setAnagrafica((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };
  const handlerChangeCheckBoxDatiAggiuntivi = (e: React.ChangeEvent) => {
    const target = e.target as typeof e.target & {
      id: string;
      checked: boolean;
    };

    let newDatiAggiuntivi: AnagraficaGoodyearDatiAggiuntivi =
      anagrafica?.anagraficaGoodyearDatiAggiuntivi;
    newDatiAggiuntivi = {
      ...newDatiAggiuntivi,
      [target.id]: target.checked,
      codDitta: anagrafica?.codDitta || "",
    };

    setAnagrafica((prevState: AnagraficaGoodyear) => {
      return {
        ...prevState,
        anagraficaGoodyearDatiAggiuntivi: newDatiAggiuntivi,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "ragioneSociale",
        anagrafica?.ragioneSociale,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig("indSedeLegale", anagrafica?.indSedeLegale, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "capSedeLegale",
        anagrafica?.capSedeLegale,
        [ValidationTypes.REQUIRED, ValidationTypes.FIXED_LENGTH],
        { length: 5 }
      ),
      Validation.buildFieldConfig(
        "cap",
        anagrafica?.capSedeLegale,
        [ValidationTypes.REQUIRED, ValidationTypes.FIXED_LENGTH],
        { length: 5 }
      ),
      Validation.buildFieldConfig("locSedeLegale", anagrafica?.locSedeLegale, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "provSedeLegale",
        anagrafica?.provSedeLegale,
        [ValidationTypes.REQUIRED]
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = () => {
    setErrors({});
    if (validate()) {
      //distinguish post update from creation mode
      if (creationMode) {
        dispatch(postAnagrafica(anagrafica));
      } else {
        dispatch(putAnagrafica(anagrafica));
      }
    } else {
      dispatch(setError({ message: t("common:message:validationError") }));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      setAnagrafica({} as AnagraficaGoodyear);
      const codDittaFilter = new Filter(
        FilterTypes.AND,
        "codDitta",
        FilterMethods.EQUAL,
        id
      );
      dispatch(getSedeOperativaIndex(1, 999, { codDittaFilter }));
      dispatch(getEntiConvenzionatiSSIndex(1, 999, { codDittaFilter }));
      dispatch(getAnagraficaShow(id));
    } else {
      setAnagrafica({} as AnagraficaGoodyear);
    }
  };

  interface TemplateSedeOperativa {
    indirizzo?: string;
    localita?: string;
    cap?: string;
    provincia?: string;
    telefono1?: string;
    telefono2?: string;
    fax1?: string;
  }
  const createSedeOperativa = (autocompleteData?: TemplateSedeOperativa) => {
    if (anagrafica.codDitta !== undefined) {
      let newSede = {
        codDitta: anagrafica.codDitta,
        userIdTrf: anagrafica.codDitta,
        codSedeOperativa: 0,
        indirizzo: autocompleteData?.indirizzo || "",
        cap: autocompleteData?.cap || "",
        provincia: autocompleteData?.provincia || "",
        telefono1: autocompleteData?.telefono1 || "",
        telefono2: autocompleteData?.telefono2 || "",
        fax1: autocompleteData?.fax1 || "",
        localita: autocompleteData?.localita || "",
      } as SedeOperativa;

      setStateSediOperative((prevState) => {
        return {
          ...prevState,
          data: [...prevState?.data, newSede],
        };
      });
    }
  };

  const removeSedeOperativa = (numeroSede: number) => {
    if (numeroSede === 0) {
      const sedi = stateSediOperative.data.filter(
        (sedi) => sedi.codSedeOperativa !== 0
      );
      setStateSediOperative((prevState) => {
        return {
          ...prevState,
          data: sedi,
        };
      });

      return;
    }

    let sedeOpDel = {
      codSedeOp: numeroSede,
      codDitta: anagrafica.codDitta,
    };

    dispatch(deleteSedeOperativa(sedeOpDel));
  };

  const handlerSetDisabledAdd = (value: boolean) => {
    setDisabledAdd(value);
  };

  if (stateSediOperative?.data?.length === 0) {
    let compleateSede: TemplateSedeOperativa = {
      indirizzo: anagrafica?.indSedeLegale || "",
      cap: anagrafica?.capSedeLegale || "",
      localita: anagrafica?.locSedeLegale || "",
      provincia: anagrafica?.provSedeLegale || "",
      telefono1: anagrafica?.telefono1 || "",
      telefono2: anagrafica?.telefono2 || "",
      fax1: anagrafica?.fax1 || "",
    };

    createSedeOperativa(compleateSede);
  }

  return (
    <div>
      {Object.keys(anagrafica).length > 0 || creationMode ? (
        <div>
          <CustomHeaderPage
            title={
              creationMode
                ? `${t("anagraficaDetailsPage:newElementTitle")}`
                : `${t("anagraficaDetailsPage:title")} - ${
                    anagrafica.codDitta || ""
                  }`
            }
          >
            {/* <Button
                            variant='text'
                            onClick={() => handleDeleteItem()}
                        >
                            <TrashIcon className={classes.iconTrash} />
                        </Button> */}
          </CustomHeaderPage>
          {creationMode ? (
            <Typography variant="h4" className="mr-10 mb-10">
              {}
            </Typography>
          ) : (
            <Typography variant="h4" className="mr-10 mb-10">
              {}
            </Typography>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                >
                  <Typography className={classes.heading}>
                    {t("anagraficaDetailsPage:dealerDataTitle")}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {t("anagraficaDetailsPage:dealerDataSubtitle")}
                  </Typography>
                </AccordionSummary>
                <DataDealerComponent
                  handlerChangeData={handleChangeAttributes}
                  data={anagrafica}
                  helpDesk={false}
                  errors={errors}
                />
              </Accordion>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                >
                  <Typography className={classes.heading}>
                    {t("anagraficaDetailsPage:registeredOfficeTitle")}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {t("anagraficaDetailsPage:registeredOfficeSubtitle")}
                  </Typography>
                </AccordionSummary>
                <SedeLegaleComponent
                  handlerChangeCheckBoxDati={
                    handlerChangeCheckBoxDatiAggiuntivi
                  }
                  handlerChangeSelect={handleChangeSelectSelector}
                  handleChangeCheckBoxStatus={handleChangeCheckBox}
                  handlerChangeData={handleChangeAttributes}
                  data={anagrafica}
                  additionalData={anagrafica.anagraficaGoodyearDatiAggiuntivi}
                  errors={errors}
                />
              </Accordion>
              {!creationMode && stateSediOperative.data ? (
                <Fragment>
                  {stateSediOperative.data.map((sede) => {
                    return (
                      <Accordion
                        key={"Sede" + sede?.codSedeOperativa + sede?.codDitta}
                        defaultExpanded={
                          sede?.codSedeOperativa !== 1 ? false : true
                        }
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                        >
                          <Typography className={classes.heading}>
                            {t("anagraficaDetailsPage:operationalOfficeTitle")}
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            {sede?.indirizzo ||
                              `${t(
                                "anagraficaDetailsPage:newSedeOperativaSubtitle"
                              )}`}
                          </Typography>
                        </AccordionSummary>
                        <SedeOperativaComponent
                          data={sede}
                          create={createSedeOperativa}
                          handlerSetDisabledAdd={handlerSetDisabledAdd}
                          disabled={disabledAdd}
                          removeSedeOperativa={removeSedeOperativa}
                          dataAnagrafica={anagrafica}
                          updateEntiSS={updateEntiSS}
                        />
                      </Accordion>
                    );
                  })}
                </Fragment>
              ) : null}
              {!creationMode ? (
                <Fragment>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                    >
                      <Typography className={classes.heading}>
                        {t("anagraficaDetailsPage:dealerFlotteTitle")}
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {t("anagraficaDetailsPage:dealerFlotteSubtitle")}
                      </Typography>
                    </AccordionSummary>
                    <EntiConvSSComponent codDitta={id} />
                  </Accordion>
                </Fragment>
              ) : null}
            </Grid>
          </Grid>
          {Auth.hasAccess("save-anagrafiche") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
