import { createReducer } from '@reduxjs/toolkit'
import { CriteriServizi } from './convenzionePrezziNetti.types'
import { Pagination } from '@store/common/common.types'
import {
    resetCriteriServiziInex,
    setCriteriServiziIndex,
    setCriteriServiziShow
} from './convenzionePrezziNetti.actions'


const anagraficaGoodyear: {
    index: {
        data: CriteriServizi[] | undefined
        meta: {
            pagination?: Pagination
        }
    },
    show: {
        data: CriteriServizi | undefined
    }
} = {
    index: {
        data: undefined,
        meta: {}
    },
    show: { data: undefined }
}

export const criteriServiziReducer = createReducer(anagraficaGoodyear, (builder) => {
    builder
        .addCase(setCriteriServiziIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setCriteriServiziShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetCriteriServiziInex, (state, action) => {
            return {
                ...state,
                index: {
                    data: undefined,
                    meta: {}
                },
            }
        })
})