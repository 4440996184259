import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getLarghezzaCerchiShow,
  postLarghezzaCerchi,
  putLarghezzaCerchi,
} from "@store/larghezza-cerchi/larghezzaCerchi.effects";
import {
  LarghezzaCerchi,
  NewLarghezzaCerchi,
} from "@store/larghezza-cerchi/larghezzaCerchi.types";
import { larghezzaCerchiShow } from "@store/larghezza-cerchi/larghezzaCerchi.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { resetLarghezzaCerchiShow } from "@store/larghezza-cerchi/larghezzaCerchi.actions";
import { push } from "connected-react-router";
import { AssociazioneTipiArticolo } from "../components/AssociazioneTipiArticolo";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";

interface LarghezzaCerchiDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function LarghezzaCerchiDetail({
  match,
  creationMode,
}: LarghezzaCerchiDetailProps) {
  // const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "larghezzaCerchiDetailsPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingLarghezzaCerchio = useAppSelector(larghezzaCerchiShow);
  const [larghezzaCerchio, setLarghezzaCerchio] = useState<
    LarghezzaCerchi | NewLarghezzaCerchi
  >({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getLarghezzaCerchiShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingLarghezzaCerchio) {
      setNewElement(false);
      setLarghezzaCerchio(pollingLarghezzaCerchio);
    }
  }, [pollingLarghezzaCerchio]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetLarghezzaCerchiShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setLarghezzaCerchio((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "larghezzaCerchio",
        larghezzaCerchio?.larghezza,
        [ValidationTypes.REQUIRED, ValidationTypes.MAX_LENGTH],
        { maxLength: 6 }
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !larghezzaCerchio) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement) {
      const newLarghezzaCerchio: NewLarghezzaCerchi = {
        ...larghezzaCerchio,
        codLarghezzaCerchio: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(
        postLarghezzaCerchi(newLarghezzaCerchio)
      );
      if (postLarghezzaCerchi.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.larghezzaCerchi.show.replace(
              ":id",
              result.codLarghezzaCerchio.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putLarghezzaCerchi(larghezzaCerchio as LarghezzaCerchi));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getLarghezzaCerchiShow(id));
    } else {
      setLarghezzaCerchio({});
    }
  };

  return (
    <div>
      {larghezzaCerchio?.codLarghezzaCerchio || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("larghezzaCerchiDetailPage:newElementTitle")}`
                : `${t("larghezzaCerchiDetailPage:title")} - ${
                    larghezzaCerchio.larghezza
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!larghezzaCerchio?.codLarghezzaCerchio ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codLarghezzaCerchio")}
                    onChange={(e) =>
                      handleChangeValue(e, "codLarghezzaCerchio")
                    }
                    value={larghezzaCerchio?.codLarghezzaCerchio || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:larghezzaCerchio")}*`}
                  error={errors["larghezzaCerchio"] ? true : false}
                  helperText={errors["larghezzaCerchio"] || ""}
                  onChange={(e) => handleChangeValue(e, "larghezza")}
                  value={larghezzaCerchio?.larghezza || ""}
                />
              </Box>
            </Paper>
          </Box>
          {!newElement ? <AssociazioneTipiArticolo /> : null}
          {Auth.hasAccess("save-larghezzecerchi") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
