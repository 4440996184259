import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import { TextField } from "@material-ui/core";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { AccordionActions } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";

import { useAppDispatch } from "@store/hooks";
import {
  SedeOperativa,
  SediOpGoodyearCapiZona,
  SediOpGoodyearDistributori,
  SediOpGoodyearReti,
} from "@store/sedi-operative/sedeOperativa.types";
import { AnagraficaGoodyear } from "@store/anagrafica/anagrafica.types";
import { setError } from "@store/common/common.effects";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import {
  postSedeOperativa,
  putSedeOperativa,
} from "@store/sedi-operative/sedeOperativa.effects";
import { ConfirmAction } from "@components/index";
import { SelectProvince } from "@components/selects/province/SelectProvince";
import {
  SelectCapiZona,
  SelectDistributori,
  SelectReti,
} from "@components/selects";
import { SelectProgrammi } from "@components/selects/programmi/SelectProgrammi";
import Auth from "@utilities/auth";
import {
  getSedeOperativaIndex,
} from "@store/sedi-operative/sedeOperativa.effects";
import { Filter, FilterTypes, FilterMethods } from "@store/common/common.types";
import routes from "@utilities/routes";
import { dateFormatter } from '@utilities/utilities';

interface TypeSedeOperativaComponent {
  data: SedeOperativa;
  dataAnagrafica: AnagraficaGoodyear;
  create: () => void;
  handlerSetDisabledAdd: (value: boolean) => void;
  disabled: boolean;
  removeSedeOperativa: (numeroSede: number) => void;
  updateEntiSS: () => void;
}
const useStyles = makeStyles((theme) => ({
  bold: {
    color: "red",
  },
}));

export function SedeOperativaComponent(props: TypeSedeOperativaComponent) {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "anagraficaDetailsPage"]);

  const { data, create, handlerSetDisabledAdd, disabled, dataAnagrafica } =
    props;

  const [dataState, setDataState] = useState({
    ...data,
    // Default value
    directorySedeOp: "P:\\SIGESSTRF\\",
    codTipoDitta: 1,
  });
  const [enabledDateActivation, setEnabledDateActivation] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [confirmAction, setConfirmAction] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
    confirmAction: () => { },
  });

  useEffect(() => {
    setDisableButton(disabled);
  }, [disabled]);

  const handlerChangeAttributes = (e: React.ChangeEvent, id: string) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      id: string;
      value: string;
    };
    setDataState((prevState) => {
      return {
        ...prevState,
        [id]: target.value,
      };
    });
  };

  const handlerChangeCheckBox = (e: React.ChangeEvent, id: string) => {
    const target = e.target as typeof e.target & {
      id: string;
      checked: boolean;
    };
    setDataState((prevState) => {
      return {
        ...prevState,
        [id]: target.checked,
      };
    });
  };

  const handlerChangeAttRete = (e: ChangeEvent, id: string) => {
    const target = e.target as typeof e.target & {
      id: string;
      value: string;
    };

    setDataState((prevState: SedeOperativa) => {
      return {
        ...prevState,
        sediOpGoodyearReti: {
          ...dataState.sediOpGoodyearReti,
          [id]: target.value,
        },
      };
    });
  };

  const handleChangeSelect = (value: any, id: string) => {
    if (id === "codProgramma" && value === '') value = '0'
    setDataState((prevState: any) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const handleChangeRete = (value: any, id: string) => {
    let newRete: SediOpGoodyearReti = {
      codAttrGYDL: dataState?.sediOpGoodyearReti?.codAttrGYDL,
      codCapoZona: 0,
      codDitta: dataAnagrafica.codDitta,
      codInvoice: dataState?.sediOpGoodyearReti?.codInvoice || "",
      codShipTo: dataState?.sediOpGoodyearReti?.codShipTo || "",
      codRete: value,
      codSedeOperativa: data.codSedeOperativa,
      flgInvoiceShipTo: false,
      dataOperazione: null,
      tipoOperazione: "",
      utente: "",
    };

    setDataState((prevState: SedeOperativa) => {
      return {
        ...prevState,
        sediOpGoodyearReti: newRete,
      };
    });

    if (dataState?.sediOpGoodyearCapiZona) {
      setDataState((prevState: SedeOperativa) => {
        return {
          ...prevState,
          sediOpGoodyearCapiZona: {
            ...dataState.sediOpGoodyearCapiZona,
            codRete: value,
          },
        };
      });
    }
  };

  const handleChangeDistributore = (value: any, id: string) => {
    let newDistributore: SediOpGoodyearDistributori = {
      codClienteGY: value || '0', //TODO:vedere dove prendere questo dato
      codDitta: dataAnagrafica.codDitta,
      codSedeOperativa: data.codSedeOperativa,
      dataOperazione: "",
      tipoOperazione: "",
      utente: "",
    };

    setDataState((prevState: SedeOperativa) => {
      return {
        ...prevState,
        sediOpGoodyearDistributori: newDistributore,
      };
    });
  };

  const handleChangeCapoZona = (value: any, id: string) => {
    const newCapozona: SediOpGoodyearCapiZona = {
      codCapoZona: value || '0',
      codRete: dataState?.sediOpGoodyearReti?.codRete || 0,
      codDitta: dataAnagrafica.codDitta,
      codSedeOperativa: data.codSedeOperativa,
      dataOperazione: null,
      tipoOperazione: "",
      utente: "",
    };

    setDataState((prevState: any) => {
      return {
        ...prevState,
        sediOpGoodyearCapiZona: newCapozona,
      };
    });
  };

  const codDittaFilter = new Filter(
    FilterTypes.AND,
    "codDitta",
    FilterMethods.EQUAL,
    dataAnagrafica.codDitta
  );

  const save = async () => {
    setConfirmAction({
      ...confirmAction,
      isOpen: false,
    });
    setErrors({});
    if (validate()) {
      if (dataState.codSedeOperativa === 0) {
        handlerSetDisabledAdd(false);
        dispatch(postSedeOperativa(dataState))
          .unwrap()
          .then((updatedSede: SedeOperativa) => {
            setDataState(updatedSede);
            dispatch(getSedeOperativaIndex(1, 999, { codDittaFilter }));
          });
      } else {
        dispatch(putSedeOperativa(dataState))
          .unwrap()
          .then((updatedSede: SedeOperativa) => {
            setDataState(updatedSede);
            dispatch(getSedeOperativaIndex(1, 999, { codDittaFilter }));
          });
        props.updateEntiSS();
      }
    } else {
      dispatch(setError({ message: t("common:message:validationError") }));
    }
  };

  //test RM this

  const addSedeOperativa = () => {
    create();
    handlerSetDisabledAdd(true);
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("indirizzo", dataState?.indirizzo, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("numCivico", dataState?.numCivico, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "cap",
        dataState?.cap,
        [ValidationTypes.REQUIRED, ValidationTypes.FIXED_LENGTH],
        { length: 5 }
      ),
      Validation.buildFieldConfig("localita", dataState?.localita, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("provincia", dataState?.provincia, [
        ValidationTypes.REQUIRED,
      ]),
      // Validation.buildFieldConfig("codProgramma", dataState?.codProgramma, [
      //   ValidationTypes.REQUIRED,
      // ]),
      // Validation.buildFieldConfig('telefono1', dataState?.telefono1, [ValidationTypes.REQUIRED]),
      //Validation.buildFieldConfig('telefono2', dataState?.telefono2, [ValidationTypes.REQUIRED]),
      //Validation.buildFieldConfig('fax1', dataState?.fax1, [ValidationTypes.REQUIRED]),
      Validation.buildFieldConfig("userIdTrf", dataState?.userIdTrf, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "emailSedeOpFrom",
        dataState?.emailSedeOpFrom,
        [ValidationTypes.EMAIL]
      ),
      Validation.buildFieldConfig("emailSedeOpTo", dataState?.emailSedeOpTo, [
        ValidationTypes.EMAIL,
      ]),
      // Validation.buildFieldConfig('emailSMTP', dataState?.emailSMTP, [ValidationTypes.EMAIL]),
      // Validation.buildFieldConfig('emailPOP3', dataState?.emailPOP3, [ValidationTypes.EMAIL]),
      Validation.buildFieldConfig(
        "codRete",
        dataState?.sediOpGoodyearReti?.codRete,
        [ValidationTypes.REQUIRED]
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  return (
    <div>
      <Box className="p-2 pb-8">
        <form>
          <Box>
            <div className="flex justify-end space-x-4 px-2">
              {!Auth.hasAccess("view-helpdesk") ? null :
                 <Button
                 size="small"
                 disabled={disableButton}
                 variant="outlined"onClick={() => {
                  dispatch(push(routes.tata.replace(":id", dataState.codDitta).replace(":codSedeOp", dataState.codSedeOperativa.toString())));
                }}>
                  {t("common:helpDesk")}
                </Button>
              }
              {dataState?.codSedeOperativa !== 1 ||
                !Auth.hasAccess("save-anagrafiche") ? null : (

                <Tooltip
                  title={!disableButton ? "" : "form gia aperta "}
                  aria-label="add"
                >
                  <span>
                    <Button
                      size="small"
                      disabled={disableButton}
                      variant="outlined"
                      onClick={addSedeOperativa}
                    >
                      {t("anagraficaDetailsPage:addSedeOperativa")}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </div>

          </Box>
          <Box className="flex flex-wrap">
            <Box className="w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:userId")}
                size="small"
                id="codDitta"
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={dataState?.userIdTrf || ""}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-4/12 md:w-6/12 px-2">
              <SelectReti
                id={"codRete"}
                onChange={handleChangeRete}
                value={dataState?.sediOpGoodyearReti?.codRete || ""}
                error={errors["codRete"] ? true : false}
                helperText={errors["codRete"]}
                InputProps={
                  dataState?.sediOpGoodyearReti?.codRete === 99
                    ? {
                      className: classes.bold,
                    }
                    : {}
                }
              />
            </Box>
          </Box>
          <Box className="flex flex-wrap">
            <Box className="w-full 2xl:w-4/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                required
                label={t("common:indirizzo")}
                error={errors["indirizzo"] ? true : false}
                helperText={errors["indirizzo"]}
                size="small"
                type="text"
                value={dataState?.indirizzo || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "indirizzo");
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-1/12 xl:w-1/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:numCivico")}
                size="small"
                type="text"
                error={errors["numCivico"] ? true : false}
                helperText={errors["numCivico"]}
                value={dataState?.numCivico || ""}
                required={true}
                onChange={(e) => {
                  handlerChangeAttributes(e, "numCivico");
                }}
                inputProps={{
                  maxLength: 15,
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:cap")}
                size="small"
                error={errors["cap"] ? true : false}
                helperText={errors["cap"]}
                value={dataState?.cap || ""}
                type="number"
                onChange={(e) => {
                  handlerChangeAttributes(e, "cap");
                }}
                inputProps={{ min: 4, max: 10 }}
                required={true}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:localita")}
                size="small"
                type="text"
                error={errors["localita"] ? true : false}
                helperText={errors["localita"]}
                value={dataState?.localita || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "localita");
                }}
                inputProps={{
                  maxLength: 50,
                }}
                required={true}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <SelectProvince
                id={"provincia"}
                onChange={handleChangeSelect}
                value={dataState?.provincia || ""}
                error={errors["provincia"] ? true : false}
                helperText={errors["provincia"]}
              />
            </Box>
          </Box>
          <Box className="flex flex-wrap">
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:latitudine")}
                size="small"
                type="number"
                error={errors["latitudine"] ? true : false}
                helperText={errors["latitudine"]}
                value={dataState?.latitudine || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "latitudine");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:longitudine")}
                size="small"
                type="number"
                error={errors["longitudine"] ? true : false}
                helperText={errors["longitudine"]}
                value={dataState?.longitudine || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "longitudine");
                }}
              />
            </Box>
          </Box>
          <Box className="flex flex-wrap">
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:telefono1")}
                size="small"
                error={errors["telefono1"] ? true : false}
                helperText={errors["telefono1"]}
                type="text"
                value={dataState?.telefono1 || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "telefono1");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:telefono2")}
                size="small"
                error={errors["telefono2"] ? true : false}
                helperText={errors["telefono2"]}
                type="text"
                value={dataState?.telefono2 || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "telefono2");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:fax1")}
                size="small"
                type="text"
                error={errors["fax1"] ? true : false}
                helperText={errors["fax1"]}
                value={dataState?.fax1 || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "fax1");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <SelectProgrammi
                id={"codProgramma"}
                onChange={handleChangeSelect}
                value={dataState?.codProgramma}
                error={errors["codProgramma"] ? true : false}
                helperText={errors["codProgramma"]}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:responsabile")}
                size="small"
                type="text"
                error={errors["responsabile"] ? true : false}
                helperText={errors["responsabile"]}
                value={dataState?.responsabile || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "responsabile");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <SelectCapiZona
                id={"codCapoZona"}
                onChange={handleChangeCapoZona}
                value={dataState?.sediOpGoodyearCapiZona?.codCapoZona || 0}
                error={errors["codCapoZona"] ? true : false}
                helperText={errors["codCapoZona"]}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:codShipTo")}
                size="small"
                type="text"
                value={dataState?.sediOpGoodyearReti?.codShipTo || ""}
                onChange={(e) => {
                  handlerChangeAttRete(e, "codShipTo");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:codAttrGYDL")}
                size="small"
                type="text"
                value={dataState?.sediOpGoodyearReti?.codAttrGYDL || ""}
                onChange={(e) => {
                  handlerChangeAttRete(e, "codAttrGYDL");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:codInvoice")}
                size="small"
                type="text"
                value={dataState?.sediOpGoodyearReti?.codInvoice || ""}
                onChange={(e) => {
                  handlerChangeAttRete(e, "codInvoice");
                }}
                disabled={dataState?.codSedeOperativa === 0 ? false : true}
              />
            </Box>

            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <SelectDistributori
                id={"codClienteGY"}
                onChange={handleChangeDistributore}
                value={
                  dataState?.sediOpGoodyearDistributori?.codClienteGY || ""
                }
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:emailFrom")}
                size="small"
                type="email"
                error={errors["emailSedeOpFrom"] ? true : false}
                helperText={errors["emailSedeOpFrom"]}
                value={dataState?.emailSedeOpFrom || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "emailSedeOpFrom");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:emailTo")}
                size="small"
                type="email"
                error={errors["emailSedeOpTo"] ? true : false}
                helperText={errors["emailSedeOpTo"]}
                value={dataState?.emailSedeOpTo || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "emailSedeOpTo");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:emailSMTP")}
                size="small"
                type="email"
                error={errors["emailSMTP"] ? true : false}
                helperText={errors["emailSMTP"]}
                value={dataState?.emailSMTP || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "emailSMTP");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:emailPOP3")}
                size="small"
                type="email"
                error={errors["emailPOP3"] ? true : false}
                helperText={errors["emailPOP3"]}
                value={dataState?.emailPOP3 || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "emailPOP3");
                }}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <TextField
                fullWidth
                label={t("common:note")}
                size="small"
                type="text"
                value={dataState?.note || ""}
                onChange={(e) => {
                  handlerChangeAttributes(e, "note");
                }}
              />
            </Box>
            <Box className='w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 px-2'>             
              <TextField
                    fullWidth
                    label={t('common:dataAttivazioneNegozio')}
                    size='small'
                    type='date'                 
                    disabled={!enabledDateActivation}
                    id='dataAttivazioneNegozio'
                    value={dataState?.dataAttivazioneNegozio ? dateFormatter(new Date(dataState?.dataAttivazioneNegozio)) : ''}
                    onChange={(e) => {
                      handlerChangeAttributes(e, "dataAttivazioneNegozio");
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>
            <Box className="w-full 2xl:w-1/12 xl:w-1/12 md:w-6/12 px-2">
              <Checkbox
                  checked={enabledDateActivation}
                  onChange={(event: any) => {
                    setEnabledDateActivation(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
             </Box>         
          </Box>
          <Box className="flex flex-wrap">
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <FormControlLabel
                className="w-full"
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={dataState?.flgOfficinaMobile}
                    disableRipple={true}
                    onChange={(e) => {
                      handlerChangeCheckBox(e, "flgOfficinaMobile");
                    }}
                  />
                }
                label={t("common:flgOfficinaMobile")}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <FormControlLabel
                className="w-full"
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={dataState?.flgPrenotazioneOnLine}
                    disableRipple={true}
                    onChange={(e) => {
                      handlerChangeCheckBox(e, "flgPrenotazioneOnLine");
                    }}
                  />
                }
                label={t("common:flgPrenotazioneOnLine")}
              />
            </Box>
            <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
              <FormControlLabel
                className="w-full"
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={dataState?.flgForFleet}
                    disableRipple={true}
                    onChange={(e) => {
                      handlerChangeCheckBox(e, "flgForFleet");
                    }}
                  />
                }
                label={t("common:flgForFleet")}
              />
            </Box>
          </Box>
        </form>
        {Auth.hasAccess("save-anagrafiche") ? (
          <AccordionActions>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setConfirmAction({
                  isOpen: true,
                  title: "Salvare",
                  subtitle: "Salvare le modifiche effetuate?",
                  confirmAction: () => {
                    save();
                  },
                });
              }}
            >
              {t("common:save")}
            </Button>
          </AccordionActions>
        ) : null}
      </Box>
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
    </div>
  );
}
