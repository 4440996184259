import { createReducer } from '@reduxjs/toolkit'
import {
    setArticoliIndex,
    setArticoliShow,
    resetArticoliShow,
    resetArticoliIndex,
    setRicercaArticoli,
    resetRicercaArticoli,
    setLastInsertedArticle,
    resetLastInsertedArticle
} from '@store/articoli/articoli.actions'
import { Articoli, ArticoliIndex, ArticoliShow } from './articoli.types'

const initialState: {
    index: ArticoliIndex,
    show: ArticoliShow,
    ricercaArticoli: ArticoliIndex,
    lastInsertedArticle: Articoli | undefined
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined },
    ricercaArticoli: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    lastInsertedArticle: {} as Articoli
}

export const articoliReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setArticoliIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setArticoliShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetArticoliShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
        .addCase(resetArticoliIndex, (state, action) => {
            return {
                ...state,
                index: {
                    data: [],
                    meta: {
                        pagination: undefined
                    }
                }
            }
        })
        .addCase(setRicercaArticoli, (state, action) => {
            return {
                ...state,
                ricercaArticoli: action.payload
            }
        })
        .addCase(resetRicercaArticoli, (state, action) => {
            return {
                ...state,
                ricercaArticoli: {
                    data: [],
                    meta: {
                        pagination: undefined
                    }
                }
            }
        })
        .addCase(setLastInsertedArticle, (state, action) => {
            return {
                ...state,
                lastInsertedArticle: action.payload
            }
        })
        .addCase(resetLastInsertedArticle, (state, action) => {
            return {
                ...state,
                lastInsertedArticle: undefined
            }
        })
})

