import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getScontoAddizionaleShow,
  postScontoAddizionale,
  putScontoAddizionale,
} from "@store/sconto-addizionale/scontoAddizionale.effects";
import { ScontoAddizionale, NewScontoAddizionale } from "@store/sconto-addizionale/scontoAddizionale.type";
import { scontoAddizionaleShow } from "@store/sconto-addizionale/scontoAddizionale.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetScontoAddizionaleShow } from "@store/sconto-addizionale/scontoAddizionale.actions";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";
import moment from "moment";
import { setWarning } from "@store/common/common.effects";

interface ScontoAddizionaleDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function ScontoAddizionaleDetail({
  match,
  creationMode,
}: ScontoAddizionaleDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "scontoAddizionaleDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingScontoAddizionale = useAppSelector(scontoAddizionaleShow);
  const [scontoAddizionale, setScontoAddizionale] = useState<ScontoAddizionale | NewScontoAddizionale>({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getScontoAddizionaleShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingScontoAddizionale) {
      setNewElement(false);
      setScontoAddizionale(pollingScontoAddizionale);
    }
  }, [pollingScontoAddizionale]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetScontoAddizionaleShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    const target = e.target as typeof e.target & { value: string };
    updateValue(target.value, param);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setScontoAddizionale((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("descrizione", scontoAddizionale?.descrizione, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig("sconto", scontoAddizionale?.sconto, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "dataInizioValidita",
        scontoAddizionale.dataInizioValidita
          ? scontoAddizionale.dataInizioValidita
          : undefined,
        [ValidationTypes.REQUIRED]
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (! moment(scontoAddizionale.dataInizioValidita).isAfter(moment())) {
      dispatch(setWarning({ message: "La data inizio validità selezionata deve essere maggiore della data odierna" }));
      return;
    }

    if (
      scontoAddizionale.dataInizioValidita !== undefined && scontoAddizionale.dataFineValidita !== undefined &&
      moment(scontoAddizionale.dataInizioValidita) >
        moment(scontoAddizionale.dataFineValidita)
    ) {
      dispatch(setWarning({ message: "La data fine validità deve essere maggiore della data inzio validità", }));
      return;
    };

    if (!validate() || !scontoAddizionale) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement && scontoAddizionale) {
      const newScontoAddizionale: NewScontoAddizionale = {
        ...scontoAddizionale,
        id: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(postScontoAddizionale(newScontoAddizionale));
      if (postScontoAddizionale.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(routes.scontoAddizionale.show.replace(":id", result.id.toString()))
        );
      } else {
        //error
      }
    } else {
      dispatch(putScontoAddizionale(scontoAddizionale as ScontoAddizionale));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getScontoAddizionaleShow(id));
    } else {
      setScontoAddizionale({});
    }
  };

  return (
    <div>
      {scontoAddizionale?.id || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("scontoAddizionaleDetailPage:newElementTitle")}`
                : `${t("scontoAddizionaleDetailPage:title")} - ${
                    scontoAddizionale.descrizione
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!scontoAddizionale?.id ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:id")}
                    onChange={(e) => handleChangeValue(e, "id")}
                    value={scontoAddizionale?.id || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")}*`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValue(e, "descrizione")}
                  value={scontoAddizionale?.descrizione || ""}
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:sconto")}*`}
                  error={errors["sconto"] ? true : false}
                  helperText={errors["sconto"] || ""}
                  onChange={(e) => handleChangeValue(e, "sconto")}
                  value={scontoAddizionale?.sconto?.toString() || ""}
                  type="number"
                />     
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  type="date"
                  InputLabelProps={{ className: "pl-4, mx-4", shrink: true }}
                  label={t("common:dataInizioValidita")}
                  error={errors["dataInizioValidita"] ? true : false}
                  helperText={errors["dataInizioValidita"] || ""}
                  onChange={(e) => handleChangeValue(e, "dataInizioValidita")}
                  value={
                    scontoAddizionale?.dataInizioValidita
                      ? moment(scontoAddizionale?.dataInizioValidita).format("YYYY-MM-DD")
                      : ""
                  }
                />
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  type="date"
                  InputLabelProps={{ className: "pl-4, mx-4", shrink: true }}
                  label={t("common:dataFineValidita")}
                  error={errors["dataFineValidita"] ? true : false}
                  helperText={errors["dataFineValidita"] || ""}
                  onChange={(e) => handleChangeValue(e, "dataFineValidita")}
                  value={
                    scontoAddizionale?.dataFineValidita
                      ? moment(scontoAddizionale?.dataFineValidita).format("YYYY-MM-DD")
                      : ""
                  }
                />         
              </Box>
            </Paper>
            {Auth.hasAccess("save-scontoaddizionale") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
