import { createAction } from '@reduxjs/toolkit';
import { User } from './auth.types'

export const loginRequest = createAction('auth/loginRequest')
export const loginSuccess = createAction<User>('auth/loginSuccess')

export const logoutRequest = createAction('auth/logoutRequest')
export const logoutSuccess = createAction('auth/logoutSuccess')

export const setAuthError = createAction('auth/error')
export const resetAuthError = createAction('auth/resetError')


