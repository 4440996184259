import { createReducer } from '@reduxjs/toolkit'
import { Pagination } from '@store/common/common.types'
import {
    setDistributoriIndex,
    setDistributoreShow
} from './distributori.actions'
import { Distributori } from './distributori.types'

const initialState: {
    index: {
        data: Distributori[]
        meta: {
            pagination: Pagination | undefined
        }
    },
    show: {
        data: Distributori | undefined
    }
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const disctributoriReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setDistributoriIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setDistributoreShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
})

