import React, { Fragment, useEffect, useState } from "react";
import {
  CustomPaginatedDataGrid,
  CustomHeaderPage,
  ArticlesFilters,
  ConfirmAction,
  ConfirmActionPropsData,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef, GridEditCellPropsParams } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import { useIndexPage } from "@pages/common/UseIndexPage";
import moment from "moment";
import { getDefaultArticlesFilter } from "@components/articles-filters/defaultArticleFilters";
import {
  SelectCategorieMerceologiche,
  SelectLivelliListino,
  SelectProduttori,
  SelectStagionalita,
  SelectTipiArticolo,
} from "@components/selects";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { listiniDate, listiniIndex } from "@store/listini/listini.selector";
import {
  aggiornaIva,
  aggiornaLisiniFuturi,
  duplicateListini,
  getCronologiaListino,
  getDateListini,
  getListiniIndex,
  putListini,
} from "@store/listini/listini.effects";
import {
  DuplicaListini,
  ListiniIndex,
  Listino,
  ListinoHistory,
} from "@store/listini/listini.types";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import environment from "@environment/environment";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { CustomSelect } from "@components/selects/custom-select/CustomSelect";
import { setError } from "@store/common/common.effects";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CronologiaListinoDialog } from "../components/CronologiaListinoDialog";
import {
  FileCopyOutlined,
  HistoryOutlined,
  SystemUpdateAlt,
  UpdateOutlined,
} from "@material-ui/icons";
import { DuplicaListiniDialog } from "../components/DuplicaListiniDialog";
import { AggiornaIvaDialog } from "../components/AggiornaIvaDialog";
import { resetListiniIndex } from "@store/listini/listini.actions";
import _ from "lodash";
import {
  removeRequestLoader,
  setRequestLoader,
} from "@store/common/common.actions";
import Auth from "@utilities/auth";

export function AnagraficaListini() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["anagraficaListiniPage", "common"]);

  const initialFilter = Object.assign(getDefaultArticlesFilter("1", "false"), {
    listinoType: new FieldFilter(
      "listinoType",
      "",
      FilterTypes.AND,
      "listinoType",
      FilterMethods.EQUAL,
      "clienti",
      false,
      true
    ),
    listinoPeriod: new FieldFilter(
      "listinoPeriod",
      "",
      FilterTypes.AND,
      "listinoPeriod",
      FilterMethods.EQUAL,
      "corrente",
      false,
      true
    ),
    listinoDate: new FieldFilter(
      "listinoDate",
      "",
      FilterTypes.AND,
      "dataInizioValidita",
      FilterMethods.EQUAL,
      ""
    ),
    livelloListino: new FieldFilter(
      "livelloListino",
      t(`common:livelloListino`),
      FilterTypes.AND,
      "livelloListino",
      FilterMethods.EQUAL,
      ""
    ),
  });

  const {
    data,
    setData,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
    handleCheckBoxChangeValue,
    updateFilters,
  } = useIndexPage<Listino>(
    initialFilter,
    [{ field: "codArticolo", sort: "asc" }],
    getListiniIndex,
    listiniIndex
  );

  const dateListini = useAppSelector(listiniDate);

  const [listini, setListini] = useState<ListiniIndex>({} as ListiniIndex);
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [editEnabled, setEditEnabled] = useState(true);

  const [cronologiaListinoType, setCronologiaListinoType] =
    useState<string>("clienti");
  const [cronologiaListino, setCronologiaListino] = useState<ListinoHistory[]>(
    []
  );
  const [dialogCronologiaOpen, setDialogCronologiaOpen] =
    useState<boolean>(false);
  const [dialogDuplicaListiniOpen, setDialogDuplicaListiniOpen] =
    useState<boolean>(false);
  const [duplicateErrors, setDuplicateErrors] = useState(
    {} as { [key: string]: string }
  );
  const [dialogAggiornaIvaOpen, setDialogAggiornaIvaOpen] =
    useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  useEffect(() => {
    dispatch(
      getDateListini({
        livelloListino: filters.filterFields.livelloListino,
        listinoPeriod: filters.filterFields.listinoPeriod,
        listinoType: filters.filterFields.listinoType,
        produttore: filters.filterFields.produttore,
        stagionalita: filters.filterFields.stagionalita,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.filterFields.livelloListino.value,
    filters.filterFields.listinoPeriod.value,
    filters.filterFields.listinoType.value,
    filters.filterFields.produttore.value,
    filters.filterFields.stagionalita.value,
  ]);

  useEffect(() => {
    // let nearestDate: moment.MomentInput
    if (dateListini.length === 1) {
      updateFilters(dateListini[0], "listinoDate");
    } else {
      let nearestDate: moment.MomentInput;
      _.forEach(dateListini, function (value) {
        let diff = moment(value)
          .startOf("day")
          .diff(moment().startOf("day"), "days");
        if (diff <= 0) {
          nearestDate = value;
          return false;
        }
      });
      updateFilters(nearestDate, "listinoDate");
    }
  }, [dateListini]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filters.filterFields.listinoPeriod.value !== "storico") {
      updateFilters("", filters.filterFields.listinoDate.id);
    }
  }, [filters.filterFields.listinoPeriod.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setListini(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(resetListiniIndex());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validate = () => {
    setErrors({});

    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "livelloListino",
        filters.filterFields?.livelloListino?.value,
        [ValidationTypes.REQUIRED]
      ),
    ];

    if (filters.filterFields?.divisione.value === "1") {
      fieldsConfig.push(
        Validation.buildFieldConfig(
          "produttore",
          filters.filterFields?.produttore?.value,
          [ValidationTypes.REQUIRED]
        )
      );
    }

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleFilterSubmit = (e?: React.SyntheticEvent): void => {
    if (e?.preventDefault) {
      e?.preventDefault();
    }

    if (!validate()) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    let currentActiveFilter: { [key: string]: FieldFilter } = {};
    const _filters = Object.entries(filters.filterFields);
    _filters.forEach(([key, field]) => {
      if (field.value === "") return;

      if (field.alwaysActive) {
        // Controllo se esiste un nuovo filtro per questo parametro
        const overrideFilter = _filters.find(
          ([key, filter]) =>
            filter.param === field.param && !filter.alwaysActive
        );
        if (overrideFilter && overrideFilter[1].value.toString() !== "") return;
      }

      currentActiveFilter[key] = field;
    });

    setPage(1);

    setFilters((prevState) => {
      return {
        ...prevState,
        activeFilters: currentActiveFilter,
      };
    });

    // Modifica attiva solo per listini correnti e futuri
    setEditEnabled(filters.filterFields.listinoPeriod.value !== "storico");
    setCronologiaListinoType(filters.filterFields.listinoType.value);
  };

  /**
   * If id is specified, only that filter is reset
   */
  const handleFilterReset = (e?: React.SyntheticEvent, id?: string): void => {
    e?.preventDefault();

    let currentFilterFields = filters.filterFields;
    Object.entries(currentFilterFields).forEach(([key, value]) => {
      if (id && id === value.id && !value.unDeletable)
        currentFilterFields[key].value = "";
      else if (!id && !value.alwaysActive && !value.unDeletable)
        currentFilterFields[key].value = "";
    });

    setFilters((prevState) => {
      return {
        ...prevState,
        filterFields: currentFilterFields,
        activeFilters: {},
      };
    });
  };

  const handleEditCellChangeCommitted = (
    { id, field, props }: GridEditCellPropsParams,
    event?: React.SyntheticEvent<Element, Event> | undefined
  ) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    const ids = (id as string).split("||");
    console.log(ids);
    let rowIndex = listini.data.findIndex((item) => {
      return (
        item.livelloListino === Number(ids[0]) &&
        item.codProduttore === Number(ids[1]) &&
        item.codArticolo === ids[2] &&
        item.dataInizioValidita === ids[3]
      );
    });
    if (rowIndex !== -1) {
      let tempListini = [...listini.data];
      const row = { ...tempListini[rowIndex] };
      if (field === "dataInizioValidita") {
        row[field] = moment(props.value as string).format(
          "YYYY-MM-DD 00:00:00"
        );
      } else {
        row[field] = props.value;
      }
      if (field === "prezzoEuro") {
        row.prezzoEuroConIVA =
          Number(props.value) +
          Number(props.value) * (environment.defaultCausaleIva.aliquota / 100);
      }
      tempListini[rowIndex] = row;

      setListini((prevState) => {
        return {
          ...prevState,
          data: [...tempListini],
        };
      });
    }
  };

  const handleSave = () => {
    dispatch(
      putListini(
        listini.data,
        filters.activeFilters.listinoType.value,
        filters.activeFilters.listinoPeriod.value
      )
    );
  };

  const handleDuplicate = (duplicaListini: DuplicaListini) => {
    setDuplicateErrors({});
    if (
      duplicaListini.livelloListino ===
      Number(filters.activeFilters.livelloListino.value)
    ) {
      setDuplicateErrors({
        livelloListino: t("common:message:selectDifferentLivelloListino"),
      });
    } else {
      setDialogDuplicaListiniOpen(false);
      setConfirmAction({
        isOpen: true,
        title: t("common:message:defaultConfirmationTitle"),
        subtitle: t("common:message:defaultConfirmationMessage"),
        confirmAction: () => {
          dispatch(
            duplicateListini({
              duplicaListini: duplicaListini,
              filters: filters.activeFilters,
            })
          )
            .unwrap()
            .then(() => {
              setConfirmAction({
                isOpen: true,
                title: t("common:message:showDuplicateListini"),
                subtitle: t("common:message:showDuplicateListiniMessage"),
                confirmAction: () => {
                  updateFilters(
                    duplicaListini.livelloListino,
                    "livelloListino"
                  );
                  dispatch(setRequestLoader("loadingDate"));
                  // Ottimizzabile
                  setTimeout((_: any) => {
                    dispatch(removeRequestLoader("loadingDate"));
                    handleFilterSubmit();
                  }, 1000);
                },
              });
            })
            .catch(() => {
              setDialogDuplicaListiniOpen(true);
            });
        },
        cancelAction: () => {
          setDialogDuplicaListiniOpen(true);
        },
      });
    }
  };

  const handleAggiornaIva = (aliquota: number, dataInizioValidita: string) => {
    setDialogAggiornaIvaOpen(false);
    setConfirmAction({
      isOpen: true,
      title: t("common:message:defaultConfirmationTitle"),
      subtitle: t("common:message:defaultConfirmationMessage"),
      confirmAction: () => {
        dispatch(
          aggiornaIva({
            dataInizioValidita: dataInizioValidita,
            aliquotaIva: aliquota,
          })
        );
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "livListino",
      headerName: t("common:livelloListino"),
      width: 200,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      valueGetter: (params) => {
        return params.row.livListino?.descrizione || "";
      },
    },
    {
      field: "codArticolo",
      headerName: t("common:codArticolo"),
      width: 150,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      valueGetter: (params) => {
        return params.row.codArticolo;
      },
    },
    {
      field: "description",
      headerName: t("common:descrizione"),
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      valueGetter: (params) => {
        return (
          params.row.articolo?.descrizione || t("common:anagraficaMancante")
        );
      },
    },
    {
      field: "dataInizioValidita",
      headerName: t("common:dataInizioValidita"),
      width: 150,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      editable: Auth.hasAccess("save-listini") && editEnabled,
      type: "date",
      valueFormatter: ({ value }) => {
        return moment(new Date(value as string)).format("DD/MM/YYYY");
      },
    },
    {
      field: "prezzoEuro",
      headerName: t("common:prezzoEuro"),
      width: 150,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      editable: Auth.hasAccess("save-listini") && editEnabled,
      type: "number",
      valueFormatter: ({ value }) => {
        return Number(value).toFixed(2);
      },
    },
    {
      field: "prezzoEuroConIVA",
      headerName: t("common:prezzoEuroConIVA"),
      width: 150,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      type: "number",
      valueFormatter: ({ value }) => {
        return Number(value).toFixed(2);
      },
    },
    {
      field: "history",
      headerName: t("common:priceListHistory"),
      headerAlign: "center",
      align: "center",
      width: 150,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          dispatch(
            getCronologiaListino({
              livelloListino: Number(filters.filterFields.livelloListino.value),
              codArticolo: params.row.codArticolo,
              type: cronologiaListinoType,
            })
          )
            .unwrap()
            .then((res: any) => {
              setCronologiaListino(res.data as ListinoHistory[]);
              setDialogCronologiaOpen(true);
            });
        };

        return (
          <IconButton color="primary" size="small" onClick={onClick}>
            <HistoryOutlined />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Fragment>
      <CustomHeaderPage title={`${t("anagraficaListiniPage:title")}`} />
      <Box className="mb-4">
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="pl-2">
              {t("common:filtriListini")}
            </Typography>
          </AccordionSummary>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            className="p-4 pt-0"
          >
            <Box className="px-2 w-full">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="listinoType"
                  name="controlled-radio-buttons-group"
                  row
                  value={filters.filterFields.listinoType?.value}
                  onChange={(event: any) => {
                    handleTextFieldChangeValue(
                      event,
                      filters.filterFields.listinoType?.id
                    );
                  }}
                >
                  <FormControlLabel
                    value="clienti"
                    control={<Radio />}
                    label={t("common:clienti")}
                  />
                  <FormControlLabel
                    value="fornitori"
                    control={<Radio />}
                    label={t("common:fornitori")}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
              <SelectLivelliListino
                id="livelloListino"
                onChange={handleSelectChangeValue}
                value={filters.filterFields.livelloListino.value}
                error={errors["livelloListino"] ? true : false}
                helperText={errors["livelloListino"]}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              className="w-full"
            >
              <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                <SelectCategorieMerceologiche
                  id={filters.filterFields.categoriaMerceologica?.id}
                  onChange={handleSelectChangeValue}
                  value={filters.filterFields.categoriaMerceologica?.value}
                  codDivisione={filters.filterFields?.divisione?.value}
                />
              </Box>
              <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                <SelectTipiArticolo
                  id={filters.filterFields.tipoArticolo?.id}
                  onChange={handleSelectChangeValue}
                  value={filters.filterFields.tipoArticolo?.value}
                  codDivisione={filters.filterFields?.divisione?.value}
                />
              </Box>
              <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                <SelectProduttori
                  id={filters.filterFields.produttore?.id}
                  onChange={handleSelectChangeValue}
                  value={filters.filterFields.produttore?.value}
                  error={errors?.produttore ? true : false}
                  helperText={errors?.produttore}
                />
              </Box>
              <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                <SelectStagionalita
                  id={filters.filterFields.stagionalita?.id}
                  onChange={handleSelectChangeValue}
                  value={filters.filterFields.stagionalita?.value}
                />
              </Box>
            </Box>
            <Box className="px-2 w-full">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="listinoPeriod"
                  name="controlled-radio-buttons-group"
                  row
                  value={filters.filterFields.listinoPeriod?.value}
                  onChange={(event: any) => {
                    handleTextFieldChangeValue(
                      event,
                      filters.filterFields.listinoPeriod?.id
                    );
                  }}
                >
                  <FormControlLabel
                    value="storico"
                    control={<Radio />}
                    label={t("common:storico")}
                  />
                  <FormControlLabel
                    value="corrente"
                    control={<Radio />}
                    label={t("common:corrente")}
                  />
                  <FormControlLabel
                    value="futuro"
                    control={<Radio />}
                    label={t("common:futuro")}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
              <CustomSelect
                id={filters.filterFields.listinoDate.id}
                value={filters.filterFields.listinoDate.value}
                label={t("common:dataInizioValidita")}
                data={dateListini.map((date) => {
                  return {
                    id: date,
                    description: moment(date).format("DD/MM/YYYY"),
                  };
                })}
                descriptionKey="description"
                valueKey="id"
                onChange={handleSelectChangeValue}
                // disabled={filters.filterFields.listinoPeriod.value !== 'storico'}
              />
            </Box>
          </Box>
        </Accordion>
      </Box>
      <Box className="mb-4">
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="pl-2">
              {t("common:ricercaArticoli")}
            </Typography>
          </AccordionSummary>
          <ArticlesFilters
            filters={filters}
            onSelectChangeValue={handleSelectChangeValue}
            onTextFieldChangeValue={handleTextFieldChangeValue}
            onCheckBoxChangeValue={handleCheckBoxChangeValue}
            errors={errors}
            hiddenDivisions={["2", "3"]}
            disabledFields={[
              "categoriaMerceologica",
              "tipoArticolo",
              "produttore",
              "stagionalita",
            ]}
          />
        </Accordion>
        <Box className="w-full flex justify-end px-4">
          <Button
            variant="outlined"
            className="m-4"
            type="reset"
            onClick={() => {
              handleFilterReset();
            }}
          >
            {t("common:resetFilters")}
          </Button>
          <Button className="my-4" type="submit" onClick={handleFilterSubmit}>
            {t("common:search")}
          </Button>
        </Box>
      </Box>
      <Paper>
        <CustomPaginatedDataGrid
          data={listini}
          columns={columns}
          getRowId={(row) => {
            return `${row.livelloListino}||${row.codProduttore}||${row.codArticolo}||${row.dataInizioValidita}`;
          }}
          sortColumns={sortColumns}
          onChangePageSize={setPageSize}
          onChangeSortColumns={setSortColumns}
          onChangePage={setPage}
          onEditCellChangeCommitted={handleEditCellChangeCommitted}
        />
      </Paper>
      <CommonSpeedDial
        onSave={
          Auth.hasAccess("save-listini") &&
          editEnabled &&
          listini &&
          listini?.data?.length > 0
            ? handleSave
            : undefined
        }
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .listinoClientiStorico,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .listinoFornitori,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .listinoClienti,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .listinoFornitoriStorico,
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .livelloListino,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.centralDatabaseUpdates.entities
                .listini,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.areaRiservataUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .listino,
            ],
          },
        ]}
        otherActions={
          Auth.hasAccess("save-listini")
            ? [
                {
                  icon: <FileCopyOutlined />,
                  tooltip: t("common:duplicateListini"),
                  onClick: () => {
                    setDialogDuplicaListiniOpen(true);
                  },
                  hidden: !(
                    editEnabled &&
                    listini &&
                    listini?.data?.length > 0
                  ),
                },
                {
                  icon: <UpdateOutlined />,
                  tooltip: t("common:aggiornaIVA"),
                  onClick: () => {
                    setDialogAggiornaIvaOpen(true);
                  },
                },
                {
                  icon: <SystemUpdateAlt />,
                  tooltip: t("common:aggiornaListiniFuturi"),
                  onClick: () => {
                    setConfirmAction({
                      isOpen: true,
                      title: t("common:message:defaultConfirmationTitle"),
                      subtitle: t("common:message:defaultConfirmationMessage"),
                      confirmAction: async () => {
                        const res = await dispatch(aggiornaLisiniFuturi());
                        if (res.payload) {
                          updateFilters("corrente", "listinoPeriod");
                          setData({
                            data: [],
                            meta: {
                              pagination: undefined,
                            },
                          });
                        }
                      },
                    });
                  },
                  hidden: filters.filterFields.listinoPeriod.value !== "futuro",
                },
              ]
            : []
        }
      />
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
      <DuplicaListiniDialog
        open={dialogDuplicaListiniOpen}
        setOpen={setDialogDuplicaListiniOpen}
        onDuplicaListini={handleDuplicate}
        errors={duplicateErrors}
      />
      <CronologiaListinoDialog
        open={dialogCronologiaOpen}
        setOpen={setDialogCronologiaOpen}
        listini={cronologiaListino}
      />
      <AggiornaIvaDialog
        open={dialogAggiornaIvaOpen}
        setOpen={setDialogAggiornaIvaOpen}
        onAggiornaIva={handleAggiornaIva}
      />
    </Fragment>
  );
}
