import { createReducer } from '@reduxjs/toolkit'
import {
    setEntiConvenzionatiSSIndex,
    setEntiConvenzionatiSSShow
} from './entiConvenzioniSS.actions'
import { EntiConvenzionatiSSIndex, EntiConvenzionatiSSShow } from './entiConvenzioniSS.types'

const initialState: {
    index: EntiConvenzionatiSSIndex,
    show: EntiConvenzionatiSSShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const entiConvenzionatiSSReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setEntiConvenzionatiSSIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setEntiConvenzionatiSSShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
})

