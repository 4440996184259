import { createReducer } from '@reduxjs/toolkit'
import { Common } from './common.types'
import {
    toggleSidebar,
    openSidebar,
    closeSidebar,
    setCustomAlert,
    closeCustomAlert,
    setRequestLoader,
    removeRequestLoader
} from './common.actions'

const initialCustomAlert = {
    severity: undefined,
    title: '',
    message: ''
}

const initialState: Common = {
    sidebar: {
        isOpen: true
    },
    customAlert: {
        isOpen: false,
        payload: initialCustomAlert
    },
    loader: []
}

export const commonReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(toggleSidebar, (state, action) => {
            return {
                ...state,
                sidebar: {
                    isOpen: !state.sidebar.isOpen
                }
            }
        })
        .addCase(openSidebar, (state, action) => {
            return {
                ...state,
                sidebar: {
                    isOpen: true
                }
            }
        })
        .addCase(closeSidebar, (state, action) => {
            return {
                ...state,
                sidebar: {
                    isOpen: false
                }
            }
        })
        .addCase(setCustomAlert, (state, action) => {
            return {
                ...state,
                customAlert: {
                    isOpen: true,
                    payload: action.payload
                }
            }
        })
        .addCase(closeCustomAlert, (state, action) => {
            return {
                ...state,
                customAlert: {
                    isOpen: false,
                    payload: state.customAlert.payload
                }
            }
        })
        .addCase(setRequestLoader, (state, action) => {
            return {
                ...state,
                loader: [...state.loader, action.payload]
            }
        })
        .addCase(removeRequestLoader, (state, action) => {
            return {
                ...state,
                loader: state.loader.filter(item => item !== action.payload)
            }
        })
})

