import { createReducer } from '@reduxjs/toolkit'
import {
    setIndiciDiCaricoIndex,
    setIndiciDiCaricoShow,
    resetIndiciDiCaricoShow
} from '@store/indici-di-carico/indiciDiCarico.actions'
import { IndiciDiCaricoIndex, IndiciDiCaricoShow } from './indiciDiCarico.types'

const initialState: {
    index: IndiciDiCaricoIndex
    show: IndiciDiCaricoShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const indiciDiCaricoReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setIndiciDiCaricoIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setIndiciDiCaricoShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetIndiciDiCaricoShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

