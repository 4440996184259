export function dateFormatter(date: Date): string | null {

    let formattedDate: string | null = null;

    // Se l'anno è 1970 allora la data era null
    if (date.getFullYear() !== 1970) {
        const year = date.getFullYear().toString()
        const month = ('00' + (date.getMonth() + 1).toString()).slice(-2)
        const day = ('00' + date.getDate().toString()).slice(-2)

        formattedDate = `${year}-${month}-${day}`
    }

    return formattedDate;
}

export function scrollFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};
