import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";
import {
  CommonSpeedDial,
  CustomHeaderPage,
  CustomPaginatedDataGrid,
} from "@components/index";
import {
  getCapiZonaShow,
  postCapiZona,
  putCapiZona,
} from "@store/capi-zona/capiZona.effects";
import { CapiZona, NewCapiZona } from "@store/capi-zona/capiZona.types";
import { capiZonaShow } from "@store/capi-zona/capiZona.selector";
import { Box, Paper, TextField, IconButton } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import { resetCapiZonaShow } from "@store/capi-zona/capiZona.actions";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";
import { SortModel } from "@store/common/common.types";
import { scrollFunction } from "@utilities/utilities";
import { SelectProvince } from "@components/selects/province/SelectProvince";
import { GridColDef } from "@material-ui/data-grid";
import environment from "@environment/environment";
import Auth from "@utilities/auth";

interface CapiZonaDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function CapiZonaDetail({ match, creationMode }: CapiZonaDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "capiZonaDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingCapiZona = useAppSelector(capiZonaShow);
  const [capoZona, setCapoZona] = useState<CapiZona | NewCapiZona>({});

  const { defaultPageSize } = environment.pagination;
  const [sortColumns, setSortColumns] = useState<SortModel[]>([
    { field: "codDitta", sort: "asc" },
  ]);

  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {}, [dispatch, page, pageSize, sortColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getCapiZonaShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingCapiZona) {
      setNewElement(false);
      setCapoZona(pollingCapiZona);
    }
  }, [pollingCapiZona]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetCapiZonaShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: any, param: string) => {
    const target = e.target as typeof e.target & { value: string };
    updateValues(target.value, param);
  };

  const handleSelectChangeValue = (value: any, id: string) => {
    updateValues(value, id);
  };

  const updateValues = (value: any, id: string) => {
    setErrors({});
    setCapoZona((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig("nominativo", capoZona?.nominativo, [
        ValidationTypes.REQUIRED,
      ]),
      Validation.buildFieldConfig(
        "telefono",
        capoZona?.telefono,
        [ValidationTypes.MAX_LENGTH],
        { maxLength: 15 }
      ),
      Validation.buildFieldConfig(
        "fax",
        capoZona?.fax,
        [ValidationTypes.MAX_LENGTH],
        { maxLength: 15 }
      ),
      Validation.buildFieldConfig("indirizzoEmail", capoZona?.indirizzoEmail, [
        ValidationTypes.EMAIL,
      ]),
      Validation.buildFieldConfig(
        "cap",
        capoZona?.cap,
        [ValidationTypes.FIXED_LENGTH],
        { length: 5 }
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !capoZona) return;

    if (newElement && capoZona) {
      const newCapiZona: NewCapiZona = {
        ...capoZona,
        codCapoZona: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(postCapiZona(newCapiZona));
      if (postCapiZona.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.capiZona.show.replace(":id", result.codCapoZona.toString())
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putCapiZona(capoZona as CapiZona));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getCapiZonaShow(id));
    } else {
      setCapoZona({});
    }
  };
  const columns: GridColDef[] = [
    {
      field: "codDitta",
      headerName: t("common:codDitta"),
      //flex: 1,
      width: 150,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.codDitta,
    },
    {
      field: "ragioneSociale",
      headerName: t("common:ragioneSociale"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) =>
        params.row.sedeOperativeGoodyear.anagraficaGoodyear.ragioneSociale,
    },
    {
      field: "indSedeLegale",
      headerName: t("common:indirizzosedelegale"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) =>
        params.row.sedeOperativeGoodyear.anagraficaGoodyear.indSedeLegale,
    },
    {
      field: "indirizzo",
      headerName: t("common:indirizzosedeoperativa"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.sedeOperativeGoodyear.indirizzo,
    },
    {
      field: "",
      headerName: t("common:"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(push(`/anagrafica/${params.row.codDitta}`));
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];
  return (
    <div>
      {capoZona?.codCapoZona || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("capiZonaDetailPage:newElementTitle")}`
                : `${t("capiZonaDetailPage:title")} - ${capoZona.nominativo}`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!capoZona?.codCapoZona ? null : (
                  <TextField
                    className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codCapoZona")}
                    onChange={(e) => handleChangeValue(e, "codCapoZona")}
                    value={capoZona?.codCapoZona || ""}
                    style={{ minWidth: "15rem" }}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:nominativo")}*`}
                  error={errors["nominativo"] ? true : false}
                  helperText={errors["nominativo"] || ""}
                  onChange={(e) => handleChangeValue(e, "nominativo")}
                  value={capoZona?.nominativo || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:indirizzo")}
                  error={errors["indirizzo"] ? true : false}
                  helperText={errors["indirizzo"] || ""}
                  onChange={(e) => handleChangeValue(e, "indirizzo")}
                  value={capoZona?.indirizzo || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:localita")}
                  error={errors["localita"] ? true : false}
                  helperText={errors["localita"] || ""}
                  onChange={(e) => handleChangeValue(e, "localita")}
                  value={capoZona?.localita || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:cap")}
                  error={errors["cap"] ? true : false}
                  helperText={errors["cap"] || ""}
                  onChange={(e) => handleChangeValue(e, "cap")}
                  value={capoZona?.cap || ""}
                  type="number"
                  style={{ minWidth: "15rem" }}
                />
                <Box
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectProvince
                    id={"provincia"}
                    onChange={handleSelectChangeValue}
                    value={(capoZona as CapiZona)?.provincia || ""}
                  />
                </Box>
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:telefono1")}
                  error={errors["telefono"] ? true : false}
                  helperText={errors["telefono"] || ""}
                  onChange={(e) => handleChangeValue(e, "telefono")}
                  value={capoZona?.telefono || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4  w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:fax1")}
                  error={errors["fax"] ? true : false}
                  helperText={errors["fax"] || ""}
                  onChange={(e) => handleChangeValue(e, "fax")}
                  value={capoZona?.fax || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4  w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:indirizzoEmail")}
                  error={errors["indirizzoEmail"] ? true : false}
                  helperText={errors["indirizzoEmail"] || ""}
                  onChange={(e) => handleChangeValue(e, "indirizzoEmail")}
                  value={capoZona?.indirizzoEmail || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4  w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:codiceGLS")}
                  error={errors["codGLS"] ? true : false}
                  helperText={errors["codGLS"] || ""}
                  onChange={(e) => handleChangeValue(e, "codGLS")}
                  value={capoZona?.codGLS || ""}
                  style={{ minWidth: "15rem" }}
                />
              </Box>
            </Paper>
            {Auth.hasAccess("save-capizona") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
            {capoZona?.codCapoZona ? (
              <Paper className="mt-4">
                <Box>
                  <CustomPaginatedDataGrid
                    data={{
                      data: (capoZona as CapiZona).sediOpGoodyearCapiZona,
                    }}
                    columns={columns}
                    getRowId={(row) => row.codDitta}
                    sortColumns={sortColumns}
                    sortingMode="client"
                    onChangePageSize={setPageSize}
                    onChangeSortColumns={setSortColumns}
                    onChangePage={setPage}
                  />
                </Box>
              </Paper>
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
