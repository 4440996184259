import React from 'react';
import './App.css';

import {
  Switch,
  Route,
} from 'react-router-dom';
import routes from '@utilities/routes'

import {
  PrivateRoute,
  CustomAlert,
  PublicRouter,
  Loader
} from '@components/index'

import {
  LoginPage,
  ForgotPasswod
} from '@pages/index';

import { Layout } from './layout'

function App() {
  return (
    <div className="App">
      <Switch>
        <PublicRouter path={routes.auth.login} component={LoginPage} />
        <Route path={routes.auth.forgotPassword} component={ForgotPasswod} />
        <PrivateRoute path={routes.dashboard} component={Layout} />
      </Switch>
      <CustomAlert />
      <Loader />
    </div>
  );
}

export default App