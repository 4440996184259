import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Chip,
  makeStyles,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CustomHeaderPage,
  ArticlesSearch,
  ConfirmActionPropsData,
  ConfirmAction,
  CommonSpeedDial,
  CustomRecordFound,
} from "@components/index";
import Paper from "@material-ui/core/Paper";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import {
  Filter,
  FilterMethods,
  Filters,
  FilterTypes,
} from "@store/common/common.types";
import { dateFormatter, scrollFunction } from "@utilities/utilities";

import React, { FunctionComponent, Fragment, useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { getPrezziNettiConvenzioniDate } from "@store/dettaglio-sconti-convenzioni/dettaglioScontiConvenzioni.effects";
import { DettaglioScontiConvenzioniDate } from "@store/dettaglio-sconti-convenzioni/dettaglioScontiConvenzioni.type";
import { EntiConvenzionati } from "@store/enti-convenzionati/entiConvenzionati.types";
import _ from "lodash";
import { CriteriServizi } from "@store/convenzione-prezzi-netti/convenzionePrezziNetti.types";
import { criteriServiziIndex } from "@store/convenzione-prezzi-netti/convenzionePrezziNetti.selector";
import {
  getCriteriServiziIndex,
  prorogaPrezziNetti,
  putCriteriServizi,
} from "@store/convenzione-prezzi-netti/convenzionePrezziNetti.effects";
import { getGlobalCausaliIva } from "@store/global/global.effects";
import { globalCausaliIva } from "@store/global/global.selector";
import environment from "@environment/environment";
import { resetCriteriServiziInex } from "@store/convenzione-prezzi-netti/convenzionePrezziNetti.actions";
import { Articoli } from "@store/articoli/articoli.types";
import { SelectEntiConvenzionati } from "@components/selects";
import { ExpandLess, ExpandMore, Delete, Schedule } from "@material-ui/icons";
import { setWarning } from "@store/common/common.effects";
import { CusomDataGridSelctPage } from "@components/custom-datagrid-pagination/CusomDataGridSelctPage";
import NumberFormat from "react-number-format";
import moment from "moment";
import { DesConvenzioniGeneriche } from "@store/des-convenzioni-generiche/desConvenzioniGeneriche.types";
import { SelectConvenzioniGeneriche } from "@components/selects/convenzioni-generiche/ConvenzioniGeneriche";
import Auth from "@utilities/auth";
import { resetRicercaArticoli } from "@store/articoli/articoli.actions";
import { ProrogaConvenzioniDialog } from "./components/ProrogaConvenzioniDialog";

const useStyles = makeStyles((theme) => ({
  hiddenCip: {
    maxWidth: "55vw",
    maxHeight: "40px",
    overflow: "hidden",
  },
  showChip: {
    maxWidth: "55vw",
  },
}));

interface ConvenzioniAPrezziNettiProps {
  pageTitle: string;
  isConvenzioneGenerica?: boolean;
  duplicateConvenzione?: boolean;
  convenzioneKey: string;
  convenzioneFlotteKey?: string;
}

export const ConvenzioniAPrezziNetti: FunctionComponent<
  ConvenzioniAPrezziNettiProps
> = (props) => {
  const {
    pageTitle,
    isConvenzioneGenerica = false,
    duplicateConvenzione = false,
    convenzioneKey,
    convenzioneFlotteKey = null,
  } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "convenzioniPrezziNetti"]);
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const causaliIva = useAppSelector(globalCausaliIva);
  // const [convezione, setConvenzione] = useState({} as { [key: string]: any });
  const [progConvenzione, setProgConvenzione] = useState<number>(0);
  const [progConvenzioneFlotte, setProgConvenzioneFlotte] = useState<number>(0);

  const [selectedEnte, setSelectedEnte] = useState<number>(0);
  const prezziNettiData = useAppSelector(criteriServiziIndex).data;
  const [chipShow, setChipShow] = useState(false);

  const [dataConvenzione, setDataConvenzione] = useState(
    {} as DettaglioScontiConvenzioniDate
  );
  const [convData, setConvData] = useState([] as CriteriServizi[]); // eslint-disable-next-line
  const [gridKey, setGridKey] = useState(0);
  const [selectedArticles, setSelectedArticles] = useState([] as Articoli[]);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });
  const [pageSize, setPagesize] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);

  const [prorogaConvenzioniOpen, setProrogaConvenzioniOpen] = useState(false);

  useEffect(() => {
    // executed on page leve to empty store
    return () => {
      resetDatagird();
      dispatch(resetRicercaArticoli())
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (causaliIva.length < 1) {
      dispatch(getGlobalCausaliIva());
    }
  }, [causaliIva]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prezziNettiData) {
      let result = _.cloneDeep(prezziNettiData);
      if (selectedArticles.length > 0) {
        // if to check if we need to carate produttori ore articoli
        // loop selected produttori and crate structure
        selectedArticles.forEach((articolo) => {
          let convenzione = prezziNettiData.find((convenzioneData) => {
            return convenzioneData.codArticolo === articolo.codArticolo;
          });
          if (convenzione) {
            return;
          } else {
            let newServizio: CriteriServizi = {
              progConvenzione: progConvenzione,
              codArticolo: articolo.codArticolo,
              progressivo: 0,
              prezzo: 0,
              prezzoConIVA: 0,
              prezzoEuro: 0,
              prezzoEuroConIVA: 0,
              flgGratis: "false",
              descrizioneArticolo: articolo.descrizione,
              tipoOperazione: "U",
              utente: "",
              dataOperazione: "",
              dataInizioValidita: dataConvenzione.dataInizioValidita,
              dataFineValidita: dataConvenzione.dataFineValidita,
            };
            result.push(newServizio);
          }
        });
      }
      setConvData(result);
    } else {
      setConvData([]);
    }
  }, [prezziNettiData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollFunction();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeDate = (data: any) => {
    setDataConvenzione((prevState) => {
      return {
        ...prevState,
        [data.id]: data.value,
      };
    });
  };

  const validate = () => {
    // validate before calling api
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "progConvenzione",
        progConvenzione ? progConvenzione : undefined,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig(
        "dataInizioValidita",
        dataConvenzione.dataInizioValidita
          ? dataConvenzione.dataInizioValidita
          : undefined,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig(
        "dataFineValidita",
        dataConvenzione.dataFineValidita
          ? dataConvenzione.dataFineValidita
          : undefined,
        [ValidationTypes.REQUIRED]
      ),
      //Validation.buildFieldConfig('codArticolo', selectedArticles.length > 0 ? selectedArticles.length : undefined, [ValidationTypes.REQUIRED]),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const resetDatagird = () => {
    dispatch(resetCriteriServiziInex());
  };

  const handleChangeEnte = async (progConvenzione: number) => {
    // reset table
    resetDatagird();

    setProgConvenzione(progConvenzione);
    if (progConvenzione) {
      let { payload }: any = await dispatch(
        getPrezziNettiConvenzioniDate(progConvenzione.toString())
      );
      let formatDate: DettaglioScontiConvenzioniDate = {
        dataFineValidita:
          dateFormatter(new Date(payload.dataFineValidita)) || "",
        dataInizioValidita:
          dateFormatter(new Date(payload.dataInizioValidita)) || "",
      };
      setDataConvenzione(() => {
        return formatDate;
      });
    }
  };

  const getFilters = (dataFine?: string) => {
    let codArticolo: string[] = [];
    selectedArticles.forEach((article) => {
      codArticolo.push(article.codArticolo);
    });
    const CodArticolo = new Filter(
      FilterTypes.AND,
      "CodArticolo",
      FilterMethods.EQUALIN,
      codArticolo.toString()
    );
    const progConv = new Filter(
      FilterTypes.AND,
      "progConvenzione",
      FilterMethods.EQUAL,
      progConvenzione.toString()
    );
    const dataValiditaInizio = new Filter(
      FilterTypes.AND,
      "dataInizioValidita",
      FilterMethods.GREATERTHANOREQUALTO,
      dataConvenzione.dataInizioValidita
    );
    const dataValiditaFino = new Filter(
      FilterTypes.AND,
      "dataFineValidita",
      FilterMethods.LESSTHANOREQUALTO,
      dataFine ? dataFine : dataConvenzione.dataFineValidita
    );

    const filter: Filters = {
      dataValiditaInizio,
      dataValiditaFino,
      progConv,
      CodArticolo,
    };

    return filter;
  }

  const search = (dataFine?: string) => {
    if (
      dataConvenzione.dataInizioValidita !== undefined &&
      moment(dataConvenzione.dataInizioValidita) >
        moment(dataConvenzione.dataFineValidita)
    ) {
      dispatch(
        setWarning({
          message:
            "La data fine validità deve essere maggiore della data inzio",
        })
      );
      return;
    }
    //extract all cods

    if (validate()) {
      const filter = getFilters(dataFine);
      setPageNumber(0);
      dispatch(
        getCriteriServiziIndex(undefined, undefined, filter, [
          { field: "codArticolo,dataInizioValidita", sort: "asc" },
        ])
      );
    }
  };

  const handleImportoUpdate = (value: any, row: any) => {
    // do Object comparason
    function findeValue(param: any) {
      if (_.isEqual(param, row)) {
        return param;
      }
    }
    // finde value in array and its index and modify it
    const result = _.cloneDeep(convData.find(findeValue));
    const index = convData.findIndex(findeValue);
    if (result) {
      result.prezzoEuro = parseFloat(value);
      console.log(value);
      result.prezzoEuroConIVA = calculatePriceIVA(parseFloat(value));
      let tempData = _.cloneDeep(convData);
      tempData[index] = { ...result };
      setConvData(() => {
        return [...tempData];
      });
    }
  };
  const calculatePriceIVA = (price: number): number => {
    let percent = (environment.defaultCausaleIva.aliquota / 100) * price;
    percent = _.round(percent, 2);
    let priveIva = _.add(percent, price);
    return _.round(priveIva, 2);
  };

  const handleChangeEndDate = (value: string, row: any) => {
    console.log(row);
    function findValue(param: any) {
      if (_.isEqual(param, row)) {
        return param;
      }
    }
    function findDuplicate(param: any) {
      // long if to avoid using object comparison
      if (
        param.progConvenzione === row.progConvenzione &&
        param.progCriterio === row.progCriterio &&
        row.prezzoEuro === 0
      ) {
        return param;
      }
    }

    if (row.dataFineValidita === value) return;

    const isSplit = convData.findIndex(findDuplicate); // you can't duplicate 2 times a line if you have no codSconto

    if (isSplit === -1) {
      const index = convData.findIndex(findValue);

      const result = convData.find(findValue);
      if (result) {
        if (checkValiditiDate(value, result.dataInizioValidita)) {
          // add new date to current row
          let tempConvData = convData;
          const currentRowData = _.cloneDeep(result); // this to rm reference to result object

          currentRowData.dataFineValidita = value;
          tempConvData[index] = { ...currentRowData };
          //copy the row and create a new one
          if (value < result.dataFineValidita) {
            const newRowData = addSecondLine(_.cloneDeep(result), value); // this to rm reference to result object
            tempConvData.splice(index + 1, 0, newRowData); // add the new row under the current one
          }

          setConvData(() => {
            return [...tempConvData];
          });
        } else {
          dispatch(
            setWarning({ message: `${t("common:message:invalidDate")}` })
          );
          setGridKey((prevState) => {
            return prevState + 1;
          }); // update the gid to set the defValue of the date
        }
      }
    } else {
      dispatch(
        setWarning({
          message: "Impossibile duplicare più di uno sconto alla volta",
        })
      );
      setGridKey((prevState) => {
        return prevState + 1;
      }); // update the gid to set the defValue of the date
    }
  };

  const handleProrogaConvenzioni = async (date: string) => {

    if (! moment(date).isAfter(moment())) {
      dispatch(setWarning({ message: "La data selezionata deve essere maggiore di oggi" }));
      return;
    }

    const filter = getFilters();
    setProrogaConvenzioniOpen(false);
    await dispatch(
      prorogaPrezziNetti(
        progConvenzione.toString(),
        date,
        filter
      )
    );

    handleChangeDate({
      id: 'dataFineValidita',
      value: date
    });

    search(date);
  }

  const addSecondLine = (rowData: CriteriServizi, startDate: string) => {
    let newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + 1);

    rowData.dataFineValidita = dataConvenzione.dataFineValidita;
    rowData.dataInizioValidita = newStartDate.toISOString().split("T")[0];
    rowData.progressivo = 0;
    rowData.prezzoEuro = 0;
    rowData.prezzoEuroConIVA = 0;
    rowData.prezzoConIVA = 0;
    rowData.prezzo = 0;
    rowData.articoli = undefined;
    rowData.isDuplicate = true;

    //rowData.progSconto = 0
    return rowData;
  };

  const checkValiditiDate = (date: string, startDate: string): boolean => {
    const dateCheck = new Date(date);
    // const dateEnd = new Date(dataConvenzione.dataFineValidita)
    const dateStart = new Date(startDate);

    if (dateStart < dateCheck /*&& dateEnd > dateCheck*/) {
      return true;
    } else {
      return false;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "codArticolo",
      headerName: `Codice Articolo`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      flex: 1,
      valueGetter: (params) => {
        return params.row.codArticolo;
      },
    },
    {
      field: "descrizione",
      headerName: `Descrizione`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      flex: 1,
      valueGetter: (params) => {
        return params.row.descrizioneArticolo; //return params.row.codArticolo
      },
    },
    {
      field: "dataInizioValidita",
      headerName: `Data inizio`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            type="date"
            variant="standard"
            disabled={!Auth.hasAccess("save-convenzioni")}
            defaultValue={
              params.row.dataInizioValidita
                ? dateFormatter(new Date(params.row.dataInizioValidita))
                : ""
            }
          />
        );
      },
    },
    {
      field: "dataFineValidita",
      headerName: `Data fine`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            key={params.row.dataFineValidita}
            type="date"
            variant="standard"
            disabled={!Auth.hasAccess("save-convenzioni")}
            defaultValue={
              params.row.dataFineValidita
                ? dateFormatter(new Date(params.row.dataFineValidita))
                : ""
            }
            onBlur={(event) =>
              handleChangeEndDate(event.target.value, params.row)
            }
            InputLabelProps={{}}
          />
        );
      },
    },
    {
      field: "ImportoNettoEuro",
      headerName: `Imp. netto EUR`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <NumberFormat
            // type='text'
            //variant="standard"
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator="."
            value={params.row.prezzoEuro}
            disabled={!Auth.hasAccess("save-convenzioni")}
            //onChange={(event: any) => handleImportoUpdate(event.target.value, params.row)}
            onBlur={(event: any) =>
              handleImportoUpdate(event.target.value, params.row)
            }
            className={"text-base bg-transparent outline-hidden	pt-3"}
            style={{ outline: "none" }}
          />
        );
      },
    },
    {
      field: "ImportoNettoEuroIVA",
      headerName: `Imp. netto EUR + IVA`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <NumberFormat
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator="."
            value={params.row.prezzoEuroConIVA}
            className={"text-base bg-transparent outline-hidden	pt-3"}
            style={{ outline: "none" }}
            disabled={true}
          />
        );
      },
    },
  ];

  if (Auth.hasAccess("save-convenzioni")) {
    columns.push({
      field: "delete",
      headerName: `Elimina`,
      width: 100,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        return params.row.isDuplicate ? (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="primary"
            size="small"
            onClick={() => {
              var tmpConvData = [...convData]; // make a separate copy of the array
              var index = tmpConvData.findIndex(
                (x) =>
                  x.progConvenzione === params.row.progConvenzione &&
                  x.codArticolo === params.row.codArticolo &&
                  x.progressivo === params.row.progressivo &&
                  x.dataInizioValidita === params.row.dataInizioValidita
              );
              if (index !== -1) {
                tmpConvData.splice(index, 1);
                setConvData(tmpConvData);
              }
            }}
          >
            <Delete />
          </IconButton>
        ) : (
          <div></div>
        );
      },
    });
  }

  const handleSave = async () => {
    let confirmCheckbox = false;
    const clickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
      confirmCheckbox = e.target.checked;
    };
    let customContent: JSX.Element | undefined = undefined;
    if (duplicateConvenzione) {
      customContent = (
        <FormControlLabel
          control={<Checkbox color="primary" onChange={clickCheckbox} />}
          label={`${t("convenzioniScontiDetailPage:allert:duplicate")}`}
        />
      );
    }
    setConfirmAction({
      isOpen: true,
      title: `${t("common:message:defaultConfirmationTitle")}`,
      subtitle: `${t("common:message:defaultConfirmationMessage")}`,
      confirmAction: async () => {
        if (validate()) {
          await dispatch(
            putCriteriServizi({
              progConvenzine: progConvenzione,
              data: _.cloneDeep(convData),
              duplicate: confirmCheckbox,
              progFlotte: progConvenzioneFlotte,
            })
          );
          search();
        }
      },
      customContent: customContent,
    });
  };

  const handleArticoliSelezionati = (articles: Articoli[]) => {
    resetDatagird();

    let tempSelected = selectedArticles.concat(articles);
    // eliminate duplicated values
    var mySet = new Set(tempSelected);
    tempSelected = Array.from(mySet);
    setSelectedArticles(() => {
      return tempSelected;
    });
  };

  const handleDeleteChip = (codArticolo: string) => {
    resetDatagird();

    let tempCodArt = selectedArticles;
    let index = tempCodArt.findIndex((article: Articoli) => {
      return article.codArticolo === codArticolo;
    });
    tempCodArt.splice(index, 1);

    setSelectedArticles(() => {
      return [...tempCodArt];
    });
  };
  // const [confirmDialogeOpen, setConfirmDialogeOpen] = useState(false);

  // const openDuplucate = () => {
  //     if (progConvenzione) {
  //         setConfirmDialogeOpen(true)
  //     } else {
  //         dispatch(setWarning({ message: 'Seleziona una convenzione' }));
  //     }
  // }
  return (
    <Fragment>
      <CustomHeaderPage title={pageTitle} disabledGoBack></CustomHeaderPage>
      <Box className="flex flex-grow flex-col mb-4">
        <Paper>
          <Box className="flex flex-grow flex-row flex-wrap pt-4">
            <Typography variant="h6" className="px-4">
              {t("common:cliente")}
            </Typography>
          </Box>
          <Box className="p-2 pb-8">
            <Box className="flex flex-wrap">
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12  pl-2">
                {isConvenzioneGenerica ? (
                  <SelectConvenzioniGeneriche
                    id={"entiConvenzionati"}
                    // onChange={(desConvenzioneGenerica: DesConvenzioniGeneriche) => {
                    //     handleChangeEnte(desConvenzioneGenerica.progConvenzione)
                    //     setSelectedEnte(desConvenzioneGenerica.codConvGenerica)
                    // }}
                    onChange={(
                      desConvenzioneGenerica: DesConvenzioniGeneriche
                    ) => {
                      handleChangeEnte(desConvenzioneGenerica[convenzioneKey]);
                      setSelectedEnte(desConvenzioneGenerica.codConvGenerica);
                    }}
                    value={selectedEnte}
                    error={errors["progConvenzione"] ? true : false}
                    helperText={errors["progConvenzione"]}
                    fullData={true}
                  />
                ) : (
                  <SelectEntiConvenzionati
                    id={"entiConvenzionati"}
                    // onChange={(ente: EntiConvenzionati) => {
                    //     handleChangeEnte(ente.progConvenzione)
                    //     setSelectedEnte(ente.codCliente)
                    // }}
                    onChange={(ente: EntiConvenzionati) => {
                      handleChangeEnte(ente[convenzioneKey]);
                      if (convenzioneFlotteKey) {
                        setProgConvenzioneFlotte(ente[convenzioneFlotteKey]);
                      }
                      setSelectedEnte(ente.codCliente);
                    }}
                    value={selectedEnte}
                    error={errors["progConvenzione"] ? true : false}
                    helperText={errors["progConvenzione"]}
                    fullData={true}
                  />
                )}
              </Box>
            </Box>
            <Box className="flex flex-wrap">
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                <TextField
                  fullWidth
                  error={errors["dataInizioValidita"] ? true : false}
                  helperText={errors["dataInizioValidita"]}
                  label={t("common:dataInizioValidita")}
                  size="small"
                  type="date"
                  id="dataInizioValidita"
                  value={
                    dataConvenzione?.dataInizioValidita
                      ? dataConvenzione?.dataInizioValidita
                      : ""
                  }
                  onChange={(event) => handleChangeDate(event.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                <TextField
                  fullWidth
                  error={errors["dataFineValidita"] ? true : false}
                  helperText={errors["dataFineValidita"]}
                  label={t("common:dataFineValidita")}
                  size="small"
                  type="date"
                  id="dataFineValidita"
                  value={
                    dataConvenzione?.dataFineValidita
                      ? dataConvenzione?.dataFineValidita
                      : ""
                  }
                  onChange={(event) => handleChangeDate(event.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
        <Paper className="mt-4">
          <Box className="flex flex-grow flex-row flex-wrap pt-4">
            <Typography variant="h6" className="px-4">
              {"Criterio convenzione"}
            </Typography>
          </Box>
          <Box className="p-2">
            <Box className="flex flex-wrap">
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-12/12">
                <ArticlesSearch
                  onSelectedArticles={handleArticoliSelezionati}
                  error={errors["codArticolo"] ? true : false}
                  defaultCodDivisione="3"
                  defaultFuoriProd={'false'}
                />
              </Box>
            </Box>
            <Box className="flex flex-wrap">
              <Box className="pl-2 mt-2">
                <div style={{ display: "flex" }}>
                  <div
                    className={chipShow ? classes.showChip : classes.hiddenCip}
                  >
                    {selectedArticles.map((article) => {
                      return (
                        <Chip
                          key={article.codArticolo}
                          label={article.codArticolo}
                          onDelete={() => handleDeleteChip(article.codArticolo)}
                          className={"mr-2 mb-3"}
                        />
                      );
                    })}
                  </div>
                  {selectedArticles.length >= 1 ? (
                    chipShow ? (
                      <ExpandLess onClick={() => setChipShow(!chipShow)} />
                    ) : (
                      <ExpandMore onClick={() => setChipShow(!chipShow)} />
                    )
                  ) : null}
                </div>
              </Box>
            </Box>
            <Box className="flex justify-end pl-2 pr-2 pb-0 pt-0">
              {selectedArticles.length > 0 ? (
                <Button
                  onClick={() => {
                    setSelectedArticles([]);
                  }}
                  className="m-4 mr-0"
                >
                  {t("convenzioniPrezziNetti:resetArticoli")}
                </Button>
              ) : null}
              <Button onClick={() => search()} className="m-4 mr-0">
                {t("common:search")}
              </Button>
            </Box>
          </Box>
        </Paper>
        <Grid item xs={12}>
          <Paper className="mt-4">
            <CustomRecordFound recordFound={convData?.length || 0} />
            <DataGrid
              autoHeight
              rows={convData || []}
              columns={columns}
              getRowId={(row) => {
                return `${row.progConvenzione}/${row.codArticolo}/${row.progressivo}/${row.dataInizioValidita}`;
              }}
              key={gridKey}
              disableColumnFilter
              page={pageNumber}
              pageSize={pageSize}
              hideFooter
              getRowClassName={(params) =>
                params.row.prezzoEuro <= 0 ? "highlighted-row-text" : ""
              }
            />
            <CusomDataGridSelctPage
              pageSize={pageSize}
              pageNumber={pageNumber}
              numberOfPages={
                convData.length > 0 ? convData.length / pageSize : 1
              }
              changePageNumber={setPageNumber}
              changePageSize={setPagesize}
            />
          </Paper>
        </Grid>
        <CommonSpeedDial
          onSave={Auth.hasAccess("save-convenzioni") ? handleSave : undefined}
          disableSaveConfirmDialog
          updateData={[
            {
              taskName:
                environment.semaphoreJobsData.anagraficheUpdates.taskName,
              entities: [
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .criteriConvenzioni,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .desConvenzioniGeneriche,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .convenzioniDatiAggiuntivi,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .prezziNettiConvenzioni,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .dettaglioScontiConvenzioni,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .testataConvenzioni,
              ],
            },
            {
              taskName:
                environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
              entities: [
                environment.semaphoreJobsData.centralDatabaseUpdates.entities
                  .convenzioni,
              ],
            },
          ]}
          otherActions={
            Auth.hasAccess("save-convenzioni") ? [
              {
                icon: <Schedule />,
                tooltip: t("Proroga convenzioni"),
                onClick: () => {
                  setProrogaConvenzioniOpen(true);
                }
              }
            ] : []
          }
        />
      </Box>
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
      <ProrogaConvenzioniDialog
        defaultDate={dataConvenzione.dataFineValidita}
        open={prorogaConvenzioniOpen}
        setOpen={setProrogaConvenzioniOpen}
        handleProrogaConvenzioni={handleProrogaConvenzioni}
      />
    </Fragment>
  );
};
