import { Pagination } from "@store/common/common.types";
import { Server } from "@store/flow-manager/flowManager.type";
import { MasterDataUser } from "@store/master-data-user/masterDataUser.type";

export interface LogManagerIndex {
    data: LogManager[]
    meta: {
        pagination: Pagination | undefined
    }
}

export interface LogManagerShow {
    data: LogManager | undefined
}

export interface LogManager {
    id: string,
    serverId: string,
    correlationId: string,
    user: string,
    source: string,
    level: string,
    message: string,
    event: string,
    timestamp: string,
    relatedServer: Server,
    relatedLogs: LogManager[],
    relatedUser: MasterDataUser
}

export interface NewLogManager {
    id?: string,
    serverId?: string,
    correlationId?: string,
    user?: string,
    source?: string,
    level?: string,
    message?: string,
    event?: string,
    timestamp?: string,
}

export enum LogManagerTypes {
    Info = 'Info',
    Error = 'Error',
    Warning = 'Warning'
}