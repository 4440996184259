import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";
import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getArticoliShow,
  postArticoli,
  putArticoli,
} from "@store/articoli/articoli.effects";
import { Articoli, NewArticoli } from "@store/articoli/articoli.types";
import {
  articoliShow,
  lastInsertedArticle,
} from "@store/articoli/articoli.selector";
import { Box, Paper, TextField, Button, Typography } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { push } from "connected-react-router";
import {
  resetArticoliShow,
  resetLastInsertedArticle,
  setLastInsertedArticle,
} from "@store/articoli/articoli.actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import {
  SelectCategorieMerceologiche,
  SelectCausaliIva,
  SelectDisegni,
  SelectProduttori,
  SelectStagionalita,
  SelectStrutture,
  SelectTipiArticolo,
  SelectUnitaDiMisura,
} from "@components/selects";
import AddIcon from "@material-ui/icons/Add";
import {
  globalCodiciVelocita,
  globalIndiciDiCarico,
  globalLarghezzeCerchi,
  globalLarghezzeNominali,
  globalLivelliListino,
  globalSerieGomme,
} from "@store/global/global.selector";
import {
  getGlobalCodiciVelocita,
  getGlobalIndiciDiCarico,
  getGlobalLarghezzeCerchi,
  getGlobalLarghezzeNominali,
  getGlobalLivelliListino,
  getGlobalSerieGomme,
} from "@store/global/global.effects";
import moment from "moment";
import { Listino } from "@store/listini/listini.types";
import environment from "@environment/environment";
import { DataGridListiniPneumatico } from "../components/DataGridListiniPneumatico";
import _ from "lodash";
import Auth from "@utilities/auth";

interface PneumaticiDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function PneumaticiDetail({
  match,
  creationMode,
}: PneumaticiDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "pneumaticiDetailPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const livelliListino = useAppSelector(globalLivelliListino);
  const pollingArticoli = useAppSelector(articoliShow);
  const ultimoArticoloInserito = useAppSelector(lastInsertedArticle);

  const [articolo, setArticolo] = useState<Articoli | NewArticoli>({
    codProduttore: ultimoArticoloInserito?.codProduttore || undefined,
    codStagionalita: ultimoArticoloInserito?.codStagionalita || undefined,
    codCategoriaMerc: ultimoArticoloInserito?.codCategoriaMerc || undefined,
    codTipoArticolo: ultimoArticoloInserito?.codTipoArticolo || undefined,
    codUdM: ultimoArticoloInserito?.codUdM || 4,
    codIVA: 100,
    qtaDefault: ultimoArticoloInserito?.qtaDefault || 4,
    codStruttura: 1, // Radiale
    listiniClienti: [],
    listiniFornitori: [],
  });

  const larghezzeNominali = useAppSelector(globalLarghezzeNominali);
  const serieGomme = useAppSelector(globalSerieGomme);
  const larghezzeCerchi = useAppSelector(globalLarghezzeCerchi);
  const indiciDiCarico = useAppSelector(globalIndiciDiCarico);
  const codiciVelocita = useAppSelector(globalCodiciVelocita);

  const [selectedLargNom, setSelectedLargNom] = useState<string>("");
  const [selectedSerie, setSelectedSerie] = useState<string>("");
  const [selectedCerchio, setSelectedCerchio] = useState<string>("");
  const [selectedIndiceDiCarico1, setSelectedIndiceDiCarico1] =
    useState<string>("");
  const [selectedIndiceDiCarico2, setSelectedIndiceDiCarico2] =
    useState<string>("");
  const [selectedVelocita, setSelectedVelocita] = useState<string>("");

  const [errors, setErrors] = useState({} as { [key: string]: string });

  const alwaysRequiredFields = [
    "codArticolo",
    "descrizione",
    "codProduttore",
    "codTipoArticolo",
    "codCategoriaMerc",
    "qtaDefault",
    "codIVA",
    "codUdM",
  ];
  const [requiredFields, setRequiredFields] =
    useState<string[]>(alwaysRequiredFields);

  useEffect(() => {
    dispatch(getGlobalLivelliListino());
    dispatch(getGlobalLarghezzeNominali());
    dispatch(getGlobalSerieGomme());
    dispatch(getGlobalLarghezzeCerchi());
    dispatch(getGlobalIndiciDiCarico());
    dispatch(getGlobalCodiciVelocita());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fields = alwaysRequiredFields;
    if (
      articolo?.codCategoriaMerc === 1 &&
      (articolo?.codTipoArticolo ===
        environment.codiciTipiArticolo.autovettura ||
        articolo?.codTipoArticolo ===
          environment.codiciTipiArticolo.trasportoLeggero ||
        articolo?.codTipoArticolo ===
          environment.codiciTipiArticolo.fuoristrada ||
        articolo?.codTipoArticolo ===
          environment.codiciTipiArticolo.trasportoPesante)
    ) {
      fields.push("codLarghezzaNominale");
      fields.push("codSerie");
      fields.push("codStruttura");
      fields.push("codLarghezzaCerchio");
      fields.push("codIndiceCarico1");
      fields.push("codVelocita");
      fields.push("codStagionalita");
      fields.push("codDisegno");
    }
    setRequiredFields(fields);
  }, [articolo.codTipoArticolo, articolo.codCategoriaMerc]); // eslint-disable-line react-hooks/exhaustive-deps

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getArticoliShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingArticoli) {
      setNewElement(false);
      setArticolo(pollingArticoli);
    } else {
      setArticolo({
        codProduttore: ultimoArticoloInserito?.codProduttore || undefined,
        codStagionalita: ultimoArticoloInserito?.codStagionalita || undefined,
        codCategoriaMerc: ultimoArticoloInserito?.codCategoriaMerc || undefined,
        codTipoArticolo: ultimoArticoloInserito?.codTipoArticolo || undefined,
        codUdM: ultimoArticoloInserito?.codUdM || 4,
        codIVA: 100,
        qtaDefault: ultimoArticoloInserito?.qtaDefault || 4,
        codStruttura: 1, // Radiale
      });
    }
  }, [pollingArticoli]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pollingArticoli) {
      const larghezzaNominale = larghezzeNominali.find(
        (x) => x.codLarghezzaNominale === pollingArticoli.codLarghezzaNominale
      );
      setSelectedLargNom(
        larghezzaNominale ? larghezzaNominale.larghezzaNominale : ""
      );
      const serie = serieGomme.find(
        (x) => x.codSerie === pollingArticoli.codSerie
      );
      setSelectedSerie(serie ? serie.descrizione.toString() : "");
      const larghezzaCerchio = larghezzeCerchi.find(
        (x) => x.codLarghezzaCerchio === pollingArticoli.codLarghezzaCerchio
      );
      setSelectedCerchio(larghezzaCerchio ? larghezzaCerchio.larghezza : "");
      let indiceDiCarico = indiciDiCarico.find(
        (x) => x.codIndiceCarico === pollingArticoli.codIndiceCarico1
      );
      setSelectedIndiceDiCarico1(
        indiceDiCarico ? indiceDiCarico.indiceCarico : ""
      );
      indiceDiCarico = indiciDiCarico.find(
        (x) => x.codIndiceCarico === pollingArticoli.codIndiceCarico2
      );
      setSelectedIndiceDiCarico2(
        indiceDiCarico ? indiceDiCarico.indiceCarico : ""
      );
      const velocita = codiciVelocita.find(
        (x) => x.codVelocita === pollingArticoli.codVelocita
      );
      setSelectedVelocita(velocita ? velocita.simbolo : "");
    }
  }, [
    pollingArticoli,
    larghezzeNominali,
    serieGomme,
    larghezzeCerchi,
    indiciDiCarico,
    codiciVelocita,
  ]);
  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetArticoliShow());
      dispatch(resetLastInsertedArticle());
    };
  }, [dispatch]);

  const handleChangeValueArticolo = (e: any, param: string) => {
    const target = e.target as typeof e.target & { value: string };
    updateValue(target.value, param);
  };

  const handleChangeSelectValue = (value: any, id: string) => {
    updateValue(value, id);
  };

  const updateValue = (value: any, id: string) => {
    setErrors({});
    setArticolo((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  const handleChangeArticoliDatiAggiuntivi = (e: any, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setArticolo((prevState) => {
      return {
        ...prevState,
        articoliDatiAggiuntivi: {
          ...prevState.articoliDatiAggiuntivi,
          [param]: target.value,
        },
      };
    });
  };

  const handleChangeArticoliLabeling = (e: any, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setArticolo((prevState) => {
      return {
        ...prevState,
        articoliLabeling: {
          ...prevState.articoliLabeling,
          [param]: target.value,
        },
      };
    });
  };

  const handleChangeListinoClienti = (
    livelloListino: number,
    field: string,
    value: any
  ) => {
    handleChangeListino(livelloListino, field, value, "listiniClienti");
    if (livelloListino === 0) {
      handleChangeListinoFornitori(livelloListino, field, value);
    }
  };

  const handleChangeListinoFornitori = (
    livelloListino: number,
    field: string,
    value: any
  ) => {
    handleChangeListino(livelloListino, field, value, "listiniFornitori");
  };

  const handleChangeListino = (
    livelloListino: number,
    field: string,
    value: any,
    type: string
  ) => {
    let listinoIndex = articolo[type]?.findIndex(
      (listino: Listino) => listino.livelloListino === livelloListino
    );
    if (listinoIndex === undefined) {
      listinoIndex = -1;
    }
    let tmpListini = articolo[type] ? [...articolo[type]] : [];
    let listino: Listino;
    if (listinoIndex !== -1) {
      listino = { ...tmpListini[listinoIndex] };
    } else {
      listino = {
        codProduttore: articolo.codProduttore || 0,
        codArticolo: articolo.codArticolo || "",
        livelloListino: livelloListino,
        dataInizioValidita: moment(new Date()).format("YYYY-MM-DD"),
        prezzoEuro: 0,
        prezzoEuroConIVA: 0,
        prezzo: 0,
        prezzoConIVA: 0,
        dtaUltAgg: moment(new Date()).format("YYYY-MM-DD"),
        flgListinoGY: true,
        livListino: livelliListino.find(
          (livListino) => livListino.codLivelloListino === livelloListino
        ),
      };
    }

    if (field === "prezzoEuro") {
      listino[field] = Number(value);
      listino.prezzoEuroConIVA =
        Number(value) +
        Number(value) * (environment.defaultCausaleIva.aliquota / 100);
    } else {
      listino[field] = value;
    }

    if (listinoIndex !== -1) {
      tmpListini[listinoIndex] = listino;
    } else {
      tmpListini.push(listino);
    }

    if (type === "listiniClienti") {
      setArticolo((prevState) => {
        return {
          ...prevState,
          listiniClienti: [...tmpListini],
        };
      });
    } else {
      setArticolo((prevState) => {
        return {
          ...prevState,
          listiniFornitori: [...tmpListini],
        };
      });
    }
  };

  const validateTechnicalCharacteristics = () => {
    const techErrors: { [key: string]: string } = {};
    if (selectedLargNom) {
      const larghezzaNominale = larghezzeNominali.find(
        (x) => x.larghezzaNominale === selectedLargNom
      );
      if (!larghezzaNominale) {
        techErrors["codLarghezzaNominale"] = t(
          "common:validatorErrors:valueNotFound"
        );
      }
    }
    if (selectedSerie) {
      const serieGomma = serieGomme.find(
        (x) => x.descrizione.toString() === selectedSerie
      );
      if (!serieGomma) {
        techErrors["codSerie"] = t("common:validatorErrors:valueNotFound");
      }
    }
    if (selectedCerchio) {
      const cerchio = larghezzeCerchi.find(
        (x) => x.larghezza === selectedCerchio
      );
      if (!cerchio) {
        techErrors["codLarghezzaCerchio"] = t(
          "common:validatorErrors:valueNotFound"
        );
      }
    }
    if (selectedIndiceDiCarico1) {
      const indiceDiCarico1 = indiciDiCarico.find(
        (x) => x.indiceCarico === selectedIndiceDiCarico1
      );
      if (!indiceDiCarico1) {
        techErrors["codIndiceCarico1"] = t(
          "common:validatorErrors:valueNotFound"
        );
      }
    }
    if (selectedIndiceDiCarico2) {
      const indiceDiCarico2 = indiciDiCarico.find(
        (x) => x.indiceCarico === selectedIndiceDiCarico2
      );
      if (!indiceDiCarico2) {
        techErrors["codIndiceCarico2"] = t(
          "common:validatorErrors:valueNotFound"
        );
      }
    }
    if (selectedVelocita) {
      const velocita = codiciVelocita.find(
        (x) => x.simbolo === selectedVelocita
      );
      if (!velocita) {
        techErrors["codVelocita"] = t("common:validatorErrors:valueNotFound");
      }
    }
    setErrors(techErrors);
    return Object.keys(techErrors).length === 0;
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "rollingResistance",
        articolo?.articoliLabeling?.rollingResistance,
        [ValidationTypes.FIXED_LENGTH],
        { length: 1 }
      ),
      Validation.buildFieldConfig(
        "wetGrip",
        articolo?.articoliLabeling?.wetGrip,
        [ValidationTypes.FIXED_LENGTH],
        { length: 1 }
      ),
      Validation.buildFieldConfig(
        "noisePerformance",
        articolo?.articoliLabeling?.noisePerformance,
        [ValidationTypes.MAX_LENGTH],
        { maxLength: 2 }
      ),
      Validation.buildFieldConfig(
        "numberWaves",
        articolo?.articoliLabeling?.numberWaves,
        [ValidationTypes.FIXED_LENGTH],
        { length: 1 }
      ),
    ];

    requiredFields.forEach((field) => {
      fieldsConfig.push(
        Validation.buildFieldConfig(field, articolo?.[field], [
          ValidationTypes.REQUIRED,
        ])
      );
    });

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateTechnicalCharacteristics() || !validate() || !articolo) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement) {
      const newArticoli: NewArticoli = {
        ...articolo,
        codDivisione: 1,
        utente: "SYSTEM",
        articoliDatiAggiuntivi: {
          ...articolo.articoliDatiAggiuntivi,
          codArticolo: articolo.codArticolo,
        },
      };

      const resultAction = await dispatch(postArticoli(newArticoli));
      if (postArticoli.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.pneumatici.show.replace(":id", result.codArticolo.toString())
          )
        );
        dispatch(setLastInsertedArticle(result));
      } else {
        //error
      }
    } else {
      const putArticolo = { ...articolo };
      if (
        articolo.articoliLabeling !== undefined &&
        !_.isEmpty(articolo.articoliLabeling)
      ) {
        putArticolo.articoliLabeling = {
          ...articolo.articoliLabeling,
          codArticolo: articolo.codArticolo,
        };
      }

      dispatch(putArticoli(putArticolo as Articoli));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getArticoliShow(id));
    } else {
      setArticolo({
        codIVA: 100,
        qtaDefault: 4,
      });
    }
  };

  return (
    <div>
      {articolo?.codArticolo || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("pneumaticiDetailPage:newElementTitle")}`
                : `${t("pneumaticiDetailPage:title")} - ${id}`
            }
          >
            {Auth.hasAccess("save-articoli") ? (
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(resetArticoliShow());
                  dispatch(push(routes.pneumatici.new));
                }}
              >
                <AddIcon color="primary" />
                {t("pneumaticiIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper className="p-4 mb-4">
              <Box className="flex flex-grow flex-row flex-wrap pt-4">
                <Typography variant="h6" className="px-4">
                  {t("common:articoliDatiTecnici")}
                </Typography>
              </Box>
              <Box className="flex flex-grow flex-row flex-wrap">
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:codArticolo")} *`}
                  onChange={(e) => {
                    const target = e.target as typeof e.target & { value: string };
                    updateValue(target.value.toUpperCase(), "codArticolo");
                  }}
                  value={articolo?.codArticolo || ""}
                  error={errors["codArticolo"] ? true : false}
                  helperText={errors["codArticolo"] || ""}
                  style={{ minWidth: "15rem" }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
                <TextField
                  className="px-4 w-full 2xl:w-4/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:descrizione")} *`}
                  error={errors["descrizione"] ? true : false}
                  helperText={errors["descrizione"] || ""}
                  onChange={(e) => handleChangeValueArticolo(e, "descrizione")}
                  value={articolo?.descrizione || ""}
                  style={{ minWidth: "15rem" }}
                />
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectProduttori
                    id={"codProduttore"}
                    onChange={(value: any, id: string) => {
                      setArticolo((prevState) => {
                        return {
                          ...prevState,
                          codDisegno: undefined,
                        };
                      });
                      handleChangeSelectValue(value, id);
                    }}
                    value={articolo?.codProduttore}
                    error={errors["codProduttore"] ? true : false}
                    helperText={errors["codProduttore"] || ""}
                    required
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectCategorieMerceologiche
                    id="codCategoriaMerc"
                    value={articolo?.codCategoriaMerc}
                    onChange={handleChangeSelectValue}
                    codDivisione="1"
                    error={errors["codCategoriaMerc"] ? true : false}
                    helperText={errors["codCategoriaMerc"] || ""}
                    required
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectTipiArticolo
                    id={"codTipoArticolo"}
                    onChange={(value: any, id: string) => {
                      setArticolo((prevState) => {
                        return {
                          ...prevState,
                          codDisegno: undefined,
                        };
                      });
                      handleChangeSelectValue(value, id);
                    }}
                    value={articolo?.codTipoArticolo}
                    codDivisione="1"
                    error={errors["codTipoArticolo"] ? true : false}
                    helperText={errors["codTipoArticolo"] || ""}
                    required
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <TextField
                    id="codLarghezzaNominale"
                    size="small"
                    required={requiredFields.includes("codLarghezzaNominale")}
                    label={`${t("common:larghezzaNominale")}`}
                    error={errors["codLarghezzaNominale"] ? true : false}
                    helperText={errors["codLarghezzaNominale"] || ""}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const larghezzaNominale = larghezzeNominali.find(
                          (x) => x.larghezzaNominale === e.target.value
                        );
                        if (larghezzaNominale) {
                          updateValue(
                            larghezzaNominale.codLarghezzaNominale,
                            "codLarghezzaNominale"
                          );
                        } else {
                          updateValue(undefined, "codLarghezzaNominale");
                          setErrors((prevState) => {
                            return {
                              ...prevState,
                              codLarghezzaNominale: t(
                                "common:validatorErrors:valueNotFound"
                              ),
                            };
                          });
                        }
                      } else {
                        updateValue(undefined, "codLarghezzaNominale");
                      }
                    }}
                    onChange={(e) => {
                      setSelectedLargNom(e.target.value);
                    }}
                    value={selectedLargNom}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <TextField
                    id="codSerie"
                    size="small"
                    required={requiredFields.includes("codSerie")}
                    label={`${t("common:serie")}`}
                    error={errors["codSerie"] ? true : false}
                    helperText={errors["codSerie"] || ""}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const serie = serieGomme.find(
                          (x) => x.descrizione.toString() === e.target.value
                        );
                        if (serie) {
                          updateValue(serie.codSerie, "codSerie");
                        } else {
                          updateValue(undefined, "codSerie");
                          setErrors((prevState) => {
                            return {
                              ...prevState,
                              codSerie: t(
                                "common:validatorErrors:valueNotFound"
                              ),
                            };
                          });
                        }
                      } else {
                        updateValue(undefined, "codSerie");
                      }
                    }}
                    onChange={(e) => {
                      setSelectedSerie(e.target.value);
                    }}
                    value={selectedSerie}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <TextField
                    id="codLarghezzaCerchio"
                    size="small"
                    required={requiredFields.includes("codLarghezzaCerchio")}
                    label={`${t("common:cerchio")}`}
                    error={errors["codLarghezzaCerchio"] ? true : false}
                    helperText={errors["codLarghezzaCerchio"] || ""}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const cerchio = larghezzeCerchi.find(
                          (x) => x.larghezza === e.target.value
                        );
                        if (cerchio) {
                          updateValue(
                            cerchio.codLarghezzaCerchio,
                            "codLarghezzaCerchio"
                          );
                        } else {
                          updateValue(undefined, "codLarghezzaCerchio");
                          setErrors((prevState) => {
                            return {
                              ...prevState,
                              codLarghezzaCerchio: t(
                                "common:validatorErrors:valueNotFound"
                              ),
                            };
                          });
                        }
                      } else {
                        updateValue(undefined, "codLarghezzaCerchio");
                      }
                    }}
                    onChange={(e) => {
                      setSelectedCerchio(e.target.value);
                    }}
                    value={selectedCerchio}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectStrutture
                    id="codStruttura"
                    value={(articolo as Articoli)?.codStruttura || null}
                    onChange={handleChangeSelectValue}
                    error={errors["codStruttura"] ? true : false}
                    helperText={errors["codStruttura"] || ""}
                    required={requiredFields.includes("codStruttura")}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <TextField
                    id="codIndiceCarico1"
                    size="small"
                    required={requiredFields.includes("codIndiceCarico1")}
                    label={`${t("common:articoliIndiceCarico1")}`}
                    error={errors["codIndiceCarico1"] ? true : false}
                    helperText={errors["codIndiceCarico1"] || ""}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const indice = indiciDiCarico.find(
                          (x) => x.indiceCarico === e.target.value
                        );
                        if (indice) {
                          updateValue(
                            indice.codIndiceCarico,
                            "codIndiceCarico1"
                          );
                        } else {
                          updateValue(undefined, "codIndiceCarico1");
                          setErrors((prevState) => {
                            return {
                              ...prevState,
                              codIndiceCarico1: t(
                                "common:validatorErrors:valueNotFound"
                              ),
                            };
                          });
                        }
                      } else {
                        updateValue(undefined, "codIndiceCarico1");
                      }
                    }}
                    onChange={(e) => {
                      setSelectedIndiceDiCarico1(e.target.value);
                    }}
                    value={selectedIndiceDiCarico1}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <TextField
                    id="codIndiceCarico2"
                    size="small"
                    required={requiredFields.includes("codIndiceCarico2")}
                    label={`${t("common:articoliIndiceCarico2")}`}
                    error={errors["codIndiceCarico2"] ? true : false}
                    helperText={errors["codIndiceCarico2"] || ""}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const indice = indiciDiCarico.find(
                          (x) => x.indiceCarico === e.target.value
                        );
                        if (indice) {
                          updateValue(
                            indice.codIndiceCarico,
                            "codIndiceCarico2"
                          );
                        } else {
                          updateValue(undefined, "codIndiceCarico2");
                          setErrors((prevState) => {
                            return {
                              ...prevState,
                              codIndiceCarico2: t(
                                "common:validatorErrors:valueNotFound"
                              ),
                            };
                          });
                        }
                      } else {
                        updateValue(undefined, "codIndiceCarico2");
                      }
                    }}
                    onChange={(e) => {
                      setSelectedIndiceDiCarico2(e.target.value);
                    }}
                    value={selectedIndiceDiCarico2}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <TextField
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    id="codVelocita"
                    size="small"
                    required={requiredFields.includes("codVelocita")}
                    label={`${t("common:codVelocita")}`}
                    error={errors["codVelocita"] ? true : false}
                    helperText={errors["codVelocita"] || ""}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const codiceVelocita = codiciVelocita.find(
                          (x) => x.simbolo === e.target.value
                        );
                        if (codiceVelocita) {
                          updateValue(
                            codiceVelocita.codVelocita,
                            "codVelocita"
                          );
                        } else {
                          updateValue(undefined, "codVelocita");
                          setErrors((prevState) => {
                            return {
                              ...prevState,
                              codVelocita: t(
                                "common:validatorErrors:valueNotFound"
                              ),
                            };
                          });
                        }
                      } else {
                        updateValue(undefined, "codVelocita");
                      }
                    }}
                    onChange={(e) => {
                      setSelectedVelocita(e.target.value.toUpperCase());
                    }}
                    value={selectedVelocita}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectStagionalita
                    id="codStagionalita"
                    value={(articolo as Articoli)?.codStagionalita}
                    onChange={handleChangeSelectValue}
                    error={errors["codStagionalita"] ? true : false}
                    helperText={errors["codStagionalita"] || ""}
                    required={requiredFields.includes("codStagionalita")}
                  />
                </Box>
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectDisegni
                    id="codDisegno"
                    value={(articolo as Articoli)?.codDisegno}
                    onChange={handleChangeSelectValue}
                    autocomplete={true}
                    codDivisione="1"
                    codProduttore={(
                      articolo as Articoli
                    )?.codProduttore?.toString()}
                    codTipoArticolo={(
                      articolo as Articoli
                    )?.codTipoArticolo?.toString()}
                    error={errors["codDisegno"] ? true : false}
                    helperText={errors["codDisegno"] || ""}
                    required={requiredFields.includes("codDisegno")}
                  />
                </Box>
              </Box>
            </Paper>
            <Paper className="p-4 mb-4">
              <Box className="flex flex-grow flex-row flex-wrap">
                <Typography variant="h6" className="px-4">
                  {t("common:articoliDatiGenerali")}
                </Typography>
              </Box>
              <Box className="flex flex-grow flex-row flex-wrap">
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectUnitaDiMisura
                    id="codUdM"
                    value={(articolo as Articoli)?.codUdM}
                    onChange={handleChangeSelectValue}
                    error={errors["codUdm"] ? true : false}
                    helperText={errors["codUdm"] || ""}
                    required
                  />
                </Box>
                <TextField
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:articoliqtaDefault")} *`}
                  error={errors["qtaDefault"] ? true : false}
                  helperText={errors["qtaDefault"] || ""}
                  onChange={(e) => handleChangeValueArticolo(e, "qtaDefault")}
                  value={articolo?.qtaDefault || ""}
                  style={{ minWidth: "15rem" }}
                />
                <Box
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  style={{ minWidth: "15rem" }}
                >
                  <SelectCausaliIva
                    id="codIVA"
                    value={(articolo as Articoli)?.codIVA}
                    onChange={handleChangeSelectValue}
                    autocomplete={true}
                    error={errors["codIVA"] ? true : false}
                    helperText={errors["codIVA"] || ""}
                    required
                  />
                </Box>
                <TextField
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:barCode")}
                  error={errors["barCode"] ? true : false}
                  helperText={errors["barCode"] || ""}
                  onChange={(e) => handleChangeValueArticolo(e, "barCode")}
                  value={articolo?.barCode || ""}
                  style={{ minWidth: "15rem" }}
                />
                <FormControlLabel
                  className="px-4 w-full xl:w-2/12 md:w-6/12"
                  control={
                    <Checkbox
                      checked={
                        articolo?.articoliDatiAggiuntivi?.flgRunOnFlat || false
                      }
                      disableRipple={true}
                      onChange={(e) =>
                        handleChangeArticoliDatiAggiuntivi(
                          {
                            target: {
                              value: !(
                                articolo?.articoliDatiAggiuntivi
                                  ?.flgRunOnFlat || false
                              ),
                            },
                          },
                          "flgRunOnFlat"
                        )
                      }
                      color="primary"
                    />
                  }
                  style={{ minWidth: "15rem" }}
                  label={t("common:articoliflgRunOnFlat")}
                />
                <FormControlLabel
                  className="px-4 mr-0 w-full xl:w-2/12 md:w-6/12"
                  control={
                    <Checkbox
                      checked={articolo?.flgInEstinzione || false}
                      disableRipple={true}
                      onChange={(e) =>
                        handleChangeValueArticolo(
                          {
                            target: {
                              value: !(articolo.flgInEstinzione || false),
                            },
                          },
                          "flgInEstinzione"
                        )
                      }
                      color="primary"
                    />
                  }
                  style={{ minWidth: "15rem" }}
                  label={t("common:articoliflgInEstinzione")}
                />
              </Box>
            </Paper>
            <Paper className="p-4 mb-4">
              <Box className="flex flex-grow flex-row flex-wrap">
                <Typography variant="h6" className="px-4">
                  {t("common:articoliDataLabeling")}
                </Typography>
              </Box>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:articolirollingResistance")}
                  error={errors["rollingResistance"] ? true : false}
                  helperText={errors["rollingResistance"] || ""}
                  onChange={(e) =>
                    handleChangeArticoliLabeling(e, "rollingResistance")
                  }
                  value={
                    articolo?.articoliLabeling?.rollingResistance?.toString() ||
                    ""
                  }
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:articoliwetGrip")}
                  error={errors["wetGrip"] ? true : false}
                  helperText={errors["wetGrip"] || ""}
                  onChange={(e) => handleChangeArticoliLabeling(e, "wetGrip")}
                  value={articolo?.articoliLabeling?.wetGrip?.toString() || ""}
                  style={{ minWidth: "15rem" }}
                />
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:articolinoisePerformance")}
                  error={errors["noisePerformance"] ? true : false}
                  helperText={errors["noisePerformance"] || ""}
                  onChange={(e) =>
                    handleChangeArticoliLabeling(
                      {
                        target: {
                          value: e.target.value !== "" ? e.target.value : 0,
                        },
                      },
                      "noisePerformance"
                    )
                  }
                  value={
                    articolo?.articoliLabeling?.noisePerformance?.toString() ||
                    ""
                  }
                  style={{ minWidth: "15rem" }}
                  type="number"
                />
                <TextField
                  className="px-4 w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={t("common:articolinumberWaves")}
                  error={errors["numberWaves"] ? true : false}
                  helperText={errors["numberWaves"] || ""}
                  onChange={(e) =>
                    handleChangeArticoliLabeling(
                      {
                        target: {
                          value: e.target.value !== "" ? e.target.value : 0,
                        },
                      },
                      "numberWaves"
                    )
                  }
                  value={
                    articolo?.articoliLabeling?.numberWaves?.toString() || ""
                  }
                  style={{ minWidth: "15rem" }}
                  type="number"
                />
              </Box>
            </Paper>
            <Paper className="p-4">
              <Box className="flex flex-grow flex-row flex-wrap">
                <Typography variant="h6" className="px-4">
                  {t("common:datiListino")}
                </Typography>
              </Box>
              <Typography className="mb-4 mt-5 px-4 font-bold">
                {t("common:listinoClienti")}
              </Typography>
              <DataGridListiniPneumatico
                livelliListino={livelliListino}
                onChangeListino={handleChangeListinoClienti}
                listini={articolo.listiniClienti}
              />
              {/* Per il momento non verrà visualizzato il listino fornitore
                                    <Typography className='mb-4 mt-5 px-4 font-bold'>{t('common:listinoFornitori')}</Typography>
                                    <DataGridListiniPneumatico
                                        livelliListino={livelliListino.filter(livListino => livListino.codLivelloListino === 0)}
                                        onChangeListino={handleChangeListinoFornitori}
                                        listini={articolo.listiniFornitori}
                                    />
                                    */}
            </Paper>
            {Auth.hasAccess("save-articoli") ? (
              <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
            ) : null}
          </Box>
        </Fragment>
      ) : null}
    </div>
  );
}
