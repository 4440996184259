import { AxiosAPI } from '@store/axios.config';
import { AppDispatch, AppThunk, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import i18n from '@assets/i18n';
import { setDateListini, setListiniIndex } from './listini.actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DuplicaListini, ImportListiniSummary, Listino, NewListino } from './listini.types';

const getBaseURL = (filters?: Filters) => {
    const clonedFilters = {...filters};

    if (clonedFilters && Object.keys(clonedFilters).length === 0) {
        return;
    }

    let url = '';
    if (clonedFilters && 'listinoType' in clonedFilters) {
        switch (clonedFilters.listinoType.value) {
            case 'clienti':
                url = 'ListinoClienti';
                break;
            case 'fornitori':
                    url = 'ListinoFornitori';
                    break;
        }
        delete clonedFilters.listinoType;
    }

    if (clonedFilters && 'listinoPeriod' in clonedFilters) {
        switch (clonedFilters.listinoPeriod.value) {
            case 'futuro':
                url += 'Futuri';
                break;
            case 'storico':
                url += 'Storico';
                break;
        }
        delete clonedFilters.listinoPeriod;
    }

    return url;
}

export const getListiniIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {

        if (filters && Object.keys(filters).length === 0) {
            return;
        }

        const url = getBaseURL(filters);

        dispatch(setRequestLoader('getListiniIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/${url}?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setListiniIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getListiniIndex'));
    }
}

const pendingRequests: string[] = [];

export const getDateListini = (filters: Filters): AppThunk => async (dispatch, getState) => {

    try {

        if (filters && Object.keys(filters).length === 0) {
            return;
        }

        const filterSearch = createFilters(filters)
        const url = `/${getBaseURL(filters)}/Date?${filterSearch}`;

        if (pendingRequests.findIndex((request) => request === url) === -1) {
            pendingRequests.push(url)
            const response = await AxiosAPI.get(url)
            dispatch(setDateListini(response.data.data as string[]))
            pendingRequests.splice(pendingRequests.findIndex((request) => request === url), 1)
        }

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    }
}

interface PostListini {
    livelloListino: number
    codProduttore: number
    dataInizioValidita: string
    listini: NewListino[]
    flgReplicaSuListinoFornitori: boolean
}

export const postListini = createAsyncThunk<
    // Return type of the payload creator
    ImportListiniSummary,
    // First argument to the payload creator
    PostListini,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postListini', async (listini: PostListini, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postListini'));

        try {
            const response = await AxiosAPI.post(`/ListinoClienti`, listini)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as ImportListiniSummary
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postListini'));
        }
    })

export const putListini = (listini: Listino[], type: string, period: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putListini'));

    try {

        let url = '';
        switch (type) {
            case 'clienti':
                url = 'ListinoClienti';
                break;
            case 'fornitori':
                    url = 'ListinoFornitori';
                    break;
        }

        if (period === 'futuro') {
            url += 'Futuri';
        }

        await AxiosAPI.put(`/${url}`, listini)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
    } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putListini'));
    }
}

interface CronologiaListino {
    livelloListino: number
    codArticolo: string
    type: string
}

export const getCronologiaListino = createAsyncThunk<
    // Return type of the payload creator
    Listino[],
    // First argument to the payload creator
    CronologiaListino,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getCronologiaListino', async (cronologiaListino: CronologiaListino, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('getCronologiaListino'));

        try {
            let url = 'ListinoClienti';
            if (cronologiaListino.type === 'fornitori') {
                url = 'ListinoFornitori';
            }
            url += `/cronologia/${cronologiaListino.livelloListino}/${cronologiaListino.codArticolo}`;

            const response = await AxiosAPI.get(`/${url}`)
            return response.data as Listino[]

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            }
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('getCronologiaListino'));
        }
    })

interface DuplicaListiniData {
    duplicaListini: DuplicaListini
    filters: Filters
}

export const duplicateListini = createAsyncThunk<
    // Return type of the payload creator
    boolean | undefined,
    // First argument to the payload creator
    DuplicaListiniData,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('duplicaListini', async (duplicaListiniData: DuplicaListiniData, { rejectWithValue, dispatch, getState }) => {

        if (duplicaListiniData.filters && Object.keys(duplicaListiniData.filters).length === 0) {
            return;
        }

        const url = getBaseURL(duplicaListiniData.filters);
        const filterSearch = createFilters(duplicaListiniData.filters)

        dispatch(setRequestLoader('duplicaListini'));

        try {
            await AxiosAPI.post(`/${url}/duplica?1=1${filterSearch}`, duplicaListiniData.duplicaListini)
            return true;

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            }
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('duplicaListini'));
        }
    })

export const aggiornaIva = (aggiornaIvaData: {
    dataInizioValidita: string
    aliquotaIva: number
}): AppThunk => async (dispatch) => {
    dispatch(setRequestLoader('aggiornaIva'));

    try {
        await AxiosAPI.put(`/ListinoClienti/iva`, aggiornaIvaData)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('aggiornaIva'));
    }
}

export const aggiornaLisiniFuturi = createAsyncThunk<
    // Return type of the payload creator
    boolean,
    // First argument to the payload creator
    undefined,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    // eslint-disable-next-line
    }>('getUltimaDataListino', async (undefined, { rejectWithValue, dispatch, getState }) => {
    
        dispatch(setRequestLoader('aggiornaLisiniFuturi'));

        try {
            await AxiosAPI.put(`/ListinoClienti/futuri`)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            return true;

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            }
        } finally {
            dispatch(removeRequestLoader('aggiornaLisiniFuturi'));
        }
        return false;
    })

export const getUltimaDataListino = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    Filters,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getUltimaDataListino', async (filters: Filters, { rejectWithValue, dispatch, getState }) => {

        const filterSearch = createFilters(filters)

        try {
            const response = await AxiosAPI.get(`/ListinoClienti/ultimaDataListino?1=1${filterSearch}`)
            return response.data;

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            }
            throw new Error(error);
        }
    })