import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { globalRuoli } from "@store/global/global.selector";
import { getGlobalRuoli } from "@store/global/global.effects";

export function SelectRuoli(props: CustomSelectProps) {
    
    const { t } = useTranslation(['common'])
    const role = useAppSelector(globalRuoli);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalRuoli())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:role`)}
            data={role}
            descriptionKey={'name'}
            valueKey={'id'}
            {...props}
        />
    );
}