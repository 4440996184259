import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelectProps } from "..";
import { CustomSelect } from "../custom-select/CustomSelect";
import { globalEntiConvenzionati } from "@store/global/global.selector";
import { getGlobalEntiConvenzionati } from "@store/global/global.effects";

export function SelectEntiConvenzionati(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const entiConv = useAppSelector(globalEntiConvenzionati);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalEntiConvenzionati())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:entiConvenzionati`)}
            data={entiConv}
            descriptionKey={'ragioneSociale'}
            valueKey={'codCliente'}
            {...props}
        />
    );
}
