import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelectProps } from "../CustomSelectProps";
import { CustomSelect } from "../custom-select/CustomSelect";
import { globalStagionalita } from "@store/global/global.selector";
import { getGlobalStagionalita } from "@store/global/global.effects";

export function SelectStagionalita(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const stagionalita = useAppSelector(globalStagionalita);



    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalStagionalita())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:stagionalita`)}
            data={stagionalita}
            descriptionKey={'descrizione'}
            valueKey={'codStagionalita'}
            {...props}
        />
    );
}
