import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import React, { useState } from "react";
import { BuildOutlined, Restore, Save } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import {
  ConfirmAction,
  ConfirmActionPropsData,
} from "@components/alert/ConfirmAction";
import { ReactComponent as TrafficLightIcon } from "@assets/icons/Full/Traffic-light.svg";
import _ from "lodash";
import { useAppDispatch } from "@store/hooks";
import { semaphorePut } from "@store/semaphore/semaphore.effects";
import Auth from "@utilities/auth";

export interface Action {
  icon: React.ReactNode;
  tooltip: string;
  onClick: () => void;
  hidden?: boolean;
}

interface CommonSpeedDialProps {
  onSave?: () => void;
  disableSaveConfirmDialog?: boolean;
  onCancel?: () => void;
  updateData?: {
    taskName: string;
    entities: string[] | null;
  }[];
  otherActions?: Action[];
}

function getSpeedDialAction(
  key: string,
  icon: React.ReactNode,
  title: string,
  onClick: () => void
): React.ReactNode {
  return (
    <SpeedDialAction
      key={key}
      icon={icon}
      tooltipTitle={title}
      onClick={onClick}
    />
  );
}

export function CommonSpeedDial(props: CommonSpeedDialProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  const [open, setOpen] = useState<boolean>(false);
  const {
    onSave,
    disableSaveConfirmDialog = false,
    onCancel,
    updateData,
    otherActions = [],
  } = props;
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  const actions: React.ReactNode[] = [];

  if (onSave) {
    actions.push(
      getSpeedDialAction(
        "save",
        <Save color="primary" />,
        t("common:save"),
        () => {
          if (!disableSaveConfirmDialog) {
            setConfirmAction({
              isOpen: true,
              title: t("common:message:defaultConfirmationTitle"),
              subtitle: t("common:message:defaultConfirmationMessage"),
              confirmAction: () => {
                onSave();
              },
            });
          } else {
            onSave();
          }
        }
      )
    );
  }

  if (onCancel) {
    actions.push(
      getSpeedDialAction(
        "cancel",
        <Restore style={{ color: "#000" }} />,
        t("common:cancel"),
        onCancel
      )
    );
  }

  if (updateData && Auth.hasAccess("send-updates")) {
    actions.push(
      getSpeedDialAction(
        "updateData",
        <TrafficLightIcon fill="rgba(45, 201, 55, 1)" />,
        t("common:startUpdateJob"),
        () => {
          setConfirmAction({
            isOpen: true,
            title: t("common:message:defaultConfirmationTitle"),
            subtitle: t("common:message:defaultConfirmationMessage"),
            confirmAction: () => {
              updateData.forEach((x) => {
                dispatch(
                  semaphorePut({
                    taskName: x.taskName,
                    entities: x.entities,
                    green: true,
                  })
                );
              });
            },
          });
        }
      )
    );
  }

  otherActions.forEach((action: Action, index: any) => {
    if (!action.hidden) {
      actions.push(
        getSpeedDialAction(index, action.icon, action.tooltip, action.onClick)
      );
    }
  });

  return (
    <div className="fixed bottom-10 right-10 z-index-9999">
      {
        // nascondo lo speed dial se non ci sono azioni all'interno
        actions.length > 0 ? (
          <SpeedDial
            ariaLabel="common-speed-dial"
            icon={<BuildOutlined />}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          >
            {actions.map((action) => action)}
          </SpeedDial>
        ) : null
      }
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
    </div>
  );
}

const dataEqual = (prevProps: any, nextProps: any) => {
  if (
    _.isEqual(prevProps.otherActions.tooltip, nextProps.otherActions.tooltip)
  ) {
    console.log("speedDialeData is equal so block Rerender");
    return true; // dose not render grid
  } else {
    return false; // dose RENDER grid
  }
};

export const CommonSpeedDialNoReRender = React.memo(CommonSpeedDial, dataEqual);
