import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState, ResourcesIndexThunk } from '../store'
import {
    setAnagraficaIndex,
    setAnagraficaShow,
    setAnagraficaTata,
    setDatiAggiuntiviTata
} from './anagrafica.actions'
import environment from '@environment/environment'

import { setError, setSuccess } from '../common/common.effects'
import { push } from 'connected-react-router';
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { Filters, Pagination, SortModel } from '@store/common/common.types';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { AnagraficaGoodyear, DatiAggiuntiviSedeOp } from './anagrafica.types';
import routes from '@utilities/routes';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface AnagraficaGoodyearIndex {
    data: AnagraficaGoodyear[]
    meta: {
        pagination?: Pagination
    }
}

export const getActiveAnagraficheGoodyear = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    undefined,

    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getActiveAnagraficheGoodyear', async (_, { rejectWithValue, dispatch, getState }) => {
        try {
                
            const response = await AxiosAPI.get(`/AnagraficaGoodyear/Attive/Count`)
            return response.data as AnagraficaGoodyearIndex

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            }
        }
    })

export const getAnagraficaIndex: ResourcesIndexThunk = (page: number = 1, sizePage = environment.anagrafica.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getAnagraficaIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/AnagraficaGoodyear?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setAnagraficaIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getAnagraficaIndex'));
    }
}

export const getAnagraficaShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getAnagraficaShow'));

    try {
        const response = await AxiosAPI.get(`/AnagraficaGoodyear/${id}`)
        dispatch(setAnagraficaShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getAnagraficaShow'));
    }
}
export const getAnagraficaTata = (id: string, codSedeOperativa: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getAnagraficaTata'));

    try {
        const response = await AxiosAPI.get(`/AnagraficaGoodyear/${id}/${codSedeOperativa}`)
        dispatch(setAnagraficaTata(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getAnagraficaTata'));
    }
}

export const getDatiAggiuntiviSedeOpTata = (id: string, codSedeOperativa: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getDatiAggiuntiviSedeOpTata'));

    try {
        const response = await AxiosAPI.get(`/DatiAggiuntiviSedeOp/${id}/${codSedeOperativa}`)
        dispatch(setDatiAggiuntiviTata(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getDatiAggiuntiviSedeOpTata'));
    }
}

export const putAnagrafica = (anagrafica: AnagraficaGoodyear): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putAnagrafica'));

    try {
        const response = await AxiosAPI.put(`/AnagraficaGoodyear/${anagrafica.codDitta}`, anagrafica)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        dispatch(setAnagraficaShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putAnagrafica'));
    }
}

export const putDatiAggiuntiviSedeOp = (datiAggiuntiviSedeOp: DatiAggiuntiviSedeOp): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putDatiAggiuntiviSedeOp'));

    try {
        const response = await AxiosAPI.put(`/DatiAggiuntiviSedeOp/${datiAggiuntiviSedeOp.codDitta}/${datiAggiuntiviSedeOp.codSedeOperativa}`
        , datiAggiuntiviSedeOp)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

        dispatch(setDatiAggiuntiviTata(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putDatiAggiuntiviSedeOp'));
    }
}

export const postAnagrafica = (anagrafica: AnagraficaGoodyear): AppThunk => async dispatch => {

    dispatch(setRequestLoader('postAnagrafica'));

    try {
        const response = await AxiosAPI.post(`/AnagraficaGoodyear`, anagrafica)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(setAnagraficaShow(response.data))
        dispatch(push(routes.anagrafica.show.replace(':id', response.data.codDitta)))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('postAnagrafica'));
    }
}


