import { GridSortItem } from "@material-ui/data-grid";

export interface Common {
    sidebar: {
        isOpen: boolean
    },
    customAlert: {
        isOpen: boolean,
        payload: CustomAlert
    }
    loader: string[]
}

export interface CustomAlert {
    severity: 'error' | 'warning' | 'info' | 'success' | undefined,
    title?: string
    message?: string
}
export interface SearchAdvanceed {
    data: any //TODO : correct data 
}

export interface Pagination {
    totalCount: number,
    pageSize: number,
    currentPage: number,
    totalPages: number,
    hasNext: boolean,
    hasPrevious: boolean
}

export enum FilterMethods {
    CONTAINS = 'like:',
    GREATERTHAN = 'gt:',
    LESSTHAN = 'lt:',
    EQUAL = 'eq:',
    EQUALIN = 'eqin:',
    NOTEQUAL = 'ne:',
    GREATERTHANOREQUALTO = 'ge:',
    LESSTHANOREQUALTO = 'le:',
    IN = 'in:',
    STARTWITH = 'sw:'

}

export enum FilterTypes {
    AND = 'and',
    OR = 'or'
}

export interface Filters {
    [key: string]: Filter
}

export class Filter {
    /**
     * @description Type of the filter
     * @type FilterTypes
     */
    public type: FilterTypes;
    /**
     * @description Param of the filter
     * @type string
     */
    public param: string;
    /**
     * @description Method of the filter
     * @type FilterMethods | ''
     */
    public method: FilterMethods | '';
    /**
     * @description Value of the filter
     * @type string
     */
    public value: string;
    /**
     * Create a new instance of Filter
     * @param type: FilterTypes
     * @param param: string
     * @param method: FilterMethods | ''
     * @param value: string
     */
    public constructor(type: FilterTypes, param: string, method: FilterMethods | '', value: string) {
        this.type = type;
        this.param = param;
        this.method = method;
        this.value = value;
    }
}

export class FieldFilter extends Filter {
    /**
     * @description ID of the filter
     * @type string
     */
    public id: string;
    /**
     * @description Label of the filter
     * @type string
     */
    public label: string;
    /**
     * @description Always active or not
     * @type boolean
     */
    public alwaysActive: boolean;
    /**
     * @description Always active or not
     * @type boolean
     */
    public unDeletable: boolean;
    /**
     * Create a new instance of FieldFilter
     * @param id: string
     * @param label: string
     * @param type: FilterTypes
     * @param param: string
     * @param method: FilterMethods | ''
     * @param value: string
     * @param alwaysActive: boolean = false
     * @param unDeletable: boolean = false
     */
    public constructor(id: string, label: string, type: FilterTypes, param: string, method: FilterMethods | '', value: string, alwaysActive: boolean = false, unDeletable: boolean = false) {
        super(type, param, method, value);
        this.id = id;
        this.label = label;
        this.alwaysActive = alwaysActive
        this.unDeletable = unDeletable;
    }
}

export interface SortModel extends GridSortItem {
    field: string
    //sort: string
}