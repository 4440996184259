import { useAppDispatch, useAppSelector } from "@store/hooks";
import routes from "@utilities/routes";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router-dom";

import { CommonSpeedDial, CustomHeaderPage } from "@components/index";
import {
  getLarghezzeNominaliShow,
  postLarghezzeNominali,
  putLarghezzeNominali,
} from "@store/larghezze-nominali/larghezzeNominali.effects";
import {
  LarghezzaNominale,
  NewLarghezzaNominale,
} from "@store/larghezze-nominali/larghezzeNominali.types";
import { larghezzeNominaliShow } from "@store/larghezze-nominali/larghezzeNominali.selector";
import { Box, Paper, TextField } from "@material-ui/core";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { resetLarghezzeNominaliShow } from "@store/larghezze-nominali/larghezzeNominali.actions";
import { push } from "connected-react-router";
import { AssociazioneLargNominali } from "../components/AssociazioneLargNominali";
import { scrollFunction } from "@utilities/utilities";
import { setError } from "@store/common/common.effects";
import Auth from "@utilities/auth";

interface LarghezzeNominaliDetailProps extends RouteComponentProps<{}> {
  creationMode: boolean;
}
export function LarghezzeNominaliDetail({
  match,
  creationMode,
}: LarghezzeNominaliDetailProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "larghezzeNominaliDetailsPage"]);

  const { id } = useParams<{ id: string }>();
  const [newElement, setNewElement] = useState<boolean>(false);

  const pollingLarghezzaNominale = useAppSelector(larghezzeNominaliShow);
  const [larghezzaNominale, setLarghezzaNominale] = useState<
    LarghezzaNominale | NewLarghezzaNominale
  >({});

  const [errors, setErrors] = useState({} as { [key: string]: string });

  // componentDidMount()
  useEffect(() => {
    scrollFunction();

    if (creationMode) return setNewElement(true);

    setNewElement(false);
    dispatch(getLarghezzeNominaliShow(id));
  }, [dispatch, id, creationMode]);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingLarghezzaNominale) {
      setNewElement(false);
      setLarghezzaNominale(pollingLarghezzaNominale);
    }
  }, [pollingLarghezzaNominale]);

  // componentDidUnmount()
  useEffect(() => {
    return () => {
      dispatch(resetLarghezzeNominaliShow());
    };
  }, [dispatch]);

  const handleChangeValue = (e: SyntheticEvent, param: string) => {
    setErrors({});

    const target = e.target as typeof e.target & { value: string };

    setLarghezzaNominale((prevState) => {
      return {
        ...prevState,
        [param]: target.value,
      };
    });
  };

  const validate = () => {
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "larghezzaNominale",
        larghezzaNominale?.larghezzaNominale,
        [ValidationTypes.REQUIRED, ValidationTypes.RANGE_LENGTH],
        { maxLength: 6 }
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate() || !larghezzaNominale) {
      dispatch(setError({ message: t("common:message:validationError") }));
      return;
    }

    if (newElement) {
      const newLarghezzaNominale: NewLarghezzaNominale = {
        ...larghezzaNominale,
        codLarghezzaNominale: 0,
        utente: "SYSTEM",
      };

      const resultAction = await dispatch(
        postLarghezzeNominali(newLarghezzaNominale)
      );
      if (postLarghezzeNominali.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        dispatch(
          push(
            routes.larghezzeNominali.show.replace(
              ":id",
              result.codLarghezzaNominale.toString()
            )
          )
        );
      } else {
        //error
      }
    } else {
      dispatch(putLarghezzeNominali(larghezzaNominale as LarghezzaNominale));
    }
  };

  const handleCancel = () => {
    setErrors({});
    if (!creationMode) {
      dispatch(getLarghezzeNominaliShow(id));
    } else {
      setLarghezzaNominale({});
    }
  };

  return (
    <div>
      {larghezzaNominale?.codLarghezzaNominale || newElement ? (
        <Fragment>
          <CustomHeaderPage
            title={
              newElement
                ? `${t("larghezzeNominaliDetailPage:newElementTitle")}`
                : `${t("larghezzeNominaliDetailPage:title")} - ${
                    larghezzaNominale.larghezzaNominale
                  }`
            }
          ></CustomHeaderPage>
          <Box className="flex flex-grow flex-col mb-4">
            <Paper>
              <Box className="flex flex-grow flex-row flex-wrap pb-2">
                {!larghezzaNominale?.codLarghezzaNominale ? null : (
                  <TextField
                    className="px-4 w-full xl:w-3/12 md:w-6/12"
                    size="small"
                    InputLabelProps={{ className: "pl-4, mx-4" }}
                    label={t("common:codLarghezzaNominale")}
                    onChange={(e) =>
                      handleChangeValue(e, "codLarghezzaNominale")
                    }
                    value={larghezzaNominale?.codLarghezzaNominale || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                  />
                )}
                <TextField
                  className="px-4 w-full xl:w-3/12 md:w-6/12"
                  size="small"
                  InputLabelProps={{ className: "pl-4, mx-4" }}
                  label={`${t("common:larghezzaNominale")}*`}
                  error={errors["larghezzaNominale"] ? true : false}
                  helperText={errors["larghezzaNominale"] || ""}
                  onChange={(e) => handleChangeValue(e, "larghezzaNominale")}
                  value={larghezzaNominale?.larghezzaNominale || ""}
                />
              </Box>
            </Paper>
          </Box>
          {!newElement ? <AssociazioneLargNominali /> : null}
          {Auth.hasAccess("save-larghezzenominali") ? (
            <CommonSpeedDial onSave={handleSave} onCancel={handleCancel} />
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
