import { createReducer } from '@reduxjs/toolkit'
import { Global } from './global.types'
import { setGlobalCapiZona, setGlobalConvenzioniGeneriche, setGlobalEntiConvenzionati, setGlobalCategorieMerceologice, setGlobalCausaliIva, setGlobalCodiciVelocita, setGlobalDisegni, setGlobalDistributori, setGlobalDivisioni, setGlobalIndiciDiCarico, setGlobalLarghezzeCerchi, setGlobalLarghezzeNominali, setGlobalLivelliListino, setGlobalMarche, setGlobalModelli, setGlobalMonete, setGlobalNazioni, setGlobalPagamenti, setGlobalProduttori, setGlobalProgrammi, setGlobalProvince, setGlobalRegioni, setGlobalReti, setGlobalRuoli, setGlobalSerieGomme, setGlobalServers, setGlobalStagionalita, setGlobalStrutture, setGlobalTasks, setGlobalTipiArticolo, setGlobalTipiCausaliIva, setGlobalTipiDocumento, setGlobalUnitaDiMisura, setGlobalSemaphoresTasks, setGlobalTipiContrattoFlotte,setGlobalScontoAddizionale } from './global.actions'

const initialState: Global = {
    capiZona: [],
    categorieMerceologiche: [],
    causaliIva: [],
    codiciVelocita: [],
    convenzioniGeneriche: [],
    disegni: [],
    distributori: [],
    divisioni: [],
    entiConvenzionati: [],
    indiciDiCarico: [],
    larghezzeCerchi: [],
    larghezzeNominali: [],
    livelliListino: [],
    marche: [],
    modelli: [],
    monete: [],
    pagamenti: [],
    produttori: [],
    programmi: [],
    province: [],
    nazioni: [],
    regioni: [],
    reti: [],
    role: [],
    semaphoresTasks: [],
    serieGomme: [],
    servers: [],
    stagionalita: [],
    strutture: [],
    tasks: [],
    tipiArticolo: [],
    tipiCausaliIva: [],
    tipiContrattoFlotte: [],
    tipiDocumento: [],
    unitaDiMisura: [],
    scontoAddizionale: []
}

export const globalReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setGlobalCapiZona, (state, action) => {
            return {
                ...state,
                capiZona: action.payload
            }
        })
        .addCase(setGlobalCategorieMerceologice, (state, action) => {
            return {
                ...state,
                categorieMerceologiche: action.payload
            }
        })
        .addCase(setGlobalCausaliIva, (state, action) => {
            return {
                ...state,
                causaliIva: action.payload
            }
        })
        .addCase(setGlobalCodiciVelocita, (state, action) => {
            return {
                ...state,
                codiciVelocita: action.payload
            }
        })
        .addCase(setGlobalConvenzioniGeneriche, (state, action) => {
            return {
                ...state,
                convenzioniGeneriche: action.payload
            }
        })
        .addCase(setGlobalDisegni, (state, action) => {
            return {
                ...state,
                disegni: action.payload
            }
        })
        .addCase(setGlobalDistributori, (state, action) => {
            return {
                ...state,
                distributori: action.payload
            }
        })
        .addCase(setGlobalDivisioni, (state, action) => {
            return {
                ...state,
                divisioni: action.payload
            }
        })
        .addCase(setGlobalEntiConvenzionati, (state, action) => {
            return {
                ...state,
                entiConvenzionati: action.payload
            }
        })
        .addCase(setGlobalIndiciDiCarico, (state, action) => {
            return {
                ...state,
                indiciDiCarico: action.payload
            }
        })
        .addCase(setGlobalLarghezzeCerchi, (state, action) => {
            return {
                ...state,
                larghezzeCerchi: action.payload
            }
        })
        .addCase(setGlobalLarghezzeNominali, (state, action) => {
            return {
                ...state,
                larghezzeNominali: action.payload
            }
        })
        .addCase(setGlobalLivelliListino, (state, action) => {
            return {
                ...state,
                livelliListino: action.payload
            }
        })
        .addCase(setGlobalMarche, (state, action) => {
            return {
                ...state,
                marche: action.payload
            }
        })
        .addCase(setGlobalModelli, (state, action) => {
            return {
                ...state,
                modelli: action.payload
            }
        })
        .addCase(setGlobalMonete, (state, action) => {
            return {
                ...state,
                monete: action.payload
            }
        })
        .addCase(setGlobalPagamenti, (state, action) => {
            return {
                ...state,
                pagamenti: action.payload
            }
        })
        .addCase(setGlobalProduttori, (state, action) => {
            return {
                ...state,
                produttori: action.payload
            }
        })
        .addCase(setGlobalProgrammi, (state, action) => {
            return {
                ...state,
                programmi: action.payload
            }
        })
        .addCase(setGlobalProvince, (state, action) => {
            return {
                ...state,
                province: action.payload
            }
        })

        .addCase(setGlobalNazioni, (state, action) => {
            return {
                ...state,
                nazioni: action.payload
            }
        })

        .addCase(setGlobalRegioni, (state, action) => {
            return {
                ...state,
                regioni: action.payload
            }
        })
        .addCase(setGlobalReti, (state, action) => {
            return {
                ...state,
                reti: action.payload
            }
        })
        .addCase(setGlobalRuoli, (state, action) => {
            return {
                ...state,
                role: action.payload
            }
        })
        .addCase(setGlobalSemaphoresTasks, (state, action) => {
            return {
                ...state,
                semaphoresTasks: action.payload
            }
        })
        .addCase(setGlobalSerieGomme, (state, action) => {
            return {
                ...state,
                serieGomme: action.payload
            }
        })
        .addCase(setGlobalServers, (state, action) => {
            return {
                ...state,
                servers: action.payload
            }
        })
        .addCase(setGlobalStagionalita, (state, action) => {
            return {
                ...state,
                stagionalita: action.payload
            }
        })
        .addCase(setGlobalStrutture, (state, action) => {
            return {
                ...state,
                strutture: action.payload
            }
        })
        .addCase(setGlobalTasks, (state, action) => {
            return {
                ...state,
                tasks: action.payload
            }
        })
        .addCase(setGlobalTipiArticolo, (state, action) => {
            return {
                ...state,
                tipiArticolo: action.payload
            }
        })
        .addCase(setGlobalTipiCausaliIva, (state, action) => {
            return {
                ...state,
                tipiCausaliIva: action.payload
            }
        })
        .addCase(setGlobalTipiContrattoFlotte, (state, action) => {
            return {
                ...state,
                tipiContrattoFlotte: action.payload
            }
        })
        .addCase(setGlobalTipiDocumento, (state, action) => {
            return {
                ...state,
                tipiDocumento: action.payload
            }
        })
        .addCase(setGlobalUnitaDiMisura, (state, action) => {
            return {
                ...state,
                unitaDiMisura: action.payload
            }
        })       
        .addCase(setGlobalScontoAddizionale, (state, action) => {
            return {
                ...state,
                scontoAddizionale: action.payload
            }
        })
})

