import { createReducer } from '@reduxjs/toolkit'
import {
    setSerieGommeTipoArtIndex,
    setSerieGommeTipoArtShow,
} from './serieGommeTipoArt.actions'
import { SerieGommeTipoArtIndex, SerieGommeTipoArtShow } from './serieGommeTipoArt.types'

const initialState: {
    index: SerieGommeTipoArtIndex
    show: SerieGommeTipoArtShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const serieGommeTipoArtReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setSerieGommeTipoArtIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setSerieGommeTipoArtShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
})

