import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setDesConvenzioniGenericheIndex, setDesConvenzioniGenericheShow } from './desConvenzioniGeneriche.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { DesConvenzioniGeneriche, NewDesConvenzioniGeneriche } from './desConvenzioniGeneriche.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalConvenzioniGeneriche } from '@store/global/global.effects';


export const getDesConvenzioniGenericheIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getDesConvenzioniGenericheIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/DesConvenzioniGeneriche?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setDesConvenzioniGenericheIndex(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getDesConvenzioniGenericheIndex'));
    }
}


export const getDesConvenzioniGenericheShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getDesConvenzioniGenericheShow'));

    try {
        const response = await AxiosAPI.get(`/DesConvenzioniGeneriche/${id}`)
        dispatch(setDesConvenzioniGenericheShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getDesConvenzioniGenericheShow'));
    }
}


export const postDesConvenzioniGeneriche = createAsyncThunk<
    // Return type of the payload creator
    DesConvenzioniGeneriche,
    // First argument to the payload creator
    NewDesConvenzioniGeneriche,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postDesConvenzioniGeneriche', async (desConvenzioniGeneriche: NewDesConvenzioniGeneriche, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postDesConvenzioniGeneriche'));

        try {
            const response = await AxiosAPI.post(`/DesConvenzioniGeneriche`, desConvenzioniGeneriche)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalConvenzioniGeneriche(true))
            return response.data as DesConvenzioniGeneriche
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postDesConvenzioniGeneriche'));
        }
    })


export const putDesConvenzioniGeneriche = (desConvenzioniGeneriche: DesConvenzioniGeneriche): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putDesConvenzioniGeneriche'));

    try {
        const { codConvGenerica } = desConvenzioniGeneriche

        await AxiosAPI.put(`/DesConvenzioniGeneriche/${codConvGenerica}`, desConvenzioniGeneriche)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalConvenzioniGeneriche(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putDesConvenzioniGeneriche'));
    }
}


export const deleteDesConvenzioniGeneriche = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    DesConvenzioniGeneriche,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteDesConvenzioniGeneriche', async (desConvenzioniGeneriche: DesConvenzioniGeneriche, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteDesConvenzioniGeneriche'));

        try {
            const { codConvGenerica } = desConvenzioniGeneriche

            await AxiosAPI.delete(`/DesConvenzioniGeneriche/${codConvGenerica}`)

            const currentState = getState().desConvenzioniGeneriche.index;
            const data = currentState.data.filter((item) => item.codConvGenerica !== codConvGenerica)
            dispatch(setDesConvenzioniGenericheIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalConvenzioniGeneriche(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteDesConvenzioniGeneriche'));
        }
    })
