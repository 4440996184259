import { createReducer } from '@reduxjs/toolkit'
import {
    setLivelloListinoIndex,
    setLivelloListinoShow,
    resetLivelloListinoShow
} from '@store/livello-listino/livelloListino.actions'
import { LivelloListinoIndex, LivelloListinoShow } from './livelloListino.types'

const initialState: {
    index: LivelloListinoIndex
    show: LivelloListinoShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const livelloListinoReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLivelloListinoIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setLivelloListinoShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetLivelloListinoShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})



