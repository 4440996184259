import { FunctionComponent } from "react";
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as RowLeftIcon } from '@assets/icons/Full/Arrow-left.svg';
import { goBack, push } from 'connected-react-router';
import { useAppDispatch } from "@store/hooks";



interface CustomHeaderPageProps {
    title?: string
    goBackUrl?: string | boolean
    disabledGoBack?: boolean
}

export const CustomHeaderPage: FunctionComponent<CustomHeaderPageProps> = (props) => {

    const { title, goBackUrl, children, disabledGoBack = false } = props;

    const dispatch = useAppDispatch()

    const handleGoBack = (goBackUrl?: string | boolean) => {
        if (goBackUrl && typeof goBackUrl === 'string') dispatch(push(goBackUrl))
        else dispatch(goBack())
    }

    return (
        <Box display='flex' width="100%" paddingY="1rem">
            {!disabledGoBack ?
                <Button
                    style={{
                        border: "none",
                        outline: "none",
                        width: "50px",
                        minWidth: "50px",
                        padding: "6px 15px"
                    }}
                    variant="text"
                    className=""
                    onClick={() => handleGoBack(goBackUrl)}
                >
                    <RowLeftIcon className='w-full h-6' color='' />
                </Button>
                : null
            }
            <Typography className='flex-grow' component='h1' variant='h4'>
                {title}
            </Typography>
            {children}
        </Box>
    );
}