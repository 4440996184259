import { useAppDispatch, useAppSelector } from "@store/hooks";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Button, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { IndiciDiCarico } from "@store/indici-di-carico/indiciDiCarico.types";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { indiciDiCaricoShow } from "@store/indici-di-carico/indiciDiCarico.selector";
import { Grid } from "@material-ui/core";
import {
  deleteIndiciDiCaricoTipoArt,
  postIndiciDiCaricoTipoArt,
} from "@store/ind-carico-tipo-art/indCaricoTipoArt.effects";
import { IndCaricoTipoArt } from "@store/ind-carico-tipo-art/indCaricoTipoArt.types";
import { SelectTipiArticolo } from "@components/selects";
import Auth from "@utilities/auth";

export function AssociazioneTipoArticolo() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "indiciDiCaricoDetailPage"]);

  const pollingIndiciDiCarico = useAppSelector(indiciDiCaricoShow);
  const [indiceDiCarico, setIndiceDiCarico] = useState<
    IndiciDiCarico | undefined
  >(undefined);
  const [tipoArticoloSearch, setTipoArticoloSearch] = useState(0);

  // componentDidUpdate()
  useEffect(() => {
    if (pollingIndiciDiCarico) {
      setIndiceDiCarico(pollingIndiciDiCarico);
    }
  }, [pollingIndiciDiCarico]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (indiceDiCarico && tipoArticoloSearch > 0) {
      dispatch(
        postIndiciDiCaricoTipoArt(
          indiceDiCarico.codIndiceCarico,
          tipoArticoloSearch
        )
      );
      setTipoArticoloSearch(0);
    }
  };

  const handlerTipoArticoloSearch = (value: any, id: string) => {
    setTipoArticoloSearch(value);
  };

  const columns: GridColDef[] = [
    {
      field: "codTipoArticolo",
      headerName: `${t("common:tipiArticolo")}`,
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      valueGetter: (params) => {
        const row = params.row as typeof params.row & IndCaricoTipoArt;
        return row?.tipiArticolo?.descrizione || "";
      },
    },
  ];

  if (Auth.hasAccess("save-indicidicarico")) {
    columns.push({
      field: "remove",
      headerName: `${t("common:remove")}`,
      headerAlign: "center",
      align: "center",
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const indCaricoTipoArt = params.row as typeof params.row &
            IndCaricoTipoArt;
          const { codIndiceCarico, codTipoArticolo } = indCaricoTipoArt;

          dispatch(
            deleteIndiciDiCaricoTipoArt(codIndiceCarico, codTipoArticolo)
          );
        };

        return (
          <IconButton
            onClick={onClick}
            style={{
              border: "none",
              outline: "none",
            }}
            edge="start"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    });
  }

  return (
    <Paper>
      <Box className="flex flex-col flex-grow p-4">
        {Auth.hasAccess("save-indicidicarico") ? (
          <form onSubmit={handleSubmit}>
            <Box className="flex flex-wrap flex-col lg:flex-row">
              <SelectTipiArticolo
                id={"tipiArticolo"}
                onChange={handlerTipoArticoloSearch}
                value={tipoArticoloSearch}
                codDivisione="1"
                autocomplete={true}
                style={{ minWidth: "15rem" }}
              />
              <Button
                type="submit"
                className="self-center rounded-full h-10 w-10 min-w-0 mt-2 ml-8"
                style={{
                  border: "none",
                  outline: "none",
                }}
                color="primary"
              >
                <AddIcon />
              </Button>
            </Box>
          </form>
        ) : null}
        <Grid container>
          <DataGrid
            rows={indiceDiCarico?.indCaricoTipoArt || []}
            autoHeight
            columns={columns}
            getRowId={(row) => {
              return `${row.codIndiceCarico}${row.codTipoArticolo}`;
            }}
            disableColumnFilter
            hideFooter
          />
        </Grid>
      </Box>
    </Paper>
  );
}
