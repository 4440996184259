import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setDettaglioScontiConvenzioniIndex, setDettaglioScontiConvenzioniShow } from './dettaglioScontiConvenzioni.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { DettaglioScontiConvenzioni, DettaglioScontiConvenzioniDate, NewDettaglioScontiConvenzioni, PrezziNettiConvenzioniDate } from './dettaglioScontiConvenzioni.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getDettaglioScontiConvenzioniIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getDettaglioScontiConvenzioniIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/DettaglioScontiConvenzioni?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setDettaglioScontiConvenzioniIndex(response.data))

    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getDettaglioScontiConvenzioniIndex'));
    }
}

export const getDettaglioScontiConvenzioniShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getDettaglioScontiConvenzioniShow'));

    try {
        const response = await AxiosAPI.get(`/DettaglioScontiConvenzioni/${id}`)
        dispatch(setDettaglioScontiConvenzioniShow(response.data.data))
    } catch (error) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getDettaglioScontiConvenzioniShow'));
    }
}


export const postDettaglioScontiConvenzioni = createAsyncThunk<
    // Return type of the payload creator
    DettaglioScontiConvenzioni,
    // First argument to the payload creator
    NewDettaglioScontiConvenzioni,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postDettaglioScontiConvenzioni', async (dettaglioScontiConvenzioni: NewDettaglioScontiConvenzioni, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postDettaglioScontiConvenzioni'));

        try {
            const response = await AxiosAPI.post(`/DettaglioScontiConvenzioni`, dettaglioScontiConvenzioni)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as DettaglioScontiConvenzioni
        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postDettaglioScontiConvenzioni'));
        }
    })

export const putDettaglioScontiConvenzioni = (dettaglioScontiConvenzioni: DettaglioScontiConvenzioni): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putDettaglioScontiConvenzioni'));

    try {
        const { progConvenzione } = dettaglioScontiConvenzioni

        await AxiosAPI.put(`/DettaglioScontiConvenzioni/${progConvenzione}`, dettaglioScontiConvenzioni)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error) {
        dispatch(setError({ message: error.message }))
    } finally {
        dispatch(removeRequestLoader('putDettaglioScontiConvenzioni'));
    }
}

export const deleteDettaglioScontiConvenzioni = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    DettaglioScontiConvenzioni,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteDettaglioScontiConvenzioni', async (dettaglioScontiConvenzioni: DettaglioScontiConvenzioni, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteDettaglioScontiConvenzioni'));

        try {
            const { progConvenzione } = dettaglioScontiConvenzioni

            await AxiosAPI.delete(`/DettaglioScontiConvenzioni/${progConvenzione}`)

            const currentState = getState().dettaglioScontiConvenzioni.index;
            const data = currentState.data.filter((item) => item.progConvenzione !== progConvenzione)
            dispatch(setDettaglioScontiConvenzioniIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteDettaglioScontiConvenzioni'));
        }
    })
export const getDettaglioScontiConvenzioniDate = createAsyncThunk<
    // Return type of the payload creator
    DettaglioScontiConvenzioniDate,
    // First argument to the payload creator
    String,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteDettaglioScontiConvenzioni', async (id: String, { rejectWithValue, dispatch, getState }) => {

        //dispatch(setRequestLoader('getDettaglioScontiConvenzioniDate'));

        try {
            const response = await AxiosAPI.get(`DettaglioScontiConvenzioni/GetDateRange/${id}`)

            //dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

            return response.data

        } catch (error) {
            dispatch(setError({ message: error.message }));
            throw new Error(error);
        } finally {
            //dispatch(removeRequestLoader('getDettaglioScontiConvenzioniDate'));
        }
    })
export const getPrezziNettiConvenzioniDate = createAsyncThunk<
    // Return type of the payload creator
    PrezziNettiConvenzioniDate,
    // First argument to the payload creator
    String,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deletePrezziNettiConvenzioni', async (id: String, { rejectWithValue, dispatch, getState }) => {

        //dispatch(setRequestLoader('getDettaglioScontiConvenzioniDate'));

        try {
            const response = await AxiosAPI.get(`PrezziNettiConvenzioni/GetDateRange/${id}`)

            //dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

            return response.data

        } catch (error) {
            dispatch(setError({ message: error.message }));
            throw new Error(error);
        } finally {
            //dispatch(removeRequestLoader('getDettaglioScontiConvenzioniDate'));
        }
    })
