import React from 'react';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { customAlert } from '@store/common/common.selector'
import { closeCustomAlert } from '@store/common/common.actions';



const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function CustomAlert() {
  const classes = useStyles();
  const dispatch = useAppDispatch()

  const currentAlert = useAppSelector(customAlert);
  const detailsAlert = currentAlert.payload

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeCustomAlert())
  };

  return (
    <div className={classes.main}>
      <Snackbar open={currentAlert.isOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert className='flex items-center w-96' variant="filled" onClose={handleClose} severity={detailsAlert.severity}>
          <AlertTitle> {detailsAlert.title} </AlertTitle>
          {detailsAlert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
