import { createReducer } from '@reduxjs/toolkit';
//import { Pagination } from '@store/common/common.types'
import {
    setProvinceIndex,
    setProvinceShow,
    resetProvinceShow
} from './province.actions'
import { ProvinceIndex, ProvinceShow } from './province.types'

/* const initialState: {
    index: {
        data: Province[]
        meta: {
            pagination: Pagination | undefined
        }
    },
    show: {
        data: Province | undefined
    }
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
} */

const initialState: {
    index: ProvinceIndex,
    show: ProvinceShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const provinceReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setProvinceIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setProvinceShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetProvinceShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

