import React from "react";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../selects/custom-select/CustomSelect";

interface BooleanFilterProps {
  label: string;
  id: string;
  value: any;
  onChange: (value: any, id: string) => void;
}

export function BooleanFilter(props: BooleanFilterProps) {

    const { label, id, value, onChange } = props;
    const { t } = useTranslation(['common'])

    return (
        <CustomSelect
            label={label}
            data={[
              {
                value: "true",
                description: t("common:yes"),
              },
              {
                value: "false",
                description: t("common:no"),
              },
            ]}
            descriptionKey={"description"}
            valueKey={"value"}
            id={id}
            onChange={onChange}
            value={value}
          />
    );
}
