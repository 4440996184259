import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalTipiContrattoFlotte } from "@store/global/global.effects";
import { globalTipiContrattoFlotte } from "@store/global/global.selector";
import { TipoContratto } from "@store/tipi-contratto/tipiContratto.type";

export interface CustomSelectTipiContrattoProps extends CustomSelectProps {
  codEnteConv?: string
}

export function SelectTipiContrattoFlotte(props: CustomSelectTipiContrattoProps) {

    const { codEnteConv } = props;

    const { t } = useTranslation(['common'])
    const allTipiContratto = useAppSelector(globalTipiContrattoFlotte);
    const [tipiContratto, setTipiContratto] = useState<TipoContratto[]>([])

    // reload disegni on change produttore or tipo articolo
    useEffect(() => {
        let filteredTipiContratto: TipoContratto[] = allTipiContratto;
        if (codEnteConv) {
            filteredTipiContratto = filteredTipiContratto.filter(t => t.codCliente === Number(codEnteConv));
        } else {
            filteredTipiContratto = [];
        }
        setTipiContratto(filteredTipiContratto);
    }, [allTipiContratto, codEnteConv]) // eslint-disable-line react-hooks/exhaustive-deps

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalTipiContrattoFlotte())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:tipoContratto`)}
            data={tipiContratto}
            descriptionKey={"nomeContratto"}
            valueKey={'id'}
            {...props}
        />
    );
}
