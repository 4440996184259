import { createAction } from '@reduxjs/toolkit';
//import { Pagination } from '@store/common/common.types';
import { Monete, MoneteIndex } from './monete.types';
/* import { Monete } from './monete.types'; */

/* export const setMoneteIndex = createAction<{
    data: Monete[]
    meta: {
        pagination: Pagination
    }
}>('monete/index') */

export const setMoneteIndex = createAction<MoneteIndex>('monete/index')
export const setMoneteShow = createAction<Monete>('monete/show')
export const resetMoneteShow = createAction('monete/rest')