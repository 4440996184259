import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { ConvenzioniFlotte } from './convenzioniFlotte.types'

export const setConvenzioniFlotteIndex = createAction<{
    data: ConvenzioniFlotte[]
    meta: {
        pagination?: Pagination
    }
}>('convenzioniFlotte/index')

export const setConvenzioniFlotteShow = createAction<ConvenzioniFlotte>('convenzioniFlotte/show')
export const resetConvenzioniFlotteShow = createAction('convenzioniFlotte/reset')