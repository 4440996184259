import { createReducer } from '@reduxjs/toolkit'
import {
    setArticoliLabelingIndex,
    setArticoliLabelingShow,
    resetArticoliLabelingShow
} from '@store/articoli-labeling/articoliLabeling.actions'
import { ArticoliLabelingIndex, ArticoliLabelingShow } from './articoliLabeling.types'

const initialState: {
    index: ArticoliLabelingIndex
    show: ArticoliLabelingShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const articoliLabelingReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setArticoliLabelingIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setArticoliLabelingShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetArticoliLabelingShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

