import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setCausaliIvaIndex, setCausaliIvaShow, setTipiCausaliIvaIndex } from './causaliIva.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { CausaliIva, NewCausaliIva } from './causaliIva.type';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalCausaliIva } from '@store/global/global.effects';

export const getCausaliIvaIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getCausaliIvaIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/CausaliIva?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setCausaliIvaIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCausaliIvaIndex'));
    }
}

export const getCausaliIvaShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getCausaliIvaShow'));

    try {
        const response = await AxiosAPI.get(`/CausaliIva/${id}`)
        dispatch(setCausaliIvaShow(response.data.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCausaliIvaShow'));
    }
}

export const postCausaliIva = createAsyncThunk<
    // Return type of the payload creator
    CausaliIva,
    // First argument to the payload creator
    NewCausaliIva,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postCausaliIva', async (causaliIva: NewCausaliIva, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postCausaliIva'));

        try {
            const response = await AxiosAPI.post(`/CausaliIva`, causaliIva)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalCausaliIva(true))
            return response.data as CausaliIva
        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postCausaliIva'));
        }
    })

export const putCausaliIva = (causaliIva: CausaliIva): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putCausaliIva'));

    try {
        const { codIVA } = causaliIva

        await AxiosAPI.put(`/CausaliIva/${codIVA}`, causaliIva)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalCausaliIva(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putCausaliIva'));
    }
}


export const deleteCausaliIva = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    CausaliIva,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteCausaliIva', async (causaliIva: CausaliIva, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteCausaliIva'));

        try {
            const { codIVA } = causaliIva

            await AxiosAPI.delete(`/CausaliIva/${codIVA}`)

            const currentState = getState().causaliIva.index;
            const data = currentState.data.filter((item) => item.codIVA !== codIVA)
            dispatch(setCausaliIvaIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalCausaliIva(true))
        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteCausaliIva'));
        }
    })

export const getTipiCausaliIvaIndex = (): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getCausaliIvaIndex'));

        const response = await AxiosAPI.get(`/CausaliIva/tipi`)
        dispatch(setTipiCausaliIvaIndex(response.data.sort()))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getCausaliIvaIndex'));
    }
}
