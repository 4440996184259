import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setSerieGommeIndex, setSerieGommeShow } from './serieGomme.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { NewSerieGomme, SerieGomme } from './serieGomme.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalSerieGomme } from '@store/global/global.effects';


export const getSerieGommeIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getSerieGommeIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/SerieGomme?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setSerieGommeIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getSerieGommeIndex'));
    }
}


export const getSerieGommeShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getSerieGommeShow'));

    try {
        const response = await AxiosAPI.get(`/SerieGomme/${id}`)
        dispatch(setSerieGommeShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getSerieGommeShow'));
    }
}


export const postSerieGomme = createAsyncThunk<
    // Return type of the payload creator
    SerieGomme,
    // First argument to the payload creator
    NewSerieGomme,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postSerieGomme', async (serieGomme: NewSerieGomme, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postSerieGomme'));

        try {
            const response = await AxiosAPI.post(`/SerieGomme`, serieGomme)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalSerieGomme(true))
            return response.data as SerieGomme
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postSerieGomme'));
        }
    })


export const putSerieGomme = (serieGomme: SerieGomme): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putSerieGomme'));

    try {
        const { codSerie } = serieGomme

        await AxiosAPI.put(`/SerieGomme/${codSerie}`, serieGomme)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalSerieGomme(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putSerieGomme'));
    }
}


export const deleteSerieGomme = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    SerieGomme,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteSerieGomme', async (serieGomme: SerieGomme, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteSerieGomme'));

        try {
            const { codSerie } = serieGomme

            await AxiosAPI.delete(`/SerieGomme/${codSerie}`)

            const currentState = getState().serieGomme.index;
            const data = currentState.data.filter((item) => item.codSerie !== codSerie)
            dispatch(setSerieGommeIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalSerieGomme(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteSerieGomme'));
        }
    })
