import { createReducer } from '@reduxjs/toolkit'
import {
    setIndCaricoTipoArtIndex,
    setIndCaricoTipoArtShow,
} from './indCaricoTipoArt.actions'
import { IndCaricoTipoArtIndex, IndCaricoTipoArtShow } from './indCaricoTipoArt.types'

const initialState: {
    index: IndCaricoTipoArtIndex
    show: IndCaricoTipoArtShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const IndCaricoTipoArtReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setIndCaricoTipoArtIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setIndCaricoTipoArtShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
})

