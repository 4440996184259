import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@store/hooks";

import { eserciziIndex } from "@store/esercizi/esercizi.selector";
import {
  getEserciziIndex,
  postEsercizi,
} from "@store/esercizi/esercizi.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
  ConfirmActionPropsData,
  ConfirmAction,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Box, Button, IconButton, TextField } from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { globalMonete } from "@store/global/global.selector";
import { SelectMonete } from "@components/selects";
import { Esercizi } from "@store/esercizi/esercizi.type";
import { useIndexPage } from "@pages/common/UseIndexPage";
import moment from "moment";
import environment from "@environment/environment";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
  COD_MONETA = "filter-codMonetaRif",
  DATA_INIZIO = "filter-dataInizio",
  DATA_FINE = "filter-dataFine",
}

export function EserciziIndex() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["eserciziIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`eserciziIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "codEsercizio,descrizione",
      "",
      ""
    ),
    codMonetaRif: new FieldFilter(
      FILTER_ID.COD_MONETA,
      t(`eserciziIndexPage:filter:filtercodMonetaRif`),
      FilterTypes.AND,
      "codMonetaRif",
      FilterMethods.EQUAL,
      ""
    ),
    dataInizio: new FieldFilter(
      FILTER_ID.DATA_INIZIO,
      t(`eserciziIndexPage:filter:filterdataInizio`),
      FilterTypes.AND,
      "dataInizio",
      FilterMethods.GREATERTHANOREQUALTO,
      ``
    ),
    dataFine: new FieldFilter(
      FILTER_ID.DATA_FINE,
      t(`eserciziIndexPage:filter:filterdataFine`),
      FilterTypes.AND,
      "dataFine",
      FilterMethods.LESSTHANOREQUALTO,
      ``
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    pageSize,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<Esercizi>(
    initialFilter,
    [{ field: "codEsercizio", sort: "desc" }],
    getEserciziIndex,
    eserciziIndex
  );

  const monete = useAppSelector(globalMonete);

  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  const handleNewExercise = () => {
    setConfirmAction({
      isOpen: true,
      title: t("common:message:defaultConfirmationTitle"),
      subtitle: t("common:message:defaultConfirmationMessage"),
      confirmAction: () => {
        dispatch(postEsercizi())
          .unwrap()
          .then(() => {
            dispatch(
              getEserciziIndex(1, pageSize, filters.activeFilters, sortColumns)
            );
          });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "codEsercizio",
      headerName: t("common:codEsercizio"),
      flex: 1,
      valueGetter: (params) => params.row.codEsercizio,
    },
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "dataInizio",
      headerName: t("common:dataInizio"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        return moment(new Date(params.row.dataInizio as string)).format(
          "DD/MM/YYYY"
        );
      },
    },
    {
      field: "dataFine",
      headerName: t("common:dataFine"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        return moment(new Date(params.row.dataFine as string)).format(
          "DD/MM/YYYY"
        );
      },
    },
    {
      field: "codMonetaRif",
      headerName: t("common:moneta"),
      flex: 1,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        const row = params.row as typeof params.row;

        const codMonetaRif = row.codMonetaRif;
        const moneta = monete?.find((moneta) => {
          return codMonetaRif === moneta.codMoneta;
        });

        return moneta?.descrizione;
      },
    },
    {
      field: "flgBloccoFatturazioneGY",
      headerName: t("common:fatturazione"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      flex: 1,
      type: "boolean",
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(routes.esercizi.show.replace(":id", params.row.codEsercizio))
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`eserciziIndexPage:title`)} disabledGoBack>
            {Auth.hasAccess("save-esercizi") ? (
              <Button variant="outlined" onClick={handleNewExercise}>
                <AddIcon color="primary" />
                {t("eserciziIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectMonete
                    id={FILTER_ID.COD_MONETA}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.codMonetaRif.value}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    type="Date"
                    fullWidth
                    size="small"
                    id={FILTER_ID.DATA_INIZIO}
                    InputLabelProps={{ shrink: true }}
                    value={filters.filterFields.dataInizio.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.dataInizio.id
                      )
                    }
                    label={filters.filterFields.dataInizio.label}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <TextField
                    type="Date"
                    fullWidth
                    size="small"
                    id={FILTER_ID.DATA_FINE}
                    InputLabelProps={{ shrink: true }}
                    value={filters.filterFields.dataFine.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.dataFine.id
                      )
                    }
                    label={filters.filterFields.dataFine.label}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.codEsercizio;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .esercizi,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.centralDatabaseUpdates.entities
                .altro,
            ],
          },
        ]}
      />
    </div>
  );
}
