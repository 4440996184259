import clsx from "clsx";
import moment from "moment";
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";

import { flowManagerIndex } from "@store/flow-manager/flowManager.selector";
import {
  getFlowManagerIndex,
  putTaskHistory,
} from "@store/flow-manager/flowManager.effects";
import { ReactComponent as TasksIcon } from "@assets/icons/Full/Cogs.svg";
import { ReactComponent as ServerIcon } from "@assets/icons/Full/Server.svg";
import { ReactComponent as TrafficLightIcon } from "@assets/icons/Full/Traffic-light.svg";

import { ReactComponent as TaskPlayIcon } from "@assets/icons/Outline/Cog-play-outline.svg";
import { ReactComponent as TaskStopIcon } from "@assets/icons/Outline/Cog-stop-outline.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  CustomHeaderPage,
  FiltersAccordion,
} from "@components/index";
import { GridColDef, GridCellParams } from "@material-ui/data-grid";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import { push } from "connected-react-router";
import routes from "@utilities/routes";
import {
  FlowManager,
  FlowManagerIndex as FlowManagerIndexType,
} from "@store/flow-manager/flowManager.type";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { SelectServers, SelectTasks } from "@components/selects";
import Auth from "@utilities/auth";
import cronstrue from 'cronstrue';
import 'cronstrue/locales/it';

export function FlowManagerIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["flowManagerIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    server: new FieldFilter(
      "server",
      t(`common:server`),
      FilterTypes.AND,
      "serverId",
      FilterMethods.EQUAL,
      ""
    ),
    task: new FieldFilter(
      "task",
      t(`common:task`),
      FilterTypes.AND,
      "taskId",
      FilterMethods.EQUAL,
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
  } = useIndexPage<FlowManager>(
    initialFilter,
    [
      { field: "server", sort: "asc" },
      { field: "task", sort: "asc" },
      { field: "version", sort: "asc" },
    ],
    getFlowManagerIndex,
    flowManagerIndex
  );

  const [flowManager, setFlowManager] = useState<FlowManagerIndexType>(
    {} as FlowManagerIndexType
  );
  useEffect(() => {
    setFlowManager(data);
  }, [data]);

  const updataCurrentData = () => {
    dispatch(
      getFlowManagerIndex(
        page,
        pageSize,
        filters.activeFilters,
        sortColumns,
        true
      )
    );
  };

  // Reload set interval on filters change
  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(updataCurrentData, 5000);
  }, [filters.activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  let interval: MutableRefObject<any> = useRef(null);

  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(updataCurrentData, 5000);
    return () => clearInterval(interval.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: GridColDef[] = [
    {
      field: "server",
      headerName: t("common:server"),
      width: 200,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.relatedSchedule.relatedScheduler?.relatedServer.name,
    },
    {
      field: "extraData",
      headerName: t("common:extraData"),
      width: 200,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) =>
        params.row.relatedSchedule.relatedScheduler?.relatedServer.extraData,
    },
    {
      field: "task",
      headerName: t("common:task"),
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip
            title={`${params.row.relatedSchedule.relatedTask?.name}${
              params.row.relatedSchedule.config
                ? " (" + params.row.relatedSchedule.config + ")"
                : ""
            }`}
          >
            <div>{params.row.relatedSchedule.relatedTask?.name}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "version",
      headerName: t("common:version"),
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      width: 120,
      valueGetter: (params) => params.row.relatedSchedule.relatedTask?.version,
    },
    {
      field: "status",
      headerName: t("common:status"),
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: (params: GridCellParams) =>
        clsx({
          lightGreen: params.value === "Idle",
          green: params.value === "Running",
          red: params.value === "Stopped",
        }),
      valueGetter: (params) => params.row.status,
    },
    {
      field: "type",
      headerName: t("common:periodicity"),
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      width: 120,
      renderCell: (params) => {

        let readableCron = '';
        try {
          readableCron = cronstrue.toString(
            params.row.relatedSchedule.type,
            { verbose: true, use24HourTimeFormat: true, locale: 'it' }
          );
        } catch(e) {}

        return (
          <Tooltip
            title={<Fragment>
              <div className="text-center">{params.row.relatedSchedule.type}</div>
              {readableCron}
            </Fragment>}
          >
            <div>{params.row.relatedSchedule.type}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "nextStartDate",
      headerName: t("common:nextStartDate"),
      width: 200,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        if (params.row.nextStartDate) {
          const date = moment(params.row.nextStartDate).format(
            "DD/MM/yyyy HH:mm:ss"
          );
          return `${date}`;
        }
        return "...";
      },
    },
    {
      field: "lastStartDate",
      headerName: t("common:lastStartDate"),
      width: 200,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        if (params.row.lastStartDate) {
          const date = moment(params.row.lastStartDate).format(
            "DD/MM/yyyy HH:mm:ss"
          );
          return `${date}`;
        }
        return "...";
      },
    },
    {
      field: "lastEndDate",
      headerName: t("common:lastEndDate"),
      width: 200,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        if (params.row.lastEndDate) {
          const date = moment(params.row.lastEndDate).format(
            "DD/MM/yyyy HH:mm:ss"
          );
          return `${date}`;
        }
        return "...";
      },
    },
  ];

  if (Auth.hasAccess("stopstart-flowmanager")) {
    columns.push({
      field: "actions",
      headerName: t("common:actions"),
      width: 100,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <CellActions
          status={params.row.status as string}
          id={params.row.id as string}
          preUpdateHistory={() => {
            clearInterval(interval.current);
          }}
          postUpdateHistory={(id: string, history: FlowManager) => {
            const tmpHistory = [...flowManager.data];
            const index = tmpHistory.findIndex((item) => item.id === id);
            if (index !== -1) {
              const result = { ...tmpHistory[index] };
              result.status = history.status;
              result.nextStartDate = history.nextStartDate;
              result.lastEndDate = history.lastEndDate;
              tmpHistory[index] = result;
            }

            setFlowManager((prevState) => {
              return {
                ...prevState,
                data: tmpHistory,
              };
            });
            interval.current = setInterval(updataCurrentData, 5000);
          }}
        />
      ),
    });
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`flowManagerIndexPage:title`)}
            disabledGoBack
          >
            <div>
              {Auth.hasAccess("view-tasks") ? (
                <Button
                  variant="outlined"
                  className="mr-5"
                  onClick={() => {
                    dispatch(push(routes.flowManager.tasks));
                  }}
                >
                  <TasksIcon
                    width="1.5rem"
                    height="1.5rem"
                    fill="#1D7EB5"
                    className="mr-3"
                  />
                  {t("flowManagerIndexPage:showAllTasks")}
                </Button>
              ) : null}
              {Auth.hasAccess("view-schedulers") ? (
                <Button
                  variant="outlined"
                  className="mr-5"
                  onClick={() => {
                    dispatch(push(routes.flowManager.servers));
                  }}
                >
                  <ServerIcon
                    width="1.5rem"
                    height="1.5rem"
                    fill="#1D7EB5"
                    className="mr-3"
                  />
                  {t("flowManagerIndexPage:showAllServers")}
                </Button>
              ) : null}
              {Auth.hasAccess("view-semaphores") ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(push(routes.flowManager.semaphores));
                  }}
                >
                  <TrafficLightIcon
                    width="1.5rem"
                    height="1.5rem"
                    fill="#1D7EB5"
                    className="mr-3"
                  />
                  {t("flowManagerIndexPage:handleSemaphores")}
                </Button>
              ) : null}
            </div>
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectServers
                    id={"server"}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.server.value}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12">
                  <SelectTasks
                    id={"task"}
                    onChange={handleSelectChangeValue}
                    value={filters.filterFields.task.value}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={flowManager}
              columns={columns}
              getRowId={(row) => {
                return row.id;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

function CellActions(props: {
  status: string;
  id: string;
  preUpdateHistory: () => void;
  postUpdateHistory: (id: string, history: FlowManager) => void;
}) {
  const { status, id, preUpdateHistory, postUpdateHistory } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  const stopTask = () => {
    preUpdateHistory();
    dispatch(
      putTaskHistory({
        id: id,
        status: "Stopped",
      })
    )
      .unwrap()
      .then((res: any) => {
        postUpdateHistory(id, res.data);
      });
  };

  const startTask = () => {
    preUpdateHistory();
    dispatch(
      putTaskHistory({
        id: id,
        status: "Idle",
      })
    )
      .unwrap()
      .then((res: any) => {
        postUpdateHistory(id, res.data);
      });
  };

  return (
    <Fragment>
      <Tooltip title={`${t("common:restart")}`}>
        <IconButton
          disabled={status === "Idle"}
          style={{
            border: "none",
            outline: "none",
          }}
          color="primary"
          size="small"
          onClick={startTask}
        >
          {status === "Stopped" ? (
            <TaskPlayIcon
              width="1.5rem"
              height="1.5rem"
              fill="rgba(0, 128, 0, 1)"
            />
          ) : (
            <TaskPlayIcon
              width="1.5rem"
              height="1.5rem"
              fill="rgba(0, 128, 0, 0.4)"
            />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title={`${t("common:stop")}`}>
        <IconButton
          disabled={status === "Stopped"}
          style={{
            border: "none",
            outline: "none",
          }}
          color="primary"
          size="small"
          onClick={stopTask}
        >
          {status === "Idle" ? (
            <TaskStopIcon
              width="1.5rem"
              height="1.5rem"
              fill="rgba(255, 0, 0, 1)"
            />
          ) : (
            <TaskStopIcon
              width="1.5rem"
              height="1.5rem"
              fill="rgba(255, 0, 0, 0.4)"
            />
          )}
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}
