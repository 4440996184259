import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "..";
import { getGlobalStrutture } from "@store/global/global.effects";
import { globalStrutture } from "@store/global/global.selector";

export function SelectStrutture(props: CustomSelectProps) {

    const { t } = useTranslation(['common'])
    const strutture = useAppSelector(globalStrutture);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGlobalStrutture())
    }, [dispatch]);

    return (
        <CustomSelect
            label={t(`common:struttura`)}
            data={strutture}
            descriptionKey={'descrizione'}
            valueKey={'codStruttura'}
            {...props}
        />
    );
}
