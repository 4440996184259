
import { useAppSelector } from '@store/hooks';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { loader } from '@store/common/common.selector'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

export function Loader() {
    const classes = useStyles();
    const requestPending = useAppSelector(loader);

    const handleClose = () => {
    };

    return (
        <Backdrop className={classes.backdrop} open={requestPending.length !== 0} onClick={handleClose}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )

}