import {
  Box,
  Button,
  IconButton,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { push } from "connected-react-router";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "@assets/icons/Full/Edit.svg";
import { anagraficaIndex } from "@store/anagrafica/anagrafica.selector";
import moment from "moment";
import {
  getAnagraficaIndex,
  getActiveAnagraficheGoodyear,
} from "@store/anagrafica/anagrafica.effects";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
  SortModel,
} from "@store/common/common.types";
import { resetAnagraficaIndex } from "@store/anagrafica/anagrafica.actions";
import { KeyboardArrowRight } from "@material-ui/icons";
import routes from "@utilities/routes";
import { getListiniIndex } from "@store/listini/listini.effects";
import { listiniIndex } from "@store/listini/listini.selector";
import { resetListiniIndex } from "@store/listini/listini.actions";
import { makeStyles } from "@material-ui/styles";
import { ReactComponent as UsersIcon } from "@assets/icons/Full/Account-multiple.svg";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/View-list.svg";
import { ReactComponent as TireIcon } from "@assets/icons/Full/Tire.svg";
import { getArticoliIndex } from "@store/articoli/articoli.effects";
import { articoliIndex } from "@store/articoli/articoli.selector";
import { resetArticoliIndex } from "@store/articoli/articoli.actions";
import { CustomHeaderPage } from "@components/index";
import { getMasterDataUserIndex } from "@store/master-data-user/masterDataUser.effects";
import { masterDataUserIndex } from "@store/master-data-user/masterDataUser.selector";
import { resetMasterDataUserIndex } from "@store/master-data-user/masterDataUser.actions";
import Auth from "@utilities/auth";

const useStyle = makeStyles({
  span: {
    color: "#66788A",
    fontSize: "12px",
    textTransform: "uppercase",
  },
});

const sortDataOperazione: SortModel = { field: "dataOperazione", sort: "desc" };

export function Dashboard() {
  const currentUser = JSON.parse(
    localStorage.getItem("user")
      ? (localStorage.getItem("user") as string)
      : "{}"
  );
  const currentUserFilter: FieldFilter = new FieldFilter(
    "current-user",
    "",
    FilterTypes.AND,
    "utente",
    FilterMethods.EQUAL,
    currentUser.userCode,
    true
  );

  const classes = useStyle();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["dashboardPage", "common"]);

  const ultimeAnagraficheModificate = useAppSelector(anagraficaIndex);
  const ultimiListiniModificati = useAppSelector(listiniIndex);
  const pneumatici = useAppSelector(articoliIndex);
  const utenti = useAppSelector(masterDataUserIndex);

  const [anagraficheAttive, setAnagraficheAttive] = useState<number>(0);
  const [showAllAnagrafiche, setShowAllAnagrafiche] = useState<boolean>(false);
  const [showAllListini, setShowAllListini] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getActiveAnagraficheGoodyear())
      .unwrap()
      .then((res) => {
        if (res && res.data) {
          setAnagraficheAttive(res.data as number);
        }
      });

    dispatch(
      getArticoliIndex(
        1,
        1,
        {
          codDivisione: new FieldFilter(
            "codDivisione",
            "",
            FilterTypes.AND,
            "codDivisione",
            FilterMethods.EQUAL,
            "1",
            true
          ),
        },
        []
      )
    );

    dispatch(
      getMasterDataUserIndex(
        1,
        1,
        {
          emailConfirmed: new FieldFilter(
            "emailConfirmed",
            "",
            FilterTypes.AND,
            "emailConfirmed",
            FilterMethods.EQUAL,
            "true",
            true
          ),
        },
        []
      )
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadAnagrafiche();
    loadListini();
  }, [showAllAnagrafiche, showAllListini]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAnagrafiche = () => {
    const filters: {
      [key: string]: FieldFilter;
    } = {
      codReteNonAttivi: new FieldFilter(
        "cod-rete-non-attivi",
        "",
        FilterTypes.AND,
        "codRete",
        FilterMethods.NOTEQUAL,
        "99",
        true
      ),
    };
    if (!showAllAnagrafiche) {
      filters["currentUser"] = currentUserFilter;
    }
    dispatch(getAnagraficaIndex(1, 5, filters, [sortDataOperazione]));
  };

  const loadListini = () => {
    const filters: {
      [key: string]: FieldFilter;
    } = {
      listinoType: new FieldFilter(
        "listinoType",
        "",
        FilterTypes.AND,
        "listinoType",
        FilterMethods.EQUAL,
        "clienti",
        true
      ),
    };
    if (!showAllListini) {
      filters["currentUser"] = currentUserFilter;
    }
    dispatch(getListiniIndex(1, 5, filters, [sortDataOperazione]));
  };

  useEffect(() => {
    return () => {
      dispatch(resetAnagraficaIndex());
      dispatch(resetListiniIndex());
      dispatch(resetArticoliIndex());
      dispatch(resetMasterDataUserIndex());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CustomHeaderPage title="Dashboard" disabledGoBack />
      <Box className="flex items-start flex-wrap mt-10">
        <Box className="w-full my-5 xl:my-0 xl:pl-0 xl:px-5 xl:w-1/3">
          <Paper className="p-4 border-left-secondary">
            <Box className="w-full flex justify-between items-center">
              <span className={classes.span}>{t("common:anagrafiche")}</span>
              {Auth.hasAccess("view-anagrafiche") ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => dispatch(push(routes.anagrafica.index))}
                >
                  {t("dashboardPage:vediTutteLeAnagrafiche")}
                  <KeyboardArrowRight color="primary" />
                </Button>
              ) : null}
            </Box>
            <Box>
              <Typography variant="h5">
                {t("common:anagraficheAttive")}
              </Typography>
            </Box>
            <Box className="flex items-center justify-between">
              <Typography variant="h3">
                {anagraficheAttive.toLocaleString("it")}
              </Typography>
              <DealersIcon width="5rem" height="5rem" fill="#000" />
            </Box>
          </Paper>
        </Box>
        <Box className="w-full my-5 xl:my-0 xl:px-5 xl:w-1/3">
          <Paper className="p-4 border-left-secondary">
            <Box className="w-full flex justify-between items-center">
              <span className={classes.span}>{t("common:pneumatici")}</span>
              {Auth.hasAccess("view-articoli") ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => dispatch(push(routes.pneumatici.index))}
                >
                  {t("dashboardPage:vediTuttiGliPneumatici")}
                  <KeyboardArrowRight color="primary" />
                </Button>
              ) : null}
            </Box>
            <Box>
              <Typography variant="h5">
                {t("common:pneumaticiRegistrati")}
              </Typography>
            </Box>
            <Box className="flex items-center justify-between">
              <Typography variant="h3">
                {pneumatici.meta.pagination?.totalCount.toLocaleString("it")}
              </Typography>
              <TireIcon width="5rem" height="5rem" fill="#000" />
            </Box>
          </Paper>
        </Box>
        <Box className="w-full my-5 xl:my-0 xl:pr-0 xl:px-5 xl:w-1/3">
          <Paper className="p-4 border-left-secondary">
            <Box className="w-full flex justify-between items-center">
              <span className={classes.span}>{t("common:users")}</span>
              {Auth.hasAccess("view-users") ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => dispatch(push(routes.users.index))}
                >
                  {t("dashboardPage:vediTuttiGliUtenti")}
                  <KeyboardArrowRight color="primary" />
                </Button>
              ) : null}
            </Box>
            <Box>
              <Typography variant="h5">{t("common:activeUsers")}</Typography>
            </Box>
            <Box className="flex items-center justify-between">
              <Typography variant="h3">
                {utenti.meta.pagination?.totalCount.toLocaleString("it")}
              </Typography>
              <UsersIcon width="5rem" height="5rem" fill="#000" />
            </Box>
          </Paper>
        </Box>
        {Auth.hasAccess("view-anagrafiche") ? (
          <Box className="w-full my-10 xl:pl-0 xl:px-5 xl:w-1/2">
            <Paper className="p-4">
              <Box className="w-full pb-5 flex flex-col items-start md:flex-row md:justify-between md:items-center">
                <div className="flex flex-col items-start">
                  <Typography variant="h6">
                    {t("dashboardPage:ultimeAnagraficheModificate")}
                  </Typography>
                  <div className="flex items-center">
                    <span className={!showAllAnagrafiche ? "font-bold" : ""}>
                      {t(`common:editedByMe`)}
                    </span>
                    <Switch
                      className="always-colored"
                      color="primary"
                      checked={showAllAnagrafiche}
                      onChange={(event: any) => {
                        setShowAllAnagrafiche(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span className={showAllAnagrafiche ? "font-bold" : ""}>
                      {t(`common:showAll`)}
                    </span>
                  </div>
                </div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => dispatch(push(routes.anagrafica.index))}
                >
                  {t("dashboardPage:vediTutteLeAnagrafiche")}
                  <KeyboardArrowRight color="primary" />
                </Button>
              </Box>
              <DataGrid
                autoHeight
                hideFooter
                sortModel={[{ field: "dataOperazione", sort: "desc" }]}
                rows={ultimeAnagraficheModificate?.data}
                columns={[
                  {
                    field: "codDitta",
                    headerName: t("common:codDitta"),
                    flex: 1,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    sortable: false,
                  },
                  {
                    field: "ragioneSociale",
                    headerName: t("common:ragioneSociale"),
                    flex: 1,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    sortable: false,
                  },
                  {
                    field: "dataOperazione",
                    headerName: t("common:dataOperazione"),
                    flex: 1,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    sortable: false,
                    valueFormatter: ({ value }) => {
                      return moment(new Date(value as string)).format(
                        "DD/MM/YYYY"
                      );
                    },
                  },
                  {
                    field: "edit",
                    headerName: t("common:edit"),
                    headerAlign: "center",
                    align: "center",
                    disableColumnMenu: true,
                    disableClickEventBubbling: true,
                    sortable: false,
                    renderCell: (params) => {
                      const onClick = () => {
                        return dispatch(
                          push(`/anagrafica/${params.row.codDitta}`)
                        );
                      };

                      return (
                        <IconButton
                          style={{
                            border: "none",
                            outline: "none",
                          }}
                          color="inherit"
                          size="small"
                          onClick={onClick}
                        >
                          <EditIcon />
                        </IconButton>
                      );
                    },
                  },
                ]}
                getRowId={(row) => {
                  return row.codDitta;
                }}
              />
            </Paper>
          </Box>
        ) : null}
        {Auth.hasAccess("view-listini") ? (
          <Box className="w-full my-10 xl:pr-0 xl:px-5 xl:w-1/2">
            <Paper className="p-4">
              <Box className="w-full pb-5 flex flex-col items-start md:flex-row md:justify-between md:items-center">
                <div className="flex flex-col items-start">
                  <Typography variant="h6">
                    {t("dashboardPage:ultimiListiniModificati")}
                  </Typography>
                  <div className="flex items-center">
                    <span className={!showAllListini ? "font-bold" : ""}>
                      {t(`common:editedByMe`)}
                    </span>
                    <Switch
                      className="always-colored"
                      color="primary"
                      checked={showAllListini}
                      onChange={(event: any) => {
                        setShowAllListini(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span className={showAllListini ? "font-bold" : ""}>
                      {t(`common:showAll`)}
                    </span>
                  </div>
                </div>

                <Button
                  variant="text"
                  color="primary"
                  onClick={() =>
                    dispatch(push(routes.priceList.anagraficaListini))
                  }
                >
                  {t("dashboardPage:vediTuttiIListini")}
                  <KeyboardArrowRight color="primary" />
                </Button>
              </Box>
              <DataGrid
                autoHeight
                hideFooter
                sortModel={[{ field: "dataOperazione", sort: "desc" }]}
                rows={ultimiListiniModificati?.data}
                columns={[
                  {
                    field: "livListino",
                    headerName: t("common:livelloListino"),
                    flex: 1,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    sortable: false,
                    valueGetter: (params) => {
                      return params.row.livListino.descrizione;
                    },
                  },
                  {
                    field: "codArticolo",
                    headerName: t("common:codArticolo"),
                    flex: 1,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    sortable: false,
                  },
                  {
                    field: "prezzoEuro",
                    headerName: t("common:prezzoEuro"),
                    flex: 1,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    sortable: false,
                    type: "number",
                    valueFormatter: ({ value }) => {
                      return Number(value).toFixed(2);
                    },
                  },
                  {
                    field: "dataOperazione",
                    headerName: t("common:dataOperazione"),
                    flex: 1,
                    disableClickEventBubbling: true,
                    disableColumnMenu: true,
                    sortable: false,
                    valueFormatter: ({ value }) => {
                      return moment(new Date(value as string)).format(
                        "DD/MM/YYYY"
                      );
                    },
                  },
                ]}
                getRowId={(row) => {
                  return `${row.livelloListino}||${row.codProduttore}||${row.codArticolo}||${row.dataInizioValidita}`;
                }}
              />
            </Paper>
          </Box>
        ) : null}
      </Box>
    </Fragment>
  );
}
