import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";

import { produttoriIndex } from "@store/produttori/produttori.selector";
import { getProduttoriIndex } from "@store/produttori/produttori.effects";

import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DealersIcon } from "@assets/icons/Full/Edit.svg";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CustomPaginatedDataGrid,
  FiltersAccordion,
  CustomHeaderPage,
  CommonSpeedDial,
} from "@components/index";
import { GridColDef } from "@material-ui/data-grid";
import { Button, IconButton, TextField } from "@material-ui/core";
import { FieldFilter, FilterTypes } from "@store/common/common.types";
import routes from "@utilities/routes";
import { push } from "connected-react-router";
import { Produttori } from "@store/produttori/produttori.types";
import { useIndexPage } from "@pages/common/UseIndexPage";
import environment from "@environment/environment";
import Auth from "@utilities/auth";

enum FILTER_ID {
  FILTER_OR = "filter-or-multi",
}

export function ProduttoriIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["produttoriIndexPage", "common"]);

  const initialFilter: { [key: string]: FieldFilter } = {
    multi: new FieldFilter(
      FILTER_ID.FILTER_OR,
      t(`produttoriIndexPage:filter:filterOrMulti`),
      FilterTypes.OR,
      "descrizione",
      "",
      ""
    ),
  };

  const {
    data,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleTextFieldChangeValue,
  } = useIndexPage<Produttori>(
    initialFilter,
    [{ field: "descrizione", sort: "asc" }],
    getProduttoriIndex,
    produttoriIndex
  );

  const columns: GridColDef[] = [
    {
      field: "descrizione",
      headerName: t("common:descrizione"),
      flex: 1,
      valueGetter: (params) => params.row.descrizione,
    },
    {
      field: "simbolo",
      headerName: t("common:simbolo"),
      flex: 1,
      valueGetter: (params) => params.row.simbolo,
    },
    {
      field: "barCode",
      headerName: t("common:barCode"),
      flex: 1,
      valueGetter: (params) => params.row.barCode,
    },
    {
      field: "edit",
      headerName: t("common:edit"),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          return dispatch(
            push(
              routes.produttori.show
                .replace(":codProduttore", params.row.codProduttore)
                .replace(":codDisegno", params.row.codDisegno)
            )
          );
        };

        return (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="inherit"
            size="small"
            onClick={onClick}
          >
            <DealersIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage
            title={t(`produttoriIndexPage:title`)}
            disabledGoBack
          >
            {Auth.hasAccess("save-produttori") ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(push(routes.produttori.new))}
              >
                <AddIcon color="primary" />
                {t("produttoriIndexPage:newElementButton")}
              </Button>
            ) : null}
          </CustomHeaderPage>
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            multiSearchFilter={
              <TextField
                fullWidth
                size="small"
                id={FILTER_ID.FILTER_OR}
                value={filters.filterFields.multi.value}
                onChange={(event) =>
                  handleTextFieldChangeValue(
                    event,
                    filters.filterFields.multi.id
                  )
                }
                label={filters.filterFields.multi.label}
              />
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.codProduttore;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <CommonSpeedDial
        updateData={[
          {
            taskName: environment.semaphoreJobsData.anagraficheUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.anagraficheUpdates.entities
                .produttori,
            ],
          },
          {
            taskName:
              environment.semaphoreJobsData.areaRiservataUpdates.taskName,
            entities: [
              environment.semaphoreJobsData.areaRiservataUpdates.entities
                .produttori,
            ],
          },
        ]}
      />
    </div>
  );
}
