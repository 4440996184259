import { AppDispatch, AppThunk } from '../store'
import { setTipoContrattoIndex, setTipoContrattoShow } from './tipiContratto.actions';
import environment from '@environment/environment';
import { Filters, SortModel } from '@store/common/common.types';
import { ImportContratti, TipoContratto, TipoContrattoIndex } from './tipiContratto.type';
import { getEntityIndex, getEntityShow, postEntity, putEntity } from '@utilities/apiService';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getTipiContrattoIndex = (page: number = 1, pageSize = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {
  dispatch(getEntityIndex<TipoContrattoIndex>('AnagraficaContrattiFlotte', 'getTipoContantiIndex', page, pageSize, setTipoContrattoIndex, filters, sortModel));
}

export const getTipiContrattoShow = (id: string): AppThunk => async dispatch => {
  dispatch(getEntityShow<TipoContratto>(`AnagraficaContrattiFlotte/${id}`, 'getTipoContantiShow', setTipoContrattoShow));
}

export const postTipoContratto = createAsyncThunk<TipoContratto, TipoContratto, {dispatch: AppDispatch} >('postTipoContratto', async (tipoContratto: TipoContratto, {dispatch}) => {

  const response = await dispatch(postEntity({
    path: `/AnagraficaContrattiFlotte`,
    loaderId: 'postTipoContratto',
    entity: tipoContratto
  }))

  return response.payload as TipoContratto;

});

export const putTipoContratto = createAsyncThunk<TipoContratto,TipoContratto,{dispatch: AppDispatch}>('putTipoContratto', async (tipoContratto: TipoContratto, {dispatch}) => {

  const response = await dispatch(putEntity({
    path: `/AnagraficaContrattiFlotte/${tipoContratto.id}`,
    loaderId: 'putTipoContratto',
    entity: tipoContratto
  }))

  return response.payload as TipoContratto;

});

export const importContratti = createAsyncThunk<any, ImportContratti, {dispatch: AppDispatch} >('importContratti', async (importContratti: ImportContratti, {dispatch}) => {

  const response = await dispatch(postEntity({
    path: `/AnagraficaContrattiFlotte/Import`,
    loaderId: 'importContratti',
    entity: importContratti
  }))

  return response;

});