import {
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyIcon } from "@assets/icons/Full/Stack.svg";
import { ReactComponent as DuplicateIcon } from "@assets/icons/Full/Copy.svg";
import { ReactComponent as AddedIcon } from "@assets/icons/Full/Add.svg";
import { ReactComponent as UpdatedIcon } from "@assets/icons/Full/Refresh.svg";

import { ImportFleetVehiclesResponse } from "@store/enti-convenzionati/entiConvenzionati.effects";
import { Fragment } from "react";

interface FleetVehiclesImportSummaryDialogProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  importFleetVehiclesResponse?: ImportFleetVehiclesResponse;
}

export function FleetVehiclesImportSummaryDialog(
  props: FleetVehiclesImportSummaryDialogProps
) {
  const { open, setOpen, importFleetVehiclesResponse } = props;
  const { t } = useTranslation(["common"]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>{t("common:importSummary")}</DialogTitle>
      <Box>
        <List>
          <ListItem>
            <ListItemIcon>
              <EmptyIcon width="1.5rem" height="1.5rem" />
            </ListItemIcon>
            <ListItemText
              primary={t("common:emptyItems")}
              secondary={importFleetVehiclesResponse?.emptyItems}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AddedIcon width="1.5rem" height="1.5rem" />
            </ListItemIcon>
            <ListItemText
              primary={t("common:itemsAdded")}
              secondary={importFleetVehiclesResponse?.itemsAdded}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <UpdatedIcon width="1.5rem" height="1.5rem" />
            </ListItemIcon>
            <ListItemText
              primary={t("common:itemsUpdated")}
              secondary={importFleetVehiclesResponse?.itemsUpdated}
            />
          </ListItem>
          {importFleetVehiclesResponse &&
            importFleetVehiclesResponse?.duplicateItems &&
            importFleetVehiclesResponse?.duplicateItems.length > 0 ? (
            <Fragment>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <DuplicateIcon width="1.5rem" height="1.5rem" />
                </ListItemIcon>
                <ListItemText
                  primary={t("common:duplicateItems")}
                  secondary={importFleetVehiclesResponse?.duplicateItems.length}
                />
              </ListItem>
              <Box className="p-2">
                {importFleetVehiclesResponse?.duplicateItems
                  .filter((targa) => targa.trim() !== "")
                  .map((targa) => (
                    <Chip label={targa} className="m-2" />
                  ))}
              </Box>
            </Fragment>
          ) : null}
          {importFleetVehiclesResponse &&
            importFleetVehiclesResponse?.invalidItems &&
            importFleetVehiclesResponse?.invalidItems.length > 0 ? (
            <Fragment>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <DuplicateIcon width="1.5rem" height="1.5rem" />
                </ListItemIcon>
                <ListItemText
                  primary={t("common:invalidItems")}
                  secondary={importFleetVehiclesResponse?.invalidItems.length}
                />
              </ListItem>
              <Box className="p-2">
                {importFleetVehiclesResponse?.invalidItems
                  .filter((targa) => targa.trim() !== "")
                  .map((targa) => (
                    <Chip label={targa} className="m-2" />
                  ))}
              </Box>
            </Fragment>
          ) : null}
          {importFleetVehiclesResponse &&
            importFleetVehiclesResponse?.nonExistentFascia &&
            importFleetVehiclesResponse?.nonExistentFascia.length > 0 ? (
            <Fragment>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <DuplicateIcon width="1.5rem" height="1.5rem" />
                </ListItemIcon>
                <ListItemText
                  primary={t("common:nonExistentFascia")}
                  secondary={
                    importFleetVehiclesResponse?.nonExistentFascia.length
                  }
                />
              </ListItem>
              <Box className="p-2">
                {Array.from(
                  new Set(
                    importFleetVehiclesResponse?.nonExistentFascia.map(
                      (item) => item.fascia
                    )
                  )
                ).map((fascia) => (
                  <Chip label={fascia} className="m-2" />
                ))}
              </Box>
            </Fragment>
          ) : null}
          {importFleetVehiclesResponse &&
            importFleetVehiclesResponse?.nonExistentMarca &&
            importFleetVehiclesResponse?.nonExistentMarca.length > 0 ? (
            <Fragment>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <DuplicateIcon width="1.5rem" height="1.5rem" />
                </ListItemIcon>
                <ListItemText
                  primary={t("common:nonExistentMarca")}
                  secondary={
                    importFleetVehiclesResponse?.nonExistentMarca.length
                  }
                />
              </ListItem>
              <Box className="p-2">
                {Array.from(
                  new Set(
                    importFleetVehiclesResponse?.nonExistentMarca.map(
                      (item) => item.marca
                    )
                  )
                ).map((marca) => (
                  <Chip label={marca} className="m-2" />
                ))}
              </Box>
            </Fragment>
          ) : null}
        </List>
      </Box>
    </Dialog>
  );
}
