import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmAction, ConfirmActionPropsData, CustomHeaderPage } from '@components/index'
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from '@material-ui/core';
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { getAnagraficaTata, getDatiAggiuntiviSedeOpTata } from '@store/anagrafica/anagrafica.effects';
import { AnagraficaGoodyear, DatiAggiuntiviSedeOp } from '@store/anagrafica/anagrafica.types';
import { DataDealerComponent } from '@pages/anagrafica/components/DataDealer';
import { TataDataDealerComponent } from '@pages/admin/tata/components/TataDataDealer';
import { anagraficaTata, datiSedeAggiuntiviTata } from "@store/anagrafica/anagrafica.selector";
import { useParams } from "react-router-dom";
import { getOperationHD, putOperationResults } from '@store/operazioni-hd/operazioniHD.effects';
import { OperationResults, OperationToExecute, Operazioni, TataParameters } from '@store/operazioni-hd/operazioniHD.types';
import { operationsShow } from "@store/operazioni-hd/operazioniHD.selector";
import { AspectRatio } from '@material-ui/icons';
import _ from 'lodash';
import { resetOperationResults } from '@store/operazioni-hd/operazioniHD.actions';
// import { Validation, ValidationConfig, ValidationTypes } from '@utilities/validation';

var CryptoJS = require("crypto-js");
interface ParametersProps {
    idOperation: number;
    // operation: Operazioni;
    // parametriOperazioneRef: any;
    // errors: { [key: string]: string },
    handleClick: (isSelect: boolean) => Promise<void>;
    onChange: (value: any, id: string) => void;
}
function Parameters(props: ParametersProps) {

    const { t } = useTranslation(['common', 'masterDataTata'])
    const { idOperation, onChange, handleClick } = props;
    return (
        <div><Box className="flex items-center space-x-4 px-2">
            {/* {_.split(operation.variabile, ";").map(op => 
                 <TextField
                    label={t(`masterDataTata:${operation.descrizione}`)}
                    size='small'
                    id={op}
                    type='number'
                    // error={errors["#ANNO_ORD#"] ? true : false}
                    // helperText={errors["#ANNO_ORD#"]}
                    InputProps={{ inputProps: { max: 9999 } }}
                    // inputRef={parametriOperazioneRef}
                    onChange={(e) => onChange(e, op)}
                 // onChange={(e) => onChange(e)}
             />
            )} */}
            {idOperation === 2 ?
                <>
                    <TextField
                        label={t('masterDataTata:annoOrdine')}
                        size='small'
                        id='#ANNO_ORD#'
                        type='number'
                        // error={errors["#ANNO_ORD#"] ? true : false}
                        // helperText={errors["#ANNO_ORD#"]}
                        InputProps={{ inputProps: { max: 9999 } }}
                        // inputRef={parametriOperazioneRef}
                        onChange={(e) => onChange(e, "#ANNO_ORD#")}
                        // onChange={(e) => onChange(e)}
                    />
                    <TextField
                        label={t('masterDataTata:numeroOrdine')}
                        size='small'
                        id='#NORD#'
                        type='number'
                        // error={errors["#NORD#"] ? true : false}
                        // helperText={errors["#NORD#"]}
                        // inputRef={parametriOperazioneRef}
                        onChange={(e) => onChange(e, "#NORD#")}
                        // onChange={(e) => onChange(e)}

                    />
                </>
                : 
                <TextField
                    fullWidth
                    label={t('masterDataTata:numPolizza')}
                    size='small'
                    id='#NPOLIZZA#'
                    type='number'
                    // error={errors["#NPOLIZZA#"] ? true : false}
                    // helperText={errors["#NPOLIZZA#"]}
                    // inputRef={parametriOperazioneRef}
                    onChange={(e) => onChange(e, '#NPOLIZZA#')}
                    // onChange={(e) => onChange(e)}
                />
            }
            <Button onClick={() => { handleClick(true) }}>
                {/* <Button type='submit' onSubmit={(e) => handleSubmit(e)}> */}
                {t('masterDataTata:executeSelectsQuery')}</Button></Box>
        </div>
    )
}


export function MasterDataTata() {
    const dispatch = useAppDispatch();
    const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
        isOpen: false,
    });

    const { id, codSedeOp } = useParams<{
        id: string;
        codSedeOp: string;
    }>();
    const { t } = useTranslation(['common', 'masterDataTata'])
    // const [errors, setErrors] = useState({} as { [key: string]: string });

    const utenteRef = useRef<HTMLInputElement>(null)
    // const parametriOperazioneRef = useRef<HTMLInputElement>(null)
    // const ipClientRef = useRef<HTMLInputElement>(null)
    const dbClientRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const [ipCliente, setIpCliente] = useState("")
    // const [parameters, setParameters] = useState({})
    const parameters: TataParameters = {}
    const [params, setParams] = useState<TataParameters>({})

    const payloadAnagrafica = useAppSelector(anagraficaTata);
    const payloadDatiAggiuntiviSedeOp = useAppSelector(datiSedeAggiuntiviTata);

    // const payloadOperationQuery = useAppSelector(operationQueriesShow);
    const payloadOperations = useAppSelector(operationsShow);

    const [idOperation, setIdOperation] = useState(0)
    const [anagrafica, setAnagrafica] = useState({} as AnagraficaGoodyear);
    const [datiAggiuntiviSedeOp, setDatiAggiuntiviSedeOp] = useState({} as DatiAggiuntiviSedeOp);
    // const [operazioniQueries, setOperazioniQueries] = useState<OperazioniQuery[]>([])
    // const [operationToExecute, setOperationToExecute] = useState<OperationToExecute>({} as OperationToExecute);
    // var [operations, setOperations] = useState(["Eliminazione polizze", "Eliminazione sinistri"])
    const [operations, setOperations] = useState<Operazioni[]>([])
    const [operationResults, setOperationResults] = useState<OperationResults[]>([]);

    const [selectedRow, setSelectedRow] = useState(0)
    const [hasExecutedSelects, setHasExecutedSelects] = useState(false)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [expandDialogOpen, setExpandDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getAnagraficaTata(id, codSedeOp))
        dispatch(getDatiAggiuntiviSedeOpTata(id, codSedeOp))
        dispatch(getOperationHD())
    }, [dispatch, id, codSedeOp]);

    useEffect(() => {
        if (payloadAnagrafica) {
            setAnagrafica(() => {
                return payloadAnagrafica;
            });
        }
    }, [payloadAnagrafica]);


    useEffect(() => {
        if (payloadDatiAggiuntiviSedeOp)
            setDatiAggiuntiviSedeOp(payloadDatiAggiuntiviSedeOp);
    }, [payloadDatiAggiuntiviSedeOp]);

    useEffect(() => {
        if (payloadOperations) {
            setOperations(() => {
                return payloadOperations;
            });
        }
    }, [payloadOperations])

    useEffect(() => {
        return () => {
            dispatch(resetOperationResults());
        };
    }, [dispatch]);

    useEffect(() => {
        // setErrors({})
        setHasExecutedSelects(false)
        setOperationResults([])
        setParams({})
    }, [dialogOpen])

    const getColumns = (): GridColDef[] => {
        const columns: GridColDef[] = [
            {
                field: "databaseName",
                headerName: t('masterDataTata:databaseName'),
                disableClickEventBubbling: true,
            },
            {
                field: "description",
                headerName: t('masterDataTata:descriptionQuery'),
                flex: 1,
                disableClickEventBubbling: true,
            },
            {
                field: "query",
                headerName: t('masterDataTata:query'),
                flex: 3,
                disableClickEventBubbling: true,
            },
            {
                field: "expand",
                headerName: t("common:expand"),
                headerAlign: "center",
                align: "center",
                disableColumnMenu: true,
                disableClickEventBubbling: true,
                sortable: false,
                renderCell: (params) => {
                    return (
                        <IconButton
                            style={{
                                border: "none",
                                outline: "none",
                            }}
                            color="inherit"
                            size="small"
                            onClick={async () => {
                                let op = params.row as OperationResults
                                console.log(params.row.id)
                                setExpandDialogOpen(true)
                                setSelectedRow(op.id)
                            }}
                        >
                            <AspectRatio />
                        </IconButton>
                    );
                },
            },
        ];
        return columns;
    };

    // const validate = () => {
    //     const fieldsConfig: ValidationConfig[] = [
    //         Validation.buildFieldConfig("#ANNO_ORD#", params["#ANNO_ORD#"], [
    //             ValidationTypes.REQUIRED,
    //         ]),
    //         Validation.buildFieldConfig("#NPOLIZZA#",
    //         params["#NPOLIZZA#"],
    //         [
    //              ValidationTypes.REQUIRED 
    //         ]),
    //         Validation.buildFieldConfig("#NORD#", params['#NORD#'], [
    //             ValidationTypes.REQUIRED,
    //         ]),
    //     ]


    //     const validationErrors = Validation.validate(fieldsConfig);
    //     setErrors(validationErrors);

    //     return Object.keys(validationErrors).length === 0;
    // }

    const handleChangeValue = (e: any, param: string) => {
        const target = e.target as typeof e.target & { value: string };
        updateValues(target.value, param);
    };

    const updateValues = (value: any, id: string) => {
        // setErrors({});
        setParams((prevState) => {
            return {
                ...prevState,
                [id]: value,
            };
        });

    };

    const encryptPassword = (password: string | undefined) => {
        var key = CryptoJS.enc.Utf8.parse('8080808080808080');
        var iv = CryptoJS.enc.Utf8.parse('8080808080808080');
        // console.log(encrypted.ciphertext.toString());
        var encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        console.log('encrypted password :' + encryptedpassword);
        return encryptedpassword;
    }

    const handleClick = async (isSelect: boolean) => {

        // if (!validate()) return;

        var password = passwordRef.current?.value
        var encryptedpassword = encryptPassword(password)

        // parameters.splice(0, parameters.length)
        // parameters.push(params)

        console.log(parameters)
        console.log(params)


        var operationToExecute = {
            dbCliente: dbClientRef.current?.value as string,
            ipCliente: ipCliente || anagrafica?.datiAggiuntiviSedeOp?.numISDN,
            utente: utenteRef.current?.value as string,
            password: encryptedpassword.toString(),// passwordRef.current?.value as string,
            idOp: idOperation,
            codSedeOp: anagrafica?.datiAggiuntiviSedeOp?.codSedeOperativa,
            codDitta: anagrafica?.codDitta,
            flgSelect: isSelect,
            flgSS: anagrafica?.datiAggiuntiviSedeOp?.flgProduzione,
            params: params
            // parametriOperazione: parametriOperazioneRef.current?.value as string,
        } as OperationToExecute

        await dispatch(putOperationResults(operationToExecute))
            .unwrap()
            .then((res: any) => {
                setOperationResults(res.data)
                setHasExecutedSelects(isSelect)
            }).catch((err: any) => {
                setHasExecutedSelects(!isSelect)
                setOperationResults([])
                console.log(err);
            });
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <CustomHeaderPage
                        title={`${t('masterDataTata:title')}`}
                    >
                    </CustomHeaderPage>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="p-2 mb-4">
                        <DataDealerComponent
                            handlerChangeData={() => console.log("")}
                            helpDesk={true}
                            data={datiAggiuntiviSedeOp.anagraficaGoodyear}
                            errors={{} as { [key: string]: string }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="p-2 mb-4">
                        <TataDataDealerComponent
                            data={datiAggiuntiviSedeOp}
                            dataAnagrafica={anagrafica}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                {/* <TataOperationComponent data={anagrafica} displayedTable={displayedTable} /> */}
                {
                    operations && operations.length > 0 && operations.map((op: Operazioni) => {
                        return (
                            <>
                                <Grid item xs={12} key={op.idOp}>
                                    <Paper className="p-4 my-4">
                                        <Box className="flex items-center flex-row flex-wrap justify-between">
                                            <Box className="flex items-center flex-row flex-wrap w-full md:w-10/12">
                                                <Box className="px-2 w-full md:w-4/12">
                                                    {op.descrizione}
                                                </Box>
                                            </Box>
                                            <Box className="w-full md:w-2/12 flex justify-end">
                                                <Button className="m-4 mr-0" onClick={() => {
                                                    setDialogOpen(true)
                                                    setIdOperation(op.idOp)
                                                }}>
                                                    {t('masterDataTata:openDialogOperation')}
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </>
                        )
                    })
                }
            </Grid>
            <Dialog
                onClose={() => setDialogOpen(false)}
                open={dialogOpen}
                fullScreen
                fullWidth
                className="p-5"
            >
                <DialogTitle>{operations.find(o => o.idOp === idOperation)?.descrizione}</DialogTitle>
                <div className="p-5">
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className='flex flex-wrap'>
                                <Box className='w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2'>
                                    <TextField
                                        fullWidth
                                        label={t('common:codDitta')}
                                        size='small'
                                        id='codDitta'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        disabled={true}
                                        value={anagrafica?.codDitta || ''}
                                    />
                                </Box>
                                <Box className='w-full  2xl:w-3/12 xl:w-4/12 md:w-6/12 px-2'>
                                    <TextField
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label={t('common:ragioneSociale')}
                                        size='small'
                                        id='ragioneSociale'
                                        disabled={true}
                                        value={anagrafica?.ragioneSociale || ''}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        {anagrafica?.datiAggiuntiviSedeOp?.flgProduzione ?
                            <Grid item xs={12}>
                                <Box className='flex flex-wrap'>
                                    <Box className='w-full 2xl:w-1/12 xl:w-2/12 md:w-6/12 px-2'>
                                        <TextField
                                            fullWidth
                                            label={t('masterDataTata:ipClient')}
                                            size='small'
                                            id='numISDN'
                                            // InputProps={{
                                            //     readOnly: true,
                                            // }}
                                            // inputRef={ipClientRef}
                                            // disabled={true}
                                            onChange={(e) => {
                                                setIpCliente(e.target.value);
                                                console.log(e.target.value)
                                            }}
                                            defaultValue={anagrafica?.datiAggiuntiviSedeOp?.numISDN || ''}
                                            // value={anagrafica?.datiAggiuntiviSedeOp?.numISDN || ''}
                                        />
                                    </Box>
                                    <Box className='w-full  2xl:w-3/12 xl:w-4/12 md:w-6/12 px-2'>
                                        <TextField
                                            fullWidth
                                            label={t('masterDataTata:dbClient')}
                                            size='small'
                                            id='DbClient'
                                            value={anagrafica?.codDitta}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            inputRef={dbClientRef}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box className='w-full 2xl:w-2/12 xl:w-2/12 md:w-6/12 px-2'>
                                        <TextField
                                            fullWidth
                                            label={t('masterDataTata:user')}
                                            size='small'
                                            id='user'
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            inputRef={utenteRef}
                                            disabled={true}
                                            defaultValue={'arpasoft'}
                                        />
                                    </Box>
                                    <Box className='w-full 2xl:w-3/12 xl:w-2/12 md:w-6/12 px-2'>
                                        <TextField
                                            fullWidth
                                            label={t('masterDataTata:password')}
                                            size='small'
                                            id='password'
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            disabled={true}
                                            inputRef={passwordRef}
                                            defaultValue={'Sedna2004'}
                                            type="password"
                                        />
                                    </Box>
                                </Box>
                            </Grid> : null
                        }
                        <Grid item xs={12}>
                            <Parameters
                                idOperation={idOperation}
                                // errors={errors}
                                onChange={handleChangeValue}
                                handleClick={handleClick}
                            />

                        </Grid>
                    </Grid>
                    <div className="px-2">
                        <Typography className="py-5" component="h3" variant="h6">
                            {t('masterDataTata:executedSelects')}
                        </Typography>
                        <DataGrid
                            autoHeight
                            hideFooter
                            getRowId={(row) => { return row.id }}
                            rows={operationResults}
                            columns={getColumns()}
                            getRowClassName={(params) =>
                                params.row.id === selectedRow ? "highlighted-row" : ""
                            }
                        />
                        {hasExecutedSelects ?
                            <Box className='w-full pt-5 flex flex-row-reverse'>
                                <Button onClick={() => {
                                    setConfirmAction({
                                        isOpen: true,
                                        title: t("common:message:defaultConfirmationTitle"),
                                        subtitle: t("common:message:defaultConfirmationMessage"),
                                        confirmAction: () => {
                                            handleClick(false);
                                        },
                                    });
                                }}>
                                    {t('masterDataTata:executeUpdatesQuery')}
                                </Button>
                                <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
                            </Box> : null}
                    </div>
                </div>
            </Dialog>

            <Dialog
                onClose={() => setExpandDialogOpen(false)}
                open={expandDialogOpen}
                className="p-10"
                fullScreen
            >
                <div className="p-5">
                    {
                        operationResults.filter(result => result.id === selectedRow).map(filteredName => {
                            return (
                                filteredName.jsonResult && !_.isEmpty(filteredName.jsonResult) ?
                                    <Fragment key={filteredName.id}>
                                        <Typography variant="h6">
                                            {filteredName.databaseName} - {filteredName.description}
                                        </Typography>
                                        <TextField
                                            multiline
                                            value={filteredName.query}
                                        />
                                        <TableContainer component={Box}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {
                                                            Object.keys(JSON.parse(filteredName.jsonResult)[0]).map(heading => {
                                                                return <TableCell key={heading}>{heading}</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {JSON.parse(filteredName.jsonResult).map((row: any, index: number) => {
                                                        return <TableRow key={index}>
                                                            {Object.keys(JSON.parse(filteredName.jsonResult)[0]).map((key, index) => {
                                                                return <TableCell key={row[key]}>{row[key] || '------'}</TableCell >
                                                            })}
                                                        </TableRow >;
                                                    })}
                                                </TableBody>

                                            </Table>
                                        </TableContainer>
                                    </Fragment>
                                    :
                                    <>
                                        <Box className='w-full px-2'>
                                            <Typography variant="h6">
                                                {`${filteredName.databaseName} - ${filteredName.description}`}
                                            </Typography>
                                            <TextField
                                                multiline
                                                value={filteredName.query}
                                            />
                                            <TextField
                                                label={t('masterDataTata:effectiveRows')}
                                                value={filteredName.result}
                                            />
                                        </Box>
                                    </>
                            )
                        })
                    }
                </div>
            </Dialog >
        </div >
    )
}