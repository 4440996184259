import { createReducer } from '@reduxjs/toolkit'
import { Pagination } from '@store/common/common.types'
import {
    setEntiConvenzionatIndex,
    setEntiConvenzionatiShow
} from './entiConvenzionati.actions'
import { EntiConvenzionati } from './entiConvenzionati.types'

const initialState: {
    index: {
        data: EntiConvenzionati[]
        meta: {
            pagination: Pagination | undefined
        }
    },
    show: {
        data: EntiConvenzionati | undefined
    }
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const flottaReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setEntiConvenzionatIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setEntiConvenzionatiShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
})

