import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { TipoDocumenti } from './tipoDocumenti.types';

export const setTipoDocumentiIndex = createAction<{
    data: TipoDocumenti[]
    meta: {
        pagination: Pagination
    }
}>('tipoDocumenti/index')
