import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes from '@utilities/routes'

export function PrivateRoute({ component, ...rest }: any) {
    return (
        <Route {...rest} render={props => (
            localStorage.getItem('user')
                ? React.createElement(component, props)
                : <Redirect to={{ pathname: routes.auth.login, state: { from: props.location } }} />
        )} />
    )
}