import { useState, SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";
import { resetAuthError } from "@store/auth/auth.actions";
import validator from "validator";

import routes from "@utilities/routes";
import { push } from "connected-react-router";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { Header, Footer } from "@components/index";
import { Button, Container, TextField } from "@material-ui/core";
import { forgotPassword } from "@store/auth/auth.effects";

const useStyles = makeStyles((theme) => ({
  display: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  container: {
    padding: "0rem 4.5rem",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  textField: {
    marginTop: "0px",
    marginBottom: "0.813rem",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  assistance: {},
}));
export function ForgotPasswod() {
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const { t } = useTranslation(["forgotPasswordPage", "common"]);

  const [email, setEmail] = useState({ validatorMessage: "", value: "" });
  const isEmail = validator.isEmail;

  const handlerSubmitRecoveryForm = (event: SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      email: { value: string };
    };

    const email = target.email.value;

    if (isEmail(email)) {
      setEmail((prevState) => {
        return { ...prevState, value: "" };
      });
      dispatch(forgotPassword(email));
    } else {
      setEmail((prevState) => {
        return {
          ...prevState,
          validatorMessage: t("common:validatorError:email"),
        };
      });
    }
  };

  const handlerEmailChange = (event: SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      value: string;
    };

    const email = target.value;

    setEmail((prevState) => {
      return {
        ...prevState,
        value: email,
        validatorMessage: "",
      };
    });
  };

  const handlerGoToSingin = () => {
    dispatch(resetAuthError());
    dispatch(push(routes.auth.login));
  };

  return (
    <div className={classes.display}>
      <Header />
      <Container component="main" className={classes.container} maxWidth="xs">
        <div className="mb-9">
          <Typography component="h1" variant="h4">
            {t("forgotPasswordPage:title")}
          </Typography>
        </div>
        <form
          className={classes.form}
          noValidate
          onSubmit={handlerSubmitRecoveryForm}
        >
          <TextField
            className={classes.textField}
            required
            fullWidth
            id="RecoverPasswordEmail"
            label="Email Address"
            error={email.validatorMessage !== ""}
            helperText={email.validatorMessage}
            name="email"
            autoComplete="email"
            autoFocus
            value={email.value}
            onChange={handlerEmailChange}
          />
          <Button type="submit" fullWidth className={classes.submit}>
            {t("forgotPasswordPage:resetPassword")}
          </Button>
          <Link
            onClick={handlerGoToSingin}
            href="#"
            variant="body2"
            item-align="center"
          >
            {t("common:backToLogin")}
          </Link>
        </form>
      </Container>
      <Footer />
    </div>
  );
}
