import { createReducer } from '@reduxjs/toolkit'
import {
    setSediOperativeIndex,
    setSedeOperativaShow
} from './sedeOperativa.actions'
import { SedeOperativaIndex, SedeOperativaShow } from './sedeOperativa.types'



const initialState: {
    index: SedeOperativaIndex,
    show: SedeOperativaShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const sedeOperativaReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setSediOperativeIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setSedeOperativaShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })

})




