import { createReducer } from '@reduxjs/toolkit'
import {
    setLarghezzaCerchiIndex,
    setLarghezzaCerchiShow,
    resetLarghezzaCerchiShow
} from './larghezzaCerchi.actions'
import { LarghezzaCerchiIndex, LarghezzaCerchiShow } from './larghezzaCerchi.types'

const initialState: {
    index: LarghezzaCerchiIndex
    show: LarghezzaCerchiShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const larghezzaCerchiReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLarghezzaCerchiIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setLarghezzaCerchiShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
        .addCase(resetLarghezzaCerchiShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

