import { createReducer } from '@reduxjs/toolkit'
//import { Pagination } from '@store/common/common.types'
//import { setMoneteIndex } from './monete.actions'
//import { Monete } from './monete.types'
import {
    setMoneteIndex,
    setMoneteShow,
    resetMoneteShow
} from './monete.actions'
import { MoneteIndex, MoneteShow } from './monete.types'

const initialState: {
    index: MoneteIndex,
    show: MoneteShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

/* const initialState: {
    index: {
        data: Monete[]
        meta: {
            pagination: Pagination | undefined
        }
    }
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    }
} */

export const moneteReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setMoneteIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setMoneteShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetMoneteShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})


