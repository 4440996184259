import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'

import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setIndiciDiCaricoIndex, setIndiciDiCaricoShow } from './indiciDiCarico.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { IndiciDiCarico, NewIndiciDiCarico } from './indiciDiCarico.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalIndiciDiCarico } from '@store/global/global.effects';


export const getIndiciDiCaricoIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getIndiciDiCaricoIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/IndiciDiCarico?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setIndiciDiCaricoIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getIndiciDiCaricoIndex'));
    }
}


export const getIndiciDiCaricoShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getIndiciDiCaricoShow'));

    try {
        const response = await AxiosAPI.get(`/IndiciDiCarico/${id}`)
        dispatch(setIndiciDiCaricoShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getIndiciDiCaricoShow'));
    }
}


export const postIndiciDiCarico = createAsyncThunk<
    // Return type of the payload creator
    IndiciDiCarico,
    // First argument to the payload creator
    NewIndiciDiCarico,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postIndiciDiCarico', async (indiceDiCarico: NewIndiciDiCarico, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postIndiciDiCarico'));

        try {
            const response = await AxiosAPI.post(`/IndiciDiCarico`, indiceDiCarico)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
            dispatch(getGlobalIndiciDiCarico(true))
            return response.data as IndiciDiCarico
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postIndiciDiCarico'));
        }
    })


export const putIndiciDiCarico = (indiceDiCarico: IndiciDiCarico): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putIndiciDiCarico'));

    try {
        const { codIndiceCarico } = indiceDiCarico

        await AxiosAPI.put(`/IndiciDiCarico/${codIndiceCarico}`, indiceDiCarico)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))
        dispatch(getGlobalIndiciDiCarico(true))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putIndiciDiCarico'));
    }
}


export const deleteIndiciDiCarico = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    IndiciDiCarico,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteIndiciDiCarico', async (indiceDiCarico: IndiciDiCarico, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteIndiciDiCarico'));

        try {
            const { codIndiceCarico } = indiceDiCarico

            await AxiosAPI.delete(`/IndiciDiCarico/${codIndiceCarico}`)

            const currentState = getState().indiciDiCarico.index;
            const data = currentState.data.filter((item) => item.codIndiceCarico !== codIndiceCarico)
            dispatch(setIndiciDiCaricoIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));
            dispatch(getGlobalIndiciDiCarico(true))
        } catch (error: any) {
            if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteIndiciDiCarico'));
        }
    })
