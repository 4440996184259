export const pathNewElement = "aggiungi";

const routes = {
  dashboard: "/",
  auth: {
    login: "/login",
    forgotPassword: "/password-dimenticata",
  },
  allestimenti: {
    index: "/allestimenti",
  },
  anagrafica: {
    index: "/anagrafica",
    show: "/anagrafica/:id",
    new: `/anagrafica/${pathNewElement}`,
  },
  aggiornamentiDb: {
    index: "/aggiornamenti-db",
    show: "",
  },
  capiZona: {
    index: "/capi-zona",
    show: "/capi-zona/:id",
    new: `/capi-zona/${pathNewElement}`,
  },
  causaliIva: {
    index: "/causali-iva",
    show: "/causali-iva/:id",
    new: `/causali-iva/${pathNewElement}`,
  },
  codiciVelocita: {
    index: "/codici-velocita",
    show: "/codici-velocita/:id",
    new: `/codici-velocita/${pathNewElement}`,
  },
  convenzioni: {
    aSconto: "/convenzioni/a-sconto",
    prezziNetti: "/convenzioni/prezzi-netti",
    prezziNettiFlotte: "/convenzioni/prezzi-netti-flotte",
    flotte: "/convenzioni/flotte",
    clone: "/convenzioni/clona",
    generiche: {
      descrizioni: {
        index: "/convenzioni/generiche/descrizioni",
        show: "/convenzioni/generiche/descrizioni/:id",
        new: `/convenzioni/generiche/descrizioni/${pathNewElement}`,
      },
      index: `/convenzioni/generiche`,
    },
    servizi: "/convenzioni/servizi",
  },
  disegni: {
    index: "/disegni",
    show: "/disegni/:codProduttore/:codDisegno",
    new: `/disegni/${pathNewElement}`,
  },
  entiConvenzionati: {
    index: "/enti-convenzionati",
    show: "/enti-convenzionati/:id",
    new: "/enti-convenzionati/aggiungi",
  },
  esercizi: {
    index: "/esercizi",
    show: "/esercizi/:id",
    new: `/esercizi/${pathNewElement}`,
  },
  externalRegisters: {
    fleetVehicles: "/caricamento-veicoli-flotte",
    fleetContractTypes: "/caricamento-contratti-veicoli-flotte",
  },
  faq: {
    index: "/faq",
    show: "",
  },
  flowManager: {
    index: "/flow-manager/",
    tasks: "/flow-manager/tasks",
    servers: `/flow-manager/servers`,
    server: `/flow-manager/servers/:id`,
    semaphores: `/flow-manager/semaphores`,
  },
  indiciDiCarico: {
    index: "/indici-di-carico",
    show: "/indici-di-carico/:id",
    new: `/indici-di-carico/${pathNewElement}`,
  },
  larghezzaCerchi: {
    index: "/larghezza-cerchi",
    show: "/larghezza-cerchi/:id",
    new: `/larghezza-cerchi/${pathNewElement}`,
  },
  larghezzeNominali: {
    index: "/larghezze-nominali",
    show: "/larghezze-nominali/:id",
    new: `/larghezze-nominali/${pathNewElement}`,
  },
  logManager: {
    index: "/log-manager",
  },
  nazioni: {
    index: "/nazioni",
    show: "/nazioni/:id",
    new: `/nazioni/${pathNewElement}`,
  },
  pneumatici: {
    articoliFuoriProduzione: "/pneumatici/articoli-fuori-produzione",
    index: "/pneumatici",
    show: "/pneumatici/:id",
    new: `/pneumatici/${pathNewElement}`,
  },
  priceList: {
    anagraficaListini: "/listini/anagrafica-listini",
    importListino: "/listini/import-listino",
  },
  produttori: {
    index: "/produttori",
    show: "/produttori/:codProduttore",
    new: `/produttori/${pathNewElement}`,
  },
  province: {
    index: "/province",
    show: "/province/:siglaProvincia",
    new: `/province/${pathNewElement}`,
  },
  scontoAddizionale: {
    index: "/sconto-addizionale",
    show: "/sconto-addizionale/:id",
    new: `/sconto-addizionale/${pathNewElement}`,
  },
  serieGomme: {
    index: "/serie-gomme",
    show: "/serie-gomme/:id",
    new: `/serie-gomme/${pathNewElement}`,
  },
  servizi: {
    index: "/servizi",
    show: "/servizi/:id",
    new: `/servizi/${pathNewElement}`,
  },
  stagionalita: {
    index: "/stagionalita",
    show: "/stagionalita/:codStagionalita",
    new: `/stagionalita/${pathNewElement}`,
  },
  strutture: {
    index: "/strutture",
    show: "/strutture/:codStruttura",
    new: `/strutture/${pathNewElement}`,
  },
  tipoContanti: {
    index: "/tipo-contanti",
    show: "/tipo-contanti/:id",
    new: `/tipo-contanti/${pathNewElement}`,
  },
  tipoContratti: {
    index: "/tipo-contratti",
    show: "/tipo-contratti/:id",
    new: `/tipo-contratti/${pathNewElement}`,
  },
  users: {
    index: "/users",
    show: "/users/:id",
    new: `/users/${pathNewElement}`,
  },
  profile: "/profile",
  tata: "/help-desk/:id/:codSedeOp",

};

export default routes;
