import { createReducer } from '@reduxjs/toolkit'
import {
    setTestataConvenzioniIndex,
    setTestataConvenzioniShow,
    resetTestataConvenzioniShow
} from './testataConvenzioni.actions'
import { TestataConvenzioniIndex, TestataConvenzioniShow } from './testataConvenzioni.type'

const initialState: {
    index: TestataConvenzioniIndex,
    show: TestataConvenzioniShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const testataConvenzioniReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setTestataConvenzioniIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setTestataConvenzioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(resetTestataConvenzioniShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
})

