import { commonReducer } from './common/common.reducer';
import { configureStore, ThunkAction, Action, Reducer } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Filters, SortModel } from './common/common.types';

import { anagraficaReducer } from './anagrafica/anagrafica.reducer';
import { articoliDatiAggiuntiviReducer } from './articoli-dati-aggiuntivi/articoliDatiAggiuntivi.reducer';
import { articoliLabelingReducer } from '@store/articoli-labeling/articoliLabeling.reducer';
import { articoliReducer } from './articoli/articoli.reducer';
import { authReducer } from './auth/auth.reducer';
import { capiZonaReducer } from "./capi-zona/capiZona.reducer";
import { categorieMerceologicheReducer } from './categorie-merceologiche/categorieMerceologiche.reducer';
import { causaliIvaReducer } from './causali-iva/causaliIva.reducer';
import { codiciVelocitaReducer } from './codici-velocita/codiciVelocita.reducer';
import { codVelocitaTipoArtReducer } from './cod-velocita-tipo-art/codVelocitaTipoArt.reducer';
import { convenzioniFlotteReducer } from './convenzioni-flotte/convenzioniFlotte.reducer';
import { criteriConvenzioniReducer } from './criteri-convenzioni/criteriConvenzioni.reducer';
import { desConvenzioniGenericheReducer } from './des-convenzioni-generiche/desConvenzioniGeneriche.reducer';
import { dettaglioScontiConvenzioniReducer } from './dettaglio-sconti-convenzioni/dettaglioScontiConvenzioni.reducer';
import { disctributoriReducer } from "./anagrafica-distributori/distributori.reducer";
import { disegniReducer } from './disegni/disegni.reducer';
import { divisioniReducer } from './divisioni/divisioni.reducer';
import { entiConvenzionatiSSReducer } from './enti-convenzionati-SS/entiConvenzioniSS.reducer';
import { eserciziReducer } from './esercizi/esercizi.reducer';
import { flottaReducer } from './enti-convenzionati/entiConvenzionati.reducer';
import { flowManagerReducer } from './flow-manager/flowManager.reducer';
import { IndCaricoTipoArtReducer } from './ind-carico-tipo-art/indCaricoTipoArt.reducer';
import { indiciDiCaricoReducer } from './indici-di-carico/indiciDiCarico.reducer';
import { larghCerchiTipoArtReducer } from './largh-cerchi-tipo-art/larghCerchiTipoArt.reducer';
import { larghezzaCerchiReducer } from './larghezza-cerchi/larghezzaCerchi.reducer';
import { larghezzeNominaliReducer } from './larghezze-nominali/larghezzeNominali.reducer'
import { largNomTipoArtReducer } from './larg-nom-tipo-art/largNomTipoArt.reducer';
import { logManagerReducer } from './log-manager/logManager.reducer';
import { livelloListinoReducer } from './livello-listino/livelloListino.reducer';
import { moneteReducer } from './monete/monete.reducer';
import { nazioniReducer } from './nazioni/nazioni.reducer';
import { pagamentiReducer } from './pagamenti/pagamenti.reducer';
import { produttoriReducer } from './produttori/produttori.reducer';
import { programmiReducer } from './programma/programma.reducer';
import { provinceReducer } from './province/province.reducer';
import { regioniReducer } from './regioni/regioni.reducer';
import { retiReducer } from '@store/rete-commerciale/reti.reducer';
import { sedeOperativaReducer } from './sedi-operative/sedeOperativa.reducer';
import { serieGommeReducer } from './serie-gomme/serieGomme.reducer';
import { serieGommeTipoArtReducer } from './serie-gomme-tipo-art/serieGommeTipoArt.reducer';
import { stagionalitaReducer } from '@store/stagionalita/stagionalita.reducer';
import { struttureReducer } from '@store/strutture/strutture.reducer';
import { testataConvenzioniReducer } from './testata-convenzioni/testataConvenzioni.reducer';
import { tipiArticoloReducer } from './tipi-articolo/tipiArticolo.reducer';
import { tipoDocumentiReducer } from './tipo-documenti/tipoDocumenti.reducer';
import { tipoContantiReducer } from './tipo-contanti/tipoContanti.reducer';
import { unitaDiMisuraReducer } from './unita-di-misura/unitaDiMisura.reducer';
import { masterDataUserReducer } from './master-data-user/masterDataUser.reducer';
import { globalReducer } from './global/global.reducer';
import { listiniReducer } from './listini/listini.reducer';
import { criteriServiziReducer } from './convenzione-prezzi-netti/convenzionePrezziNetti.reducer';
import { allestimentiReducer } from './veicoli/veicoli.reducer';
import { convenzioniDatiAggiuntiviReducer } from './convenzioni-dati-aggiuntivi/convenzioniDatiAggiuntivi.reducer';
import { tipoContrattoReducer } from './tipi-contratto/tipiContratto.reducer';
import { operationHdReducer } from './operazioni-hd/operazioniHD.reducer';
import { scontoAddizionaleReducer } from './sconto-addizionale/scontoAddizionale.reducer';



export const history = createBrowserHistory()

export const store = configureStore({
  reducer: {
    allestimenti: allestimentiReducer,
    anagrafica: anagraficaReducer,
    articoli: articoliReducer,
    articoliDatiAggiuntivi: articoliDatiAggiuntiviReducer,
    articoliLabeling: articoliLabelingReducer,
    auth: authReducer,
    capiZona: capiZonaReducer,
    categorieMerceologiche: categorieMerceologicheReducer,
    causaliIva: causaliIvaReducer,
    codiciVeloci: codiciVelocitaReducer,
    codVelociTipoArt: codVelocitaTipoArtReducer,
    convenzioniFlotte: convenzioniFlotteReducer,
    common: commonReducer,
    convenzioniDatiAggiuntivi: convenzioniDatiAggiuntiviReducer,
    criteriConvenzioni: criteriConvenzioniReducer,
    criteriServizi: criteriServiziReducer,
    desConvenzioniGeneriche: desConvenzioniGenericheReducer,
    dettaglioScontiConvenzioni: dettaglioScontiConvenzioniReducer,
    disegni: disegniReducer,
    distributori: disctributoriReducer,
    divisioni: divisioniReducer,
    entiConvenzionati: flottaReducer,
    entiConvenzionatiSS: entiConvenzionatiSSReducer,
    esercizi: eserciziReducer,
    flowManager: flowManagerReducer,
    global: globalReducer,
    indCaricoTipoArt: IndCaricoTipoArtReducer,
    indiciDiCarico: indiciDiCaricoReducer,
    larghCerchiTipoArt: larghCerchiTipoArtReducer,
    larghezzaCerchi: larghezzaCerchiReducer,
    larghezzeNominali: larghezzeNominaliReducer,
    largNomTipoArt: largNomTipoArtReducer,
    listini: listiniReducer,
    livelloListino: livelloListinoReducer,
    logManager: logManagerReducer,
    operationHd: operationHdReducer,
    masterDataUser: masterDataUserReducer,
    monete: moneteReducer,
    nazioni: nazioniReducer,
    pagamenti: pagamentiReducer,
    produttori: produttoriReducer,
    programmi: programmiReducer,
    province: provinceReducer,
    regioni: regioniReducer,
    reti: retiReducer,
    router: connectRouter(history) as Reducer,
    sediOperative: sedeOperativaReducer,
    serieGomme: serieGommeReducer,
    serieGommeTipoArt: serieGommeTipoArtReducer,
    stagionalita: stagionalitaReducer,
    strutture: struttureReducer,
    testataConvenzioni: testataConvenzioniReducer,
    tipiArticolo: tipiArticoloReducer,
    tipoContanti: tipoContantiReducer,
    tipiContratto: tipoContrattoReducer,
    tipoDocumenti: tipoDocumentiReducer,
    unitaDiMisura: unitaDiMisuraReducer,
    scontoAddizionale: scontoAddizionaleReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type ResourcesIndexThunk = (page?: number, sizePage?: number, filters?: Filters, sortModel?: SortModel[]) => AppThunk;

