import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Switch, Route } from "react-router-dom";
import { Route as MDRoute } from "@utilities/routing";
import routing from "@utilities/routing";
import { Sidebar, Header, drawerWidthOpen } from "@components/index";
import { NotFound } from "@pages/not-found/NotFound";
import Auth from "@utilities/auth";
import { useAppSelector } from "@store/hooks";
import { stateSidebar } from "@store/common/common.selector";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
  },
  contentOpened: {
    marginLeft: `${drawerWidthOpen}px`,
  },
  contentClosed: {
    marginLeft: "61px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function getRouteComponent(route: MDRoute | undefined): any {
  const permissions = route && route.permissions ? route.permissions : [];
  if (!route || !Auth.hasAccess(...permissions)) return null;
  if (route.render) {
    return (
      <Route exact path={route.path} render={(props) => route.render(props)} />
    );
  } else {
    return <Route exact path={route.path} component={route.component} />;
  }
}

export function Layout() {
  const classes = useStyles();

  const sidebar = useAppSelector(stateSidebar);

  return (
    <div className={classes.root}>
      <Header />
      <Sidebar />
      <main
        className={clsx(
          classes.content,
          sidebar.isOpen ? classes.contentOpened : classes.contentClosed
        )}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Switch>
            {Object.keys(routing).map((key) => {
              const route = routing[key];
              return Object.keys(route).map((index) =>
                getRouteComponent(route[index])
              );
            })}
            <Route component={NotFound} />
          </Switch>
        </Container>
      </main>
    </div>
  );
}
