import { createAction } from '@reduxjs/toolkit';
import { CustomAlert } from '@store/common/common.types';


export const toggleSidebar = createAction('toggleSidebar')
export const openSidebar = createAction('openSidebar')
export const closeSidebar = createAction('closeSidebar')

export const setCustomAlert = createAction<CustomAlert>('setCustomAlert')
export const closeCustomAlert = createAction('closeCustomAlert')

export const setRequestLoader = createAction<string>('setRequestLoader')
export const removeRequestLoader = createAction<string>('removeRequestLoader')
