//import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { itIT } from '@material-ui/data-grid';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1D7EB5',
            dark: '#1D7EB5',
        },
        secondary: {
            main: '#ffdd00',
            dark: '#ffdd00',
            contrastText: '#000'
        },
    },
    typography: {

        h4: {
            fontSize: '2.125rem',
            fontFamily: 'Barlow',
            fontWeight: 500,
        },
        h6: {
            fontSize: '1.25rem',
            fontFamily: 'Barlow',
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: '1rem',
            fontFamily: 'Roboto',
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontFamily: 'Roboto Medium',
            fontWeight: 700,
        },
        overline: {
            lineHeight: '0.668rem',
            fontSize: '0.625rem',
            textTransform: 'none'
        },
    },
    overrides: {
        MuiButton: {
            root: {
            },
        },
        MuiButtonBase: {
            root: {
            },
        },
        MuiIconButton: {
            label: {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            }
        },
        MuiFormControlLabel: {
            label: {
                color: 'rgba(0, 0, 0, 0.54)',
            }
        },
        MuiCheckbox: {
            root: {
                color: 'rgba(0, 0, 0, 0.23)'
            },
            colorSecondary: {
                '&$checked': {
                    color: '#1D7EB5',
                }
            },
        },
        MuiRadio: {
            root: {
                color: 'rgba(0, 0, 0, 0.23)',
            },
            colorSecondary: {
                '&$checked': {
                  color: '#1D7EB5',
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '2rem'
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
            }
        },
        MuiFormLabel: {
            root: {
            }
        },
        MuiInputLabel: {
            shrink: {
                transform: "translate(0, 10.5px) scale(0.75)",
            }
        },
        MuiInput: {
            underline: {
                borderBottomColor: "trasparent",
                '&:before': {
                    borderBottom: "0px solid rgba(0, 0, 0, 0)",
                },
                '&:after': {
                    borderBottom: "0px solid rgba(0, 0, 0, 0)",
                },
                '&:hover': {
                    borderBottom: "0px solid rgba(0, 0, 0, 0)",
                }
            },
        },
    },
    props: {
        MuiButton: {
            variant: "contained",
            color: "primary"
        },
        MuiTextField: {
            variant: "outlined",
            margin: "normal"
        },
    }


}, itIT); // TODO da modificare quando verranno usate più lingue

export default theme