import { User } from "@store/auth/auth.types";
import { permissions } from "./permissions";

export default class Auth {
  /**
   * @description Auth singleton
   * @type Auth
   */
  private static instance?: Auth;
  /**
   * @description User logged user
   * @type User
   */
  private user: User;

  constructor() {
    this.user = JSON.parse(
      localStorage.getItem("user")
        ? (localStorage.getItem("user") as string)
        : "{}"
    );
  }

  /**
   * Destroy singleton
   */
  public static destroy() {
    Auth.instance = undefined;
  }

  /**
   * Get singleton
   * @returns Auth
   */
  public static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }

    return Auth.instance;
  }

  /**
   * Check if current user has access
   * @param rights: string[]
   * @returns boolean
   */
  public static hasAccess(...rights: string[]): boolean {
    let hasAccess: boolean = false;
    // Se non vengono passati permessi, considero accessibile la funzione
    if (!rights || rights.length === 0) {
      hasAccess = true;
    } else {
      const auth = Auth.getInstance();
      rights.forEach((right) => {
        const roles = permissions[right];
        if (
          roles &&
          auth.user.roles.filter((role) =>
            roles.includes(role.toLocaleLowerCase())
          ).length > 0
        ) {
          hasAccess = true;
        }
      });
    }

    return hasAccess;
  }
}
