import { AxiosAPI } from '@store/axios.config';
import { AppThunk, AppDispatch, RootState } from '../store'
import { setError, setSuccess } from '../common/common.effects'
import { setRequestLoader, removeRequestLoader } from '@store/common/common.actions';
import { setArticoliIndex, setArticoliShow, setRicercaArticoli } from './articoli.actions';
import environment from '@environment/environment';
import { createFilters, createSortColumns } from '@utilities/apiUtilities';
import { Filters, SortModel } from '@store/common/common.types';
import { Articoli, NewArticoli } from './articoli.types';
import i18n from '@assets/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface getArticoliIndexAsyncData {
    id: string,
    sizePage: number,
    filters?: Filters,
    // sortModel?: SortModel[],
    page?: number
}
export const getArticoliIndex = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getArticoliIndex'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Articoli?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setArticoliIndex(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getArticoliIndex'));
    }
}


export const getArticoliShow = (id: string): AppThunk => async dispatch => {

    dispatch(setRequestLoader('getArticoliShow'));

    try {
        const response = await AxiosAPI.get(`/Articoli/${id}`)
        dispatch(setArticoliShow(response.data))
    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getArticoliShow'));
    }
}


export const postArticoli = createAsyncThunk<
    // Return type of the payload creator
    Articoli,
    // First argument to the payload creator
    NewArticoli,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('postArticoli', async (articolo: NewArticoli, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('postArticoli'));

        try {
            const response = await AxiosAPI.post(`/Articoli`, articolo)
            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

            return response.data as Articoli
        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('postArticoli'));
        }
    })

export const getArticoliShowAsync = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    any,

    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getArticoliShow', async (id: string, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('getArticoliShow'));

        try {
            let response = await AxiosAPI.get(`/Articoli/${id}`)
            return response

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('getArticoliShow'));
        }
    })
export const getArticoliIndexAsync = createAsyncThunk<
    // Return type of the payload creator
    any,
    // First argument to the payload creator
    getArticoliIndexAsyncData,

    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('getArticoliShow', async (payload: getArticoliIndexAsyncData, { rejectWithValue, dispatch, getState }) => {
        const filterSearch = createFilters(payload.filters)
        //const sortSearch = createSortColumns(payload.sortModel)


        dispatch(setRequestLoader('getArticoliShow'));
        try {
            let response = await AxiosAPI.get(`/Articoli/?PageIndex=${payload.page}&PageSize=${payload.sizePage}${filterSearch}`)
            return response

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('getArticoliShow'));
        }
    })


export const putArticoli = (articolo: Articoli): AppThunk => async dispatch => {

    dispatch(setRequestLoader('putArticoli'));

    try {
        const { codArticolo } = articolo

        await AxiosAPI.put(`/Articoli/${codArticolo}`, articolo)
        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putArticoli'));
    }
}


export const deleteArticoli = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    Articoli,
    {
        // Optional fields for defining thunkApi field types
        dispatch: AppDispatch
        state: RootState
    }>('deleteArticoli', async (articolo: Articoli, { rejectWithValue, dispatch, getState }) => {

        dispatch(setRequestLoader('deleteArticoli'));

        try {
            const { codArticolo } = articolo

            await AxiosAPI.delete(`/Articoli/${codArticolo}`)

            const currentState = getState().articoli.index;
            const data = currentState.data.filter((item) => item.codArticolo !== codArticolo)
            dispatch(setArticoliIndex({
                ...currentState,
                data: data
            }))

            dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }));

        } catch (error: any) {
            if (error.response) {
                dispatch(setError({ message: error.response.data.message }));
            } else {
                console.error(error);
                dispatch(setError({ message: i18n.t('common:message:genericError') }));
            };
            throw new Error(error);
        } finally {
            dispatch(removeRequestLoader('deleteArticoli'));
        }
    })

export const getRicercaArticoli = (page: number = 1, sizePage = environment.pagination.defaultPageSize, filters?: Filters, sortModel?: SortModel[]): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('getRicercaArticoli'));

        const filterSearch = createFilters(filters)
        const sortSearch = createSortColumns(sortModel)

        const response = await AxiosAPI.get(`/Articoli?PageIndex=${page}&PageSize=${sizePage}${filterSearch}${sortSearch}`)
        dispatch(setRicercaArticoli(response.data))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('getRicercaArticoli'));
    }
}


export const putFlgInEstinzione = (articoli: string[], flgInEstinzione: boolean): AppThunk => async dispatch => {

    try {
        dispatch(setRequestLoader('putFlgInEstinzione'));

        await AxiosAPI.put(`/Articoli/FlgInEstinzione`, {
            flgInEstinzione: flgInEstinzione,
            codArticoli: articoli
        });

        dispatch(setSuccess({ message: i18n.t('common:message:saveSuccess') }))

    } catch (error: any) {
        if (error.response) {
            dispatch(setError({ message: error.response.data.message }));
        } else {
            console.error(error);
            dispatch(setError({ message: i18n.t('common:message:genericError') }));
        }
    } finally {
        dispatch(removeRequestLoader('putFlgInEstinzione'));
    }
}