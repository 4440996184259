import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { SedeOperativa } from './sedeOperativa.types';

export const setSediOperativeIndex = createAction<{
    data: SedeOperativa[]
    meta: {
        pagination: Pagination
    }
}>('sedeOperativa/index')

export const setSedeOperativaShow = createAction<SedeOperativa>('sedeOperativa/show')




