import { AppThunk } from '../store'
import { createSortColumns } from '@utilities/apiUtilities';
import { setGlobalCapiZona, setGlobalCategorieMerceologice, setGlobalCausaliIva, setGlobalCodiciVelocita, setGlobalConvenzioniGeneriche, setGlobalDisegni, setGlobalDistributori, setGlobalDivisioni, setGlobalEntiConvenzionati, setGlobalIndiciDiCarico, setGlobalLarghezzeCerchi, setGlobalLarghezzeNominali, setGlobalLivelliListino, setGlobalMarche, setGlobalModelli, setGlobalMonete, setGlobalNazioni, setGlobalPagamenti, setGlobalProduttori, setGlobalProgrammi, setGlobalProvince, setGlobalRegioni, setGlobalReti, setGlobalRuoli, setGlobalSemaphoresTasks, setGlobalSerieGomme, setGlobalServers, setGlobalStagionalita, setGlobalStrutture, setGlobalTasks, setGlobalTipiArticolo, setGlobalTipiCausaliIva, setGlobalTipiContrattoFlotte, setGlobalTipiDocumento, setGlobalUnitaDiMisura, setGlobalScontoAddizionale } from './global.actions';
import { AxiosAPI } from '@store/axios.config';
import { SortModel } from '@store/common/common.types';
import { CapiZona } from '@store/capi-zona/capiZona.types';
import { CategorieMerceologiche } from '@store/categorie-merceologiche/categorieMerceologiche.types';
import { CausaliIva } from '@store/causali-iva/causaliIva.type';
import { CodiciVelocita } from '@store/codici-velocita/codiciVelocita.types';
import { Disegni } from '@store/disegni/disegni.types';
import { IndiciDiCarico } from '@store/indici-di-carico/indiciDiCarico.types';
import { LarghezzaCerchi } from '@store/larghezza-cerchi/larghezzaCerchi.types';
import { LarghezzaNominale } from '@store/larghezze-nominali/larghezzeNominali.types';
import { Produttori } from '@store/produttori/produttori.types';
import { Province } from '@store/province/province.types';
import { Reti } from '@store/rete-commerciale/reti.types';
import { SerieGomme } from '@store/serie-gomme/serieGomme.types';
import { Stagionalita } from '@store/stagionalita/stagionalita.types';
import { Strutture } from '@store/strutture/strutture.types';
import { TipiArticolo } from '@store/tipi-articolo/tipiArticolo.types';
import { UnitaDiMisura } from '@store/unita-di-misura/unitaDiMisura.types';
import { Distributori } from '@store/anagrafica-distributori/distributori.types';
import { Programmi } from '@store/programma/programma.types';
import { Monete } from '@store/monete/monete.types';
import { Regioni } from '@store/regioni/regioni.type';
import { MasterDataUserRole } from '@store/master-data-user/masterDataUser.type';
import { Divisioni } from '@store/divisioni/divisioni.types';
import { Pagamenti } from '@store/pagamenti/pagamenti.types';
import { LivelloListino } from '@store/livello-listino/livelloListino.types';
import { TipoDocumenti } from '@store/tipo-documenti/tipoDocumenti.types';
import { Nazioni } from '@store/nazioni/nazioni.type';
import { Marca, Modello } from '@store/veicoli/veicoli.types';
import { Server, Task } from '@store/flow-manager/flowManager.type';
import { removeRequestLoader, setRequestLoader } from '@store/common/common.actions';
import { DesConvenzioniGeneriche } from '@store/des-convenzioni-generiche/desConvenzioniGeneriche.types';
import { EntiConvenzionati } from '@store/enti-convenzionati/entiConvenzionati.types';
import { TipoContratto } from '@store/tipi-contratto/tipiContratto.type';
import { ScontoAddizionale } from '@store/sconto-addizionale/scontoAddizionale.type';

const pendingRequests: string[] = [];

const getGlobalEntitities = async <Entity>(pageIndex: number, pageSize: number, urlPath: string, actualState: Entity[], sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }], force: boolean = false): Promise<Entity[] | boolean> => {

    let data: Entity[] | boolean = false;
    try {
        // Load actualState only the first time or if forced
        if (!actualState || actualState.length === 0 || force) {
            const sortSearch = createSortColumns(sortColumns)
            const url = `/${urlPath}?PageIndex=${pageIndex}&PageSize=${pageSize}${sortSearch}`;
            // avoid duplicate requests
            if (pendingRequests.findIndex((request) => request === url) === -1) {
                pendingRequests.push(url)
                const response = await AxiosAPI.get(url)
                pendingRequests.splice(pendingRequests.findIndex((request) => request === url), 1)
                data = response.data.data as Entity[];
            }
        }
    } catch (error: any) {
        console.error(error);
    }
    return data;
}

export const getGlobalCapiZona = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'nominativo', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {
    const data = await getGlobalEntitities<CapiZona>(1, 999, 'CapiZona', getState().global.capiZona, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalCapiZona(data as CapiZona[]))
    }
}

export const getGlobalCategorieMerceologiche = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<CategorieMerceologiche>(1, 999, 'CategorieMerceologiche', getState().global.categorieMerceologiche, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalCategorieMerceologice(data as CategorieMerceologiche[]))
    }
}

export const getGlobalCausaliIva = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<CausaliIva>(1, 999, 'CausaliIva', getState().global.causaliIva, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalCausaliIva(data as CausaliIva[]))
    }
}

export const getGlobalCodiciVelocita = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'simbolo', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<CodiciVelocita>(1, 999, 'CodiciVelocita', getState().global.codiciVelocita, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalCodiciVelocita(data as CodiciVelocita[]))
    }
}
export const getGlobalConvenzioniGeneriche = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<DesConvenzioniGeneriche>(1, 999, 'DesConvenzioniGeneriche', getState().global.convenzioniGeneriche, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalConvenzioniGeneriche(data as DesConvenzioniGeneriche[]))
    }
}

export const getGlobalDisegni = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Disegni>(1, 999999, 'Disegni', getState().global.disegni, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalDisegni(data as Disegni[]))
    }
}

export const getGlobalDistributori = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'ragioneSociale', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Distributori>(1, 999, 'AnagraficaDistributori', getState().global.distributori, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalDistributori(data as Distributori[]))
    }
}

export const getGlobalDivisioni = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Divisioni>(1, 999, 'Divisioni', getState().global.divisioni, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalDivisioni(data as Divisioni[]))
    }
}

export const getGlobalEntiConvenzionati = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'ragioneSociale', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<EntiConvenzionati>(1, 999, 'EntiConvenzionati', getState().global.entiConvenzionati, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalEntiConvenzionati(data as EntiConvenzionati[]))
    }
}

export const getGlobalIndiciDiCarico = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'indiceCarico', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<IndiciDiCarico>(1, 999, 'IndiciDiCarico', getState().global.indiciDiCarico, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalIndiciDiCarico(data as IndiciDiCarico[]))
    }
}

export const getGlobalLarghezzeCerchi = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'larghezza', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<LarghezzaCerchi>(1, 999, 'LarghezzaCerchi', getState().global.larghezzeCerchi, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalLarghezzeCerchi(data as LarghezzaCerchi[]))
    }
}

export const getGlobalLarghezzeNominali = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'larghezzaNominale', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<LarghezzaNominale>(1, 999, 'LarghezzeNominali', getState().global.larghezzeNominali, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalLarghezzeNominali(data as LarghezzaNominale[]))
    }
}

export const getGlobalLivelliListino = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<LivelloListino>(1, 999, 'LivelloListino', getState().global.livelliListino, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalLivelliListino(data as LivelloListino[]))
    }
}

export const getGlobalMarche = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Marca>(1, 999, 'MarcheAuto', getState().global.marche, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalMarche(data as Marca[]))
    }
}

export const getGlobalModelli = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Modello>(1, 9999, 'ModelliAuto', getState().global.modelli, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalModelli(data as Modello[]))
    }
}

export const getGlobalMonete = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Monete>(1, 999, 'Monete', getState().global.monete, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalMonete(data as Monete[]))
    }
}

export const getGlobalPagamenti = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Pagamenti>(1, 999, 'Pagamenti', getState().global.pagamenti, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalPagamenti(data as Pagamenti[]))
    }
}

export const getGlobalProduttori = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Produttori>(1, 999, 'Produttori', getState().global.produttori, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalProduttori(data as Produttori[]))
    }
}

export const getGlobalProgrammi = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Programmi>(1, 999, 'Programmi', getState().global.programmi, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalProgrammi(data as Programmi[]))
    }
}
export const getGlobalNazioni = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Nazioni>(1, 999, 'Nazioni', getState().global.nazioni, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalNazioni(data as Nazioni[]))
    }
}

export const getGlobalProvince = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Province>(1, 999, 'Province', getState().global.province, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalProvince(data as Province[]))
    }
}

export const getGlobalRegioni = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Regioni>(1, 999, 'Regioni', getState().global.regioni, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalRegioni(data as Regioni[]))
    }
}

export const getGlobalReti = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Reti>(1, 999, 'Reti', getState().global.reti, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalReti(data as Reti[]))
    }
}

export const getGlobalRuoli = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'name', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<MasterDataUserRole>(1, 999, 'MasterDataUser/Roles', getState().global.role, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalRuoli(data as MasterDataUserRole[]))
    }
}

export const getGlobalSemaphoresTasks = (force: boolean = false): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<string>(1, 999, 'FlowManager/Semaphore/Tasks', getState().global.semaphoresTasks?.map((item) => item.id), [], force);
    if (data !== false) {
        const sortedData: {
            id: string,
            description: string,
        }[] = (data as string[]).sort().map((item) => {
            return {
                id: item,
                description: item
            };
        });
        dispatch(setGlobalSemaphoresTasks(sortedData))
    }
}

export const getGlobalSerieGomme = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<SerieGomme>(1, 999, 'SerieGomme', getState().global.serieGomme, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalSerieGomme(data as SerieGomme[]))
    }
}

export const getGlobalServers = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'name', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Server>(1, 999, 'FlowManager/Server', getState().global.servers, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalServers(data as Server[]))
    }
}

export const getGlobalStagionalita = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Stagionalita>(1, 999, 'Stagionalita', getState().global.stagionalita, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalStagionalita(data as Stagionalita[]))
    }
}

export const getGlobalStrutture = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Strutture>(1, 999, 'Strutture', getState().global.strutture, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalStrutture(data as Strutture[]))
    }
}

export const getGlobalTasks = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'name', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<Task>(1, 999, 'FlowManager/Task', getState().global.tasks, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalTasks(data as Task[]))
    }
}

export const updateGlobalTasks = (sortColumns: SortModel[] = [{ field: 'name', sort: 'asc' }, { field: 'version', sort: 'asc' }]): AppThunk => async (dispatch) => {
    dispatch(setRequestLoader("updateGlobalTasks"));
    try {
        const sortSearch = createSortColumns(sortColumns)
        const response = await AxiosAPI.put(`/FlowManager/Task?PageIndex=1&PageSize=999${sortSearch}`)
        const data = response.data.data as Task[];
        dispatch(setGlobalTasks(data));
    } catch (error: any) {
        console.error(error);
    } finally {
        dispatch(removeRequestLoader("updateGlobalTasks"));
    }

}

export const getGlobalTipiArticolo = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<TipiArticolo>(1, 999, 'TipiArticolo', getState().global.tipiArticolo, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalTipiArticolo(data as TipiArticolo[]))
    }
}

export const getGlobalTipiCausaliIva = (force: boolean = false): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<string>(1, 999, 'CausaliIva/tipi', getState().global.tipiCausaliIva?.map((item) => item.id), [], force);
    if (data !== false) {
        const sortedData: {
            id: string,
            description: string,
        }[] = (data as string[]).sort().map((item) => {
            return {
                id: item,
                description: item
            };
        });
        dispatch(setGlobalTipiCausaliIva(sortedData))
    }
}

export const getGlobalTipiContrattoFlotte = (force: boolean = false, sortColumns: SortModel[] = [{ field: "enteConvenzionato", sort: "asc" },{ field: "nomeContratto", sort: "asc" }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<TipoContratto>(1, 999, 'AnagraficaContrattiFlotte', getState().global.tipiContrattoFlotte, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalTipiContrattoFlotte(data as TipoContratto[]))
    }
}

export const getGlobalTipiDocumento = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<TipoDocumenti>(1, 999, 'TipoDocumenti', getState().global.tipiDocumento, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalTipiDocumento(data as TipoDocumenti[]))
    }
}

export const getGlobalUnitaDiMisura = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'descrizione', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<UnitaDiMisura>(1, 999, 'UnitaDiMisura', getState().global.unitaDiMisura, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalUnitaDiMisura(data as UnitaDiMisura[]))
    }
}
export const getGlobalScontoAddizionale = (force: boolean = false, sortColumns: SortModel[] = [{ field: 'id', sort: 'asc' }]): AppThunk => async (dispatch, getState) => {

    const data = await getGlobalEntitities<ScontoAddizionale>(1, 999, 'ScontoAddizionale', getState().global.scontoAddizionale, sortColumns, force);
    if (data !== false) {
        dispatch(setGlobalScontoAddizionale(data as ScontoAddizionale[]))
    }
}
