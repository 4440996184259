import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomSelectProps } from "../CustomSelectProps";
import { globalTipiArticolo } from "@store/global/global.selector";
import { getGlobalTipiArticolo } from "@store/global/global.effects";
import { TipiArticolo } from "@store/tipi-articolo/tipiArticolo.types";

export interface CustomSelectTipiArticoloProps extends CustomSelectProps {
    codDivisione: string
}

export function SelectTipiArticolo(props: CustomSelectTipiArticoloProps) {

    const { codDivisione } = props;
    
    const { t } = useTranslation(['common']);
    const dispatch = useAppDispatch();

    const allTipiArticolo = useAppSelector(globalTipiArticolo);
    const [tipiArticolo, setTipiArticolo] = useState<TipiArticolo[]>([])

    useEffect(() => {
        let filteredTipiArticolo = allTipiArticolo;
        if (codDivisione) {
            filteredTipiArticolo = filteredTipiArticolo.filter(t => t.codDivisione === Number(codDivisione));
        }
        setTipiArticolo(filteredTipiArticolo);
    }, [allTipiArticolo, codDivisione]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getGlobalTipiArticolo())
    }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CustomSelect
            label={t(`common:tipiArticolo`)}
            data={tipiArticolo}
            descriptionKey={'descrizione'}
            valueKey={'codTipoArticolo'}
            {...props}
        />
    );
}
