import { AxiosIDP } from "@store/axios.config";
import { AppThunk } from "../store";
import i18n from "i18next";

import routes from "@utilities/routes";
import { push } from "connected-react-router";

import {
  setRequestLoader,
  removeRequestLoader,
  closeCustomAlert,
} from "@store/common/common.actions";
import { setError, setInfo } from "@store/common/common.effects";
import {
  loginRequest,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  setAuthError,
} from "./auth.actions";
import Auth from "@utilities/auth";

export const login =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    dispatch(loginRequest());
    dispatch(setRequestLoader("login"));
    dispatch(closeCustomAlert());

    try {
      const response = await AxiosIDP.post(`/Authentication/Login`, {
        email,
        password,
      });

      dispatch(loginSuccess(response.data));
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("authToken", response.data.token);

      dispatch(push(routes.dashboard));
    } catch (error) {
      dispatch(authError(error));
    } finally {
      dispatch(removeRequestLoader("login"));
    }
  };

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(logoutRequest());
  dispatch(setRequestLoader("logout"));
  dispatch(closeCustomAlert());

  try {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");

    dispatch(logoutSuccess());

    dispatch(push(routes.auth.login));
    Auth.destroy();
  } catch (error) {
    dispatch(authError(error));
  } finally {
    dispatch(removeRequestLoader("logout"));
  }
};

export const forgotPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestLoader("forgotPassword"));

    try {
      dispatch(setRequestLoader("forgotPassword"));

      await AxiosIDP.post(`/Account/ForgotPassword`, {
        email,
      });
      dispatch(setInfo({ message: i18n.t("common:message:emailSend") }));
      dispatch(push(routes.dashboard));
    } catch (error) {
      dispatch(setError({ message: i18n.t("common:message:emailNotFound") }));
    } finally {
      dispatch(removeRequestLoader("forgotPassword"));
    }
  };

const authError =
  (error: any): AppThunk =>
  async (dispatch) => {
    dispatch(setAuthError());
    if (error.response) {
      if (error.response) {
        dispatch(setError({ message: error.response.data.message }));
      } else {
        console.error(error);
        dispatch(setError({ message: i18n.t("common:message:genericError") }));
      }
    } else {
      dispatch(setError({ message: error.message }));
    }
  };
