import {
  AdvancedArticleSearch,
  ConfirmAction,
  ConfirmActionPropsData,
  CustomHeaderPage,
} from "@components/index";
import { putFlgInEstinzione } from "@store/articoli/articoli.effects";
import { Articoli } from "@store/articoli/articoli.types";
import { FieldFilter } from "@store/common/common.types";
import { useAppDispatch } from "@store/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Auth from "@utilities/auth";

export function ArticoliFuoriProduzione() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["articoliFuoriProduzionePage", "common"]);

  const [buttonLabel, setButtonLabel] = useState<string>(
    "Metti fuori produzione"
  );
  const [selectable, setSelectable] = useState<boolean>(false);
  const [flgInEstinzione, setFlgInEstinzione] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  const handleSelectArticles = (articles: Articoli[]) => {
    setConfirmAction({
      isOpen: true,
      title: t("common:message:defaultConfirmationTitle"),
      subtitle: t("common:message:defaultConfirmationMessage"),
      confirmAction: () => {
        dispatch(
          putFlgInEstinzione(
            articles.map((article) => article.codArticolo),
            flgInEstinzione
          )
        );
      },
    });
  };

  const handleActiveFiltersChange = (activeFilters: {
    [key: string]: FieldFilter;
  }) => {
    if (activeFilters.fuoriProd && activeFilters.fuoriProd.value) {
      setSelectable(true);
      const label =
        activeFilters.fuoriProd.value === "true"
          ? t("articoliFuoriProduzionePage:rimettiInProduzione")
          : t("articoliFuoriProduzionePage:mettiFuoriProduzione");
      setButtonLabel(label);
      setFlgInEstinzione(activeFilters.fuoriProd.value === "false");
    } else {
      setSelectable(false);
    }
  };

  return (
    <div>
      <CustomHeaderPage title={`${t("articoliFuoriProduzionePage:title")}`} />
      <AdvancedArticleSearch
        selectable={Auth.hasAccess("save-articoli") ? selectable : false}
        onSelectArticles={handleSelectArticles}
        selectButtonLabel={buttonLabel}
        onActiveFiltersChange={handleActiveFiltersChange}
        showDataUltimoListino
      />
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
    </div>
  );
}
