import React, { FunctionComponent, Fragment, useEffect, useState } from 'react';
import { Box, Button, Collapse, Grid, IconButton, Paper } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { FieldFilter } from "@store/common/common.types";
import { ChipFilter } from '@components/chip-filter/ChipFilter';
import FilterIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconFilter: {
        borderRadius: '100%',
        width: '2.5rem',
        height: '2.5rem',
        marginLeft: '1.5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
}));

interface FiltersAccordionProps {
    multiSearchFilter?: JSX.Element,
    searchFilters?: JSX.Element,
    filters: {
        filterFields: { [key: string]: FieldFilter },
        activeFilters: { [key: string]: FieldFilter }
    },
    setFilters: (value: React.SetStateAction<{
        filterFields: {
            [key: string]: FieldFilter;
        };
        activeFilters: {
            [key: string]: FieldFilter;
        };
    }>) => void,
    setPage?: (page: number) => void,
    forceFirstUpdate?: boolean
}

export const FiltersAccordion: FunctionComponent<FiltersAccordionProps> = (props) => {

    const { multiSearchFilter, searchFilters, filters, forceFirstUpdate = true, setFilters, setPage } = props;

    const [isOpen, setIsOpen] = useState(true);
    const classes = useStyles();

    const { t } = useTranslation(['common'])

    useEffect(() => {
        if (forceFirstUpdate) {
            handleFilterSubmit()
        }
    }, [forceFirstUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleFilterSubmit = (e?: React.SyntheticEvent): void => {
        e?.preventDefault();

        let currentActiveFilter: { [key: string]: FieldFilter } = {};
        const _filters = Object.entries(filters.filterFields);
        _filters.forEach(([key, field]) => {
            if (field.value === '') return

            if (field.alwaysActive) {
                // Controllo se esiste un nuovo filtro per questo parametro
                const overrideFilter = _filters.find(([key, filter]) => filter.param === field.param && !filter.alwaysActive);
                if (overrideFilter && overrideFilter[1].value.toString() !== '') return
            }

            currentActiveFilter[key] = field
        })

        if (setPage) {
            setPage(1);
        }

        setFilters(prevState => {
            return {
                ...prevState,
                activeFilters: currentActiveFilter,
            }
        })
    };

    /**
     * If id is specified, only that filter is reset
     */
    const handleFilterReset = (e?: React.SyntheticEvent, id?: string): void => {
        e?.preventDefault();

        let currentFilterFields = filters.filterFields;
        Object.entries(currentFilterFields).forEach(([key, value]) => {
            if (id && id === value.id && ! value.unDeletable) currentFilterFields[key].value = ''
            else if (!id && !value.alwaysActive && ! value.unDeletable) currentFilterFields[key].value = ''
        })

        setFilters(prevState => {
            return {
                ...prevState,
                filterFields: currentFilterFields,
                activeFilters: {},
            }
        })
    };

    const ButtonReset = () => {
        return (
            <Button
                variant="outlined"
                className='m-4'
                type='reset'
                onClick={(e) => { handleFilterReset(); handleFilterSubmit() }}
            >
                {t('common:resetFilters')}
            </Button>
        )
    }

    const ButtonSearch = () => {
        return (
            <Button
                className='my-4'
                type='submit'
            >
                {t('common:search')}
            </Button>
        )
    }

    return (
        <Fragment>
            <form onSubmit={handleFilterSubmit}>
                <Grid container>
                    {multiSearchFilter ? 
                        <Grid item xs={12}>
                            <Box display='flex' flexDirection='row' alignItems='center' >
                                {multiSearchFilter}
                                {
                                    searchFilters ?
                                        <Box className={classes.iconFilter} onClick={() => setIsOpen(!isOpen)}>
                                            <IconButton style={{
                                                border: "none",
                                                outline: "none"
                                            }} color='inherit' size="small">
                                                <FilterIcon />
                                            </IconButton>
                                        </Box>
                                        : null
                                }
                            </Box>
                        </Grid>
                        : null
                    }
                    <Grid item xs={12}>
                        <Collapse in={isOpen} timeout='auto' unmountOnExit>
                            {
                                searchFilters ?
                                    <Paper >
                                        {searchFilters}
                                        <Box className='pr-4' display='flex' justifyContent='flex-end'>
                                            <ButtonReset />
                                            <ButtonSearch />
                                        </Box>
                                    </Paper> :
                                    <Box display='flex' justifyContent='flex-end'>
                                        <ButtonReset />
                                        <ButtonSearch />
                                    </Box>
                            }

                        </Collapse>
                    </Grid>
                </Grid>
            </form>
            <Box display='flex' flexDirection='row' className="mb-4">
                {
                    Object.entries(filters.activeFilters).map(([key, value]) => {
                        return (!value.alwaysActive ?
                            <ChipFilter
                                key={`filter-tag-${key}`}
                                id={`filter-tag-${key}`}
                                label={value.label}
                                onDelete={value.unDeletable ? undefined : (e) => { handleFilterReset(e, value.id); handleFilterSubmit(e) }}
                            />
                            : null)
                    })
                }
            </Box>
        </Fragment>
    );
}