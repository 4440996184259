import { createReducer } from '@reduxjs/toolkit'
import {

    setConvenzioniDatiAggiuntiviIndex, resetConvenzioniDatiAggiuntiviShow, setConvenzioniDatiAggiuntiviShow, restConvenzioniDatiAggiuntiviIndex
} from './convenzioniDatiAggiuntivi.actions'
import { ConvenzioniDatiAggiuntiviShow, ConvenzioniDatiAggiuntiviIndex } from './convenzioniDatiAggiuntivi.types'
//import { ConvenzioniFlotteIndex, ConvenzioniFlotteShow } from './convenzioniFlotte.types';

const initialState: {
    index: ConvenzioniDatiAggiuntiviIndex,
    show: ConvenzioniDatiAggiuntiviShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}


export const convenzioniDatiAggiuntiviReducer = createReducer(initialState, (builder) => {
    builder
        //TODO add index
        .addCase(setConvenzioniDatiAggiuntiviShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload
                }
            }
        })
        .addCase(setConvenzioniDatiAggiuntiviIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(resetConvenzioniDatiAggiuntiviShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: undefined
                }
            }
        })
        .addCase(restConvenzioniDatiAggiuntiviIndex, (state, action) => {
            return {
                ...state,
                index: {
                    data: [],
                    meta: {
                        pagination: undefined
                    }
                },
            }
        })

})
