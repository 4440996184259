import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@store/common/common.types';
import { Programmi } from './programma.types';

export const setProgrammiIndex = createAction<{
    data: Programmi[]
    meta: {
        pagination: Pagination
    }
}>('programmi/index')

export const setProgrammiShow = createAction<Programmi>('programmi/show')