import { createReducer } from '@reduxjs/toolkit'
import {
    setCodVelocitaTipoArtIndex,
    setCodVelocitaTipoArtShow,
} from './codVelocitaTipoArt.actions'
import { CodVelocitaTipoArtIndex, CodVelocitaTipoArtShow } from './codVelocitaTipoArt.types'

const initialState: {
    index: CodVelocitaTipoArtIndex
    show: CodVelocitaTipoArtShow
} = {
    index: {
        data: [],
        meta: {
            pagination: undefined
        }
    },
    show: { data: undefined }
}

export const codVelocitaTipoArtReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCodVelocitaTipoArtIndex, (state, action) => {
            return {
                ...state,
                index: action.payload
            }
        })
        .addCase(setCodVelocitaTipoArtShow, (state, action) => {
            return {
                ...state,
                show: {
                    data: action.payload.data
                }
            }
        })
})

