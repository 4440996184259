import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@store/hooks";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  GridColDef,
  GridSelectionModelChangeParams,
} from "@material-ui/data-grid";
import {
  CustomPaginatedDataGrid,
  CustomHeaderPage,
  FiltersAccordion,
  CommonSpeedDial,
  ConfirmActionPropsData,
  ConfirmAction,
} from "@components/index";
import {
  getSemaphoresIndex,
  putSemaphore,
} from "@store/flow-manager/flowManager.effects";
import { semaphoresIndex } from "@store/flow-manager/flowManager.selector";
import { useIndexPage } from "@pages/common/UseIndexPage";
import { Semaphore } from "@store/flow-manager/flowManager.type";
import { Box, IconButton, TextField, Tooltip } from "@material-ui/core";
import {
  FieldFilter,
  FilterMethods,
  FilterTypes,
} from "@store/common/common.types";
import { ReactComponent as TrafficLightIcon } from "@assets/icons/Full/Traffic-light.svg";
import { ReactComponent as CircleIcon } from "@assets/icons/Full/Circle.svg";
import { setWarning } from "@store/common/common.effects";
import { SelectSemaphoresTasks } from "@components/selects";
import Auth from "@utilities/auth";

export function SemaphoresIndex() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["semaphoresIndexPage", "common"]);

  const [selectedSemaphores, setSelectedSemaphores] = useState<string[]>([]);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });

  const initialFilter: { [key: string]: FieldFilter } = {
    task: new FieldFilter(
      "task",
      t(`common:task`),
      FilterTypes.AND,
      "taskName",
      FilterMethods.EQUAL,
      ""
    ),
    entity: new FieldFilter(
      "entity",
      t(`common:entity`),
      FilterTypes.AND,
      "entity",
      FilterMethods.CONTAINS,
      ""
    ),
  };

  const {
    data,
    setData,
    filters,
    setFilters,
    setPage,
    setPageSize,
    sortColumns,
    setSortColumns,
    handleSelectChangeValue,
    handleTextFieldChangeValue,
  } = useIndexPage<Semaphore>(
    initialFilter,
    [{ field: "taskName", sort: "asc" }],
    getSemaphoresIndex,
    semaphoresIndex
  );

  const updateCurrentData = (ids: string[], green: boolean) => {
    const tmpSemaphores = [...data.data];
    ids.forEach((id) => {
      const index = tmpSemaphores.findIndex((item) => item.id === id);
      if (index !== -1) {
        const result = { ...tmpSemaphores[index] };
        result.green = green;
        tmpSemaphores[index] = result;
      }
    });

    setData((prevState) => {
      return {
        ...prevState,
        data: tmpSemaphores,
      };
    });
  };

  const updateSemaphores = (ids: string[], green: boolean) => {
    if (ids.length === 0) {
      dispatch(
        setWarning({ message: t("common:message:noSemaphoreSelected") })
      );
    } else {
      setConfirmAction({
        isOpen: true,
        title: t("common:message:defaultConfirmationTitle"),
        subtitle: t("common:message:defaultConfirmationMessage"),
        confirmAction: () => {
          dispatch(
            putSemaphore({
              ids: ids,
              green: green,
            })
          )
            .unwrap()
            .then(() => {
              updateCurrentData(ids, green);
            });
        },
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "taskName",
      headerName: t("common:task"),
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: "entity",
      headerName: t("common:entity"),
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: "light",
      headerName: t("common:semaphore"),
      width: 100,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <CellActions
          semaphore={params.row as Semaphore}
          postSemaphoreUpdate={(id: string, green: boolean) =>
            updateCurrentData([id], green)
          }
        />
      ),
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomHeaderPage title={t(`semaphoresIndexPage:title`)} />
        </Grid>
        <Grid item xs={12}>
          <FiltersAccordion
            searchFilters={
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                padding="1rem"
              >
                <Box className="px-2 w-full 2xl:w-4/12 xl:w-4/12 md:w-6/12">
                  <SelectSemaphoresTasks
                    id={filters.filterFields.task.id}
                    value={filters.filterFields.task.value}
                    onChange={handleSelectChangeValue}
                  />
                </Box>
                <Box className="px-2 w-full 2xl:w-4/12 xl:w-4/12 md:w-6/12">
                  <TextField
                    fullWidth
                    size="small"
                    id={filters.filterFields.entity.id}
                    value={filters.filterFields.entity.value}
                    onChange={(event) =>
                      handleTextFieldChangeValue(
                        event,
                        filters.filterFields.entity.id
                      )
                    }
                    label={filters.filterFields.entity.label}
                  />
                </Box>
              </Box>
            }
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <CustomPaginatedDataGrid
              data={data}
              columns={columns}
              getRowId={(row) => {
                return row.id;
              }}
              sortColumns={sortColumns}
              onChangePageSize={setPageSize}
              onChangeSortColumns={setSortColumns}
              onChangePage={setPage}
              checkboxSelection={true}
              onSelectionModelChange={(
                model: GridSelectionModelChangeParams
              ) => {
                setSelectedSemaphores(model.selectionModel as string[]);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      {Auth.hasAccess("save-semaphores") ? (
        <CommonSpeedDial
          otherActions={[
            {
              icon: <TrafficLightIcon fill="rgba(45, 201, 55, 1)" />,
              tooltip: t("common:startJobs"),
              onClick: () => updateSemaphores(selectedSemaphores, true),
            },
            {
              icon: <TrafficLightIcon fill="rgba(204, 50, 50, 1)" />,
              tooltip: t("common:stopJobs"),
              onClick: () => updateSemaphores(selectedSemaphores, false),
            },
          ]}
        />
      ) : null}
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
    </div>
  );
}

function CellActions(props: {
  semaphore: Semaphore;
  postSemaphoreUpdate: (id: string, green: boolean) => void;
}) {
  const { semaphore, postSemaphoreUpdate } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  const handleUpdateSemaphore = (id: string, green: boolean) => {
    dispatch(
      putSemaphore({
        ids: [id],
        green,
      })
    )
      .unwrap()
      .then(() => {
        postSemaphoreUpdate(id, green);
      });
  };

  return (
    <Fragment>
      <Tooltip title={`${t("common:stopJob")}`}>
        <IconButton
          disabled={!semaphore.green}
          style={{
            border: "none",
            outline: "none",
          }}
          color="primary"
          size="small"
          onClick={
            Auth.hasAccess("save-semaphores")
              ? () => handleUpdateSemaphore(semaphore.id, false)
              : undefined
          }
        >
          {semaphore.green ? (
            <CircleIcon width="1.5rem" height="1.5rem" fill="grey" />
          ) : (
            <CircleIcon
              width="1.5rem"
              height="1.5rem"
              fill="rgba(204, 50, 50, 1)"
            />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title={`${t("common:startJob")}`}>
        <IconButton
          disabled={semaphore.green}
          style={{
            border: "none",
            outline: "none",
          }}
          color="primary"
          size="small"
          onClick={
            Auth.hasAccess("save-semaphores")
              ? () => handleUpdateSemaphore(semaphore.id, true)
              : undefined
          }
        >
          {semaphore.green ? (
            <CircleIcon
              width="1.5rem"
              height="1.5rem"
              fill="rgba(45, 201, 55, 1)"
            />
          ) : (
            <CircleIcon width="1.5rem" height="1.5rem" fill="grey" />
          )}
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}
