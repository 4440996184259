import React, { CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { user } from '@store/auth/auth.selector'

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Avatar, Divider, ListItemIcon, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { ReactComponent as LogoutIcon } from '@assets/icons/Full/Logout.svg';
import { ReactComponent as UserIcon } from '@assets/icons/Full/User.svg';

import { loggedIn } from '@store/auth/auth.selector';
import { logout } from '@store/auth/auth.effects';
import { push } from 'connected-react-router'
import routes from '@utilities/routes';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    dorpDown: {
        root: {
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
            },
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        }
    },


}));
export function ProfileHeader() {
    const userData = useAppSelector(user);
    const userIsLogged = useAppSelector(loggedIn)
    const dispatch = useAppDispatch();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const pointer: CSSProperties = {
        cursor: 'pointer',
    }
    const handlerLogout = () => {
        dispatch(logout())

    }

    const handlerModificaProfilo = () => {
        dispatch(push(routes.profile));
    }

    return (
        <React.Fragment>
            {userIsLogged ?
                <Box className='flex flex-row mr-4 items-center pt-1'>

                    < Avatar alt={userData.name} src="/static/images/avatar/3.jpg" />
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}

                        PaperProps={{
                            elevation: 0,
                            classes: { root: classes.dorpDown },
                            style: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                transform: 'translate(+5%,40.7%)',
                            }


                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                        <MenuItem onClick={handlerModificaProfilo} style={{padding: '10px 16px 18px 16px'}}>
                            <ListItemIcon>
                                <UserIcon fontSize="small" />
                            </ListItemIcon>
                            Modifica profilo
                        </MenuItem>

                        <Divider />
                        <MenuItem onClick={handlerLogout} style={{padding: '18px 16px 10px 16px'}}>
                            <ListItemIcon>
                                <LogoutIcon className='transform -rotate-180' fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>        <div className='px-2 pr-16'  >
                        <Tooltip title="Account settings" style={pointer} >
                            <Typography onClick={handleClick} component='h6' variant='body2' className='font-bold' >{userData.name} {userData.surname}</Typography>
                        </Tooltip>

                    </div>
                    <LogoutIcon style={pointer} className='transform -rotate-180' onClick={handlerLogout} />
                    <div>
                    </div>
                </Box >
                : null
            }
        </React.Fragment >
    )
}

