import { createAction } from '@reduxjs/toolkit';
import { Articoli, ArticoliIndex, ArticoliShow } from './articoli.types';

export const setArticoliIndex = createAction<ArticoliIndex>('articoli/index')
export const setArticoliShow = createAction<ArticoliShow>('articoli/show')
export const resetArticoliIndex = createAction('articoli/index/reset')
export const resetArticoliShow = createAction('articoli/show/reset')
export const setRicercaArticoli = createAction<ArticoliIndex>('articoli/search')
export const resetRicercaArticoli = createAction('articoli/search/reset')
export const setLastInsertedArticle = createAction<Articoli>('articoli/last-insert')
export const resetLastInsertedArticle = createAction('articoli/last-insert/reset')